import { APIMiddleware } from "../middleware/api-middleware";
import { apiUrls } from "../common/api-urls";
import { UpdateCompanyPlanRequest } from "../types/request/update-company-plan-request";

const { PLANS_URL } = apiUrls;

export function getCompanyPlans() {
  const url = `${PLANS_URL}`;
  return APIMiddleware.get(url);
}

export function getPlanList(page: number, take: number) {
  const url = `${PLANS_URL}/list?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function getCompanyPlan(id: number) {
  const url = `${PLANS_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function updateCompanyPlan(id: number, req: UpdateCompanyPlanRequest) {
  const url = `${PLANS_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      name: req.name,
      price: req.price,
      forSale: req.forSale,
      salePrice: req.salePrice,
      visibilityPeriod: req.visibilityPeriod,
      advertisements: req.advertisements,
      modifyPost: req.modifyPost,
      questions: req.questions,
      profileTalent: req.profileTalent,
      profileExperience: req.profileExperience,
    },
  });
}
