import React, { Dispatch, SetStateAction, useState } from "react";
import { Package } from "../../../types/response/package-response";
import { Card, Col } from "react-bootstrap";
import { utils } from "../../../common/utils";
import "./PackageItem.css";
import BillDetailsModal from "../../bill-details-modal/BillDetailsModal";

interface PackageItemProps {
  plan: Package;
  openCulqi: any;
  setAmount: any;
  setPlan: Dispatch<SetStateAction<Package | undefined>>;
  billType: "receipt" | "bill";
  setBillType: Dispatch<SetStateAction<"receipt" | "bill">>;
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  businessName: string;
  setBusinessName: Dispatch<SetStateAction<string>>;
  ruc: string;
  setRuc: Dispatch<SetStateAction<string>>;
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
}

const PackageItem: React.FC<PackageItemProps> = ({
  plan,
  openCulqi,
  setAmount,
  setPlan,
  billType,
  setBillType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  businessName,
  setBusinessName,
  ruc,
  setRuc,
  address,
  setAddress,
}) => {
  const [showBillModal, setShowBillModal] = useState<boolean>(false);

  const handleOpenCulqi = (e: any) => {
    e.preventDefault();
    setShowBillModal(false);
    const amount = Math.round(
      parseFloat(plan.forSale ? plan.salePrice : plan.price) * 100
    );
    setAmount(amount);
    setPlan(plan);
    setTimeout(() => {
      if (amount !== 0) openCulqi();
    }, 500);
  };

  const visualizations =
    plan.type === "professional" ? plan.profileExperience : plan.profileTalent;

  const type =
    plan.type === "professional"
      ? "Talento Profesional"
      : "Talento Excelencia Académica";

  return (
    <>
      <Col xs={12} md={6} lg={4} className="mb-4">
        <Card className="package-card">
          <Card.Header
            className="plan-header text-center"
            onClick={() => setShowBillModal(true)}
          >
            <span>{plan.name}</span>
          </Card.Header>
          <Card.Body>
            <ul>
              <li className="mb-2">
                {`Válido para ${visualizations} ${
                  visualizations === 1 ? "visualización" : "visualizaciones"
                } de perfil del tipo ${type}`}
              </li>
            </ul>
            <div className="plan-container text-center">
              {plan.forSale && (
                <div className="plan-regular-price">
                  Precio regular: {utils.getPrice(plan.price)}
                </div>
              )}
              <h2 className="plan-price" onClick={() => setShowBillModal(true)}>
                {utils.getPrice(plan.forSale ? plan.salePrice : plan.price)}
              </h2>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <BillDetailsModal
        showBillModal={showBillModal}
        handleCloseBillModal={() => setShowBillModal(false)}
        billType={billType}
        setBillType={setBillType}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        businessName={businessName}
        setBusinessName={setBusinessName}
        ruc={ruc}
        setRuc={setRuc}
        address={address}
        setAddress={setAddress}
        handleOpenCulqi={handleOpenCulqi}
      />
    </>
  );
};

export default PackageItem;
