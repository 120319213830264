import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";

const { UPGRADE_REQUESTS_URL } = apiUrls;

export function getUpgradeRequests(page: number, take: number) {
  const url = `${UPGRADE_REQUESTS_URL}?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function getUpgradeRequest(id: number) {
  const url = `${UPGRADE_REQUESTS_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function removeUpgradeRequest(id: number) {
  const url = `${UPGRADE_REQUESTS_URL}/${id}`;
  return APIMiddleware.delete(url);
}

export function postUpgradeRequestDecision(id: number, decision: string) {
  const url = `${UPGRADE_REQUESTS_URL}/decision/${id}`;
  return APIMiddleware.post(url, { data: { decision } });
}
