import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import AdminProfilePurchasesForm from "./AdminProfilePurchasesForm";
import { ProfilePurchases } from "../../../types/response/profile-purchases";
import { getProfilePurchase } from "../../../api/profile-purchases.api";

const AdminProfilePurchasesSave: React.FC = () => {
  const [profilePurchase, setProfilePurchase] = useState<ProfilePurchases>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadProfilePurchase = async (id: string) => {
      try {
        const profilePurchaseData = (await getProfilePurchase(parseInt(id)))
          .data;
        setProfilePurchase(profilePurchaseData);
      } catch (error) {
        console.error("Error loading profile purchase:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadProfilePurchase(id);
    }
  }, [id]);

  return loading ? (
    <Loading />
  ) : (
    profilePurchase && <AdminProfilePurchasesForm item={profilePurchase} />
  );
};

export default AdminProfilePurchasesSave;
