import { useState } from "react";
import { resultType } from "../common/result-type";
import { sendExcelenceRequest } from "../api/applicant.api";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../common/notification-types";

export const useSendExcelenceRequest = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/applicant");
    setShowNotificationModal(true);
    setNotificationTitle("Su solicitud fue enviada");
    setNotificationText(
      "Por favor, espere a que nuestro equipo revise su solicitud"
    );
    setNotificationType(notificationTypes.SUCCESS);
  };

  const sendRequest = async (formData: FormData) => {
    setLoading(true);

    try {
      await sendExcelenceRequest(formData);
      setLoading(false);
      showSuccessMessage();
    } catch (ex: any) {
      setLoading(false);
      const message =
        ex.response.status === 409
          ? "Su usuario ya cuenta con una solicitud de excelencia. Por favor, espere a que la revisemos."
          : "Hubo un error al enviar la solicitud. Inténtelo con un documento más ligero.";
      setMessage(message);
      setType(resultType.error);
    }
  };

  return {
    sendRequest,
    loading,
    message,
    setMessage,
    type,
  };
};
