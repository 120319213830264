import { useState } from "react";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../common/notification-types";
import { ApplyJobRequest } from "../types/request/apply-job-request";
import { applyToAdvertisement } from "../api/application.api";

export const useApply = () => {
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/job-offers");
    setShowNotificationModal(true);
    setNotificationTitle("Postulación enviada");
    setNotificationText("La empresa ha recibido los datos de tu postulación.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = (ex: any) => {
    setShowNotificationModal(true);
    setNotificationTitle("¡Ups! Algo salió mal");
    const message =
      ex.response.status === 409
        ? "Ya cuenta con una postulación realizada a este anuncio."
        : "Hubo un problema al enviar la postulación. Inténtelo más tarde.";
    setNotificationText(message);
    setNotificationType(notificationTypes.ERROR);
  };

  const applyJobOffer = async (id: number, req: ApplyJobRequest) => {
    setLoading(true);
    try {
      await applyToAdvertisement(id, req);
      showSuccessMessage();
    } catch (ex) {
      showErrorMessage(ex);
    } finally {
      setLoading(false);
    }
  };

  return {
    applyJobOffer,
    loading,
  };
};
