import { useState } from "react";
import { resultType } from "../common/result-type";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../common/notification-types";
import { updateApplicantPlan } from "../api/career-boost.api";
import { UpdateApplicantPlanRequest } from "../types/request/update-applicant-plan-request";

export const useUserPlan = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/admin/user-plans");
    setShowNotificationModal(true);
    setNotificationTitle("Plan actualizado");
    setNotificationText(
      "Este cambio se verá reflejado en el plan para postulantes"
    );
    setNotificationType(notificationTypes.SUCCESS);
  };

  const updatePlan = async (id: number, req: UpdateApplicantPlanRequest) => {
    setLoading(true);
    try {
      await updateApplicantPlan(id, req);
      showSuccessMessage();
    } catch (ex) {
      setMessage(
        "Hubo un error al actualizar el plan. Por favor, inténtelo más tarde"
      );
      setType(resultType.error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updatePlan,
    loading,
    message,
    setMessage,
    type,
  };
};
