import { faBookmark, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  faUser as faUserRegular,
  faBookmark as faBookmarkRegular,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./SavedIcon.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Loading from "../loading/Loading";

interface SavedIconProps {
  active: boolean;
  onClick: (event: React.MouseEvent) => void;
  loading: boolean;
}

const SavedIcon: React.FC<SavedIconProps> = ({ active, onClick, loading }) => {
  return (
    <div
      className={`icon-container ${active ? "active" : "inactive"}`}
      onClick={onClick}
    >
      {loading ? (
        <Loading variant="white" />
      ) : (
        <>
          <FontAwesomeIcon
            icon={active ? (faUser as IconProp) : (faUserRegular as IconProp)}
            className="user-icon"
          />
          <FontAwesomeIcon
            icon={
              active
                ? (faBookmark as IconProp)
                : (faBookmarkRegular as IconProp)
            }
            className="bookmark-icon"
          />
        </>
      )}
    </div>
  );
};

export default SavedIcon;
