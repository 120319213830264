import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import EntityTable from "../../../components/admin/entity-table/EntityTable";
import DataTableButtons from "../../../components/admin/data-table-buttons/DataTableButtons";
import { CompanyPurchases } from "../../../types/response/company-purchases";
import { getCompanyPurchases } from "../../../api/company-purchases.api";
import { utils } from "../../../common/utils";

const AdminCompanyPurchases: React.FC = () => {
  const [companyPurchases, setCompanyPurchases] = useState<CompanyPurchases[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);

  const take = 10;

  useEffect(() => {
    const loadCompanyPurchases = async () => {
      try {
        const response = await getCompanyPurchases(page, take);
        const paginatedData = response.data;
        setCompanyPurchases(paginatedData.data);
        setItemsCount(paginatedData.meta.itemCount);
      } catch (error) {
        console.error("Error loading news", error);
      } finally {
        setLoading(false);
      }
    };

    loadCompanyPurchases();
  }, [page]);

  const renderNews = () => {
    return companyPurchases.map((companyPurchase) => (
      <tr key={companyPurchase.id}>
        <td>{companyPurchase.id}</td>
        <td>{companyPurchase.companyName}</td>
        <td>{companyPurchase.planName}</td>
        <td>{utils.formatDate(companyPurchase.createdAt)}</td>
        <td>S/. {companyPurchase.price}</td>
        <td>{companyPurchase.billGenerated ? "Realizada" : "Pendiente"}</td>
        <td>
          <DataTableButtons
            itemId={companyPurchase.id}
            handleView={(id: number) =>
              navigate(`/admin/company-purchases/${id}`)
            }
          />
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <EntityTable
          title={"Compras de empresas"}
          headers={[
            "ID",
            "Empresa",
            "Plan",
            "Fecha de compra",
            "Precio",
            "Estado",
            "Acciones",
          ]}
          renderData={renderNews}
          page={page}
          setPage={setPage}
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
        />
      )}
    </Container>
  );
};

export default AdminCompanyPurchases;
