const BASE_URL = "https://api.chambasihay.com.pe";

export const apiUrls = {
  BASE_URL,
  AUTH_URL: `${BASE_URL}/auth`,
  APPLICANT_URL: `${BASE_URL}/applicants`,
  EXPERIENCE_URL: `${BASE_URL}/applicants/experience`,
  STUDY_URL: `${BASE_URL}/applicants/study`,
  LANGUAGE_URL: `${BASE_URL}/applicants/language`,
  CAREERS_URL: `${BASE_URL}/careers`,
  PLANS_URL: `${BASE_URL}/plans`,
  CAREER_BOOSTS_URL: `${BASE_URL}/career-boosts`,
  USER_PURCHASES_URL: `${BASE_URL}/user-purchases`,
  COMPANY_PURCHASES_URL: `${BASE_URL}/company-purchases`,
  COMPANY_URL: `${BASE_URL}/companies`,
  ADVERTISEMENTS_URL: `${BASE_URL}/advertisements`,
  CATALOGUE_URL: `${BASE_URL}/catalogue`,
  UPGRADE_REQUESTS_URL: `${BASE_URL}/upgrade-requests`,
  USERS_URL: `${BASE_URL}/users`,
  INDUSTRIES_URL: `${BASE_URL}/industries`,
  APPLICATION_URL: `${BASE_URL}/applications`,
  PROFILE_VIEW_URL: `${BASE_URL}/profile-views`,
  PACKAGES_URL: `${BASE_URL}/profile-view-packages`,
  PROFILE_PURCHASES_URL: `${BASE_URL}/profile-purchases`,
  NEWS_URL: `${BASE_URL}/news`,
};
