import React, {
  createContext,
  useReducer,
  useEffect,
  ReactNode,
  Dispatch,
} from "react";
import { getToken } from "../service/token-service";
import { getUserData } from "../service/user-service";

interface AuthPayload {
  token: string;
  name: string;
  role: string;
}

interface AuthState {
  user: null | AuthPayload;
  loading: boolean;
}

interface AuthAction {
  type: "LOGIN" | "LOGOUT";
  payload?: AuthPayload;
}

interface AuthContextProviderProps {
  children: ReactNode;
}

const initialState: AuthState = {
  user: null,
  loading: true,
};

interface AuthContextType extends AuthState {
  dispatch: Dispatch<AuthAction>;
}

export interface UserData {
  name: string;
  role: string;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  dispatch: () => null,
});

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload ?? null, loading: false };
    case "LOGOUT":
      return { user: null, loading: false };
    default:
      return state;
  }
};

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const token = await getToken();
      const userData = await getUserData();

      if (token && userData) {
        dispatch({
          type: "LOGIN",
          payload: {
            token: token,
            name: userData.name,
            role: userData.role,
          },
        });
      } else {
        dispatch({
          type: "LOGOUT",
        });
      }
    };

    fetchData();
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
