import React from "react";
import { Col, Row } from "react-bootstrap";
import SavedIcon from "../saved-icon/SavedIcon";

interface ToggleSavedApplicantsProps {
  savedFilter?: boolean;
  setSavedFilter: (f: boolean) => void;
}

const ToggleSavedApplicants: React.FC<ToggleSavedApplicantsProps> = ({
  savedFilter,
  setSavedFilter,
}) => {
  const handleOnClick = () => {
    setSavedFilter(!savedFilter);
  };
  return (
    <div
      className={`${
        savedFilter ? "bg-main text-white" : "border-main text-main"
      } generic-card mt-3 mb-3 ps-2 pe-2 pt-1 cursor-pointer`}
      onClick={handleOnClick}
    >
      <div className="p-2">
        <Row>
          <Col className="text-center" xs={9} sm={9} md={9} lg={9}>
            <span className="font-bold">Perfiles guardados</span>
          </Col>
          <Col xs={3} sm={3} md={3} lg={3}>
            <SavedIcon
              active={!savedFilter}
              onClick={() => {}}
              loading={false}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ToggleSavedApplicants;
