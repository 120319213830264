import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";

import React from "react";
import { useNavigate } from "react-router-dom";

interface ProfileViewNotAuthorizedModalProps {
  showProfileUnauthorizedModal: boolean;
  handleCloseProfileUnauthorizedModal: (() => void) | undefined;
}

const ProfileViewNotAuthorizedModal: React.FC<
  ProfileViewNotAuthorizedModalProps
> = ({ showProfileUnauthorizedModal, handleCloseProfileUnauthorizedModal }) => {
  const navigate = useNavigate();

  return (
    <Modal
      show={showProfileUnauthorizedModal}
      onHide={handleCloseProfileUnauthorizedModal}
      centered
      data-bs-theme="light"
    >
      <ModalHeader closeButton>Acción no autorizada</ModalHeader>
      <ModalBody>
        Para poder visualizar los perfiles, por favor, inicie sesión como
        empresa
      </ModalBody>
      <ModalFooter>
        <Button variant="danger" onClick={handleCloseProfileUnauthorizedModal}>
          Seguir navegando
        </Button>
        <Button
          variant="second"
          onClick={() => navigate("/auth?userType=company&authType=login")}
        >
          Iniciar sesión
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProfileViewNotAuthorizedModal;
