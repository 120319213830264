import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Industry } from "../../../types/response/industry";
import { Container } from "react-bootstrap";
import Loading from "../../../components/loading/Loading";
import EntityTable from "../../../components/admin/entity-table/EntityTable";
import DataTableButtons from "../../../components/admin/data-table-buttons/DataTableButtons";
import { getIndustriesList } from "../../../api/industries.api";

const AdminIndustries: React.FC = () => {
  const [industries, setIndustries] = useState<Industry[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);

  const take = 10;

  useEffect(() => {
    const loadIndustries = async () => {
      try {
        const response = await getIndustriesList(page, take);
        const paginatedData = response.data;
        setIndustries(paginatedData.data);
        setItemsCount(paginatedData.meta.itemCount);
      } catch (error) {
        console.error("Error loading industries", error);
      } finally {
        setLoading(false);
      }
    };

    loadIndustries();
  }, [page]);

  const renderIndustries = () => {
    return industries.map((industry) => (
      <tr key={industry.id}>
        <td>{industry.id}</td>
        <td>{industry.name}</td>
        <td>{industry.careersCount}</td>
        <td>
          <DataTableButtons
            itemId={industry.id}
            handleGo={(id: number) =>
              navigate(`/admin/industries/careers/${id}`)
            }
            handleView={(id: number) => navigate(`/admin/industries/${id}`)}
          />
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <EntityTable
          title={"Industrias"}
          headers={["ID", "Nombre", "Carreras", "Acciones"]}
          renderData={renderIndustries}
          page={page}
          setPage={setPage}
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
        />
      )}
    </Container>
  );
};

export default AdminIndustries;
