import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";

const { CATALOGUE_URL } = apiUrls;

export function getCatalogue(page: number, take: number, filters?: any) {
  const url = `${CATALOGUE_URL}/applicants?page=${page}&take=${take}`;
  return APIMiddleware.post(url, { noAuthToken: true, data: filters });
}

export function getStatistics() {
  const url = `${CATALOGUE_URL}/statistics`;
  return APIMiddleware.get(url, { noAuthToken: true });
}
