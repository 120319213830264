import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import AdminUsersForm from "./AdminUsersForm";
import { UpdateUserRequest } from "../../../types/request/update-user-request";
import { getUser } from "../../../api/user.api";

const AdminUsersSave: React.FC = () => {
  const [user, setUser] = useState<UpdateUserRequest>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadUser = async (id: string) => {
      try {
        const userData = (await getUser(parseInt(id))).data;
        setUser(userData);
      } catch (error) {
        console.error("Error loading user:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadUser(id);
    }
  }, [id]);

  return loading ? <Loading /> : user && <AdminUsersForm item={user} />;
};

export default AdminUsersSave;
