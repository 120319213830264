import React, { useEffect, useState } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogout";
import { roles } from "../../common/roles";
import logo from "../../assets/images/logo/logo.png";
import "./Header.css";
import { UserData } from "../../context/AuthContext";

const Header: React.FC = () => {
  const { logout } = useLogout();

  const { user } = useAuthContext();

  const [userData, setUserData] = useState<UserData | null>(null);
  const [showTalentDropdown, setShowTalentDropdown] = useState(false);
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);

  useEffect(() => {
    if (user) {
      setUserData({ name: user.name, role: user.role });
    } else {
      setUserData(null);
    }
  }, [user]);

  const handleLogout = async (e: any) => {
    e.preventDefault();
    await logout();
  };

  return (
    <Navbar bg="main" variant="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/home">
          <img alt="logo" src={logo} className="brand-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navigationbar-nav" />
        <Navbar.Collapse id="navigationbar-nav">
          <Nav className="m-auto nav-header">
            {!userData ? (
              <>
                <Nav.Link href="/job-offers">Empleos</Nav.Link>
                <Nav.Link href="/catalogue">Catálogos</Nav.Link>
              </>
            ) : (
              <>
                {userData.role === roles.company ? (
                  <Nav.Link href="/catalogue">Catálogos</Nav.Link>
                ) : (
                  <>
                    <Nav.Link href="/job-offers">Empleos</Nav.Link>
                    <Nav.Link href="/catalogue">Catálogos</Nav.Link>
                  </>
                )}
                <Nav.Link href="/plans">Planes</Nav.Link>
              </>
            )}
          </Nav>
          {!userData ? (
            <Nav className="nav-button-link">
              <NavDropdown
                title="Talentos"
                id="talentDropdown"
                show={showTalentDropdown}
                onMouseEnter={() => setShowTalentDropdown(true)}
                onMouseLeave={() => setShowTalentDropdown(false)}
              >
                <NavDropdown.Item href="/auth?userType=applicant&authType=login">
                  Conectarme
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/auth?userType=applicant&authType=register">
                  Inscribirse
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Empresas"
                id="companyDropdown"
                show={showCompanyDropdown}
                onMouseEnter={() => setShowCompanyDropdown(true)}
                onMouseLeave={() => setShowCompanyDropdown(false)}
              >
                <NavDropdown.Item href="/auth?userType=company&authType=login">
                  Conectarme
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/auth?userType=company&authType=register">
                  Inscribirse
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          ) : (
            <Nav className="nav-button-link">
              <NavDropdown title={userData.name} id="navbarScrollingDropdown">
                {userData.role === roles.admin && (
                  <>
                    <NavDropdown.Item href="/admin/users">
                      Administración
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                  </>
                )}
                <NavDropdown.Item
                  href={
                    userData.role === roles.company ? "/company" : "/applicant"
                  }
                >
                  Mi Perfil
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>
                  Cerrar Sesión
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
