export const seniorities: any[] = [
  { id: 1, label: "Estudiante" },
  { id: 2, label: "Profesional" },
];

export const jobAdvertisementSeniorities: any[] = [
  { id: "student", label: "Estudiante" },
  { id: "professional", label: "Profesional" }
];

export const applicantsSeniorities: any[] = [
  { id: 1, label: "Estudiante" },
  { id: 2, label: "Profesional" }
];

export const allJobAdvertisementSeniorities: any[] = [
  { id: "student", label: "Estudiante" },
  { id: "professional", label: "Profesional" },
  { id: "excelence", label: "Excelencia" }
];