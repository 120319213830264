import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import DotsDecorator from "../../home/dots-decorator/DotsDecorator";
import { getActivity } from "../../../api/applicant.api";
import Loading from "../../loading/Loading";

const ApplicantActivity: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [applications, setApplications] = useState<number>(0);
  const [profileViews, setProfileViews] = useState<number>(0);
  const [daysRemaining, setDaysRemaining] = useState<number>(0);

  const loadApplicantActivity = async () => {
    try {
      const activity = (await getActivity()).data;
      setApplications(activity.applications);
      setProfileViews(activity.profileViews);
      setDaysRemaining(activity.daysRemaining);
    } catch (error) {
      console.error("Error loading activity:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadApplicantActivity();
  }, []);

  return (
    <div className="content-card p-4">
      <Row className="justify-content-center">
        <h4 className="text-center">Mi Actividad</h4>
        <DotsDecorator width={10} height={10} center={true} />
      </Row>
      <div className="pt-4">
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <p>
              <span className="activity-value">{applications}</span>{" "}
              Postulaciones
            </p>
            <p>
              <span className="activity-value">{profileViews}</span>{" "}
              Visualizaciones
            </p>
            {daysRemaining !== 0 && (
              <p>
                <span className="activity-value">{daysRemaining}</span> Días
                premium restantes
              </p>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ApplicantActivity;
