import React, { useEffect, useState, useRef } from "react";
import "./Counter.css";

interface CounterProps {
  end: number;
}

const Counter: React.FC<CounterProps> = ({ end }) => {
  const [count, setCount] = useState(0);
  const [startCounting, setStartCounting] = useState(false);
  const counterRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setStartCounting(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (startCounting) {
      const increment = end / (2000 / 20);

      timer = setInterval(() => {
        setCount((prevCount) => {
          const nextCount = prevCount + increment;
          if (nextCount >= end) {
            clearInterval(timer);
            return end;
          }
          return nextCount;
        });
      }, 20);
    }

    return () => clearInterval(timer);
  }, [startCounting, end]);

  return (
    <span className="count-label" ref={counterRef}>
      {Math.round(count)}
    </span>
  );
};

export default Counter;
