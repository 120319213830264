import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./AuthUserFormSwitch.css";

interface UserType {
  userType: "applicant" | "company" | null;
  setUserType: (userType: "applicant" | "company") => void;
}

const AuthUserFormSwitch: React.FC<UserType> = ({ userType, setUserType }) => {
  const [isChecked, setIsChecked] = useState(userType === "company");

  useEffect(() => {
    setIsChecked(userType === "company");
  }, [userType]);

  const handleSwitchChange = () => {
    const newUserType = isChecked ? "applicant" : "company";
    setIsChecked(!isChecked);
    setUserType(newUserType);
  };

  return (
    <div className={`${isChecked ? "text-black" : "text-white"} mt-3`}>
      <Row>
        <Col className="text-end">
          <span
            className="cursor-pointer"
            onClick={() => setUserType("applicant")}
          >
            Talentos
          </span>
        </Col>
        <Col>
          <Form.Check
            type="switch"
            id="auth-switch"
            checked={isChecked}
            onChange={handleSwitchChange}
          />
        </Col>
        <Col className="text-start">
          <span
            className="cursor-pointer"
            onClick={() => setUserType("company")}
          >
            Empresas
          </span>
        </Col>
      </Row>
      <Row className="mt-2">
        <hr className="hr-auth" />
      </Row>
    </div>
  );
};

export default AuthUserFormSwitch;
