import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import logo from "../../assets/images/logo/logo-primary.png";

const CokiesPolicyMain: React.FC = () => {
  return (
    <Row className="justify-content-center pt-4">
      <Col xs={12} md={6}>
        <div>
          <a className="d-flex justify-content-center mb-4" href="/">
            <img alt="Logo Chamba Sí Hay" src={logo} width={133} height={47} />
          </a>
        </div>
        <Card className="shadow-sm mb-4">
          <Card.Body>
            <p className="text-center">
              <strong>
                <u>POLÍTICA DE COOKIES</u>
              </strong>
            </p>
            <div className="text-justify">
              <article>
                <p>
                  Mediante el presente documento, CHAMBA SI HAY S.A.C. (la
                  “Empresa” o “Nosotros”), identificada con Registro Único de
                  Contribuyente No. 20612226424, con domicilio en, Lima, Perú,
                  informa y solicita el consentimiento a los Usuarios de la
                  plataforma “Chamba Sí Hay”, su dominio web{" "}
                  <a href="https://chambasihay.com.pe/">
                    https://ChambaSiHay.com.pe/
                  </a>
                  , sus subdominios y/u otros dominios (URLs) relacionados (en
                  adelante, "Chamba Sí Hay" o la "Plataforma") sobre la presente
                  Política de Cookies que se aplica cada vez que visita y/o
                  utiliza Chamba Sí Hay.
                </p>
              </article>
              <article>
                <div className="font-bold">
                  1. <u>Generalidades</u>
                </div>
                <div>
                  <p>
                    Al igual que muchos sitios web y aplicaciones, la Plataforma
                    utiliza cookies y otras tecnologías de seguimiento como los
                    kits de desarrollo de software (SDK), los píxeles y el
                    almacenamiento local. Estas tecnologías permiten a la
                    Empresa almacenar información o acceder a la información
                    almacenada en los dispositivos de cualquier visitante y/o
                    usuario de Chamba Sí Hay (en adelante, los “Usuarios” o el
                    “Usuario”), a fin de habilitar ciertas funciones y
                    distinguir el dispositivo del Usuario del de los demás
                    Usuarios.
                  </p>
                  <p>
                    La mayoría de las aplicaciones utilizan tecnologías de
                    seguimiento para reconocer que un dispositivo es el mismo
                    que se ha utilizado anteriormente y poder comprender algunas
                    de sus características, de modo que se le pueda ofrecer
                    mejores funcionalidades, medir el rendimiento de la
                    Plataforma y, en general, mejorar su experiencia. En esta
                    política se explica el modo en que utilizamos estas
                    tecnologías y las opciones de las que dispone el Usuario
                    para controlarlas. Salvo indicación en contrario en esta
                    política, nuestra Política de Privacidad se aplica a los
                    datos que recogemos.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  2. <u>¿Qué tecnologías se utilizan en Chamba Sí Hay?</u>
                </div>
                <div>
                  <p>
                    La Plataforma utiliza las tecnologías descritas a
                    continuación:
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">2.1. Cookies</div>
                <div>
                  <p>
                    Las cookies son pequeños archivos de texto que permiten,
                    tanto a la Empresa como a nuestros proveedores de servicios
                    y socios, identificar el navegador o dispositivo del Usuario
                    de forma exclusiva para varios propósitos, incluidos los
                    descritos anteriormente. Normalmente, las cookies funcionan
                    asignando un número único a su dispositivo y se almacenan en
                    su navegador por los sitios web que visita, así como por los
                    proveedores de servicios externos y socios de dichos sitios
                    web.
                  </p>
                  <p>
                    Las cookies pueden permanecer en el ordenador o dispositivo
                    móvil durante diferentes periodos de tiempo. Algunas cookies
                    son las denominadas «cookies de sesión»; estas cookies
                    solamente existen mientras el navegador permanece abierto y
                    se eliminan automáticamente una vez que el Usuario lo
                    cierra. Las cookies de sesión no pueden utilizarse para
                    rastrear el navegador o el dispositivo del Usuario de un
                    sitio web a otro.
                  </p>
                  <p>
                    Existen otras cookies conocidas como «cookies persistentes»,
                    que sobreviven tras cerrar el navegador. La mayoría de las
                    cookies persistentes están configuradas para caducar de
                    forma automática transcurrido un tiempo determinado (por
                    ejemplo, unos días, semanas o meses). Cuando el Usuario
                    regrese a la Plataforma (o visite otros sitios web que
                    utilizan las mismas cookies) el proveedor del sitio web o
                    sus proveedores de servicios pueden reconocer las cookies
                    persistentes que han configurado y reconocer su dispositivo
                    de navegación.
                  </p>
                  <p>
                    En la Lista que se encuentra en el siguiente enlace
                    mostramos de forma esquematizada las cookies que usamos. Los
                    nombres, números y propósitos de estas cookies pueden
                    cambiar con el transcurso del tiempo. La Empresa
                    actualizará, al menos, anualmente dicho listado:
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">2.2. Almacenamiento local</div>
                <div>
                  <p>
                    Las aplicaciones y los sitios web crean archivos de
                    almacenamiento local para almacenar información a nivel
                    local en el dispositivo del Usuario. Son muy similares a las
                    cookies, pero pueden presentar propiedades ligeramente
                    distintas (por ejemplo, el almacenamiento local puede
                    utilizarse para almacenar mayor cantidad de información y
                    ésta puede almacenarse en un lugar diferente al de las
                    cookies en el dispositivo). Por lo general, el
                    almacenamiento local se utiliza para acelerar la
                    funcionalidad de las aplicaciones y de los sitios y recordar
                    sus preferencias.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  2.3. Interfaz de programación de aplicaciones (API)
                </div>
                <div>
                  <p>
                    Una API es un software que facilita que dos o más
                    aplicaciones se comuniquen entre sí. La Empresa las utiliza
                    para comunicarnos con nuestros proveedores de servicios.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  2.4. Kits de desarrollo de software (SDK)
                </div>
                <div>
                  <p>
                    Los SDK son códigos que permiten recopilar datos sobre el
                    dispositivo del Usuario, red e interacción con un sitio web.
                    Al igual que las cookies, suelen funcionar asignando a su
                    dispositivo un número exclusivo.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">2.5. Pixeles</div>
                <div>
                  <p>
                    Los píxeles son pequeños archivos de imagen invisibles de 1
                    píxel x 1 píxel integrados en determinadas páginas de un
                    sitio web. Pueden utilizarse para recopilar información
                    sobre su visita a dicha página web.
                  </p>
                  <p>
                    Al abrir una página de un sitio web con un píxel integrado,
                    ésta, de forma automática, ejecutará un código simple que
                    descarga el archivo de imagen 1x1 en su dispositivo. Al
                    mismo tiempo, el código también transmite determinada
                    información sobre el dispositivo del Usuario y sus acciones
                    en el sitio web y/o la Plataforma. El interés del Usuario en
                    el sitio web y/o la Plataforma puede registrarse y
                    utilizarse con fines analíticos o publicitarios.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  3. <u>Finalidades de tecnologías de seguimiento</u>
                </div>
                <div>
                  <p>
                    Las tecnologías de seguimiento que la Empresa y nuestros
                    socios utilizamos en relación con las prestaciones de Chamba
                    Sí Hay pueden agruparse según las siguientes finalidades.
                    Algunas de estas finalidades son «fundamentales» para
                    prestarle los servicios que el Usuario ha solicitado como,
                    por ejemplo, permitirle acceder a zonas seguras de la
                    Plataforma. La siguiente es una lista de las diferentes
                    finalidades de tecnologías de seguimiento que utilizamos:
                  </p>
                  <p>
                    a) <u>Identificación y seguridad</u>: reconocemos al Usuario
                    cuando vuelve a la Plataforma con el fin de asegurar que es
                    el usuario correcto y evitar actividades que infrinjan
                    nuestras políticas.
                  </p>
                  <p>
                    b) <u>Recordar las preferencias del Usuario</u>: La Empresa
                    requiere recordar la configuración que el Usuario ha elegido
                    en la Plataforma de modo que opere como el Usuario desea.
                  </p>
                  <p>
                    c) <u>Funcionalidad</u>: las tecnologías de seguimiento de
                    funcionalidad ayudan a que Chamba Sí Hay opere de manera más
                    eficiente y a mejorar y optimizar la experiencia del
                    Usuario.
                  </p>
                  <p>
                    d) <u>Análisis</u>: La Empresa utiliza tecnologías de
                    seguimiento analíticas para analizar cómo el Usuario utiliza
                    Chamba Sí Hay, lo que incluye qué páginas y/o secciones
                    visita con más frecuencia, cómo interactúa con el contenido,
                    la medición de los errores que se producen y la realización
                    de pruebas de distintas ideas de diseño. La información se
                    utiliza para informar y evaluar sus actividades y patrones
                    como Usuario de la Plataforma.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  4.{" "}
                  <u>
                    ¿Existen terceros que utilicen estas tecnologías en la
                    Plataforma?
                  </u>
                </div>
                <div>
                  <p>
                    Las tecnologías de seguimiento pueden ser de «origen» o de
                    «terceros». Las tecnologías de seguimiento de origen son
                    aquellas que la Empresa establece y utiliza directamente.
                    Las tecnologías de seguimiento de terceros han sido
                    establecidas por terceros (como nuestros proveedores de
                    servicios de análisis). Encontrará una lista de estos
                    terceros en la siguiente lista:
                  </p>
                  <p>
                    a) Google Analytics <br />- Categoría de datos:
                    Identificadores de dispositivos para recoger y almacenar
                    información tal como el número de sesiones por usuario,
                    duración de la sesión, sistema operativo, modelos de los
                    dispositivos, geografía, lanzamientos iniciales y
                    actualizaciones de las aplicaciones. <br />- Sitio web:
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  5. <u>¿Cómo el Usuario puede controlar estas tecnologías?</u>
                </div>
                <div>
                  <p>
                    Como se ha indicado anteriormente, estas tecnologías
                    funcionan automáticamente en los dispositivos del Usuario,
                    siendo que algunos usos son esenciales para el
                    funcionamiento de la Plataforma y, por ende, no pueden
                    desactivarse.
                  </p>
                  <p>
                    Sin embargo, usted puede controlar en cierta medida el modo
                    en que se utilizan estas tecnologías.
                  </p>
                  <p>
                    Al respecto, algunos de los socios con los que trabajamos
                    ofrecen la opción de darse de baja en sus propios sitios
                    web. Encontrará información al respecto en sus propias
                    políticas, cuyos enlaces figuran en nuestra Lista
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  6. <u>Cambios en la presente Política</u>
                </div>
                <div>
                  <p>
                    La Empresa actualizará la presente política periódicamente a
                    fin de adaptarla a los cambios que se produzcan en nuestras
                    prácticas. Cuando publiquemos cambios en la presente
                    política, modificaremos la fecha de «última actualización»
                    que aparece en la parte superior de la misma. Si realizamos
                    algún cambio importante en la forma en que recogemos,
                    utilizamos o compartimos la información mediante estas
                    tecnologías, le informaremos al respecto mediante la
                    publicación de un aviso destacado sobre los cambios en los
                    lugares en los que utiliza la Plataforma. Recomendamos al
                    Usuario que revise esta página ocasionalmente para
                    informarse de cualquier cambio introducido en la presente
                    política.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  7. <u>Póngase en contacto con nosotros</u>
                </div>
                <div>
                  <p>
                    Póngase en contacto con la Empresa a través de los canales
                    indicados en nuestra Política de Privacidad si desea hacer
                    alguna pregunta o comentario sobre esta política, o sobre
                    cualquier cuestión de privacidad en general.
                  </p>
                </div>
              </article>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CokiesPolicyMain;
