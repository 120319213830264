import { Col, Form, Row } from "react-bootstrap";
import "./AcceptsAdvertisement.css";

interface AcceptsAdvertisementProps {
  userType: "applicant" | "company" | null;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AcceptsAdvertisement: React.FC<AcceptsAdvertisementProps> = ({
  userType,
  checked,
  onChange,
}) => {
  return (
    <Row className="p-4">
      <Col>
        <Form.Check
          type={"checkbox"}
          id={"advertisement-checkbox"}
          label={
            userType === "applicant" ? (
              <>
                Acepto recibir correos de ofertas laborales y actualizaciones de
                la web
              </>
            ) : (
              <>Acepto recibir correos de actualizaciones de la web</>
            )
          }
          className={`${
            userType === "applicant" ? "text-white" : "text-black"
          }`}
          checked={checked}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};

export default AcceptsAdvertisement;
