import React, { useState } from "react";
import GenericForm from "../../../components/generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormInput from "../../../components/form-input/FormInput";
import { JobAdvertisementResponse } from "../../../types/response/job-advertisement-response";
import { useUpdateAdvertisement } from "../../../hooks/useUpdateAdvertisement";
import { advertisementStatuses } from "../../../common/advertisement-statuses";
import FormSelect from "../../../components/form-select/FormSelect";

interface AdminAdvertisementFormProps {
  item: JobAdvertisementResponse;
}

const AdminAdvertisementForm: React.FC<AdminAdvertisementFormProps> = ({
  item,
}) => {
  const [title, setTitle] = useState(item.title);
  const [description, setDescription] = useState(item.description);
  const [status, setStatus] = useState(item.status);

  const handleSave = async (e: any) => {
    e.preventDefault();
    await modifyAdvertisement(item.id, {
      status: status,
    });
  };

  const { modifyAdvertisement, loading, message, setMessage, type } =
    useUpdateAdvertisement();

  return (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={"Editar anuncio"}
      loading={loading}
      buttonText={"Actualizar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Título"}
          type={"text"}
          name={"name"}
          maxLength={100}
          required={false}
          readOnly={true}
          value={title}
          placeholder={""}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormSelect
          label={"Estado"}
          name={"status"}
          required={true}
          value={status}
          options={advertisementStatuses}
          onChange={(e) => setStatus(e.target.value)}
        />
      </Col>
      <Col xs={12} md={12} lg={12} className="mb-4">
        <FormInput
          label={"Descripción"}
          type={"textarea"}
          name={"name"}
          maxLength={100}
          required={false}
          readOnly={true}
          value={description}
          placeholder={""}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Col>
    </GenericForm>
  );
};

export default AdminAdvertisementForm;
