import { APIMiddleware } from "../middleware/api-middleware";
import { apiUrls } from "../common/api-urls";
import { UpdateApplicantPlanRequest } from "../types/request/update-applicant-plan-request";

const { CAREER_BOOSTS_URL } = apiUrls;

export function getApplicantPlans() {
  const url = `${CAREER_BOOSTS_URL}`;
  return APIMiddleware.get(url);
}

export function getCareerBoostList(page: number, take: number) {
  const url = `${CAREER_BOOSTS_URL}/list?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function getApplicantPlan(id: number) {
  const url = `${CAREER_BOOSTS_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function updateApplicantPlan(
  id: number,
  req: UpdateApplicantPlanRequest
) {
  const url = `${CAREER_BOOSTS_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      name: req.name,
      price: req.price,
      forSale: req.forSale,
      salePrice: req.salePrice,
    },
  });
}
