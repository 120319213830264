import React, { useEffect, useState } from "react";
import GenericForm from "../../generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormDatePicker from "../../form-date-picker/FormDatePicker";
import FormCheckbox from "../../form-checkbox/FormCheckbox";
import { useAddExperience } from "../../../hooks/useAddExperience";
import { useNavigate, useParams } from "react-router-dom";
import FormInput from "../../form-input/FormInput";
import { getApplicantExperience } from "../../../api/experience.api";
import Loading from "../../loading/Loading";

const SaveExperience: React.FC = () => {
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("");
  const [isCurrent, setIsCurrent] = useState(false);
  const [yearFrom, setYearFrom] = useState(0);
  const [monthFrom, setMonthFrom] = useState(0);
  const [yearTo, setYearTo] = useState(0);
  const [monthTo, setMonthTo] = useState(0);
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  const { addExperience, updateExperience, loading } = useAddExperience();

  const navigate = useNavigate();

  useEffect(() => {
    const loadExperience = async (id: string) => {
      try {
        const experienceData = (await getApplicantExperience(parseInt(id)))
          .data;
        setCompanyName(experienceData.companyName);
        setRole(experienceData.role);
        setIsCurrent(experienceData.isCurrent);
        setYearFrom(experienceData.yearFrom);
        setMonthFrom(experienceData.monthFrom);
        setYearTo(experienceData.yearTo);
        setMonthTo(experienceData.monthTo);
        setDescription(experienceData.description);
      } catch (error) {
        console.error("Error loading experiences:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      loadExperience(id);
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const handleSaveExperience = async (e: any) => {
    e.preventDefault();
    const experienceData = {
      companyName,
      role,
      description,
      isCurrent,
      yearFrom,
      monthFrom,
      yearTo,
      monthTo,
    };
    id
      ? await updateExperience(parseInt(id), experienceData)
      : await addExperience(experienceData);
    navigate("/applicant");
  };

  const handleChangeCurrentJob = (e: any) => {
    const isChecked = e.target.checked;
    setIsCurrent(isChecked);
    if (isChecked) {
      setYearTo(0);
      setMonthTo(0);
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <GenericForm
        handleSubmit={handleSaveExperience}
        formTitle="Agregar experiencia laboral"
        loading={loading}
        buttonText={id ? "Actualizar" : "Agregar"}
      >
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label="Empresa"
            type="text"
            name="companyName"
            maxLength={100}
            required={true}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder={""}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label="Rol"
            type="text"
            name="role"
            maxLength={100}
            required={true}
            value={role}
            onChange={(e) => setRole(e.target.value)}
            placeholder={""}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormDatePicker
            labelMonth="Mes de inicio"
            labelYear="Año de inicio"
            month={monthFrom}
            setMonth={(e) => setMonthFrom(parseInt(e.target.value))}
            year={yearFrom}
            setYear={(e) => setYearFrom(parseInt(e.target.value))}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormDatePicker
            labelMonth="Mes de fin"
            labelYear="Año de fin"
            month={monthTo}
            setMonth={(e) => setMonthTo(parseInt(e.target.value))}
            year={yearTo}
            setYear={(e) => setYearTo(parseInt(e.target.value))}
            disabled={isCurrent}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4"></Col>
        <Col xs={12} md={6} lg={6} className="mb-4 text-white">
          <FormCheckbox
            type="checkbox"
            label="Es mi empleo actual"
            checked={isCurrent}
            onChange={(e) => handleChangeCurrentJob(e)}
          />
        </Col>
        <Col xs={12} md={12} lg={12} className="mb-4">
          <FormInput
            label="Descripción"
            type="textarea"
            name="description"
            maxLength={1000}
            required={true}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={""}
          />
        </Col>
      </GenericForm>
    </div>
  );
};

export default SaveExperience;
