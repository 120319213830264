export const contracts: any[] = [
  {
    id: "Contrato a tiempo determinado",
    label: "Contrato a tiempo determinado",
  },
  {
    id: "Contrato a tiempo indeterminado",
    label: "Contrato a tiempo indeterminado",
  },
  { id: "Contrato a tiempo parcial", label: "Contrato a tiempo parcial" },
  { id: "Contrato por servicios", label: "Contrato por servicios" },
];

export const contractTypes: any[] = [
  { id: "Contrato a tiempo determinado", label: "Tiempo determinado" },
  { id: "Contrato a tiempo indeterminado", label: "Tiempo indeterminado" },
  { id: "Contrato a tiempo parcial", label: "Tiempo parcial" },
  { id: "Contrato por servicios", label: "Por servicios" },
];
