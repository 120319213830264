import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form } from "react-bootstrap";

interface DataTableFilterProps {
  handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DataTableFilter: React.FC<DataTableFilterProps> = ({
  handleFilterChange,
}) => {
  return (
    <Form.Group controlId="formSearch">
      <div className="search-bar-group input-group mb-3 align-items-center">
        <FontAwesomeIcon className="search-icon" icon={faSearch} />
        <input
          type="text"
          className="form-control search-input"
          placeholder=""
          onChange={(e) => handleFilterChange(e)}
        />
      </div>
    </Form.Group>
  );
};

export default DataTableFilter;
