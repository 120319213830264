import React, { Dispatch, SetStateAction } from "react";
import {
  Col,
  Form,
  Image,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "react-bootstrap";
import FormInput from "../form-input/FormInput";
import "./BillDetailsModal.css";
import logoPrimary from "../../assets/images/logo/logo-primary.png";
import BillDetailsSwitch from "./bill-details-switch/BillDetailsSwitch";
import FormButton from "../form-button/FormButton";

interface BillDetailsProps {
  showBillModal: boolean;
  handleCloseBillModal: (() => void) | undefined;
  billType: "receipt" | "bill";
  setBillType: Dispatch<SetStateAction<"receipt" | "bill">>;
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  businessName: string;
  setBusinessName: Dispatch<SetStateAction<string>>;
  ruc: string;
  setRuc: Dispatch<SetStateAction<string>>;
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
  handleOpenCulqi: any;
}

const BillDetailsModal: React.FC<BillDetailsProps> = ({
  showBillModal,
  handleCloseBillModal,
  billType,
  setBillType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  businessName,
  setBusinessName,
  ruc,
  setRuc,
  address,
  setAddress,
  handleOpenCulqi,
}) => {
  return (
    <Modal
      show={showBillModal}
      onHide={handleCloseBillModal}
      centered
      data-bs-theme="light"
    >
      <Form onSubmit={handleOpenCulqi}>
        <ModalHeader className="justify-content-center pt-3 text-center bill-modal-header">
          <Image alt="logo" src={logoPrimary} width={133} height={47} />
        </ModalHeader>
        <ModalBody className="bill-details-modal">
          <Row>
            <BillDetailsSwitch billType={billType} setBillType={setBillType} />
          </Row>
          <Row>
            <Col xs={12} md={6} lg={6} className="mb-4">
              {billType === "receipt" ? (
                <FormInput
                  label={"Nombre"}
                  type={"text"}
                  name={"firstname"}
                  maxLength={100}
                  required={true}
                  value={firstName}
                  placeholder={""}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              ) : (
                <FormInput
                  label={"Razón Social"}
                  type={"text"}
                  name={"businessname"}
                  maxLength={100}
                  required={true}
                  value={businessName}
                  placeholder={""}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              )}
            </Col>
            <Col xs={12} md={6} lg={6} className="mb-4">
              {billType === "receipt" ? (
                <FormInput
                  label={"Apellido"}
                  type={"text"}
                  name={"lastname"}
                  maxLength={100}
                  required={true}
                  value={lastName}
                  placeholder={""}
                  onChange={(e) => setLastName(e.target.value)}
                />
              ) : (
                <FormInput
                  label={"RUC"}
                  type={"text"}
                  name={"ruc"}
                  maxLength={100}
                  required={true}
                  value={ruc}
                  placeholder={""}
                  onChange={(e) => setRuc(e.target.value)}
                />
              )}
            </Col>
            <Col xs={12} md={6} lg={6} className="mb-4">
              {billType === "receipt" ? (
                <FormInput
                  label={"Email"}
                  type={"text"}
                  name={"email"}
                  maxLength={100}
                  required={true}
                  value={email}
                  placeholder={""}
                  onChange={(e) => setEmail(e.target.value)}
                />
              ) : (
                <FormInput
                  label={"Dirección"}
                  type={"text"}
                  name={"address"}
                  maxLength={100}
                  required={true}
                  value={address}
                  placeholder={""}
                  onChange={(e) => setAddress(e.target.value)}
                />
              )}
            </Col>
            <Col xs={12} md={6} lg={6} className="mb-4">
              <FormInput
                label={"Teléfono"}
                type={"text"}
                name={"phone"}
                maxLength={9}
                minLength={9}
                required={true}
                value={phoneNumber}
                placeholder={""}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="bill-modal-footer justify-content-center text-center">
          <FormButton
            type="submit"
            buttonText={"Siguiente"}
            variant={"second"}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default BillDetailsModal;
