import React, { useEffect } from "react";
import FilterCheckbox from "../filter-checkbox/FilterCheckbox";
import {
  allJobAdvertisementSeniorities,
  applicantsSeniorities,
  jobAdvertisementSeniorities,
} from "../../common/seniorities";
import { experienceTime } from "../../common/experienceTime";
import { advancedFilterTypes } from "../../common/advanced-filter-types";
import { contractTypes } from "../../common/contracts";
import { salaryOptions } from "../../common/salaryOptions";
import { languages } from "../../common/languages";
import { useFilterContext } from "../../context/FilterContext/FilterContext";
import Loading from "../loading/Loading";

interface AdvancedFiltersProps {
  type: "jobOffers" | "candidates";
}

const AdvancedFilters: React.FC<AdvancedFiltersProps> = ({ type }) => {
  const {
    industries,
    loadingIndustries,
    filterType,
    setFilterType,
    setExperienceFilter,
    setContractFilter,
    setSectorFilter,
    setSalaryFilter,
    setTimeExperienceFilter,
    setExcelenceFilter,
    setLanguageFilter,
    loadIndustries,
  } = useFilterContext();

  const handleExperience = (filter: any[]) => {
    const nextExperienceFilter: any[] = [];
    filter.forEach((f: any) => {
      if (f.enabled) {
        const seniority = jobAdvertisementSeniorities.find(
          (v) => v.label === f.optionName
        );
        if (seniority) nextExperienceFilter.push(seniority.id);
      }
      if (f.optionName === "Excelencia") {
        setExcelenceFilter(f.enabled);
      }
    });
    setExperienceFilter(nextExperienceFilter);
  };

  const handleApplicantsExperience = (filter: any[]) => {
    const nextExperienceFilter: any[] = [];
    filter.forEach((f: any) => {
      if (f.enabled) {
        const seniority = applicantsSeniorities.find(
          (v) => v.label === f.optionName
        );
        if (seniority) nextExperienceFilter.push(seniority.id);
      }
      if (f.optionName === "Excelencia") {
        setExcelenceFilter(f.enabled);
      }
    });
    setExperienceFilter(nextExperienceFilter);
  };

  const handleContract = (filter: any[]) => {
    const nextContractFilter: any[] = [];
    filter.forEach((f: any) => {
      if (f.enabled) {
        const contract = contractTypes.find((v) => v.label === f.optionName);
        if (contract) nextContractFilter.push(contract.id);
      }
    });
    setContractFilter(nextContractFilter);
  };

  const handleSector = (filter: any[]) => {
    const nextSectorFilter: any[] = [];
    filter.forEach((f: any) => {
      if (f.enabled) {
        const sector = industries.find((v) => v.label === f.optionName);
        if (sector) nextSectorFilter.push(sector.id);
      }
    });
    setSectorFilter(nextSectorFilter);
  };

  const handleSalary = (filter: any[]) => {
    const nextSalaryFilter: any[] = [];
    filter.forEach((f: any) => {
      if (f.enabled) {
        const salary = salaryOptions.find((v) => v.label === f.optionName);
        if (salary) {
          const salaryParsed = salary.id.split("-");
          nextSalaryFilter.push({ min: salaryParsed[0], max: salaryParsed[1] });
        }
      }
    });
    setSalaryFilter(nextSalaryFilter);
  };

  const handleTimeExperience = (filter: any[]) => {
    const nextTimeExperienceFilter: any[] = [];
    filter.forEach((f: any) => {
      if (f.enabled) {
        const timeExperience = experienceTime.find(
          (v) => v.label === f.optionName
        );
        if (timeExperience) {
          const timeExperienceParsed = timeExperience.id.split("-");
          nextTimeExperienceFilter.push({
            min: timeExperienceParsed[0],
            max: timeExperienceParsed[1],
          });
        }
      }
    });
    setTimeExperienceFilter(nextTimeExperienceFilter);
  };

  const handleLanguage = (filter: any[]) => {
    const nextLanguageFilter: any[] = [];
    filter.forEach((f: any) => {
      if (f.enabled) {
        const language = languages.find((v) => v.label === f.optionName);
        if (language) nextLanguageFilter.push(language.id);
      }
    });
    setLanguageFilter(nextLanguageFilter);
  };

  useEffect(() => {
    const getIndustriesOptions = async () => {
      await loadIndustries();
    };
    setFilterType(type);
    getIndustriesOptions();
  }, []);

  return (
    <div className="bg-main generic-card mt-3 mb-3 ps-2 pe-2 pt-1 text-white">
      <div className="p-2">
        <span className="text-center font-bold">Mejora tu búsqueda</span>
      </div>
      <FilterCheckbox
        label={"Experiencia"}
        options={allJobAdvertisementSeniorities.map(
          (seniority) => seniority.label
        )}
        handleFilter={
          filterType === advancedFilterTypes.candidates
            ? handleApplicantsExperience
            : handleExperience
        }
      />
      <hr className="hr-profile-details" />
      {filterType === advancedFilterTypes.jobOffers && (
        <>
          <FilterCheckbox
            label={"Contrato"}
            options={contractTypes.map((contract) => contract.label)}
            handleFilter={handleContract}
          />
          <hr className="hr-profile-details" />
        </>
      )}
      {loadingIndustries ? (
        <Loading variant={"white"} />
      ) : (
        <FilterCheckbox
          label={"Sector"}
          options={industries.map((industry) => industry.label)}
          handleFilter={handleSector}
        />
      )}
      {filterType === advancedFilterTypes.candidates && (
        <>
          <hr className="hr-profile-details" />
          <FilterCheckbox
            label={"Salario (soles)"}
            options={salaryOptions.map((salary) => salary.label)}
            handleFilter={handleSalary}
          />
        </>
      )}
      {filterType === advancedFilterTypes.candidates && (
        <>
          <hr className="hr-profile-details" />
          <FilterCheckbox
            label={"Tiempo"}
            options={experienceTime.map((time) => time.label)}
            handleFilter={handleTimeExperience}
          />
        </>
      )}
      {filterType === advancedFilterTypes.candidates && (
        <>
          <hr className="hr-profile-details" />
          <FilterCheckbox
            label={"Idiomas"}
            options={languages.map((language) => language.label)}
            handleFilter={handleLanguage}
          />
        </>
      )}
    </div>
  );
};

export default AdvancedFilters;
