import React, { useEffect, useState } from "react";
import "./Statistics.css";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import Counter from "./counter/Counter";
import DotsDecorator from "../dots-decorator/DotsDecorator";
import { getStatistics } from "../../../api/catalogue.api";
import Loading from "../../loading/Loading";

const Statistics: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState(0);
  const [companies, setCompanies] = useState(0);
  const [advertisements, setAdvertisements] = useState(0);

  useEffect(() => {
    const loadStatistics = async () => {
      try {
        const statistics = (await getStatistics()).data;
        setUsers(statistics.usersCount);
        setCompanies(statistics.companiesCount);
        setAdvertisements(statistics.jobAdvertisementCount);
      } catch (error) {
        console.error("Error loading statistics:", error);
      } finally {
        setLoading(false);
      }
    };

    loadStatistics();
  }, []);

  return (
    <section id="statistics">
      <Container>
        <Row className="justify-content-md-center text-center align-items-center mt-5">
          <Col md={4} className="stat-item divide">
            <Fade triggerOnce>
              {loading ? (
                <Loading variant={"white"} />
              ) : (
                <Counter end={users} />
              )}
              <DotsDecorator
                height={10}
                width={10}
                color={"--white"}
                center={true}
              />
              <p>Usuarios que confían en nosotros</p>
            </Fade>
          </Col>
          <Col md={4} className="stat-item divide">
            <Fade triggerOnce>
              {loading ? (
                <Loading variant={"white"} />
              ) : (
                <Counter end={advertisements} />
              )}
              <DotsDecorator
                height={10}
                width={10}
                color={"--white"}
                center={true}
              />
              <p>Ofertas de trabajo en nuestra plataforma</p>
            </Fade>
          </Col>
          <Col md={4} className="stat-item">
            <Fade triggerOnce>
              {loading ? (
                <Loading variant={"white"} />
              ) : (
                <Counter end={companies} />
              )}
              <DotsDecorator
                height={10}
                width={10}
                color={"--white"}
                center={true}
              />
              <p>Empresas que confían en nosotros</p>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Statistics;
