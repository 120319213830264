import React from "react";
import "./NotificationModal.css";
import { Modal } from "react-bootstrap";
import { useNotificationContext } from "../../context/NotificationContext";
import { notificationTypes } from "../../common/notification-types";

const NotificationModal: React.FC = () => {
  const {
    showNotificationModal,
    notificationType,
    notificationTitle,
    notificationText,
    closeNotificationModal,
  } = useNotificationContext();

  return (
    <Modal
      dialogClassName="notification-dialog"
      show={showNotificationModal}
      onHide={closeNotificationModal}
    >
      <Modal.Body
        className={`notification-body ${
          notificationType === notificationTypes.SUCCESS
            ? "notification-body-success"
            : notificationType === notificationTypes.ERROR
            ? "notification-body-error"
            : notificationType === notificationTypes.INFORMATION
            ? "notification-body-information"
            : "notification-body-information"
        }`}
      >
        <div className="notification-title">
          <span>{notificationTitle}</span>
          <span
            className={`notification-close-icon ${
              notificationType === notificationTypes.SUCCESS
                ? "notification-close-icon-success"
                : notificationType === notificationTypes.ERROR
                ? "notification-close-icon-error"
                : notificationType === notificationTypes.INFORMATION
                ? "notification-close-icon-information"
                : "notification-close-icon-information"
            }`}
            onClick={closeNotificationModal}
          >
            ×
          </span>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: notificationText ?? "" }}
          className="notification-text"
        ></div>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationModal;
