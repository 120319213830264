import React, { useEffect, useRef, useState } from "react";
import { Image, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import "./ProfileImage.css";

import editIcon from "../../assets/images/profile/profile-image-edit-icon.png";
import saveIcon from "../../assets/images/profile/profile-image-save-icon.png";
import { useUpdateProfilePicture } from "../../hooks/useUpdateProfilePicture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

interface ProfileImageProps {
  profileType: "applicant" | "company";
  profileImage: string;
  loading: boolean;
  isExcelence?: boolean;
  nonEditable?: boolean;
  blurInfo?: boolean;
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  profileType,
  profileImage,
  loading,
  isExcelence,
  nonEditable,
  blurInfo = false,
}) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>(profileImage);
  const [showSaveIcon, setShowSaveIcon] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { updatePicture, isLoading } = useUpdateProfilePicture();

  useEffect(() => {
    if (profileImage) {
      setPreviewUrl(profileImage);
    }
  }, [profileImage]);

  const handleEditClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      setShowSaveIcon(true);
    }
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    if (selectedImage) {
      setShowSaveIcon(false);
      const formData = new FormData();
      formData.append("image", selectedImage);
      await updatePicture(formData, profileType);
    }
  };

  return (
    <div className="circle-logo">
      {previewUrl === "" || previewUrl === null ? (
        <div className={`${blurInfo ? "blur" : ""} logo-text`}>
          {profileType === "applicant" ? "Foto" : "Logo"}
        </div>
      ) : loading || isLoading ? (
        <div className="logo-text">
          <Spinner animation="border" variant="main" />
        </div>
      ) : (
        <Image
          className={`${blurInfo ? "blur" : ""}`}
          src={previewUrl}
          roundedCircle
          width={100}
          height={100}
        />
      )}
      {isExcelence && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip-excelencia-academica">
              Excelencia Académica
            </Tooltip>
          }
        >
          <FontAwesomeIcon
            className="excelence-badge"
            icon={faStar}
            color={"#6ec23f"}
            size={"lg"}
          />
        </OverlayTrigger>
      )}
      {!nonEditable && (
        <>
          {showSaveIcon && (
            <Image
              className="save-icon"
              src={saveIcon}
              width={24}
              height={24}
              onClick={handleSave}
            />
          )}
          <Image
            className="edit-icon"
            src={editIcon}
            width={24}
            height={24}
            onClick={handleEditClick}
          />
        </>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default ProfileImage;
