import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import DotsDecorator from "../home/dots-decorator/DotsDecorator";
import Loading from "../loading/Loading";
import PlanItem from "./plan-item/PlanItem";
import { getApplicantPlans } from "../../api/career-boost.api";
import { CareerBoost } from "../../types/response/career-boost";
import { useAuthContext } from "../../hooks/useAuthContext";
import { UserData } from "../../context/AuthContext";
import { roles } from "../../common/roles";
import { getCompanyPlans } from "../../api/plans.api";
import { Plan } from "../../types/response/plan-response";
import { usePurchasePlan } from "../../hooks/usePurchasePlan";
import PayToAccount from "../pay-to-account/PayToAccount";
import { Culqi, CulqiProvider } from "../../service/culqi";

const Plans: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState<CareerBoost[] | Plan[]>([]);

  const { user } = useAuthContext();

  const [userData, setUserData] = useState<UserData | null>(null);
  const [plan, setPlan] = useState<CareerBoost | Plan>();

  const [planType, setPlanType] = useState<"careerBoost" | "plan">();

  const [billType, setBillType] = useState<"receipt" | "bill">("receipt");

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [businessName, setBusinessName] = useState<string>("");
  const [ruc, setRuc] = useState<string>("");
  const [address, setAddress] = useState<string>("");

  useEffect(() => {
    if (user) {
      setUserData({ name: user.name, role: user.role });
    } else {
      setUserData(null);
    }
  }, [user]);

  useEffect(() => {
    if (userData) {
      const loadPlans = async () => {
        try {
          let plansData;
          if (userData.role === roles.company) {
            plansData = (await getCompanyPlans()).data;
            setPlanType("plan");
          } else {
            plansData = (await getApplicantPlans()).data;
            setPlanType("careerBoost");
          }
          setPlans(plansData);
        } catch (error) {
          console.error("Error loading plans", error);
        } finally {
          setLoading(false);
        }
      };

      loadPlans();
    }
  }, [userData]);

  const {
    boostCareer,
    companyPurchase,
    loading: loadingPurchase,
  } = usePurchasePlan();

  const createBillDetails = () => {
    let billDetails = {};

    if (billType === "receipt") {
      billDetails = {
        billType,
        firstName,
        lastName,
        email,
        phoneNumber,
      };
    } else if (billType === "bill") {
      billDetails = {
        billType,
        businessName,
        ruc,
        address,
        phoneNumber,
      };
    }

    return JSON.stringify(billDetails);
  };

  const payPlan = async (token: any) => {
    const billDetails = createBillDetails();
    if (plan) {
      if (planType === "careerBoost") {
        await boostCareer({
          careerId: 1,
          price: plan.forSale ? plan.salePrice : plan.price,
          careerBoostId: plan.id,
          token: token.id,
          billType,
          billDetails,
        });
      } else {
        await companyPurchase({
          price: plan.forSale ? plan.salePrice : plan.price,
          planId: plan.id,
          token: token.id,
          billType,
          billDetails,
        });
      }
    }
  };

  return loadingPurchase ? (
    <Loading />
  ) : (
    <CulqiProvider
      publicKey="pk_live_3ec5b16f009eef99"
      onToken={async (token: any) => {
        payPlan(token);
      }}
      onError={(error: any) => {
        console.log("error: ", error);
      }}
      title={"Chamba Sí Hay"}
      description={plan ? plan.name : "Plan Premium"}
      options={{
        style: {
          logo: "https://chambasihay.com.pe/logo-primary.png",
          maincolor: "#006cc4",
          buttontext: "#ffffff",
          maintext: "#4A4A4A",
          desctext: "#4A4A4A",
        },
      }}
    >
      <Culqi>
        {({ openCulqi, setAmount }: any) => {
          return (
            <div className="p-4 bg-gray-soft">
              <Row className="justify-content-center">
                <h4 className="text-center">Nuestros planes</h4>
                <DotsDecorator width={10} height={10} center={true} />
              </Row>
              <Row className="justify-content-center pt-4">
                {loading ? (
                  <Loading />
                ) : (
                  plans.map((plan) => {
                    return (
                      <PlanItem
                        key={plan.id}
                        plan={plan}
                        openCulqi={openCulqi}
                        setAmount={setAmount}
                        setPlan={setPlan}
                        billType={billType}
                        setBillType={setBillType}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        email={email}
                        setEmail={setEmail}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        businessName={businessName}
                        setBusinessName={setBusinessName}
                        ruc={ruc}
                        setRuc={setRuc}
                        address={address}
                        setAddress={setAddress}
                      />
                    );
                  })
                )}
              </Row>
              {planType === "plan" && <PayToAccount />}
            </div>
          );
        }}
      </Culqi>
    </CulqiProvider>
  );
};

export default Plans;
