import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AccountModal from "./account-modal/AccountModal";

const PayToAccount: React.FC = () => {
  const [showAccountModal, setShowAccountModal] = useState(false);

  const showModal = () => {
    setShowAccountModal(true);
  };

  const handleClose = () => {
    setShowAccountModal(false);
  };

  return (
    <div className="text-center">
      <p>O realiza el pago a nuestra cuenta</p>
      <Button
        className="form-button ms-3"
        variant={"second"}
        onClick={showModal}
      >
        Ver detalles
      </Button>
      <AccountModal
        showAccountModal={showAccountModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default PayToAccount;
