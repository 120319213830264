import { useState } from "react";
import { resetCompanyPassword, resetUserPassword } from "../api/auth.api";
import { resultType } from "../common/result-type";
import { ResetPasswordRequest } from "../types/request/reset-password-request";

interface UseResetPasswordFormProps {
  token: string | undefined;
}

export const useResetPassword = ({ token }: UseResetPasswordFormProps) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");

  const renewPassword = async (resetPasswordRequest: ResetPasswordRequest) => {
    if (!token) {
      setMessage("Token de restablecimiento de contraseña no proporcionado.");
      setType(resultType.error);
      return;
    }
    if (
      resetPasswordRequest.newPassword !== resetPasswordRequest.confirmPassword
    ) {
      setMessage("Las contraseñas no coinciden");
      setType(resultType.error);
      return;
    }

    setLoading(true);

    try {
      resetPasswordRequest.userType === "applicant"
        ? await resetUserPassword({
            email: resetPasswordRequest.email,
            token: resetPasswordRequest.token,
            newPassword: resetPasswordRequest.newPassword,
          })
        : await resetCompanyPassword({
            email: resetPasswordRequest.email,
            token: resetPasswordRequest.token,
            newPassword: resetPasswordRequest.newPassword,
          });
      setMessage(
        "Ahora podrás utilizar tu nueva contraseña para ingresar a la aplicación"
      );
      setType(resultType.success);
    } catch (error) {
      console.error("Error updating information:", error);
      setMessage(
        "Hubo un problema al restablecer su contraseña. Por favor, comuníquese con soporte o revise su correo."
      );
      setType(resultType.error);
    } finally {
      setLoading(false);
    }
  };

  return {
    renewPassword,
    loading,
    message,
    setMessage,
    type,
  };
};
