import React from "react";
import { Col, Row } from "react-bootstrap";
import "./AuthTypeFormSwitch.css";

interface UserType {
  userType: "applicant" | "company" | null;
  authType: "login" | "register" | null;
  setAuthType: (authType: "login" | "register") => void;
}

const AuthTypeFormSwitch: React.FC<UserType> = ({
  userType,
  authType,
  setAuthType,
}) => {
  const handleAuthTypeChange = (type: "login" | "register") => {
    setAuthType(type);
  };

  return (
    <div
      className={`${userType === "company" ? "text-black" : "text-white"} mt-1`}
    >
      <Row>
        <Col className="text-end">
          <div
            className={`auth-type-option ${
              authType === "login" ? "auth-type-option-selected" : ""
            }`}
            onClick={() => handleAuthTypeChange("login")}
          >
            <span>Conexión</span>
          </div>
        </Col>
        <Col className="text-start">
          <div
            className={`auth-type-option ${
              authType === "register" ? "auth-type-option-selected" : ""
            }`}
            onClick={() => handleAuthTypeChange("register")}
          >
            <span>Regístrate</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AuthTypeFormSwitch;
