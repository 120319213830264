import { useState } from "react";
import { resultType } from "../common/result-type";
import { CreateLanguageRequest } from "../types/request/create-language-request";
import {
  createApplicantLanguage,
  updateApplicantLanguage,
} from "../api/language.api";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../common/notification-types";

export const useAddLanguage = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/applicant");
    setShowNotificationModal(true);
    setNotificationTitle("Idioma agregado");
    setNotificationText("Sigue completando tu perfil para los reclutadores.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const addLanguage = async (req: CreateLanguageRequest) => {
    setLoading(true);
    try {
      await createApplicantLanguage(req);
      setLoading(false);
      showSuccessMessage();
    } catch (ex) {
      setLoading(false);
      setMessage("Hubo un error al crear idioma.");
      setType(resultType.error);
    }
  };

  const updateLanguage = async (id: number, req: CreateLanguageRequest) => {
    setLoading(true);
    try {
      await updateApplicantLanguage(id, req);
      setLoading(false);
      showSuccessMessage();
    } catch (ex) {
      setLoading(false);
      setMessage("Hubo un error al editar idioma.");
      setType(resultType.error);
    }
  };

  return {
    addLanguage,
    updateLanguage,
    loading,
    message,
    setMessage,
    type,
  };
};
