import React from "react";
import { Col, Image, Row } from "react-bootstrap";

interface ProfileItemProps {
  icon: string;
  value?: string;
  blurInfo?: boolean;
}

const ProfileItem: React.FC<ProfileItemProps> = ({
  icon,
  value,
  blurInfo = false,
}) => {
  return (
    <Row className="pb-2">
      <Col xs={1} sm={1} md={1} lg={1} className="p-0">
        <Image
          alt="icon"
          src={icon}
          width={18}
          height={18}
          className="profile-icon-image"
        />
      </Col>
      <Col
        xs={10}
        sm={10}
        md={10}
        lg={10}
        className={`${blurInfo ? "blur" : ""} text-start p-0`}
      >
        {value}
      </Col>
    </Row>
  );
};

export default ProfileItem;
