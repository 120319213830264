import { APIMiddleware } from "../middleware/api-middleware";
import { apiUrls } from "../common/api-urls";
import { UpdateIndustryRequest } from "../types/request/update-industry-request";

const { INDUSTRIES_URL } = apiUrls;

export function getIndustries() {
  const url = `${INDUSTRIES_URL}`;
  return APIMiddleware.get(url, { noAuthToken: true });
}

export function getIndustriesList(page: number, take: number) {
  const url = `${INDUSTRIES_URL}/list?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function getIndustry(id: number) {
  const url = `${INDUSTRIES_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function updateIndustry(id: number, req: UpdateIndustryRequest) {
  const url = `${INDUSTRIES_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      name: req.name,
    },
  });
}

export function removeIndustry(id: number) {
  const url = `${INDUSTRIES_URL}/${id}`;
  return APIMiddleware.delete(url);
}
