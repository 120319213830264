import { Plan } from "./../types/response/plan-response";
export const utils = {
  getFullName(lastName: string, firstName: string): string {
    return firstName + " " + lastName;
  },
  getSeniority(seniorityId: number, isExcelence?: boolean): string {
    var seniority = "";
    switch (seniorityId) {
      case 1:
        seniority = "Estudiante";
        break;
      case 2:
        seniority = "Profesional";
        break;
      default:
        seniority = "Estudiante";
        break;
    }
    seniority += isExcelence ? " - Excelencia Académica" : "";
    return seniority;
  },
  getAdvertisementStatus(status: string) {
    return status === "approved"
      ? "Aprobado"
      : status === "pending"
      ? "Pendiente"
      : "Rechazado";
  },
  formatDate(date: string) {
    const myDate = new Date(date);
    const formatter = new Intl.DateTimeFormat("es", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    return formatter.format(myDate);
  },
  getAddress(address: string, district: string): string {
    if (!address || address.trim() === "") {
      return district;
    }
    return district ? address + ", " + district : address;
  },
  getPrice(price: string): string {
    const priceNumber: number = Number(price);
    if (priceNumber === 0) return "Gratis";
    return "S/. " + priceNumber.toFixed(2);
  },
  getSalaryWithCurrency(salary: string): string {
    if (salary === "") return "";
    const salaryNumber: number = Number(salary);
    const formattedSalary = salaryNumber.toLocaleString("es-PE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return "S/. " + formattedSalary;
  },
  getApplicationLabelForApplicant(title: string): string {
    return `Haz postulado a "${title}"`;
  },
  getApplicationLabelForCompany(title: string, applicantName: string): string {
    return `${applicantName} ha postulado a "${title}"`;
  },
  getCareerBoostFeatures(): string[] {
    return [
      "Aparece dentro de los primeros candidatos en tu sector laboral del catálogo de talentos",
      "Tu perfil será más visible para las empresas",
      "Válido por 20 días",
    ];
  },
  getPlanFeatures(plan: Plan): string[] {
    return [
      `Válido para ${plan.advertisements} anuncio${
        plan.advertisements > 1 ? "s" : ""
      }`,
      `${plan.visibilityPeriod} días de visibilidad del anuncio`,
      `${
        plan.profileExperience === 0
          ? ""
          : `Desbloqueo de ${plan.profileExperience} perfiles de talento con experiencia`
      }`,
      `${
        plan.profileTalent === 0
          ? ""
          : `Desbloqueo de ${plan.profileTalent} perfil${
              plan.profileTalent > 1 ? "es" : ""
            } de talento excelencia académica`
      }`,
      `${plan.id === 1 ? "Limité de 10 postulantes por publicación" : ""}`,
      `${
        plan.questions === 0
          ? ""
          : `Beneficio de ${plan.questions} preguntas por publicación para postulantes`
      }`,
      `${
        plan.modifyPost === 0
          ? ""
          : `Opción de editar publicación durante los primeros ${plan.modifyPost} días de vigencia`
      }`,
    ];
  },
  getBillDetails(billType: string, billDetails: any): string {
    if (billType === "receipt") {
      return `Tipo: ${"Boleta"}\nCorreo: ${billDetails.email}\nApellido: ${
        billDetails.lastName
      }\nNombre: ${billDetails.firstName}\nTeléfono: ${
        billDetails.phoneNumber
      }`;
    } else if (billType === "bill") {
      return `Tipo: ${"Factura"}\nRUC: ${billDetails.ruc}\nDirección: ${
        billDetails.address
      }\nTeléfono: ${billDetails.phoneNumber}\nEmpresa: ${
        billDetails.businessName
      }`;
    } else {
      return "Invalid bill type.";
    }
  },
};
