export const studyTypes = [
  { id: "Universitario", label: "Universitario" },
  { id: "Curso de Especialización", label: "Curso de Especialización" },
  { id: "Diplomado", label: "Diplomado" },
  { id: "Bachiller", label: "Bachiller" },
  { id: "Titulado", label: "Titulado" },
  { id: "Colegiado", label: "Colegiado" },
  { id: "Maestría", label: "Maestría" },
  { id: "Doctorado", label: "Doctorado" },
];
