import React, { useState } from "react";
import { Col } from "react-bootstrap";
import FormInput from "../../../components/form-input/FormInput";
import { useUpdateApplicant } from "../../../hooks/useUpdateApplicant";
import { UpdateUserRequest } from "../../../types/request/update-user-request";
import GenericForm from "../../../components/generic-form/GenericForm";
import FormSelect from "../../../components/form-select/FormSelect";
import { roleOptions } from "../../../common/role-options";
import { userTypeOptions } from "../../../common/user-type-options";

interface AdminUsersFormProps {
  item: UpdateUserRequest;
}

const AdminUsersForm: React.FC<AdminUsersFormProps> = ({ item }) => {
  const [firstName, setFirstName] = useState(item.firstName);
  const [lastName, setLastName] = useState(item.lastName);
  const [role, setRole] = useState(item.role);
  const [isExcelence, setIsExcelence] = useState(item.isExcelence ? 1 : 0);

  const { updateApplicant, loading, message, setMessage, type } =
    useUpdateApplicant();

  const handleSave = async (e: any) => {
    e.preventDefault();
    await updateApplicant(item.id, {
      id: item.id,
      firstName,
      lastName,
      role,
      isExcelence: !!isExcelence,
    });
  };

  return (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={"Editar usuario"}
      loading={loading}
      buttonText={"Actualizar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Nombres"}
          type={"text"}
          name={"firstName"}
          maxLength={100}
          required={true}
          value={firstName}
          placeholder={""}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Apellidos"}
          type={"text"}
          name={"lastName"}
          maxLength={100}
          required={true}
          value={lastName}
          placeholder={""}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormSelect
          label={"Rol"}
          name={"role"}
          required={false}
          value={role}
          options={roleOptions}
          onChange={(e) => setRole(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormSelect
          label={"Tipo de usuario"}
          name={"userType"}
          required={false}
          value={isExcelence ? 1 : 0}
          options={userTypeOptions}
          onChange={(e) => setIsExcelence(parseInt(e.target.value))}
        />
      </Col>
    </GenericForm>
  );
};

export default AdminUsersForm;
