import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";
import { CreateAdvertisementRequest } from "../types/request/create-advertisement-request";
import { UpdateAdvertisementRequest } from "../types/request/update-advertisement-request";
import { UpdateCompanyInformationRequest } from "../types/request/update-company-information-request";
import { UpdateCompanyRequest } from "../types/request/update-company-request";

const { COMPANY_URL } = apiUrls;

export function updateCompanyPicture(formData: FormData) {
  const url = `${COMPANY_URL}/image`;
  return APIMiddleware.patch(url, {
    data: formData,
    isFormData: true,
  });
}

export function getCompanyInformation() {
  const url = `${COMPANY_URL}/information`;
  return APIMiddleware.get(url);
}

export function updateCompanyInformation(
  request: UpdateCompanyInformationRequest
) {
  const url = `${COMPANY_URL}/information`;
  return APIMiddleware.patch(url, {
    data: request,
  });
}

export function createAdvertisementApi(request: CreateAdvertisementRequest) {
  const url = `${COMPANY_URL}/create/advertisement`;
  return APIMiddleware.post(url, {
    data: request,
  });
}

export function updateAdvertisementApi(
  request: UpdateAdvertisementRequest,
  urlCode: string
) {
  const url = `${COMPANY_URL}/update/advertisement/${urlCode}`;
  return APIMiddleware.patch(url, {
    data: request,
  });
}

export function listAdvertisementsApi(page: number = 1, take: number = 5) {
  const url = `${COMPANY_URL}/list/advertisements?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function getAdvertisementInformationApi(urlCode: string) {
  const url = `${COMPANY_URL}/advertisement/${urlCode}`;
  return APIMiddleware.get(url);
}

export function getAdvertisementDetailApi(urlCode: string) {
  const url = `${COMPANY_URL}/advertisement/detail/${urlCode}`;
  return APIMiddleware.get(url, { noAuthToken: true });
}

export function deleteAdvertisementApi(advertisementId: number) {
  const url = `${COMPANY_URL}/delete/advertisement/${advertisementId}`;
  return APIMiddleware.delete(url);
}

export function getCompanies(page: number, take: number, filter: string) {
  const url = `${COMPANY_URL}?page=${page}&take=${take}&filter=${filter}`;
  return APIMiddleware.get(url);
}

export function getCompany(id: number) {
  const url = `${COMPANY_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function updateCompany(id: number, req: UpdateCompanyRequest) {
  const url = `${COMPANY_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      name: req.name,
      ruc: req.ruc,
      taxStatus: req.taxStatus,
      advFree: req.advFree,
      advCsh: req.advCsh,
      advBlack: req.advBlack,
      viewProfessional: req.viewProfessional,
      viewExcelence: req.viewExcelence,
    },
  });
}

export function removeCompany(id: number) {
  const url = `${COMPANY_URL}/${id}`;
  return APIMiddleware.delete(url);
}
