import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";

const { PROFILE_VIEW_URL } = apiUrls;

export function checkProfile(id: number) {
  const url = `${PROFILE_VIEW_URL}/check-profile/${id}`;
  return APIMiddleware.get(url);
}

export function activateProfile(id: number) {
  const url = `${PROFILE_VIEW_URL}/activate-profile/${id}`;
  return APIMiddleware.get(url);
}

export function getProfileViewInformation(id: number) {
  const url = `${PROFILE_VIEW_URL}/applicant-information/${id}`;
  return APIMiddleware.get(url);
}

export function getProfileViewExperiences(id: number) {
  const url = `${PROFILE_VIEW_URL}/applicant-experiences/${id}`;
  return APIMiddleware.get(url);
}

export function getProfileViewStudies(id: number) {
  const url = `${PROFILE_VIEW_URL}/applicant-studies/${id}`;
  return APIMiddleware.get(url);
}

export function getProfileViewLanguages(id: number) {
  const url = `${PROFILE_VIEW_URL}/applicant-languages/${id}`;
  return APIMiddleware.get(url);
}
