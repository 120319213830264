import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import AdminUserPurchasesForm from "./AdminUserPurchasesForm";
import { UserPurchases } from "../../../types/response/user-purchases";
import { getUserPurchase } from "../../../api/user-purchases.api";

const AdminUserPurchasesSave: React.FC = () => {
  const [userPurchase, setUserPurchases] = useState<UserPurchases>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadUserPurchase = async (id: string) => {
      try {
        const userPurchaseData = (await getUserPurchase(parseInt(id))).data;
        setUserPurchases(userPurchaseData);
      } catch (error) {
        console.error("Error loading user purchase:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadUserPurchase(id);
    }
  }, [id]);

  return loading ? (
    <Loading />
  ) : (
    userPurchase && <AdminUserPurchasesForm item={userPurchase} />
  );
};

export default AdminUserPurchasesSave;
