import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

export interface SearchBarInputProps {
  label: string;
  name: string;
  minLength?: number;
  maxLength: number;
  value?: string | File | number;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBarInput: React.FC<SearchBarInputProps> = ({
  name,
  value,
  minLength,
  maxLength,
  label,
  placeholder,
  onChange,
}) => {
  const commonProps = {
    name,
    minLength,
    maxLength,
    placeholder,
    onChange,
  };

  return (
    <Form.Group className="form-group p-3">
      <FloatingLabel
        className={"floating-label-search-input"}
        controlId={name}
        label={label}
      >
        <Form.Control
          className="search-bar-input"
          type={"text"}
          {...commonProps}
          value={value as string}
        />
      </FloatingLabel>
    </Form.Group>
  );
};

export default SearchBarInput;
