import { useState } from "react";
import { resultType } from "../common/result-type";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../common/notification-types";
import { UpdateCompanyRequest } from "../types/request/update-company-request";
import { updateCompany } from "../api/company.api";

export const useUpdateCompany = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/admin/companies");
    setShowNotificationModal(true);
    setNotificationTitle("Empresa actualizada");
    setNotificationText(
      "Los cambios se verán reflejados en el próximo inicio de sesión de la empresa"
    );
    setNotificationType(notificationTypes.SUCCESS);
  };

  const updateCompanyUser = async (id: number, req: UpdateCompanyRequest) => {
    setLoading(true);
    try {
      await updateCompany(id, req);
      showSuccessMessage();
    } catch (ex) {
      setMessage(
        "Hubo un error al actualizar la empresa. Por favor, inténtelo más tarde"
      );
      setType(resultType.error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateCompanyUser,
    loading,
    message,
    setMessage,
    type,
  };
};
