import React from "react";
import { UpgradeRequest } from "../../../types/response/upgrade-request";
import { Card, Col, Form, Image, Row } from "react-bootstrap";
import goBackIcon from "../../../assets/images/icons/go-back-icon.png";
import { useNavigate } from "react-router-dom";
import FormButton from "../../../components/form-button/FormButton";
import FormInput from "../../../components/form-input/FormInput";
import { useUpgradeRequest } from "../../../hooks/useUpgradeRequest";

interface AdminUpgradeRequestsFormProps {
  item: UpgradeRequest;
}

const AdminUpgradeRequestsForm: React.FC<AdminUpgradeRequestsFormProps> = ({
  item,
}) => {
  const navigate = useNavigate();

  const {
    approveRequest,
    rejectRequest,
    loading: isLoading,
  } = useUpgradeRequest();

  const handleApproveRequest = async () => {
    await approveRequest(item.id);
  };

  const handleRejectRequest = async () => {
    await rejectRequest(item.id);
  };

  return (
    <React.Fragment>
      <Row className="justify-content-center p-4">
        <Col xs={12} md={12} lg={12}>
          <Card className="bg-main">
            <Card.Body>
              <Form>
                <div className="text-center">
                  <h4 className="form-title-font pt-4">
                    {"Revisar solicitud"}
                  </h4>
                  <Image
                    className="go-back-form-icon cursor-pointer"
                    alt="go-back-icon"
                    src={goBackIcon}
                    width={40}
                    height={40}
                    onClick={() => navigate(-1)}
                  />
                </div>
                <Row className="pt-4">
                  <Col xs={12} md={6} lg={6} className="mb-4">
                    <FormInput
                      label={"Nombre"}
                      type={"text"}
                      name={"fullName"}
                      maxLength={100}
                      required={true}
                      value={item?.fullName}
                      placeholder={""}
                      readOnly={true}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mb-4">
                    <FormInput
                      label={"Correo electrónico"}
                      type={"text"}
                      name={"email"}
                      maxLength={100}
                      required={true}
                      value={item?.email}
                      placeholder={""}
                      readOnly={true}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12} className="mb-4">
                    <object
                      data={item?.documentUrl}
                      type="application/pdf"
                      className="pdf-render"
                    >
                      alt : <a href={item?.documentUrl}>{item?.documentUrl}</a>
                    </object>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                  <Col>
                    <FormButton
                      type="link"
                      onClick={handleRejectRequest}
                      loading={isLoading}
                      buttonText={"Rechazar"}
                      variant="danger"
                    />
                  </Col>
                  <Col>
                    <FormButton
                      type="link"
                      onClick={handleApproveRequest}
                      loading={isLoading}
                      buttonText={"Aprobar"}
                      variant="second"
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AdminUpgradeRequestsForm;
