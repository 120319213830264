import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";
import { CreateExperienceRequest } from "../types/request/create-experience-request";

const { EXPERIENCE_URL } = apiUrls;

export function createApplicantExperience(
  createExperienceRequest: CreateExperienceRequest
) {
  const url = `${EXPERIENCE_URL}`;
  return APIMiddleware.post(url, {
    data: {
      monthFrom: createExperienceRequest.monthFrom,
      yearFrom: createExperienceRequest.yearFrom,
      monthTo: createExperienceRequest.monthTo,
      yearTo: createExperienceRequest.yearTo,
      isCurrent: createExperienceRequest.isCurrent,
      companyName: createExperienceRequest.companyName,
      role: createExperienceRequest.role,
      description: createExperienceRequest.description,
    },
  });
}

export function getApplicantExperience(id: number) {
  const url = `${EXPERIENCE_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function deleteApplicantExperience(id: number) {
  const url = `${EXPERIENCE_URL}/${id}`;
  return APIMiddleware.delete(url);
}

export function updateApplicantExperience(
  id: number,
  createExperienceRequest: CreateExperienceRequest
) {
  const url = `${EXPERIENCE_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      monthFrom: createExperienceRequest.monthFrom,
      yearFrom: createExperienceRequest.yearFrom,
      monthTo: createExperienceRequest.monthTo,
      yearTo: createExperienceRequest.yearTo,
      isCurrent: createExperienceRequest.isCurrent,
      companyName: createExperienceRequest.companyName,
      role: createExperienceRequest.role,
      description: createExperienceRequest.description,
    },
  });
}
