import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import EntityTable from "../../../components/admin/entity-table/EntityTable";
import DataTableButtons from "../../../components/admin/data-table-buttons/DataTableButtons";
import { getNewsList } from "../../../api/news.api";
import { New } from "../../../types/response/new";

const AdminNews: React.FC = () => {
  const [news, setNews] = useState<New[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);

  const take = 10;

  useEffect(() => {
    const loadNews = async () => {
      try {
        const response = await getNewsList(page, take);
        const paginatedData = response.data;
        setNews(paginatedData.data);
        setItemsCount(paginatedData.meta.itemCount);
      } catch (error) {
        console.error("Error loading news", error);
      } finally {
        setLoading(false);
      }
    };

    loadNews();
  }, [page]);

  const renderNews = () => {
    return news.map((news) => (
      <tr key={news.id}>
        <td>{news.id}</td>
        <td>{news.subject}</td>
        <td>{news.description}</td>
        <td>{news.frequency}</td>
        <td>{news.enabled ? "Habilitado" : "Deshabilitado"}</td>
        <td>
          <DataTableButtons
            itemId={news.id}
            handleView={(id: number) => navigate(`/admin/news/${id}`)}
          />
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <EntityTable
          title={"Correos"}
          headers={[
            "ID",
            "Asunto",
            "Descripción",
            "Frecuencia",
            "Habilitado",
            "Acciones",
          ]}
          renderData={renderNews}
          page={page}
          setPage={setPage}
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
        />
      )}
    </Container>
  );
};

export default AdminNews;
