import { createContext, useContext, useState, ReactNode, FC } from "react";

interface NotificationState {
  showNotificationModal: boolean;
  notificationTitle?: string;
  notificationText?: string;
  notificationType?: string;
  setShowNotificationModal: (show: boolean) => void;
  setNotificationTitle: (title?: string) => void;
  setNotificationText: (text?: string) => void;
  setNotificationType: (type?: string) => void;
  closeNotificationModal: () => void;
}

const initialState: NotificationState = {
  showNotificationModal: false,
  setShowNotificationModal: () => {},
  setNotificationTitle: () => {},
  setNotificationText: () => {},
  setNotificationType: () => {},
  closeNotificationModal: () => {},
};

const NotificationContext = createContext<NotificationState>(initialState);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: FC<NotificationProviderProps> = ({
  children,
}) => {
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState<
    string | undefined
  >();
  const [notificationText, setNotificationText] = useState<
    string | undefined
  >();
  const [notificationType, setNotificationType] = useState<
    string | undefined
  >();

  const closeNotificationModal = () => {
    setShowNotificationModal(false);
    setNotificationTitle(undefined);
    setNotificationText(undefined);
    setNotificationType(undefined);
  };

  const notificationContextValue = {
    showNotificationModal,
    notificationTitle,
    notificationText,
    notificationType,
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
    closeNotificationModal,
  };

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};
