import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import { Plan } from "../../../types/response/plan-response";
import { getCompanyPlan } from "../../../api/plans.api";
import AdminCompanyPlansForm from "./AdminCompanyPlansForm";

const AdminCompanyPlansSave: React.FC = () => {
  const [plan, setPlan] = useState<Plan>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadCompanyPlan = async (id: string) => {
      try {
        const planData = (await getCompanyPlan(parseInt(id))).data;
        setPlan(planData);
      } catch (error) {
        console.error("Error loading company plan:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadCompanyPlan(id);
    }
  }, [id]);

  return loading ? <Loading /> : plan && <AdminCompanyPlansForm item={plan} />;
};

export default AdminCompanyPlansSave;
