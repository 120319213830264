import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import FormInput from "../../../form-input/FormInput";
import FormSelect from "../../../form-select/FormSelect";
import { documentTypes } from "../../../../common/document-types";
import { getIndustries } from "../../../../api/industries.api";
import Loading from "../../../loading/Loading";

interface CompanyRegisterFormProps {
  companyName?: string;
  setCompanyName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  businessName?: string;
  setBusinessName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  taxStatus: string;
  setTaxStatus: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  document?: string;
  setDocument: (e: React.ChangeEvent<HTMLInputElement>) => void;
  address?: string;
  setAddress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  postalCode?: string;
  setPostalCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNumber?: string;
  setPhoneNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
  industry: string;
  setIndustry: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const CompanyRegisterForm: React.FC<CompanyRegisterFormProps> = ({
  companyName,
  setCompanyName,
  businessName,
  setBusinessName,
  taxStatus,
  setTaxStatus,
  document,
  setDocument,
  address,
  setAddress,
  postalCode,
  setPostalCode,
  phoneNumber,
  setPhoneNumber,
  industry,
  setIndustry,
}) => {
  const [loading, setLoading] = useState(true);
  const [industries, setIndustries] = useState([]);

  const loadIndustries = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await getIndustries();
      setIndustries(
        response.data.map((i: any) => ({ id: i.id, label: i.name }))
      );
    } catch (error) {
      console.error("Error loading industries", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getIndustriesOptions = async () => {
      await loadIndustries();
    };
    getIndustriesOptions();
  }, []);

  return (
    <React.Fragment>
      <hr className="hr-auth mb-2" />
      <Row className="justify-content-center p-4">
        Ingresa datos de la empresa
      </Row>
      <Row className="justify-content-center pt-4">
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Nombre de la empresa"}
            type={"text"}
            name={"companyName"}
            maxLength={100}
            required={true}
            value={companyName}
            placeholder={""}
            onChange={setCompanyName}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Razón Social"}
            type={"text"}
            name={"businessName"}
            maxLength={100}
            required={true}
            value={businessName}
            placeholder={""}
            onChange={setBusinessName}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormSelect
            label={"Condición Fiscal"}
            required
            name={"taxStatus"}
            value={taxStatus}
            options={documentTypes}
            onChange={setTaxStatus}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Documento"}
            type={"text"}
            name={"document"}
            maxLength={11}
            minLength={5}
            required={true}
            value={document}
            placeholder={""}
            onChange={setDocument}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Dirección"}
            type={"text"}
            name={"address"}
            maxLength={100}
            required={true}
            value={address}
            placeholder={""}
            onChange={setAddress}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Código postal"}
            type={"text"}
            name={"postalCode"}
            maxLength={5}
            minLength={5}
            required={true}
            value={postalCode}
            placeholder={""}
            onChange={setPostalCode}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Teléfono"}
            type={"text"}
            name={"phoneNumber"}
            maxLength={9}
            minLength={9}
            required={true}
            value={phoneNumber}
            placeholder={""}
            onChange={setPhoneNumber}
          />
        </Col>
        {loading ? (
          <Loading variant={"white"} />
        ) : (
          <Col xs={12} md={6} lg={6} className="mb-4">
            <FormSelect
              label={"Industria"}
              required
              name={"industry"}
              value={industry}
              options={industries}
              onChange={setIndustry}
            />
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default CompanyRegisterForm;
