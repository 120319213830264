import React, { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import { getApplicantInformation } from "../../api/application.api";
import addressIcon from "../../assets/images/profile/address-icon.png";
import phoneIcon from "../../assets/images/profile/phone-icon.png";
import careerIcon from "../../assets/images/profile/career-icon.png";
import industryIcon from "../../assets/images/profile/industry-icon.png";
import billIcon from "../../assets/images/profile/bill-icon.png";
import cvIcon from "../../assets/images/profile/cv-icon.png";
import { utils } from "../../common/utils";
import { Col, Image, Row } from "react-bootstrap";
import ProfileImage from "../profile-image/ProfileImage";
import ProfileDetails from "../profile-details/ProfileDetails";
import { getProfileViewInformation } from "../../api/profile-view.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

interface ApplicantProfileInformationProps {
  applicationId?: number;
  applicantId?: number;
  blurInfo?: boolean;
  viewInfo?: any;
}

const ApplicantProfileInformation: React.FC<
  ApplicantProfileInformationProps
> = ({ applicationId, applicantId, blurInfo = false, viewInfo }) => {
  const [applicant, setApplicant] = useState<any>();
  const [applicantItems, setApplicantItems] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadApplicantInformation = async () => {
      try {
        const applicantInformation = applicationId
          ? (await getApplicantInformation(applicationId)).data
          : applicantId
          ? (await getProfileViewInformation(applicantId)).data
          : {};
        setApplicant(applicantInformation);
        setApplicantItems([
          {
            icon: addressIcon,
            value: utils.getAddress(
              applicantInformation.address,
              applicantInformation.district
            ),
          },
          {
            icon: phoneIcon,
            value: applicantInformation.phoneNumber,
            blurInfo: blurInfo,
          },
          { icon: careerIcon, value: applicantInformation.career },
          {
            icon: industryIcon,
            value: utils.getSeniority(
              applicantInformation.seniorityId,
              applicantInformation.isExcelence
            ),
          },
          {
            icon: billIcon,
            value: utils.getSalaryWithCurrency(
              applicantInformation.desiredSalary
            ),
          },
        ]);
      } catch (error) {
        console.error("Error loading applicant profile:", error);
      } finally {
        setLoading(false);
      }
    };

    loadApplicantInformation();
  }, [blurInfo]);

  return loading ? (
    <Loading />
  ) : (
    <div className="bg-main generic-card p-4">
      <Row>
        <ProfileImage
          profileType={"applicant"}
          profileImage={applicant ? applicant.profileImage : ""}
          loading={loading}
          isExcelence={applicant && applicant.isExcelence}
          nonEditable={true}
          blurInfo={blurInfo}
        />
      </Row>
      <Row className="pt-4 text-white">
        {loading ? (
          <Loading variant={"white"} />
        ) : (
          <Row className="p-0">
            <Col xs={10} sm={10} md={10} lg={10} className="p-0">
              <h4 className={`p-0`}>
                <span className={`${blurInfo ? "blur" : ""}`}>
                  {utils.getFullName(applicant.lastName, applicant.firstName)}
                </span>
                <span className="ms-3">
                  {blurInfo && (
                    <FontAwesomeIcon
                      icon={faEye}
                      className="cursor-pointer"
                      onClick={viewInfo}
                    />
                  )}
                </span>
              </h4>
            </Col>
            {applicant.cvURL !== null && applicant.cvURL !== "" && (
              <Col xs={2} sm={2} md={2} lg={2} className="p-0">
                <Image
                  className="profile-icon-image cursor-pointer"
                  src={cvIcon}
                  width={40}
                  height={40}
                  onClick={
                    blurInfo
                      ? viewInfo
                      : () => window.open(applicant.cvURL, "_blank")
                  }
                />
              </Col>
            )}
          </Row>
        )}
      </Row>
      <Row className="pt-2">
        {loading ? (
          <Loading variant={"white"} />
        ) : (
          <ProfileDetails
            profileType={"applicant"}
            label={"Información del postulante"}
            description={applicant.description}
            items={applicantItems}
            loading={loading}
            nonEditable={true}
          />
        )}
      </Row>
    </div>
  );
};

export default ApplicantProfileInformation;
