import React from "react";
import { Table } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";

interface DataTableProps {
  headers: any;
  renderData: any;
  itemsCount: any;
  take: number;
  page: any;
  setPage: any;
}

const DataTable: React.FC<DataTableProps> = ({
  headers,
  renderData,
  page,
  setPage,
  itemsCount,
  take,
}) => {
  return (
    <>
      <Table responsive striped bordered hover className="mt-2">
        <thead>
          <tr>
            {headers.map((header: string) => {
              return <th key={header}>{header}</th>;
            })}
          </tr>
        </thead>
        <tbody>{renderData(page)}</tbody>
      </Table>
      <div className="mt-2">
        <PaginationControl
          page={page}
          total={itemsCount}
          limit={take}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </div>
    </>
  );
};

export default DataTable;
