import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import locationIcon from "../../assets/images/profile/address-icon.png";

interface BadgeDistrictProps {
  district: string;
}

const BadgeDistrict: React.FC<BadgeDistrictProps> = ({ district }) => {
  return (
    <div className="badge-seniority">
      <Row className="p-1">
        <Col xs={2} sm={2} md={2} lg={2} className="p-0">
          <Image src={locationIcon} alt="badge-icon" width={18} height={18} />
        </Col>
        <Col xs={10} sm={10} md={10} lg={10} className="ps-2 p-0">
          <span className="badge-text">{district}</span>
        </Col>
      </Row>
    </div>
  );
};

export default BadgeDistrict;
