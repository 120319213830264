import React, { useState } from "react";
import { Plan } from "../../../types/response/plan-response";
import GenericForm from "../../../components/generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormInput from "../../../components/form-input/FormInput";
import { useCompanyPlan } from "../../../hooks/useCompanyPlan";
import FormCheckbox from "../../../components/form-checkbox/FormCheckbox";

interface AdminCompanyPlansFormProps {
  item: Plan;
}

const AdminCompanyPlansForm: React.FC<AdminCompanyPlansFormProps> = ({
  item,
}) => {
  const [name, setName] = useState(item.name);
  const [price, setPrice] = useState(item.price);
  const [forSale, setForSale] = useState(item.forSale);
  const [salePrice, setSalePrice] = useState(item.salePrice);
  const [visibilityPeriod, setVisibilityPeriod] = useState(
    item.visibilityPeriod
  );
  const [modifyPost, setModifyPost] = useState(item.modifyPost);
  const [questions, setQuestions] = useState(item.questions);
  const [profileTalent, setProfileTalent] = useState(item.profileTalent);
  const [profileExperience, setProfileExperience] = useState(
    item.profileExperience
  );
  const [advertisements, setAdvertisements] = useState(item.advertisements);

  const handleSave = async (e: any) => {
    e.preventDefault();
    await updatePlan(item.id, {
      name,
      price,
      forSale,
      salePrice,
      visibilityPeriod,
      modifyPost,
      questions,
      profileTalent,
      profileExperience,
      advertisements,
    });
  };

  const { updatePlan, loading, message, setMessage, type } = useCompanyPlan();

  return (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={"Editar información del plan"}
      loading={loading}
      buttonText={"Actualizar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Nombre"}
          type={"text"}
          name={"name"}
          maxLength={100}
          required={true}
          value={name}
          placeholder={""}
          onChange={(e) => setName(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Número de anuncios"}
          type={"number"}
          name={"advertisements"}
          maxLength={100}
          required={true}
          value={advertisements}
          placeholder={""}
          onChange={(e) => setAdvertisements(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Precio"}
          type={"number"}
          name={"price"}
          maxLength={100}
          required={true}
          value={price}
          placeholder={""}
          onChange={(e) => setPrice(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Duración de anuncio"}
          type={"number"}
          name={"visibilityPeriod"}
          maxLength={100}
          required={true}
          value={visibilityPeriod}
          placeholder={""}
          onChange={(e) => setVisibilityPeriod(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Periodo de modificación"}
          type={"number"}
          name={"modifyPost"}
          maxLength={100}
          required={true}
          value={modifyPost}
          placeholder={""}
          onChange={(e) => setModifyPost(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Preguntas"}
          type={"number"}
          name={"questions"}
          maxLength={100}
          required={true}
          value={questions}
          placeholder={""}
          onChange={(e) => setQuestions(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Perfiles excelencia"}
          type={"number"}
          name={"profileTalent"}
          maxLength={100}
          required={true}
          value={profileTalent}
          placeholder={""}
          onChange={(e) => setProfileTalent(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Perfiles profesionales"}
          type={"number"}
          name={"profileExperience"}
          maxLength={100}
          required={true}
          value={profileExperience}
          placeholder={""}
          onChange={(e) => setProfileExperience(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4 text-white">
        <FormCheckbox
          type="switch"
          label={"En oferta"}
          checked={forSale}
          onChange={(e) => setForSale(e.target.checked)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Precio de oferta"}
          type={"number"}
          name={"salePrice"}
          maxLength={100}
          required={true}
          value={salePrice}
          placeholder={""}
          onChange={(e) => setSalePrice(e.target.value)}
        />
      </Col>
    </GenericForm>
  );
};

export default AdminCompanyPlansForm;
