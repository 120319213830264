import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AdvertisementApplicationItem from "../advertisement-application-item/AdvertisementApplicationItem";
import Loading from "../loading/Loading";
import { useApplicantsContext } from "../../context/ApplicantsContext/ApplicantsContext";
import { PaginationControl } from "react-bootstrap-pagination-control";
import SearchBar from "../search-bar/SearchBar";
import { advancedFilterTypes } from "../../common/advanced-filter-types";
import AdvancedFiltersApplications from "../advanced-filters-applications/AdvancedFiltersApplications";
import { toggleSaveApplicant } from "../../api/application.api";

const JobAdvertisementApplications: React.FC = () => {
  const { id } = useParams();

  const {
    loadingApplicantsByJobOffer,
    applicantsByJobOffer,
    applicantsByJobOfferPage,
    applicantsByJobOfferItemsCount,
    applicantsByJobOfferTake,
    changeApplicantsByJobOfferPage,
    loadApplicantsByJobOffer,
    changeIdJobOffer,
  } = useApplicantsContext();

  useEffect(() => {
    const callApplicantsInformation = async (id: string) => {
      await loadApplicantsByJobOffer(parseInt(id), false);
    };

    if (id) {
      changeIdJobOffer(parseInt(id));
      callApplicantsInformation(id);
    }
  }, [id, applicantsByJobOfferPage]);

  const handleSaveApplication = async (applicationId: number) => {
    await toggleSaveApplicant(applicationId);
    if (id) {
      await loadApplicantsByJobOffer(parseInt(id), true);
    }
  };

  return (
    <div className="bg-gray-soft">
      <SearchBar type={advancedFilterTypes.applications as "applications"} />
      <Row>
        <Col xs={12} md={2}>
          <AdvancedFiltersApplications />
        </Col>
        <Col xs={12} md={10}>
          <div className="mt-3 mb-3 ps-2 pe-2">
            {loadingApplicantsByJobOffer ? (
              <Loading />
            ) : applicantsByJobOffer.length === 0 ? (
              <div className="content-card mt-4 p-3 text-center">
                Aún no cuentas con postulaciones a este anuncio
              </div>
            ) : (
              <>
                <Row className="p-0">
                  {applicantsByJobOffer.map((application) => (
                    <AdvertisementApplicationItem
                      key={application.applicationId}
                      item={application}
                      onSave={handleSaveApplication}
                    />
                  ))}
                </Row>

                <PaginationControl
                  page={applicantsByJobOfferPage}
                  total={applicantsByJobOfferItemsCount}
                  limit={applicantsByJobOfferTake}
                  changePage={(page) => {
                    changeApplicantsByJobOfferPage(page);
                  }}
                  ellipsis={1}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default JobAdvertisementApplications;
