import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import "./CatalogButton.css";
import { useNavigate } from "react-router-dom";

interface CatalogButtonProps {
  buttonText: string;
  variant: string;
  url: string;
  icon?: string;
  iconWidth?: number;
  iconHeight?: number;
}

const CatalogButton: React.FC<CatalogButtonProps> = ({
  buttonText,
  variant,
  url,
  icon,
  iconWidth,
  iconHeight,
}) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(url);
  };

  return (
    <div className="mt-3 text-center">
      <Button
        type="button"
        className="catalog-button"
        variant={variant}
        onClick={handleClick}
      >
        <Row className="align-items-center text-start">
          {icon && (
            <Col sm={2}>
              <Image
                src={icon}
                style={{ width: iconWidth ?? 40, height: iconHeight ?? 40 }}
              />
            </Col>
          )}
          <Col sm={icon ? 10 : 12}>
            <span className="catalog-button-text ms-4">Chamba para</span>
            <br />
            <span className="catalog-button-text ms-4">{buttonText}</span>
          </Col>
        </Row>
      </Button>
    </div>
  );
};

export default CatalogButton;
