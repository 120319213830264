import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import FormInput from "../../form-input/FormInput";
import Loading from "../../loading/Loading";
import FormSelect from "../../form-select/FormSelect";
import GenericForm from "../../generic-form/GenericForm";
import { getCompanyInformation } from "../../../api/company.api";
import { useUpdateCompanyInformation } from "../../../hooks/useUpdateCompanyInformation";
import { UpdateCompanyInformationRequest } from "../../../types/request/update-company-information-request";
import { employeesRange } from "../../../common/employees-range";
import { getIndustries } from "../../../api/industries.api";

const CompanyInformation: React.FC = () => {
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [web, setWeb] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [employees, setEmployees] = useState("");
  const [industry, setIndustry] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [loadingIndustries, setLoadingIndustries] = useState(true);
  const [industries, setIndustries] = useState([]);

  const loadIndustries = async (): Promise<void> => {
    setLoadingIndustries(true);
    try {
      const response = await getIndustries();
      setIndustries(
        response.data.map((i: any) => ({ id: i.id, label: i.name }))
      );
    } catch (error) {
      console.error("Error loading industries", error);
    } finally {
      setLoadingIndustries(false);
    }
  };

  useEffect(() => {
    const getIndustriesOptions = async () => {
      await loadIndustries();
    };
    getIndustriesOptions();
  }, []);

  const loadCompanyInformation = async () => {
    try {
      const companyInformation = (await getCompanyInformation()).data;
      setDescription(companyInformation.description);
      setAddress(companyInformation.address);
      setWeb(companyInformation.web);
      setEmail(companyInformation.email);
      setPhone(companyInformation.phoneNumber);
      setEmployees(companyInformation.employees);
      setIndustry(companyInformation.industry);
    } catch (error) {
      console.error("Error loading profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const { updateInformation, loading, message, setMessage, type } =
    useUpdateCompanyInformation();

  useEffect(() => {
    loadCompanyInformation();
  }, []);

  const handleSave = async (e: any) => {
    e.preventDefault();
    const request: UpdateCompanyInformationRequest = {
      address,
      phoneNumber: phone,
      description,
      web,
      employees,
      industry,
    };
    await updateInformation(request);
  };

  return isLoading ? (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "70vh" }}
    >
      <Loading />
    </div>
  ) : (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={"Editar información de la empresa"}
      loading={loading}
      buttonText={"Actualizar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Dirección"}
          type={"text"}
          name={"address"}
          maxLength={100}
          required={true}
          value={address}
          placeholder={""}
          onChange={(e) => setAddress(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Web"}
          type={"text"}
          name={"web"}
          maxLength={100}
          required={false}
          value={web}
          placeholder={""}
          onChange={(e) => setWeb(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Email"}
          type={"text"}
          name={"email"}
          maxLength={100}
          required={true}
          disabled
          value={email}
          placeholder={""}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Teléfono"}
          type={"text"}
          name={"phone"}
          maxLength={9}
          required={true}
          value={phone}
          placeholder={""}
          onChange={(e) => setPhone(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormSelect
          label={"Número de empleados"}
          name={"employees"}
          required={false}
          value={employees}
          options={employeesRange}
          placeholder={""}
          onChange={(e) => setEmployees(e.target.value)}
        />
      </Col>
      {loadingIndustries ? (
        <Loading variant={"white"} />
      ) : (
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormSelect
            label={"Industria"}
            required
            name={"industry"}
            value={industry}
            options={industries}
            onChange={(e) => setIndustry(e.target.value)}
          />
        </Col>
      )}
      <Col xs={12} md={12} lg={12} className="mb-4">
        <FormInput
          label={"Descripción"}
          type={"textarea"}
          name={"description"}
          maxLength={1000}
          required={true}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={""}
        />
      </Col>
    </GenericForm>
  );
};

export default CompanyInformation;
