import { ForgotPasswordRequest } from "./../types/request/forgot-password-request";
import { useState } from "react";
import {
  forgotPasswordApplicant,
  forgotPasswordCompany,
} from "../api/auth.api";
import { resultType } from "../common/result-type";

export const useForgotPassword = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const sendRecoveryEmail = async (
    forgotPasswordRequest: ForgotPasswordRequest
  ) => {
    setLoading(true);

    try {
      forgotPasswordRequest.userType === "applicant"
        ? await forgotPasswordApplicant(forgotPasswordRequest.email)
        : await forgotPasswordCompany(forgotPasswordRequest.email);
      setLoading(false);
      setMessage("¡Correo de restablecimiento de contraseña enviado!");
      setType(resultType.success);
    } catch (ex) {
      setLoading(false);
      setMessage("Estas credenciales no coinciden con nuestros registros.");
      setType(resultType.error);
    }
  };

  return {
    sendRecoveryEmail,
    loading,
    message,
    setMessage,
    type,
  };
};
