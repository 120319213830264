import { createBrowserRouter } from "react-router-dom";
import PageContainer from "./PageContainer";
import Home from "../pages/home/Home";
import ResetPassword from "../pages/reset-password/ResetPassword";
import Login from "../pages/login/Login";
import Protected from "./Protected";
import CompanyMain from "../pages/company-main/CompanyMain";
import AddAdvertisement from "../components/company/add-advertisement/AddAdvertisement";
import ApplicantMain from "../pages/applicant-main/ApplicantMain";
import ApplicantInformation from "../components/applicant/applicant-information/ApplicantInformation";
import SaveExperience from "../components/applicant/save-experience/SaveExperience";
import SaveStudy from "../components/applicant/save-study/SaveStudy";
import SaveLanguage from "../components/applicant/save-language/SaveLanguage";
import PlansMain from "../pages/plans-main/PlansMain";
import { roles } from "../common/roles";
import ApplicantUpgrade from "../components/applicant/applicant-upgrade/ApplicantUpgrade";
import CompanyInformation from "../components/company/company-information/CompanyInformation";
import JobOffersMain from "../pages/job-offers-main/JobOffersMain";
import CatalogueMain from "../pages/catalogue-main/CatalogueMain";
import PrivacyPolicyMain from "../pages/privacy-policy-main/PrivacyPolicyMain";
import AdminPageContainer from "./AdminPageContainer";
import AdminUsers from "../pages/admin/users/AdminUsers";
import AdminCompanies from "../pages/admin/companies/AdminCompanies";
import AdminUpgradeRequests from "../pages/admin/upgrade-requests/AdminUpgradeRequests";
import AdminAdvertisements from "../pages/admin/advertisements/AdminAdvertisements";
import AdminUserPlans from "../pages/admin/user-plans/AdminUserPlans";
import AdminCompanyPlans from "../pages/admin/company-plans/AdminCompanyPlans";
import AdminUpgradeRequestsSave from "../pages/admin/upgrade-requests/AdminUpgradeRequestsSave";
import AdminCompanyPlansSave from "../pages/admin/company-plans/AdminCompanyPlansSave";
import AdminUserPlansSave from "../pages/admin/user-plans/AdminUserPlansSave";
import AdminUsersSave from "../pages/admin/users/AdminUsersSave";
import AdminCompaniesSave from "../pages/admin/companies/AdminCompaniesSave";
import AdminIndustries from "../pages/admin/industries/AdminIndustries";
import AdminIndustriesSave from "../pages/admin/industries/AdminIndustriesSave";
import AdminCareers from "../pages/admin/careers/AdminCareers";
import AdminCareersSave from "../pages/admin/careers/AdminCareersSave";
import AdminCareersCreate from "../pages/admin/careers/AdminCareersCreate";
import AdminAdvertisementsSave from "../pages/admin/advertisements/AdminAdvertisementsSave";
import CokiesPolicyMain from "../pages/cookies-policy-main/CookiesPolicyMain";
import TermsAndConditionsMain from "../pages/terms-and-conditions-main/TermsAndConditionsMain";
import JobAdvertisementDetails from "../components/job-advertisement-details/JobAdvertisementDetails";
import JobAdvertisementApplications from "../components/job-advertisement-applications/JobAdvertisementApplications";
import ProfileViewApplication from "../components/profile-view/application/ProfileViewApplication";
import ProfileViewApplicant from "../components/profile-view/applicant/ProfileViewApplicant";
import AdminProfileViewPackages from "../pages/admin/profile-view-packages/AdminProfileViewPackages";
import AdminProfileViewPackagesSave from "../pages/admin/profile-view-packages/AdminProfileViewPackagesSave";
import PackagesMain from "../pages/packages-main/PackagesMain";
import AdminNews from "../pages/admin/news/AdminNews";
import AdminNewsSave from "../pages/admin/news/AdminNewsSave";
import AdminUserPurchases from "../pages/admin/user-purchases/AdminUserPurchases";
import AdminCompanyPurchases from "../pages/admin/company-purchases/AdminCompanyPurchases";
import AdminProfilePurchases from "../pages/admin/profile-purchases/AdminProfilePurchases";
import AdminUserPurchasesSave from "../pages/admin/user-purchases/AdminUserPurchasesSave";
import AdminCompanyPurchasesSave from "../pages/admin/company-purchases/AdminCompanyPurchasesSave";
import AdminProfilePurchasesSave from "../pages/admin/profile-purchases/AdminProfilePurchasesSave";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageContainer element={<Home />} />,
  },
  {
    path: "/home",
    element: <PageContainer element={<Home />} />,
  },
  {
    path: "/privacy-policy",
    element: <PageContainer element={<PrivacyPolicyMain />} />,
  },
  {
    path: "/cookies-policy",
    element: <PageContainer element={<CokiesPolicyMain />} />,
  },
  {
    path: "/terms-and-conditions",
    element: <PageContainer element={<TermsAndConditionsMain />} />,
  },
  {
    path: "/auth",
    element: <PageContainer element={<Login />} />,
  },
  {
    path: "/applicant",
    element: (
      <Protected roles={[roles.user, roles.admin]}>
        <PageContainer element={<ApplicantMain />} />
      </Protected>
    ),
  },
  {
    path: "/applicant/information",
    element: (
      <Protected roles={[roles.user, roles.admin]}>
        <PageContainer element={<ApplicantInformation />} />
      </Protected>
    ),
  },
  {
    path: "/applicant/upgrade",
    element: (
      <Protected roles={[roles.user, roles.admin]}>
        <PageContainer element={<ApplicantUpgrade />} />
      </Protected>
    ),
  },
  {
    path: "/company",
    element: (
      <Protected roles={[roles.company]}>
        <PageContainer element={<CompanyMain />} />
      </Protected>
    ),
  },
  {
    path: "/company/information",
    element: (
      <Protected roles={[roles.company]}>
        <PageContainer element={<CompanyInformation />} />
      </Protected>
    ),
  },
  {
    path: "/add-advertisement",
    element: (
      <Protected roles={[roles.company]}>
        <PageContainer element={<AddAdvertisement />} />
      </Protected>
    ),
  },
  {
    path: "/edit-advertisement/:urlCode?",
    element: (
      <Protected roles={[roles.company]}>
        <PageContainer element={<AddAdvertisement />} />
      </Protected>
    ),
  },
  {
    path: "/advertisement/:urlCode?",
    element: <PageContainer element={<AddAdvertisement readOnly />} />,
  },
  {
    path: "/save-experience/:id?",
    element: (
      <Protected roles={[roles.user, roles.admin]}>
        <PageContainer element={<SaveExperience />} />
      </Protected>
    ),
  },
  {
    path: "/save-study/:id?",
    element: (
      <Protected roles={[roles.user, roles.admin]}>
        <PageContainer element={<SaveStudy />} />
      </Protected>
    ),
  },
  {
    path: "/save-language/:id?",
    element: (
      <Protected roles={[roles.user, roles.admin]}>
        <PageContainer element={<SaveLanguage />} />
      </Protected>
    ),
  },
  {
    path: "/plans",
    element: (
      <Protected roles={[roles.user, roles.company, roles.admin]}>
        <PageContainer element={<PlansMain />} />
      </Protected>
    ),
  },
  {
    path: "/packages",
    element: (
      <Protected roles={[roles.company]}>
        <PageContainer element={<PackagesMain />} />
      </Protected>
    ),
  },
  {
    path: "/job-details/:id",
    element: (
      <Protected roles={[roles.user, roles.admin]}>
        <PageContainer element={<JobAdvertisementDetails />} />
      </Protected>
    ),
  },
  {
    path: "/advertisement/applications/:id",
    element: (
      <Protected roles={[roles.company]}>
        <PageContainer element={<JobAdvertisementApplications />} />
      </Protected>
    ),
  },
  {
    path: "/profile-view/application/:id",
    element: (
      <Protected roles={[roles.company]}>
        <PageContainer element={<ProfileViewApplication />} />
      </Protected>
    ),
  },
  {
    path: "/profile-view/applicant/:id",
    element: (
      <Protected roles={[roles.company]}>
        <PageContainer element={<ProfileViewApplicant />} />
      </Protected>
    ),
  },
  {
    path: "/job-offers",
    element: <PageContainer element={<JobOffersMain />} />,
  },
  {
    path: "/catalogue",
    element: <PageContainer element={<CatalogueMain />} />,
  },
  {
    path: "/reset-password/:userType/:token",
    element: <PageContainer element={<ResetPassword />} />,
  },
  {
    path: "admin/users",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminUsers />} />
      </Protected>
    ),
  },
  {
    path: "admin/users/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminUsersSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/companies",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminCompanies />} />
      </Protected>
    ),
  },
  {
    path: "admin/companies/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminCompaniesSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/advertisements",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminAdvertisements />} />
      </Protected>
    ),
  },
  {
    path: "admin/advertisements/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminAdvertisementsSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/upgrade-requests",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminUpgradeRequests />} />
      </Protected>
    ),
  },
  {
    path: "admin/upgrade-requests/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminUpgradeRequestsSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/user-plans",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminUserPlans />} />
      </Protected>
    ),
  },
  {
    path: "admin/user-plans/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminUserPlansSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/company-plans",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminCompanyPlans />} />
      </Protected>
    ),
  },
  {
    path: "admin/company-plans/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminCompanyPlansSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/company-packages",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminProfileViewPackages />} />
      </Protected>
    ),
  },
  {
    path: "admin/company-packages/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminProfileViewPackagesSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/user-purchases",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminUserPurchases />} />
      </Protected>
    ),
  },
  {
    path: "admin/user-purchases/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminUserPurchasesSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/company-purchases",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminCompanyPurchases />} />
      </Protected>
    ),
  },
  {
    path: "admin/company-purchases/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminCompanyPurchasesSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/profile-purchases",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminProfilePurchases />} />
      </Protected>
    ),
  },
  {
    path: "admin/profile-purchases/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminProfilePurchasesSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/industries",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminIndustries />} />
      </Protected>
    ),
  },
  {
    path: "admin/industries/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminIndustriesSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/industries/careers/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminCareers />} />
      </Protected>
    ),
  },
  {
    path: "admin/industries/careers/edit/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminCareersSave />} />
      </Protected>
    ),
  },
  {
    path: "admin/industries/careers/create/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminCareersCreate />} />
      </Protected>
    ),
  },
  {
    path: "admin/news",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminNews />} />
      </Protected>
    ),
  },
  {
    path: "admin/news/:id",
    element: (
      <Protected roles={[roles.admin]}>
        <AdminPageContainer element={<AdminNewsSave />} />
      </Protected>
    ),
  },
]);

export default router;
