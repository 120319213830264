import React from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import instagramIcon from "../../assets/images/social-media/instagram-icon.png";
import twitterIcon from "../../assets/images/social-media/twitter-icon.png";
import linkedinIcon from "../../assets/images/social-media/linkedin-icon.png";
import tiktokIcon from "../../assets/images/social-media/tiktok-icon.png";
import SocialMediaIcon from "./social-media-icon/SocialMediaIcon";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer-container">
      <Container className="mt-5">
        <Row>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div>
              <h4>La empresa</h4>
              <br />
              <p
                className="cursor-pointer"
                onClick={() => navigate("/privacy-policy")}
              >
                Política de Privacidad
              </p>
              <p
                className="cursor-pointer"
                onClick={() => navigate("/cookies-policy")}
              >
                Política de Cookies
              </p>
            </div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div>
              <h4>Candidatos</h4>
              <br />
              <p
                className="cursor-pointer"
                onClick={() => navigate("/terms-and-conditions")}
              >
                Términos y Condiciones
              </p>
            </div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div>
              <h4>Contáctanos</h4>
              <br />
              <p className="cursor-pointer">
                <a
                  className="contact-mail"
                  href="mailto:atencionalclientecsh@gmail.com"
                >
                  Libro de reclamaciones: atencionalclientecsh@gmail.com
                </a>
              </p>
              <p className="cursor-pointer">
                <a
                  className="contact-mail"
                  href="mailto:soportetecnicocsh@gmail.com"
                >
                  Soporte técnico: soportetecnicocsh@gmail.com
                </a>
              </p>
              <p className="cursor-pointer">
                <a
                  className="contact-mail"
                  href="mailto:a.comercialcsh@gmail.com"
                >
                  Ventas / comercial: a.comercialcsh@gmail.com
                </a>
              </p>
            </div>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <div>
              <h4>Nuestras redes</h4>
              <br />
              <SocialMediaIcon
                src={twitterIcon}
                url="https://twitter.com/chambasihay"
              />
              <SocialMediaIcon
                src={instagramIcon}
                url="https://www.instagram.com/chambasihay.com.pe"
              />
              <SocialMediaIcon
                src={linkedinIcon}
                url="https://www.linkedin.com/company/chambasihay/"
              />
              <SocialMediaIcon
                src={tiktokIcon}
                url="https://www.tiktok.com/@chambasihaype"
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <h6 className="text-center">
            ©CopyRight <b>Chamba Sí Hay</b> 2024
          </h6>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
