import { useState } from "react";
import { resultType } from "../common/result-type";
import { UpdateCompanyInformationRequest } from "../types/request/update-company-information-request";
import { updateCompanyInformation } from "../api/company.api";

export const useUpdateCompanyInformation = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const updateInformation = async (request: UpdateCompanyInformationRequest) => {
    setLoading(true);

    try {
      await updateCompanyInformation(request);
      setLoading(false);
      setMessage("Información actualizada!");
      setType(resultType.success);
    } catch (ex) {
      setLoading(false);
      setMessage("Hubo un error al actualizar la información.");
      setType(resultType.error);
    }
  };

  return {
    updateInformation,
    loading,
    message,
    setMessage,
    type,
  };
};
