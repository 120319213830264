import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import EntityTable from "../../../components/admin/entity-table/EntityTable";
import DataTableButtons from "../../../components/admin/data-table-buttons/DataTableButtons";
import { utils } from "../../../common/utils";
import { UserPurchases } from "../../../types/response/user-purchases";
import { getUserPurchases } from "../../../api/user-purchases.api";

const AdminUserPurchases: React.FC = () => {
  const [userPurchases, setUserPurchases] = useState<UserPurchases[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);

  const take = 10;

  useEffect(() => {
    const loadUserPurchases = async () => {
      try {
        const response = await getUserPurchases(page, take);
        const paginatedData = response.data;
        setUserPurchases(paginatedData.data);
        setItemsCount(paginatedData.meta.itemCount);
      } catch (error) {
        console.error("Error loading news", error);
      } finally {
        setLoading(false);
      }
    };

    loadUserPurchases();
  }, [page]);

  const renderNews = () => {
    return userPurchases.map((userPurchase) => (
      <tr key={userPurchase.id}>
        <td>{userPurchase.id}</td>
        <td>{userPurchase.email}</td>
        <td>{userPurchase.planName}</td>
        <td>{utils.formatDate(userPurchase.createdAt)}</td>
        <td>S/. {userPurchase.price}</td>
        <td>{userPurchase.billGenerated ? "Realizada" : "Pendiente"}</td>
        <td>
          <DataTableButtons
            itemId={userPurchase.id}
            handleView={(id: number) => navigate(`/admin/user-purchases/${id}`)}
          />
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <EntityTable
          title={"Compras de usuarios"}
          headers={[
            "ID",
            "Usuario",
            "Plan",
            "Fecha de compra",
            "Precio",
            "Estado",
            "Acciones",
          ]}
          renderData={renderNews}
          page={page}
          setPage={setPage}
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
        />
      )}
    </Container>
  );
};

export default AdminUserPurchases;
