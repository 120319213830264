import React, { useState } from "react";
import GenericForm from "../../../components/generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormInput from "../../../components/form-input/FormInput";
import FormCheckbox from "../../../components/form-checkbox/FormCheckbox";
import { New } from "../../../types/response/new";
import { useNew } from "../../../hooks/useNew";

interface AdminNewsFormProps {
  item: New;
}

const AdminNewsForm: React.FC<AdminNewsFormProps> = ({ item }) => {
  const [subject, setSubject] = useState(item.subject);
  const [description, setDescription] = useState(item.description);
  const [frequency, setFrequency] = useState(item.frequency);
  const [enabled, setEnabled] = useState(item.enabled);
  const [body, setBody] = useState(item.body);

  const handleSave = async (e: any) => {
    e.preventDefault();
    await updateNews(item.id, {
      subject,
      description,
      enabled,
      body,
    });
  };

  const { updateNews, loading, message, setMessage, type } = useNew();

  return (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={"Editar correo"}
      loading={loading}
      buttonText={"Actualizar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Asunto"}
          type={"text"}
          name={"subject"}
          maxLength={200}
          required={true}
          value={subject}
          placeholder={""}
          onChange={(e) => setSubject(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Descripción"}
          type={"text"}
          name={"description"}
          maxLength={100}
          required={true}
          value={description}
          placeholder={""}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4 text-white">
        <FormCheckbox
          type="switch"
          label={"Habilitado"}
          checked={enabled}
          onChange={(e) => setEnabled(e.target.checked)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Frecuencia"}
          type={"text"}
          name={"description"}
          maxLength={20}
          required={false}
          value={frequency}
          disabled={true}
          placeholder={""}
          onChange={(e) => setFrequency(e.target.value)}
        />
      </Col>
      <Col xs={12} md={12} lg={12} className="mb-4">
        <FormInput
          label={"Mensaje"}
          type={"textarea"}
          name={"body"}
          maxLength={2000}
          required={false}
          value={body}
          onChange={(e) => setBody(e.target.value)}
          placeholder={""}
        />
      </Col>
    </GenericForm>
  );
};

export default AdminNewsForm;
