import React from "react";
import "./Steps.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import DotsDecorator from "../dots-decorator/DotsDecorator";
import StepIcon from "./step-icon/StepIcon";
import stepsRegister from "../../../assets/images/steps/steps-register.png";
import stepsForm from "../../../assets/images/steps/steps-form.png";
import stepsCurriculum from "../../../assets/images/steps/steps-curriculum.png";
import stepsArrow from "../../../assets/images/steps/steps-arrow.png";
import { Fade } from "react-awesome-reveal";
import FormButton from "../../form-button/FormButton";
import { useNavigate } from "react-router";

const Steps: React.FC = () => {
  const navigate = useNavigate();

  return (
    <section id="steps">
      <Container className="text-center">
        <div className="p-5">
          <h2 className="mb-3">¿Qué esperas para unirte?</h2>
          <DotsDecorator width={10} height={10} center={true} />
        </div>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} md={2} className="step-col">
            <Fade delay={250} triggerOnce={true}>
              <StepIcon
                src={stepsRegister}
                description="Haz click en la opción regístrate"
              />
            </Fade>
          </Col>
          <Col xs={12} md={1} className="arrow-col text-center">
            <Fade delay={500} triggerOnce={true}>
              <div className="arrow-align">
                <Image src={stepsArrow} />
              </div>
            </Fade>
          </Col>
          <Col xs={12} md={2} className="step-col">
            <Fade delay={750} triggerOnce={true}>
              <StepIcon
                src={stepsForm}
                description="Completa el formulario con tu información"
              />
            </Fade>
          </Col>
          <Col xs={12} md={1} className="arrow-col text-center">
            <Fade delay={1000} triggerOnce={true}>
              <div className="arrow-align">
                <Image src={stepsArrow} />
              </div>
            </Fade>
          </Col>
          <Col xs={12} md={2} className="step-col">
            <Fade delay={1250} triggerOnce={true}>
              <StepIcon
                src={stepsCurriculum}
                description="Sube tu CV y comienza la búsqueda profesional"
              />
            </Fade>
          </Col>
        </Row>
        <Row className="p-5">
          <Fade delay={1500} triggerOnce={true}>
            <FormButton
              type="link"
              buttonText="Regístrate"
              variant="main"
              onClick={() =>
                navigate("/auth?userType=applicant&authType=register")
              }
              mainFont={true}
            />
          </Fade>
        </Row>
      </Container>
    </section>
  );
};

export default Steps;
