import React from "react";
import { ApplicantSummarized } from "../applicants-catalogue/ApplicantsCatalogue";
import applicantAnonymous from "../../assets/images/profile/applicant-anonymous.png";
import { Row, Col, Image } from "react-bootstrap";
import "./ApplicantCatalogueItem.css";
import BadgeSeniority from "../company/badge-seniority/BadgeSeniority";
import BadgeDistrict from "../badge-district/BadgeDistrict";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

interface ApplicantCatalogueItemProps {
  item: ApplicantSummarized;
  onClick: () => void;
  blurData: boolean;
}

const ApplicantCatalogueItem: React.FC<ApplicantCatalogueItemProps> = ({
  item,
  onClick,
  blurData,
}) => {
  return (
    <Col xs={12} sm={6} md={4} lg={4} className="mb-4">
      <div className="catalogue-card p-4" onClick={onClick}>
        <Row className="p-0">
          <Col xs={6} sm={6} md={6} lg={6} className="p-0">
            <div className={`${blurData ? "blur" : ""} p-0`}>
              {item.profileImage === "" || item.profileImage === null ? (
                <Image
                  src={applicantAnonymous}
                  width={120}
                  height={120}
                  rounded
                />
              ) : (
                <Image
                  src={item.profileImage}
                  width={120}
                  height={120}
                  rounded
                />
              )}
            </div>
          </Col>
          {item.isExcelence && (
            <>
              <Col xs={5} sm={5} md={5} lg={5} className="p-0 text-end">
                <div className="color-main me-1">Excelencia</div>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} className="p-0">
                <div className="circle-star-container">
                  <FontAwesomeIcon icon={faStar} size={"lg"} color={"white"} />
                </div>
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-2 p-0">
          <h5 className={`${blurData ? "blur" : ""} p-0 font-bold`}>
            {blurData ? "Nombres Apellidos" : item.fullName}
          </h5>
        </Row>
        <Row className="mt-1 p-0">
          <p className="p-0 color-main mb-0">{item.career}</p>
        </Row>
        <Row className="mt-1 p-0">
          <p className="p-0 mb-0 applicant-description">{item.description}</p>
        </Row>
        <Row className="mt-2 justify-content-center">
          <BadgeSeniority
            seniority={
              item.seniority as "student" | "excelence" | "professional"
            }
          />
          {item.district !== "" && item.district !== null && (
            <BadgeDistrict district={item.district} />
          )}
        </Row>
      </div>
    </Col>
  );
};

export default ApplicantCatalogueItem;
