import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ApplicantProfileInformation from "../ApplicantProfileInformation";
import ApplicantProfileCurriculum from "../ApplicantProfileCurriculum";
import { activateProfile, checkProfile } from "../../../api/profile-view.api";
import Loading from "../../loading/Loading";
import ProfileViewWarningModal from "../../profile-view-warning-modal/ProfileViewWarningModal";
import ProfileViewInsufficientModal from "../../profile-view-insufficient-modal/ProfileViewInsufficientModal";
import { CheckProfileDto } from "../../../types/response/check-profile";

const ProfileViewApplicant: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [checkView, setCheckView] = useState<CheckProfileDto>();

  const [showWarning, setShowWarning] = useState(false);
  const [showInsufficientView, setShowInsufficientView] = useState(false);

  const [blur, setBlur] = useState(true);
  const [insufficientView, setInsufficientView] = useState(true);

  const { id } = useParams();

  const applicantId = parseInt(id ?? "");

  const navigate = useNavigate();

  const getProfile = async () => {
    const activate = (await activateProfile(applicantId)).data;
    setBlur(false);
    setShowWarning(false);
  };

  const viewInfo = async () => {
    if (insufficientView) {
      setShowInsufficientView(true);
    } else {
      setShowWarning(true);
    }
  };

  useEffect(() => {
    const viewProfile = async (id: string) => {
      try {
        const checkViewData = (await checkProfile(parseInt(id))).data;
        setCheckView(checkViewData);
        if (checkViewData.isAllowed && checkViewData.byPassWarning) {
          setBlur(false);
        } else if (checkViewData.isAllowed && !checkViewData.byPassWarning) {
          setBlur(true);
          setInsufficientView(false);
        } else {
          setBlur(true);
          setInsufficientView(true);
        }
      } catch (ex) {
        navigate("/catalogue");
      } finally {
        setLoading(false);
      }
    };
    if (id) viewProfile(id);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div className="p-4 applicant-section">
      <Row>
        <Col md={4} lg={4}>
          <ApplicantProfileInformation
            applicantId={applicantId}
            blurInfo={blur}
            viewInfo={viewInfo}
          />
        </Col>
        <Col md={8} lg={8}>
          <ApplicantProfileCurriculum applicantId={applicantId} />
        </Col>
      </Row>
      <ProfileViewInsufficientModal
        showInsufficientViewModal={showInsufficientView}
        handleCloseInsufficientViewModal={() => setShowInsufficientView(false)}
      />
      <ProfileViewWarningModal
        showWarningModal={showWarning}
        handleCloseWarningModal={() => setShowWarning(false)}
        modalHeader={`Este es un perfil bloqueado`}
        modalBody={`Actualmente tienes ${checkView?.currentCounter} ${
          checkView?.currentCounter === 1 ? "visualización" : "visualizaciones"
        } para perfiles de este tipo. Te quedarán ${
          (checkView?.currentCounter ?? 1) - 1
        } luego de visualizar el perfil.`}
        viewProfile={() => getProfile()}
      />
    </div>
  );
};

export default ProfileViewApplicant;
