import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";

export interface SearchBarAutocompleteProps {
  id: string;
  label: string;
  initialValue?: string;
  disabled?: boolean;
  options: Option[];
  placeholder: string;
  onChange: (selected: Option[]) => void;
}

const SearchBarAutocomplete: React.FC<SearchBarAutocompleteProps> = ({
  id,
  label,
  initialValue,
  options,
  disabled,
  placeholder,
  onChange,
}) => {
  return (
    <Form.Group className="form-group p-3">
      <FloatingLabel
        className={"floating-label-search-typeahead"}
        controlId={label}
        label={label}
      >
        <Typeahead
          className="search-bar-typeahead"
          id={id}
          onChange={onChange}
          options={options}
          defaultInputValue={initialValue ?? ""}
          disabled={disabled}
          placeholder={placeholder}
        />
      </FloatingLabel>
    </Form.Group>
  );
};

export default SearchBarAutocomplete;
