import React, { useState } from "react";
import GenericForm from "../../../components/generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormInput from "../../../components/form-input/FormInput";
import FormCheckbox from "../../../components/form-checkbox/FormCheckbox";
import { ProfilePurchases } from "../../../types/response/profile-purchases";
import { utils } from "../../../common/utils";
import { usePurchaseProfile } from "../../../hooks/usePurchaseProfile";

interface AdminProfilePurchasesFormProps {
  item: ProfilePurchases;
}

const AdminProfilePurchasesForm: React.FC<AdminProfilePurchasesFormProps> = ({
  item,
}) => {
  const [companyName, setCompanyName] = useState(item.companyName);
  const [planName, setPlanName] = useState(item.planName);
  const [date, setDate] = useState(utils.formatDate(item.createdAt));
  const [price, setPrice] = useState(item.price);
  const [billType, setBillType] = useState(
    item.billType === "receipt" ? "Boleta" : "Factura"
  );
  const [billGenerated, setBillGenerated] = useState(item.billGenerated);
  const [billDetails, setBillDetails] = useState(
    utils.getBillDetails(item.billType, item.billDetails)
  );

  const handleSave = async (e: any) => {
    e.preventDefault();
    await generateCompanyBill({ id: item.id, billGenerated });
  };

  const { generateCompanyBill, loading, message, setMessage, type } =
    usePurchaseProfile();

  return (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={"Generar boleta / factura"}
      loading={loading}
      buttonText={"Actualizar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Empresa"}
          type={"text"}
          name={"companyName"}
          maxLength={200}
          required={true}
          value={companyName}
          placeholder={""}
          disabled={true}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Plan"}
          type={"text"}
          name={"plan"}
          maxLength={100}
          required={true}
          value={planName}
          placeholder={""}
          disabled={true}
          onChange={(e) => setPlanName(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Fecha"}
          type={"text"}
          name={"date"}
          maxLength={100}
          required={true}
          value={date}
          placeholder={""}
          disabled={true}
          onChange={(e) => setDate(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Precio"}
          type={"text"}
          name={"price"}
          maxLength={100}
          required={true}
          value={price}
          placeholder={""}
          disabled={true}
          onChange={(e) => setPrice(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Tipo"}
          type={"text"}
          name={"type"}
          maxLength={100}
          required={true}
          value={billType}
          placeholder={""}
          disabled={true}
          onChange={(e) => setBillType(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4 text-white">
        <FormCheckbox
          type="switch"
          label={"Generada"}
          checked={billGenerated}
          onChange={(e) => setBillGenerated(e.target.checked)}
        />
      </Col>
      <Col xs={12} md={12} lg={12} className="mb-4">
        <FormInput
          label={"Detalles"}
          type={"textarea"}
          name={"details"}
          maxLength={2000}
          required={false}
          disabled={true}
          value={billDetails}
          onChange={(e) => setBillDetails(e.target.value)}
          placeholder={""}
        />
      </Col>
    </GenericForm>
  );
};

export default AdminProfilePurchasesForm;
