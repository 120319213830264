import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";

const { APPLICANT_URL } = apiUrls;

export function getProfile() {
  const url = `${APPLICANT_URL}/profile`;
  return APIMiddleware.get(url);
}

export function getApplicationsSummarized(page: number, take: number) {
  const url = `${APPLICANT_URL}/applications-summarized?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function getCompanyApplicationsSummarized(page: number, take: number) {
  const url = `${APPLICANT_URL}/company/applications-summarized?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function getActivity() {
  const url = `${APPLICANT_URL}/activity`;
  return APIMiddleware.get(url);
}

export function getExperiences() {
  const url = `${APPLICANT_URL}/experiences`;
  return APIMiddleware.get(url);
}

export function getStudies() {
  const url = `${APPLICANT_URL}/studies`;
  return APIMiddleware.get(url);
}

export function getLanguages() {
  const url = `${APPLICANT_URL}/languages`;
  return APIMiddleware.get(url);
}

export function updateApplicantInformation(formData: FormData) {
  const url = `${APPLICANT_URL}/information`;
  return APIMiddleware.patch(url, {
    data: formData,
    isFormData: true,
  });
}

export function updateProfilePicture(formData: FormData) {
  const url = `${APPLICANT_URL}/image`;
  return APIMiddleware.patch(url, {
    data: formData,
    isFormData: true,
  });
}

export function sendExcelenceRequest(formData: FormData) {
  const url = `${APPLICANT_URL}/excelence-request`;
  return APIMiddleware.post(url, {
    data: formData,
    isFormData: true,
  });
}
