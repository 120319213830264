import React from "react";
import { Col, Row } from "react-bootstrap";
import FormInput from "../../form-input/FormInput";
import FormResult from "../../form-result/FormResult";

interface ForgotPasswordFormProps {
  email?: string;
  setEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  userType?: string;
  message?: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  type: string;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  email,
  setEmail,
  userType,
  message,
  setMessage,
  type,
}) => {
  return (
    <Row className="justify-content-center">
      <div
        className={`${
          userType === "applicant" ? "text-white" : "text-black"
        } text-center`}
      >
        <span>
          Ingresa el correo con el que creaste la cuenta y te enviaremos una
          contraseña nueva al correo.
        </span>
      </div>
      <FormResult message={message} setMessage={setMessage} type={type} />
      <Col xs={12} md={12} lg={12} className="mb-4 pt-4">
        <FormInput
          label={"Correo electrónico"}
          type={"email"}
          name={"email"}
          maxLength={100}
          required={true}
          value={email}
          placeholder={"example@gmail.com"}
          onChange={setEmail}
        />
      </Col>
    </Row>
  );
};

export default ForgotPasswordForm;
