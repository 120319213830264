import { APIMiddleware } from "../middleware/api-middleware";
import { apiUrls } from "../common/api-urls";
import { BuyCompanyPlanRequest } from "../types/request/buy-company-plan-request";

const { COMPANY_PURCHASES_URL } = apiUrls;

export function buyCompanyPlan(buyCompanyPlan: BuyCompanyPlanRequest) {
  const url = `${COMPANY_PURCHASES_URL}/buy-company-plan`;
  return APIMiddleware.post(url, {
    data: {
      price: buyCompanyPlan.price,
      token: buyCompanyPlan.token,
      planId: buyCompanyPlan.planId,
      billType: buyCompanyPlan.billType,
      billDetails: buyCompanyPlan.billDetails,
    },
  });
}

export function getCompanyPurchases(page: number, take: number) {
  const url = `${COMPANY_PURCHASES_URL}/list?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function updateCompanyPurchase(id: number, billGenerated: boolean) {
  const url = `${COMPANY_PURCHASES_URL}/bill-generated/${id}`;
  return APIMiddleware.patch(url, { data: { billGenerated } });
}

export function getCompanyPurchase(id: number) {
  const url = `${COMPANY_PURCHASES_URL}/${id}`;
  return APIMiddleware.get(url);
}
