import React from "react";
import { Col } from "react-bootstrap";

interface BadgeExpirationProps {
  daysToExpire: number;
}

const BadgeExpiration: React.FC<BadgeExpirationProps> = ({ daysToExpire }) => {
  return (
    <div
      className={`badge-expiration ${daysToExpire <= 0 ? "expired" : ""} me-2`}
    >
      <Col xs={9} sm={9} md={9} lg={9} className="p-0">
        <span className="badge-text">
          {daysToExpire <= 0 ? "Vencido" : `Vence en ${daysToExpire} días`}
        </span>
      </Col>
    </div>
  );
};

export default BadgeExpiration;
