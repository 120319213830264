import { useState } from "react";
import { resultType } from "../common/result-type";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../common/notification-types";
import { updateUser } from "../api/user.api";
import { UpdateUserRequest } from "../types/request/update-user-request";

export const useUpdateApplicant = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/admin/users");
    setShowNotificationModal(true);
    setNotificationTitle("Usuario actualizado");
    setNotificationText(
      "Los cambios se verán reflejados en el próximo inicio de sesión del usuario"
    );
    setNotificationType(notificationTypes.SUCCESS);
  };

  const updateApplicant = async (id: number, req: UpdateUserRequest) => {
    setLoading(true);
    try {
      await updateUser(id, req);
      showSuccessMessage();
    } catch (ex) {
      setMessage(
        "Hubo un error al actualizar el usuario. Por favor, inténtelo más tarde"
      );
      setType(resultType.error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateApplicant,
    loading,
    message,
    setMessage,
    type,
  };
};
