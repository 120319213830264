import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import DotsDecorator from "../../home/dots-decorator/DotsDecorator";
import { utils } from "../../../common/utils";
import ApplicationItem from "../../application-item/ApplicationItem";
import { getApplicationsSummarized } from "../../../api/applicant.api";
import Loading from "../../loading/Loading";
import { PaginationControl } from "react-bootstrap-pagination-control";

interface ApplicationSummarized {
  id: number;
  title: string;
  daysAgo: number;
}

const ApplicationsSummarized: React.FC = () => {
  const [applications, setApplications] = useState<ApplicationSummarized[]>([]);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);

  const take = 5;

  useEffect(() => {
    const loadApplicationsSummarized = async () => {
      try {
        const response = await getApplicationsSummarized(page, take);
        const paginatedData = response.data;
        setApplications(paginatedData.data);
        setItemsCount(paginatedData.meta.itemCount);
      } catch (error) {
        console.error("Error loading applications summarized", error);
      } finally {
        setLoading(false);
      }
    };

    loadApplicationsSummarized();
  }, [page]);

  return (
    <div className="content-card p-4 mt-4 mb-4">
      <Row className="justify-content-center mb-4">
        <h4 className="text-center">Últimas postulaciones</h4>
        <DotsDecorator width={10} height={10} center={true} />
      </Row>
      {loading ? (
        <Loading />
      ) : applications.length === 0 ? (
        <div className="content-card mt-4 p-3 text-center">
          Aún no cuentas con postulaciones
        </div>
      ) : (
        <>
          {applications.map((item) => (
            <ApplicationItem
              key={item.id}
              id={item.id}
              label={utils.getApplicationLabelForApplicant(item.title)}
              days={item.daysAgo}
            />
          ))}
          <div className="mt-4">
            <PaginationControl
              page={page}
              total={itemsCount}
              limit={take}
              changePage={(page) => {
                setPage(page);
              }}
              ellipsis={1}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ApplicationsSummarized;
