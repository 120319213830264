import React, { useEffect, useState } from "react";
import "./AddAdvertisement.css";
import { useNavigate, useParams } from "react-router-dom";
import { CreateAdvertisementRequest } from "../../../types/request/create-advertisement-request";
import { useCreateAdvertisement } from "../../../hooks/useCreateAdvertisement";
import { Col, Row } from "react-bootstrap";
import GenericForm from "../../generic-form/GenericForm";
import FormInput from "../../form-input/FormInput";
import FormCheckbox from "../../form-checkbox/FormCheckbox";
import { jobAdvertisementSeniorities } from "../../../common/seniorities";
import FormSelect from "../../form-select/FormSelect";
import FormAutocomplete from "../../form-autocomplete/FormAutocomplete";
import { districts } from "../../../common/districts";
import { contracts } from "../../../common/contracts";
import { visibilities } from "../../../common/visibilities";
import Plans from "../../plans/Plans";
import {
  getAdvertisementDetailApi,
  getAdvertisementInformationApi,
  getCompanyInformation,
} from "../../../api/company.api";
import Loading from "../../loading/Loading";
import { UpdateAdvertisementRequest } from "../../../types/request/update-advertisement-request";
import { PLANS } from "../../../common/enums";
import { resultType } from "../../../common/result-type";
import { useNotificationContext } from "../../../context/NotificationContext";
import { getIndustries } from "../../../api/industries.api";

interface AddAvertisementProps {
  readOnly?: boolean;
}

const AddAdvertisement: React.FC<AddAvertisementProps> = ({
  readOnly = false,
}) => {
  const { urlCode } = useParams();

  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [contractType, setContractType] = useState("");
  const [description, setDescription] = useState("");
  const [seniority, setSeniority] = useState("");
  const [industry, setIndustry] = useState("");
  const [district, setDistrict] = useState("");
  const [isRemote, setIsRemote] = useState(false);
  const [isHybrid, setIsHybrid] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isExcelence, setIsExcelence] = useState(false);
  const [visibility, setVisibility] = useState<any>();

  const [currentIndustries, setCurrentIndustries] = useState<any[]>([]);
  const [currentVisibilities, setCurrentVisibilities] = useState<any[]>([]);

  const [loadingCompanyInformation, setLoadingCompanyInformation] =
    useState(false);

  const [advertisementQuestions, setAdvertisementQuestions] = useState(
    Array(5).fill({ question: "" })
  );

  const [loadingInformation, setLoadingInformation] = useState(true);

  const districtOptions = districts.filter((x: any) => x.provinceId === 128);

  const { loading, createAdvertisement, updateAdvertisement, message, type } =
    useCreateAdvertisement();

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const handleTitle = (): string => {
    if (readOnly) {
      return "Oferta Laboral";
    } else {
      return urlCode ? "Editar oferta laboral" : "Nueva oferta laboral";
    }
  };

  const handleQuestionValue = (value: string, index: number) => {
    const nextQuestions = advertisementQuestions.map((q, i) => {
      if (i !== index) {
        return q;
      } else {
        return {
          ...q,
          question: value,
        };
      }
    });
    setAdvertisementQuestions(nextQuestions);
  };

  const handleButton = (): string => {
    if (readOnly) {
      return "";
    } else {
      return urlCode ? "Editar anuncio" : "Publicar anuncio";
    }
  };

  const canSubmit = (): boolean => {
    if (!urlCode) return true;
    return visibility !== PLANS.GRATUITA;
  };

  const canEdit = (
    field:
      | "title"
      | "contractType"
      | "description"
      | "seniority"
      | "industry"
      | "district"
      | "isRemote"
      | "isHybrid"
      | "isAnonymous"
      | "isExcelence"
      | "question"
  ): boolean => {
    if (!urlCode) return true;
    if (visibility === PLANS.GRATUITA) return false;
    if (visibility === PLANS.CHAMBASIHAY) {
      switch (field) {
        case "description":
        case "seniority":
        case "industry":
          return true;
        default:
          return false;
      }
    }
    return true;
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    if (!urlCode) {
      const createAdvertisementRequest: CreateAdvertisementRequest = {
        title,
        contractType,
        description,
        seniority,
        district,
        industry,
        isRemote,
        isHybrid,
        isAnonymous,
        isExcelence,
        visibility,
        questions:
          visibility === 2 || visibility === 3
            ? advertisementQuestions.map((v) => {
                return v.question;
              })
            : [],
      };
      await createAdvertisement(createAdvertisementRequest);
    } else {
      const updateAdvertisementRequest: UpdateAdvertisementRequest = {
        title,
        contractType,
        description,
        seniority,
        district,
        industry,
        isRemote,
        isHybrid,
        isAnonymous,
        isExcelence,
        questions:
          visibility === 2 || visibility === 3 ? advertisementQuestions : [],
      };
      await updateAdvertisement(updateAdvertisementRequest, urlCode);
    }
  };

  const loadCompanyInformation = async () => {
    setLoadingCompanyInformation(true);
    try {
      const companyInfo = (await getCompanyInformation()).data;
      const industriesInfo = (await getIndustries()).data;
      const nextVisibilities = [];
      nextVisibilities.push(visibilities[0]);
      if (companyInfo.advCsh && companyInfo.advCsh > 0) {
        nextVisibilities.push(visibilities[1]);
      }
      if (companyInfo.advBlack && companyInfo.advBlack > 0) {
        nextVisibilities.push(visibilities[2]);
      }
      setCurrentIndustries(industriesInfo);
      setCurrentVisibilities(nextVisibilities);
    } catch (error) {
      console.error("Error loading profile:", error);
    } finally {
      setLoadingCompanyInformation(false);
    }
  };

  useEffect(() => {
    const loadAdvertisementInformation = async (urlCode: string) => {
      try {
        const advertisementData = (
          readOnly
            ? await getAdvertisementDetailApi(urlCode)
            : await getAdvertisementInformationApi(urlCode)
        ).data;
        const industriesInfo = (await getIndustries()).data;
        setCurrentIndustries(industriesInfo);
        setTitle(advertisementData.title);
        setContractType(advertisementData.contractType);
        setDescription(advertisementData.description);
        setSeniority(advertisementData.seniority);
        setIndustry(advertisementData.industry);
        setDistrict(advertisementData.district);
        setIsRemote(advertisementData.isRemote);
        setIsHybrid(advertisementData.isHybrid);
        setIsAnonymous(advertisementData.isAnonymous);
        setIsExcelence(advertisementData.isExcelence);
        setVisibility(advertisementData.visibility);
        if (advertisementData.visibility === "1") {
          readOnly = true;
        }
        if (advertisementData.questions.length) {
          setAdvertisementQuestions(
            advertisementData.questions.map((v: any) => {
              return v;
            })
          );
        }
      } catch (error: any) {
        setShowNotificationModal(true);
        setNotificationTitle("Ha ocurrido un error");
        setNotificationText(
          error.response?.data?.message ||
            "Hubo un error al obtener puesto de trabajo."
        );
        setNotificationType(resultType.error);
        navigate("/company");
      } finally {
        setLoadingInformation(false);
      }
    };

    if (urlCode) {
      loadAdvertisementInformation(urlCode);
      if (!readOnly) loadCompanyInformation();
    } else {
      loadCompanyInformation();
      setLoadingInformation(false);
    }
  }, [urlCode]);

  return loadingInformation || loadingCompanyInformation ? (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "70vh" }}
    >
      <Loading />
    </div>
  ) : (
    <>
      <GenericForm
        handleSubmit={handleSave}
        formTitle={handleTitle()}
        loading={loading}
        readOnly={readOnly || !canSubmit()}
        buttonText={handleButton()}
        message={message}
        type={type}
      >
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Título"}
            type={"text"}
            name={"title"}
            maxLength={100}
            required={true}
            value={title}
            placeholder={""}
            onChange={(e) =>
              readOnly || !canEdit("title") ? {} : setTitle(e.target.value)
            }
            disabled={readOnly || !canEdit("title")}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormSelect
            label={"Tipo de contrato"}
            name={"contractType"}
            required={true}
            value={contractType}
            options={contracts}
            onChange={(e) =>
              readOnly || !canEdit("contractType")
                ? {}
                : setContractType(e.target.value)
            }
            disabled={readOnly || !canEdit("contractType")}
          />
        </Col>
        <Col xs={12} md={12} lg={12} className="mb-4">
          <FormInput
            label={"Descripción"}
            type={"textarea"}
            name={"description"}
            maxLength={1000}
            required={true}
            value={description}
            onChange={(e) =>
              readOnly || !canEdit("description")
                ? {}
                : setDescription(e.target.value)
            }
            placeholder={""}
            disabled={readOnly || !canEdit("description")}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormSelect
            label={"Experiencia"}
            name={"Experiencia"}
            required={true}
            value={seniority}
            options={jobAdvertisementSeniorities}
            onChange={(e) =>
              readOnly || !canEdit("seniority")
                ? {}
                : setSeniority(e.target.value)
            }
            disabled={readOnly || !canEdit("seniority")}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormSelect
            label={"Sector"}
            name={"Sector"}
            required={true}
            value={industry}
            options={currentIndustries}
            onChange={(e) =>
              readOnly || !canEdit("industry")
                ? {}
                : setIndustry(e.target.value)
            }
            disabled={readOnly || !canEdit("industry")}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormAutocomplete
            id="district"
            label="Ubicación"
            options={districtOptions}
            initialValue={district}
            onChange={(selected: any[]) => {
              if (selected.length > 0 && canEdit("district"))
                setDistrict(selected[0].label);
            }}
            disabled={readOnly || !canEdit("district")}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <Row>
            <Col className="p-0 text-white">
              <FormCheckbox
                checked={isRemote ?? false}
                type={"checkbox"}
                label={"Remoto"}
                onChange={(e) => {
                  if (!readOnly || canEdit("isRemote")) {
                    setIsRemote(e.target.checked);
                    if (e.target.checked) setIsHybrid(false);
                  }
                }}
                disabled={readOnly || !canEdit("isRemote")}
              />
            </Col>
            <Col className="p-0 text-white">
              <FormCheckbox
                checked={isHybrid ?? false}
                type={"checkbox"}
                label={"Híbrido"}
                onChange={(e) => {
                  if (!readOnly || canEdit("isHybrid")) {
                    setIsHybrid(e.target.checked);
                    if (e.target.checked) setIsRemote(false);
                  }
                }}
                disabled={readOnly || !canEdit("isHybrid")}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormSelect
            label={"Elige la visibilidad de tu anuncio"}
            name={"visibility"}
            required={true}
            value={visibility}
            options={urlCode ? visibilities : currentVisibilities}
            disabled={urlCode ? true : false}
            onChange={(e) =>
              !urlCode ? setVisibility(Number(e.target.value)) : {}
            }
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4 text-white">
          <Row>
            <Col className="p-0 text-white">
              <FormCheckbox
                checked={isAnonymous ?? false}
                type={"checkbox"}
                label={"Publicación anónima"}
                onChange={(e) =>
                  readOnly || !canEdit("isAnonymous")
                    ? {}
                    : setIsAnonymous(e.target.checked)
                }
                disabled={readOnly || !canEdit("isAnonymous")}
              />
            </Col>
            <Col className="p-0 text-white">
              <FormCheckbox
                checked={isExcelence ?? false}
                type={"checkbox"}
                label={"Excelencia"}
                onChange={(e) =>
                  readOnly || !canEdit("isExcelence")
                    ? {}
                    : setIsExcelence(e.target.checked)
                }
                disabled={readOnly || !canEdit("isExcelence")}
              />
            </Col>
          </Row>
        </Col>
        {(visibility === 2 || visibility === 3) && (
          <>
            {advertisementQuestions.map((questionObject, index) => {
              return (
                <Col xs={12} md={12} lg={12} className="mb-4">
                  <FormInput
                    label={"Pregunta para el candidato"}
                    type={"text"}
                    name={`question${index}`}
                    maxLength={400}
                    required={false}
                    value={questionObject.question}
                    placeholder={""}
                    onChange={(e) =>
                      readOnly || !canEdit("question")
                        ? {}
                        : handleQuestionValue(e.target.value, index)
                    }
                    disabled={readOnly || !canEdit("question")}
                  />
                </Col>
              );
            })}
          </>
        )}
      </GenericForm>
      {!urlCode && <Plans />}
    </>
  );
};

export default AddAdvertisement;
