import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import { Plan } from "../../../types/response/plan-response";
import AdminUserPlansForm from "./AdminUserPlansForm";
import { getApplicantPlan } from "../../../api/career-boost.api";

const AdminUserPlansSave: React.FC = () => {
  const [plan, setPlan] = useState<Plan>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadUserPlan = async (id: string) => {
      try {
        const planData = (await getApplicantPlan(parseInt(id))).data;
        setPlan(planData);
      } catch (error) {
        console.error("Error loading user plan:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadUserPlan(id);
    }
  }, [id]);

  return loading ? <Loading /> : plan && <AdminUserPlansForm item={plan} />;
};

export default AdminUserPlansSave;
