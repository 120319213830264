import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";

import React from "react";

interface ProfileViewWarningModalProps {
  showWarningModal: boolean;
  handleCloseWarningModal: (() => void) | undefined;
  modalHeader: string;
  modalBody: string;
  viewProfile: any;
}

const ProfileViewWarningModal: React.FC<ProfileViewWarningModalProps> = ({
  showWarningModal,
  handleCloseWarningModal,
  modalHeader,
  modalBody,
  viewProfile,
}) => {
  return (
    <Modal
      show={showWarningModal}
      onHide={handleCloseWarningModal}
      centered
      data-bs-theme="light"
    >
      <ModalHeader closeButton>{modalHeader}</ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
      <ModalFooter>
        <Button variant="danger" onClick={handleCloseWarningModal}>
          No, la usaré más adelante
        </Button>
        <Button variant="second" onClick={viewProfile}>
          Sí, ir al perfil
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProfileViewWarningModal;
