import { useState } from "react";
import { resultType } from "../common/result-type";
import {
  createApplicantExperience,
  updateApplicantExperience,
} from "../api/experience.api";
import { CreateExperienceRequest } from "../types/request/create-experience-request";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../common/notification-types";

export const useAddExperience = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/applicant");
    setShowNotificationModal(true);
    setNotificationTitle("Experiencia agregada");
    setNotificationText("Sigue completando tu perfil para los reclutadores.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const addExperience = async (req: CreateExperienceRequest) => {
    setLoading(true);
    try {
      await createApplicantExperience(req);
      setLoading(false);
      showSuccessMessage();
    } catch (ex) {
      setLoading(false);
      setMessage("Hubo un error al crear experiencia.");
      setType(resultType.error);
    }
  };

  const updateExperience = async (id: number, req: CreateExperienceRequest) => {
    setLoading(true);
    try {
      await updateApplicantExperience(id, req);
      setLoading(false);
      showSuccessMessage();
    } catch (ex) {
      setLoading(false);
      setMessage("Hubo un error al actualizar experiencia.");
      setType(resultType.error);
    }
  };

  return {
    addExperience,
    updateExperience,
    loading,
    message,
    setMessage,
    type,
  };
};
