import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

interface DataTableTitleProps {
  title: string;
  createLabel?: string;
  createClick?: () => void;
}

const DataTableTitle: React.FC<DataTableTitleProps> = ({
  title,
  createLabel,
  createClick,
}) => {
  return (
    <Container className="p-0">
      <Row className="mt-2">
        <Col>
          <h2 className="color-main">{title}</h2>
        </Col>
        {createLabel && createClick && (
          <Col>
            <Button variant="main" onClick={createClick}>
              <FontAwesomeIcon icon={faAdd} />
              <span className="ms-2">{createLabel}</span>
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default DataTableTitle;
