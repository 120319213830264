import React, { FormEvent, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useResetPassword } from "../../hooks/useResetPassword";
import GenericForm from "../../components/generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormInput from "../../components/form-input/FormInput";

const ResetPassword: React.FC = () => {
  const { userType, token } = useParams<{
    userType?: "applicant" | "company";
    token?: string;
  }>();
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email") ?? "";

  const [email, setEmail] = useState(emailParam);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { renewPassword, loading, message, setMessage, type } =
    useResetPassword({ token });

  const handleForgotPassword = async (e: FormEvent) => {
    e.preventDefault();
    await renewPassword({
      email,
      token: token ?? "",
      newPassword: password,
      confirmPassword: confirmPassword,
      userType: userType ?? "applicant",
    });
  };

  return (
    <GenericForm
      handleSubmit={handleForgotPassword}
      formTitle={"Restablecer contraseña"}
      loading={loading}
      buttonText={"Confirmar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={12} lg={12} className="mb-4">
        <FormInput
          label={"Correo electrónico"}
          type={"email"}
          name={"email"}
          maxLength={100}
          required={true}
          value={email}
          placeholder={""}
          onChange={(e) => setEmail(e.target.value)}
          disabled={true}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Nueva contraseña"}
          type={"password"}
          name={"password"}
          maxLength={100}
          required={true}
          value={password}
          placeholder={""}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Confirmar contraseña"}
          type={"password"}
          name={"confirmPassword"}
          maxLength={100}
          required={true}
          value={confirmPassword}
          placeholder={""}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Col>
    </GenericForm>
  );
};

export default ResetPassword;
