import { Card, Col } from "react-bootstrap";
import { utils } from "../../../common/utils";
import "./PlanItem.css";
import { CareerBoost } from "../../../types/response/career-boost";
import { Plan } from "../../../types/response/plan-response";
import { Dispatch, SetStateAction, useState } from "react";
import BillDetailsModal from "../../bill-details-modal/BillDetailsModal";

interface PlanItemProps {
  plan: CareerBoost | Plan;
  openCulqi: any;
  setAmount: any;
  setPlan: Dispatch<SetStateAction<CareerBoost | Plan | undefined>>;
  billType: "receipt" | "bill";
  setBillType: Dispatch<SetStateAction<"receipt" | "bill">>;
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  businessName: string;
  setBusinessName: Dispatch<SetStateAction<string>>;
  ruc: string;
  setRuc: Dispatch<SetStateAction<string>>;
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
}

const PlanItem: React.FC<PlanItemProps> = ({
  plan,
  openCulqi,
  setAmount,
  setPlan,
  billType,
  setBillType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  businessName,
  setBusinessName,
  ruc,
  setRuc,
  address,
  setAddress,
}) => {
  const [showBillModal, setShowBillModal] = useState<boolean>(false);

  const planType = "visibilityPeriod" in plan ? "company" : "applicant";

  let features: string[] = [];
  if ("visibilityPeriod" in plan) {
    features = utils.getPlanFeatures(plan);
  } else {
    features = utils.getCareerBoostFeatures();
  }

  const handleOpenCulqi = (e: any) => {
    e.preventDefault();
    setShowBillModal(false);
    const amount = Math.round(
      parseFloat(plan.forSale ? plan.salePrice : plan.price) * 100
    );
    setAmount(amount);
    setPlan(plan);
    setTimeout(() => {
      if (amount !== 0) openCulqi();
    }, 500);
  };

  return (
    <>
      <Col xs={12} md={6} lg={4} className="mb-4">
        <Card
          className={`${
            planType === "applicant" ? "plan-applicant" : ""
          } plan-card`}
        >
          <Card.Header
            className="plan-header text-center"
            onClick={() => setShowBillModal(true)}
          >
            <span>{plan.name}</span>
          </Card.Header>
          <Card.Body>
            <ul>
              {features.map((feature) => {
                return (
                  feature !== "" && (
                    <li key={feature} className="mb-2">
                      {feature}
                    </li>
                  )
                );
              })}
            </ul>
            <div className="plan-container text-center">
              {plan.forSale && (
                <div className="plan-regular-price">
                  Precio regular: {utils.getPrice(plan.price)}
                </div>
              )}
              <h2 className="plan-price" onClick={() => setShowBillModal(true)}>
                {utils.getPrice(plan.forSale ? plan.salePrice : plan.price)}
              </h2>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <BillDetailsModal
        showBillModal={showBillModal}
        handleCloseBillModal={() => setShowBillModal(false)}
        billType={billType}
        setBillType={setBillType}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        businessName={businessName}
        setBusinessName={setBusinessName}
        ruc={ruc}
        setRuc={setRuc}
        address={address}
        setAddress={setAddress}
        handleOpenCulqi={handleOpenCulqi}
      />
    </>
  );
};

export default PlanItem;
