import React, { useState } from "react";
import GenericForm from "../../../components/generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormInput from "../../../components/form-input/FormInput";
import { CareerResponse } from "../../../types/response/career-response";
import { useCareer } from "../../../hooks/useCareer";

interface AdminCareersFormProps {
  item: CareerResponse;
}

const AdminCareersForm: React.FC<AdminCareersFormProps> = ({ item }) => {
  const [name, setName] = useState(item.name);

  const handleSave = async (e: any) => {
    e.preventDefault();
    if (item.id !== 0) {
      await modifyCareer(item.id, {
        id: item.id,
        name,
      });
    } else {
      if (item.industryId)
        createCareer(item.industryId, {
          name,
        });
    }
  };

  const { modifyCareer, createCareer, loading, message, setMessage, type } =
    useCareer();

  return (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={item.id !== 0 ? "Editar carrera" : "Nueva carrera"}
      loading={loading}
      buttonText={item.id !== 0 ? "Actualizar" : "Crear"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={12} lg={12} className="mb-4">
        <FormInput
          label={"Nombre"}
          type={"text"}
          name={"name"}
          maxLength={100}
          required={true}
          value={name}
          placeholder={""}
          onChange={(e) => setName(e.target.value)}
        />
      </Col>
    </GenericForm>
  );
};

export default AdminCareersForm;
