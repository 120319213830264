import React from "react";
import { Alert } from "react-bootstrap";
import { resultType } from "../../common/result-type";

interface FormResultProps {
  message?: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  type?: string;
}

const FormResult: React.FC<any> = ({
  message,
  setMessage,
  type,
}) => {
  return (
    message && (
      <Alert
        className="mb-0 mt-2"
        variant={type === resultType.success ? "primary" : "danger"}
        onClose={setMessage}
        dismissible
      >
        <b>
          {type === resultType.success
            ? "Operación completada con éxito"
            : "¡Ups! Algo salió mal."}
        </b>
        <br />
        {message}
      </Alert>
    )
  );
};

export default FormResult;
