import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import FormButton from "../../form-button/FormButton";
import { useNavigate } from "react-router";
import { getProfile } from "../../../api/applicant.api";
import Loading from "../../loading/Loading";
import { useNotificationContext } from "../../../context/NotificationContext";
import { notificationTypes } from "../../../common/notification-types";

const ApplicantActions: React.FC = () => {
  const [careerId, setCareerId] = useState(1);
  const [isExcelence, setIsExcelente] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProfileInformation = async () => {
      try {
        const applicantInformation = (await getProfile()).data;
        setCareerId(applicantInformation.careerId);
        setIsExcelente(applicantInformation.isExcelence);
      } catch (error) {
        console.error("Error loading profile:", error);
      } finally {
        setLoading(false);
      }
    };

    loadProfileInformation();
  }, []);

  const navigate = useNavigate();

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const showErrorMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("No podemos seguir");
    setNotificationText(
      "Por favor, completa tu carrera universitaria para continuar."
    );
    setNotificationType(notificationTypes.SUCCESS);
  };

  const boostCareer = () => {
    if (careerId === 1) {
      showErrorMessage();
    } else {
      navigate("/plans");
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Row className="mt-3">
        <FormButton
          type={"link"}
          buttonText={"Aumenta tus visualizaciones"}
          variant={"second"}
          onClick={boostCareer}
        />
      </Row>
      {!isExcelence && (
        <Row className="mt-3">
          <FormButton
            type={"link"}
            buttonText={"Mejora a excelencia"}
            variant={"main"}
            onClick={() => navigate("/applicant/upgrade")}
          />
        </Row>
      )}
    </React.Fragment>
  );
};

export default ApplicantActions;
