import React, { useState } from "react";
import { Col, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Advertisement } from "../Company";
import viewIcon from "../../../assets/images/icons/view-icon.png";
import editIcon from "../../../assets/images/icons/edit-icon.png";
import deleteIcon from "../../../assets/images/icons/delete-icon.png";
import BadgeSeniority from "../badge-seniority/BadgeSeniority";
import RemoveElement from "../../remove-element/RemoveElement";
import { deleteAdvertisementApi } from "../../../api/company.api";
import { useNotificationContext } from "../../../context/NotificationContext";
import { notificationTypes } from "../../../common/notification-types";
import Loading from "../../loading/Loading";
import { useNavigate } from "react-router-dom";
import BadgeExpiration from "../badge-expiration/BadgeExpiration";

interface CompanyAdvertisementsProps {
  loadAdvertisements: () => Promise<void>;
}

const AdvertisementItem: React.FC<
  Advertisement & CompanyAdvertisementsProps
> = ({
  id,
  title,
  description,
  seniority,
  urlCode,
  daysToExpire,
  loadAdvertisements,
}) => {
  const [loading, setLoading] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  const navigate = useNavigate();

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const handleDeleteAdvertisement = () => {
    setShowRemove(true);
  };

  const handleEditAdvertisement = () => {
    navigate(`/edit-advertisement/${urlCode}`);
  };

  const handleViewAdvertisement = () => {
    navigate(`/advertisement/applications/${id}`);
  };

  const showSucessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Operación exitosa");
    setNotificationText("La oferta laboral fue removida.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = (message: string) => {
    setShowNotificationModal(true);
    setNotificationTitle("Ocurrió un error");
    setNotificationText(message);
    setNotificationType(notificationTypes.ERROR);
  };

  const deleteAdvertisment = () => {
    setLoading(true);
    deleteAdvertisementApi(id)
      .then(async ({ data }) => {
        if (data) {
          await loadAdvertisements();
          showSucessMessage();
        }
      })
      .catch(({ message }) => {
        showErrorMessage(message || "Ocurrió un error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="content-card mt-4 p-3 advertisement-card">
      <Row>
        <Col sx={9} sm={9} md={9} lg={9}>
          <h5 className="text-start">{title}</h5>
        </Col>
        <Col sx={3} sm={3} md={3} lg={3} className="text-center">
          <Row>
            <Col className="p-0">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-applications">Ver postulaciones</Tooltip>
                }
              >
                <Image
                  src={viewIcon}
                  alt="view-icon"
                  width={24}
                  height={18}
                  className="cursor-pointer"
                  onClick={handleViewAdvertisement}
                />
              </OverlayTrigger>
            </Col>
            <Col className="p-0">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-edit-advertisement">
                    Editar anuncio
                  </Tooltip>
                }
              >
                <Image
                  src={editIcon}
                  alt="edit-icon"
                  width={18}
                  height={18}
                  className="cursor-pointer"
                  onClick={handleEditAdvertisement}
                />
              </OverlayTrigger>
            </Col>
            <Col className="p-0">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-delete-advertisement">
                    Eliminar anuncio
                  </Tooltip>
                }
              >
                {loading ? (
                  <Loading />
                ) : (
                  <Image
                    src={deleteIcon}
                    alt="delete-icon"
                    width={18}
                    height={18}
                    className="cursor-pointer"
                    onClick={handleDeleteAdvertisement}
                  />
                )}
              </OverlayTrigger>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-2">
        <p>{description}</p>
      </Row>
      <Row className="mt-2 ps-2" style={{ alignItems: "center" }}>
        <Col xs={3} sm={3} md={3} lg={3} className="p-0">
          <BadgeSeniority seniority={seniority} />
        </Col>
        <Col xs={9} sm={9} md={9} lg={9} className="p-0">
          <BadgeExpiration daysToExpire={daysToExpire} />
        </Col>
      </Row>
      <RemoveElement
        showRemoveModal={showRemove}
        handleCloseRemoveModal={() => setShowRemove(false)}
        modalHeader={"¿Está seguro?"}
        modalBody={
          "Una vez eliminada la oferta laboral, la información desaparecerá para los postulantes"
        }
        removeElement={deleteAdvertisment}
      />
    </div>
  );
};

export default AdvertisementItem;
