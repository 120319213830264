import { useState } from "react";
import { useNotificationContext } from "../context/NotificationContext";
import { notificationTypes } from "../common/notification-types";
import { BuyProfilePackageRequest } from "../types/request/buy-profile-package-request";
import {
  buyProfilePackage,
  updateProfilePurchase,
} from "../api/profile-purchases.api";
import { resultType } from "../common/result-type";
import { useNavigate } from "react-router-dom";
import { BillGenerated } from "../types/request/bill-generated";

export const usePurchaseProfile = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("PAGO PROCESADO");
    setNotificationText("Los perfiles han sido agregados a tu usuario.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Tuvimos problemas al procesar su pago");
    setNotificationText(
      "Por favor, vuelve a intentarlo más tarde o comúnicate con soporte."
    );
    setNotificationType(notificationTypes.ERROR);
  };

  const showErrorBillMessage = () => {
    setMessage(
      "Hubo un error al actualizar el registro. Por favor, inténtelo más tarde"
    );
    setType(resultType.error);
  };

  const showGeneratedBill = () => {
    navigate("/admin/profile-purchases");
    setShowNotificationModal(true);
    setNotificationTitle("Información actualizada");
    setNotificationText("Se actualizó la información para la boleta / factura");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const generateCompanyBill = async (req: BillGenerated) => {
    setLoading(true);
    try {
      await updateProfilePurchase(req.id, req.billGenerated);
      showGeneratedBill();
    } catch (ex) {
      showErrorBillMessage();
    } finally {
      setLoading(false);
    }
  };

  const profilePurchase = async (req: BuyProfilePackageRequest) => {
    setLoading(true);
    try {
      await buyProfilePackage(req);
      showSuccessMessage();
    } catch (ex) {
      showErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  return {
    profilePurchase,
    generateCompanyBill,
    loading,
    message,
    setMessage,
    type,
  };
};
