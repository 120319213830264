import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import { getCompany } from "../../../api/company.api";
import AdminCompaniesForm from "./AdminCompaniesForm";
import { UpdateCompanyRequest } from "../../../types/request/update-company-request";

const AdminUsersSave: React.FC = () => {
  const [company, setCompany] = useState<UpdateCompanyRequest>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadCompany = async (id: string) => {
      try {
        const userData = (await getCompany(parseInt(id))).data;
        setCompany(userData);
      } catch (error) {
        console.error("Error loading company:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadCompany(id);
    }
  }, [id]);

  return loading ? (
    <Loading />
  ) : (
    company && <AdminCompaniesForm item={company} />
  );
};

export default AdminUsersSave;
