import React from "react";
import FormInput from "../../../form-input/FormInput";
import { Col, Row } from "react-bootstrap";

interface ApplicantRegisterFormProps {
  firstName?: string;
  setFirstName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  lastName?: string;
  setLastName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  email?: string;
  setEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  password?: string;
  setPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  confirmPassword?: string;
  setConfirmPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ApplicantRegisterForm: React.FC<ApplicantRegisterFormProps> = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  return (
    <React.Fragment>
      <Row className="pt-4">
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Nombre"}
            type={"text"}
            name={"firstname"}
            maxLength={100}
            required={true}
            value={firstName}
            capitalize={true}
            placeholder={""}
            onChange={setFirstName}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Apellido"}
            type={"text"}
            name={"lastname"}
            maxLength={100}
            required={true}
            value={lastName}
            capitalize={true}
            placeholder={""}
            onChange={setLastName}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Correo electrónico"}
            type={"email"}
            name={"email"}
            maxLength={100}
            required={true}
            value={email}
            placeholder={""}
            onChange={setEmail}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Contraseña"}
            type={"password"}
            name={"password"}
            maxLength={100}
            required={true}
            value={password}
            placeholder={""}
            onChange={setPassword}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Confirmar contraseña"}
            type={"password"}
            name={"confirm-password"}
            maxLength={100}
            required={true}
            value={confirmPassword}
            placeholder={""}
            onChange={setConfirmPassword}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ApplicantRegisterForm;
