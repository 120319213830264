import React, { useState } from "react";
import { getMonthName } from "../../../common/months";
import { Row, Col, Image } from "react-bootstrap";
import { Study } from "../../../types/response/applicant-profile-response";

import editIcon from "../../../assets/images/icons/edit-icon.png";
import deleteIcon from "../../../assets/images/icons/delete-icon.png";
import { useNavigate } from "react-router-dom";
import RemoveElement from "../../remove-element/RemoveElement";
import { deleteApplicantStudy } from "../../../api/study.api";
import { useNotificationContext } from "../../../context/NotificationContext";
import { notificationTypes } from "../../../common/notification-types";

interface ApplicantStudyProps {
  study: Study;
  reloadStudies: () => Promise<void>;
  nonEditable?: boolean;
}

const StudyItem: React.FC<ApplicantStudyProps> = ({
  study,
  reloadStudies,
  nonEditable,
}) => {
  const [showRemove, setShowRemove] = useState(false);

  const navigate = useNavigate();

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const handleEditStudy = () => {
    navigate("/save-study/" + study.id);
  };

  const handleDeleteStudy = () => {
    setShowRemove(true);
  };

  const showSucessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Operación exitosa");
    setNotificationText("La formación académica fue eliminada.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const deleteExperience = async () => {
    await deleteApplicantStudy(study.id);
    setShowRemove(false);
    reloadStudies();
    showSucessMessage();
  };

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col
            xs={10}
            md={10}
            lg={10}
            className="text-start p-0 fw-bold text-main mt-2"
          >
            <h5>{study.type}</h5>
          </Col>
          {!nonEditable && (
            <Col xs={2} md={2} lg={2} className="text-end">
              <Row>
                <Col className="p-0">
                  <Image
                    src={editIcon}
                    alt="edit-icon"
                    width={18}
                    height={18}
                    className="cursor-pointer"
                    onClick={handleEditStudy}
                  />
                </Col>
                <Col className="p-0">
                  <Image
                    src={deleteIcon}
                    alt="delete-icon"
                    width={18}
                    height={18}
                    className="cursor-pointer"
                    onClick={handleDeleteStudy}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <p className="fw-bold mb-0">{study.institution}</p>
        <p className="mb-0 text-gray">
          {getMonthName(study.monthFrom)} {study.yearFrom} -{" "}
          {study.isCurrent
            ? "Al presente"
            : getMonthName(study.monthTo) + " " + study.yearTo}
        </p>
        <p>{study.field}</p>
      </div>
      <RemoveElement
        showRemoveModal={showRemove}
        handleCloseRemoveModal={() => setShowRemove(false)}
        modalHeader={"¿Está seguro?"}
        modalBody={
          "Una vez eliminada la formación académica, la información desaparecerá para los reclutadores"
        }
        removeElement={deleteExperience}
      />
    </React.Fragment>
  );
};

export default StudyItem;
