import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import students from "../../../assets/images/slogan/students.png";
import excelence from "../../../assets/images/slogan/excelence.png";
import professionals from "../../../assets/images/slogan/professionals.png";

interface BadgeSeniorityProps {
  seniority: "student" | "excelence" | "professional";
}

const BadgeSeniority: React.FC<BadgeSeniorityProps> = ({ seniority }) => {
  const badgeIcon =
    seniority === "student"
      ? students
      : seniority === "excelence"
      ? excelence
      : seniority === "professional"
      ? professionals
      : students;
  const badgeName =
    seniority === "student"
      ? "Practicantes"
      : seniority === "excelence"
      ? "Excelencia"
      : seniority === "professional"
      ? "Profesionales"
      : "Practicantes";

  return (
    <div className="badge-seniority me-2">
      <Row className="p-1">
        <Col xs={3} sm={3} md={3} lg={3} className="p-0">
          <Image src={badgeIcon} alt="badge-icon" width={18} height={18} />
        </Col>
        <Col xs={9} sm={9} md={9} lg={9} className="p-0">
          <span className="badge-text">{badgeName}</span>
        </Col>
      </Row>
    </div>
  );
};

export default BadgeSeniority;
