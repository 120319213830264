import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { JobAdvertisementsContextState } from "./JobAdvertisementsContextState";
import { getJobAdvertisements } from "../../api/job-advertisements.api";
import { useFilterContext } from "../FilterContext/FilterContext";
import { advancedFilterTypes } from "../../common/advanced-filter-types";

const initialState: JobAdvertisementsContextState = {
  loadingJobAdvertisements: false,
  loadJobAdvertisements: () => {},
  jobAdvertisements: [],
  jobAdvertisementsItemsCount: 0,
  jobAdvertisementsPage: 1,
  changeJobAdvertisementsPage: () => {},
};

const JobAdvertisementsContext =
  createContext<JobAdvertisementsContextState>(initialState);

interface JobAdvertisementsProviderProps {
  children: ReactNode;
}

export const JobAdvertisementsProvider: FC<JobAdvertisementsProviderProps> = ({
  children,
}) => {
  //states
  const [jobAdvertisements, setJobAdvertisements] = useState<any[]>([]);
  const [jobAdvertisementsItemsCount, setJobAdvertisementsItemsCount] =
    useState<number>(0);
  const [jobAdvertisementsPage, setJobAdvertisementsPage] = useState<number>(1);
  const [loadingJobAdvertisements, setLoadingJobAdvertisements] =
    useState<boolean>(false);

  const {
    buildQueryFilter,
    experienceFilter,
    contractFilter,
    sectorFilter,
    careerFilter,
    filterType,
  } = useFilterContext();

  const changeJobAdvertisementsPage = (page: number = 1): void => {
    setJobAdvertisementsPage(page);
  };

  const loadJobAdvertisements = async (
    take: number = 12,
    reload?: boolean
  ): Promise<void> => {
    setLoadingJobAdvertisements(true);
    if (reload) setJobAdvertisementsPage(1);
    try {
      const filters = buildQueryFilter();
      const response = await getJobAdvertisements(
        jobAdvertisementsPage,
        take,
        filters
      );
      setJobAdvertisements(response.data);
      setJobAdvertisementsItemsCount(response.meta.itemCount);
    } catch (error) {
      console.error("Error loading job advertisements", error);
    } finally {
      setLoadingJobAdvertisements(false);
    }
  };

  useEffect(() => {
    if (filterType === advancedFilterTypes.jobOffers)
      loadJobAdvertisements(12, true);
  }, [experienceFilter, contractFilter, sectorFilter, careerFilter]);

  const modalContextValue = {
    jobAdvertisements,
    jobAdvertisementsItemsCount,
    loadingJobAdvertisements,
    jobAdvertisementsPage,
    loadJobAdvertisements,
    changeJobAdvertisementsPage,
  };

  return (
    <JobAdvertisementsContext.Provider value={modalContextValue}>
      {children}
    </JobAdvertisementsContext.Provider>
  );
};

export const useJobAdvertisementsContext = () => {
  return useContext(JobAdvertisementsContext);
};
