import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";

import React from "react";
import { useNavigate } from "react-router-dom";

interface ProfileViewWarningModalProps {
  showInsufficientViewModal: boolean;
  handleCloseInsufficientViewModal: (() => void) | undefined;
}

const ProfileViewInsufficientModal: React.FC<ProfileViewWarningModalProps> = ({
  showInsufficientViewModal,
  handleCloseInsufficientViewModal,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      show={showInsufficientViewModal}
      onHide={handleCloseInsufficientViewModal}
      centered
      data-bs-theme="light"
    >
      <ModalHeader closeButton>Haz agotado tus vistas de perfil</ModalHeader>
      <ModalBody>
        Para seguir visualizando perfiles, dirígase a la sección de planes y
        adquiera uno
      </ModalBody>
      <ModalFooter>
        <Button variant="danger" onClick={handleCloseInsufficientViewModal}>
          Seguir navegando
        </Button>
        <Button variant="second" onClick={() => navigate("/packages")}>
          Comprar perfil
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProfileViewInsufficientModal;
