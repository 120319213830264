import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";
import { CreateLanguageRequest } from "../types/request/create-language-request";

const { LANGUAGE_URL } = apiUrls;

export function createApplicantLanguage(
  createLanguageRequest: CreateLanguageRequest
) {
  const url = `${LANGUAGE_URL}`;
  return APIMiddleware.post(url, {
    data: {
      language: createLanguageRequest.language,
      speakingLevel: createLanguageRequest.speakingLevel,
      writingLevel: createLanguageRequest.writingLevel,
    },
  });
}

export function getApplicantLanguage(id: number) {
  const url = `${LANGUAGE_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function deleteApplicantLanguages(id: number) {
  const url = `${LANGUAGE_URL}/${id}`;
  return APIMiddleware.delete(url);
}

export function updateApplicantLanguage(
  id: number,
  createLanguageRequest: CreateLanguageRequest
) {
  const url = `${LANGUAGE_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      language: createLanguageRequest.language,
      speakingLevel: createLanguageRequest.speakingLevel,
      writingLevel: createLanguageRequest.writingLevel,
    },
  });
}
