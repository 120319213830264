import React from "react";
import { Col, Row } from "react-bootstrap";
import DataTableFilter from "../data-table-filter/DataTableFilter";

interface DataTableHeaderProps {
  handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DataTableHeader: React.FC<DataTableHeaderProps> = ({
  handleFilterChange,
}) => {
  return (
    <Row>
      <Col></Col>
      <Col>
        <DataTableFilter handleFilterChange={handleFilterChange} />
      </Col>
    </Row>
  );
};

export default DataTableHeader;
