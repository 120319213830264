import { useState } from "react";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router";
import { notificationTypes } from "../common/notification-types";
import { BuyApplicantPlanRequest } from "../types/request/buy-applicant-plan-request";
import {
  buyApplicantPlan,
  updateUserPurchase,
} from "../api/user-purchases.api";
import { BuyCompanyPlanRequest } from "../types/request/buy-company-plan-request";
import {
  buyCompanyPlan,
  updateCompanyPurchase,
} from "../api/company-purchases.api";
import { BillGenerated } from "../types/request/bill-generated";
import { resultType } from "../common/result-type";

export const usePurchasePlan = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = (userType: string) => {
    userType === "applicant" ? navigate("/applicant") : navigate("/company");
    setShowNotificationModal(true);
    setNotificationTitle("PAGO PROCESADO");
    setNotificationText(
      userType === "applicant"
        ? "⁠¡Bienvenido al catálogo premium de tu carrera! El pago ha sido procesado con éxito. Como usuario premium, tu talento tendrá una mejor visibilidad ante las empresas durante 20 días."
        : "Los beneficios fueron aplicados a la cuenta de tu empresa."
    );
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = (userType: string, ex: any) => {
    userType === "applicant" ? navigate("/applicant") : navigate("/company");
    const message =
      ex.response.status === 409 && userType === "applicant"
        ? "Ya cuenta con una compra realizada hace menos de 20 días, o el límite de la carrera ha sido excedido."
        : "Por favor, vuelve a intentarlo más tarde o comúnicate con soporte.";
    setShowNotificationModal(true);
    setNotificationTitle("Tuvimos problemas al procesar su pago");
    setNotificationText(message);
    setNotificationType(notificationTypes.ERROR);
  };

  const showErrorBillMessage = () => {
    setMessage(
      "Hubo un error al actualizar el registro. Por favor, inténtelo más tarde"
    );
    setType(resultType.error);
  };

  const boostCareer = async (req: BuyApplicantPlanRequest) => {
    setLoading(true);
    try {
      await buyApplicantPlan(req);
      showSuccessMessage("applicant");
    } catch (ex) {
      showErrorMessage("applicant", ex);
    } finally {
      setLoading(false);
    }
  };

  const companyPurchase = async (req: BuyCompanyPlanRequest) => {
    setLoading(true);
    try {
      await buyCompanyPlan(req);
      showSuccessMessage("company");
    } catch (ex) {
      showErrorMessage("company", ex);
    } finally {
      setLoading(false);
    }
  };

  const showGeneratedBill = (userType: string) => {
    userType === "applicant"
      ? navigate("/admin/user-purchases")
      : navigate("/admin/company-purchases");
    setShowNotificationModal(true);
    setNotificationTitle("Información actualizada");
    setNotificationText("Se actualizó la información para la boleta / factura");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const generateCompanyBill = async (req: BillGenerated) => {
    setLoading(true);
    try {
      await updateCompanyPurchase(req.id, req.billGenerated);
      showGeneratedBill("company");
    } catch (ex) {
      showErrorBillMessage();
    } finally {
      setLoading(false);
    }
  };

  const generateUserBill = async (req: BillGenerated) => {
    setLoading(true);
    try {
      await updateUserPurchase(req.id, req.billGenerated);
      showGeneratedBill("applicant");
    } catch (ex) {
      showErrorBillMessage();
    } finally {
      setLoading(false);
    }
  };

  return {
    boostCareer,
    companyPurchase,
    generateCompanyBill,
    generateUserBill,
    setMessage,
    loading,
    message,
    type,
  };
};
