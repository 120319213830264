import React from "react";
import { Col, Row } from "react-bootstrap";

export interface ApplicationDetails {
  id: number;
  label: string;
  days: number;
}

const ApplicationItem: React.FC<ApplicationDetails> = ({ id, label, days }) => {
  return (
    <div className="mt-2">
      <Row>
        <Col xs={10} sm={10} md={10} lg={10} className="p-0 ellipsis-text">
          {label}
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} className="p-0">
          <span style={{ fontSize: 10 }}>
            {days > 0 ? `hace ${days} día${days > 1 ? "s" : ""}` : "hoy"}
          </span>
        </Col>
      </Row>
      <hr className="hr-application-item" />
    </div>
  );
};

export default ApplicationItem;
