import { APIMiddleware } from "../middleware/api-middleware";
import { apiUrls } from "../common/api-urls";
import { UpdateCareerRequest } from "../types/request/update-career-request";

const { CAREERS_URL } = apiUrls;

export function getCareers() {
  const url = `${CAREERS_URL}`;
  return APIMiddleware.get(url, { noAuthToken: true });
}

export function getCareersList(
  id: number,
  page: number,
  take: number,
  filter: string
) {
  const url = `${CAREERS_URL}/list/${id}?page=${page}&take=${take}&filter=${filter}`;
  return APIMiddleware.get(url);
}

export function getCareer(id: number) {
  const url = `${CAREERS_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function postCareer(id: number, req: UpdateCareerRequest) {
  const url = `${CAREERS_URL}/${id}`;
  return APIMiddleware.post(url, {
    data: {
      name: req.name,
    },
  });
}

export function updateCareer(id: number, req: UpdateCareerRequest) {
  const url = `${CAREERS_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      name: req.name,
    },
  });
}

export function removeCareer(id: number) {
  const url = `${CAREERS_URL}/${id}`;
  return APIMiddleware.delete(url);
}
