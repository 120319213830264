import { Col, Row } from "react-bootstrap";
import ProfileInformation from "../profile-information/ProfileInformation";
import React, { useEffect, useState } from "react";
import "./Company.css";
import CompanyAdvertisements from "./company-advertisements/CompanyAdvertisements";
import MyApplicants from "./my-applicants/MyApplicants";

import Loading from "../loading/Loading";
import { listAdvertisementsApi } from "../../api/company.api";

export interface Advertisement {
  id: number;
  title: string;
  description: string;
  daysToExpire: number;
  seniority: "student" | "professional";
  urlCode: string;
  planName: string;
  createdAt: string;
}

const Company: React.FC = () => {
  const [advertisements, setAdvertisements] = useState<Advertisement[]>([]);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);

  const take = 5;

  const loadAdvertisements = async () => {
    try {
      const response = await listAdvertisementsApi(page, take);
      const paginatedData = response.data;
      setAdvertisements(paginatedData.data);
      setItemsCount(paginatedData.meta.itemCount);
    } catch (error) {
      console.error("Error loading company applications summarized", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAdvertisements();
  }, [page]);

  return (
    <div className="p-4 company-section">
      <Row>
        <Col md={4} lg={4}>
          <ProfileInformation profileType="company" />
          <MyApplicants />
        </Col>
        <Col md={8} lg={8}>
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Loading />
            </div>
          ) : (
            <>
              <CompanyAdvertisements
                advertisements={advertisements}
                loadAdvertisements={loadAdvertisements}
                page={page}
                total={itemsCount}
                limit={take}
                changePage={(page) => {                  
                  setPage(page);
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Company;
