import { Col, Image, Row } from "react-bootstrap";
import addIcon from "../../../assets/images/profile/profile-image-add-icon.png";
import React from "react";
import StudyItem from "../study-item/StudyItem";
import { Study } from "../../../types/response/applicant-profile-response";
import { useNavigate } from "react-router-dom";
import Loading from "../../loading/Loading";

interface ApplicantStudiesProps {
  studies: Study[];
  reloadStudies: () => Promise<void>;
  loading: boolean;
  nonEditable?: boolean;
}

const ApplicantStudies: React.FC<ApplicantStudiesProps> = ({
  studies,
  reloadStudies,
  loading,
  nonEditable,
}) => {
  const navigate = useNavigate();

  const handleAddStudy = () => {
    navigate("/save-study");
  };

  return (
    <div className="mb-4">
      <Row>
        <Col xs={10} md={10} lg={10} className="text-start p-0 fw-bold">
          <h5 className="fw-bold">Formación académica</h5>
        </Col>
        <Col xs={2} md={2} lg={2} className="text-end">
          {!nonEditable && (
            <Image
              alt="add-icon"
              src={addIcon}
              width={24}
              height={24}
              className="cursor-pointer"
              onClick={handleAddStudy}
            />
          )}
        </Col>
      </Row>
      <hr className="hr-profile-details" />
      {loading ? (
        <Loading />
      ) : (
        studies.map((study) => (
          <StudyItem
            key={study.id}
            study={study}
            reloadStudies={reloadStudies}
            nonEditable={nonEditable}
          />
        ))
      )}
    </div>
  );
};

export default ApplicantStudies;
