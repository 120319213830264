import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import AdminCareersForm from "./AdminCareersForm";
import { getCareer } from "../../../api/careers.api";
import { CareerResponse } from "../../../types/response/career-response";

const AdminCareersSave: React.FC = () => {
  const [career, setCareer] = useState<CareerResponse>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadCareer = async (id: string) => {
      try {
        const careerData = (await getCareer(parseInt(id))).data;
        setCareer(careerData);
      } catch (error) {
        console.error("Error loading career:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadCareer(id);
    }
  }, [id]);

  return loading ? <Loading /> : career && <AdminCareersForm item={career} />;
};

export default AdminCareersSave;
