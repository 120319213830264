import React, { useEffect, useState } from "react";
import EntityTable from "../../../components/admin/entity-table/EntityTable";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import Loading from "../../../components/loading/Loading";
import {
  getUpgradeRequests,
  removeUpgradeRequest,
} from "../../../api/upgrade-requests";
import { UpgradeRequest } from "../../../types/response/upgrade-request";
import { utils } from "../../../common/utils";
import DataTableButtons from "../../../components/admin/data-table-buttons/DataTableButtons";
import { useNotificationContext } from "../../../context/NotificationContext";
import { notificationTypes } from "../../../common/notification-types";

const AdminUpgradeRequests: React.FC = () => {
  const [upgradeRequests, setUpgradeRequests] = useState<UpgradeRequest[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);

  const [item, setItem] = useState<number | undefined>();

  const [showRemove, setShowRemove] = useState(false);

  const take = 10;

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const showSuccessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Operación exitosa");
    setNotificationText(
      "Se eliminó la solicitud de mejora a excelencia académica."
    );
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("¡Ups! Algo salió mal");
    setNotificationText(
      "Hubo un problema al eliminar la solicitud de mejora a excelencia académica."
    );
    setNotificationType(notificationTypes.ERROR);
  };

  const deleteUpgradeRequest = async () => {
    if (item) {
      try {
        await removeUpgradeRequest(item);
        loadUpgradeRequests();
        showSuccessMessage();
      } catch (error) {
        showErrorMessage();
      }
    }
  };

  const loadUpgradeRequests = async () => {
    try {
      const response = await getUpgradeRequests(page, take);
      const paginatedData = response.data;
      setUpgradeRequests(paginatedData.data);
      setItemsCount(paginatedData.meta.itemCount);
    } catch (error) {
      console.error("Error loading upgrade requests", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUpgradeRequests();
  }, [page]);

  const renderUpgradeRequests = () => {
    return upgradeRequests.map((request) => (
      <tr key={request.id}>
        <td>{request.id}</td>
        <td>{request.email}</td>
        <td>{request.fullName}</td>
        <td>{utils.formatDate(request.createdAt)}</td>
        <td>
          <DataTableButtons
            itemId={request.id}
            handleView={(id: number) =>
              navigate(`/admin/upgrade-requests/${id}`)
            }
            handleDelete={(id: number) => {
              setItem(id);
              setShowRemove(true);
            }}
          />
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <EntityTable
          title={"Solicitudes de Excelencia Académica"}
          headers={["ID", "Email", "Nombre", "Fecha de envío", "Acciones"]}
          renderData={renderUpgradeRequests}
          page={page}
          setPage={setPage}
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
          deleteButton={deleteUpgradeRequest}
          deleteHeader={"¿Está seguro que desea eliminar la solicitud?"}
          deleteMessage={
            "Una vez eliminada, el usuario podrá enviar nuevas solicitudes"
          }
          showRemove={showRemove}
          setShowRemove={setShowRemove}
        />
      )}
    </Container>
  );
};

export default AdminUpgradeRequests;
