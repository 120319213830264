import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import logo from "../../assets/images/logo/logo-primary.png";

const TermsAndConditionsMain: React.FC = () => {
  return (
    <Row className="justify-content-center pt-4">
      <Col xs={12} md={6}>
        <div>
          <a className="d-flex justify-content-center mb-4" href="/">
            <img alt="Logo Chamba Sí Hay" src={logo} width={133} height={47} />
          </a>
        </div>
        <Card className="shadow-sm mb-4">
          <Card.Body>
            <p className="text-center">
              <strong>
                <u>TÉRMINOS Y CONDICIONES</u>
              </strong>
            </p>
            <div className="text-justify">
              <article>
                <p>
                  Mediante el presente documento, CHAMBA SI HAY S.A.C. (la
                  “Empresa” o “Nosotros”), identificada con Registro Único de
                  Contribuyente No. 20612226424, con domicilio en, Lima, Perú,
                  informa y solicita el consentimiento a los Usuarios de la
                  plataforma “Chamba Sí Hay”, su dominio web{" "}
                  <a href="https://chambasihay.com.pe/">
                    https://ChambaSiHay.com.pe/
                  </a>
                  , en donde estos Términos y Condiciones se encuentren.
                  Cualquier persona que mantenga un vínculo comercial con la
                  Empresa y desee acceder usar la Plataforma podrá hacerlo
                  sujetándose a los Términos y Condiciones, las demás políticas
                  y principios que rigen Chamba Sí Hay y que son incorporados al
                  presente directamente o por referencia. En consecuencia, todas
                  las visitas, registros y operaciones que se realicen en la
                  Plataforma, así como sus efectos jurídicos, quedarán regidos
                  por estas reglas y sometidos a la legislación aplicable en
                  Perú.
                </p>
              </article>
              <article>
                <div className="font-bold">
                  I. <u>Aceptación de los Términos y Condiciones</u>
                </div>
                <div>
                  <p>
                    Al crearse una cuenta, y de manera previa a hacer uso de los
                    servicios de la Empresa y/o utilizar la Plataforma de
                    cualquier manera, Usted acepta estos Términos y Condiciones,
                    así como todas las demás reglas, políticas y procedimientos
                    operativos que se publiquen en la Plataforma, al llenar sus
                    datos y dar clic en «Aceptar», manifestando de esta forma su
                    voluntad a través del presente medio electrónico, de
                    conformidad con el artículo 141 del Código Civil Peruano.
                  </p>
                  <p>
                    Estos Términos y Condiciones se aplicarán a todos los
                    usuarios de la Plataforma (en adelante, los “Usuarios” o el
                    “Usuario”) y se entenderán como parte integral de todos los
                    actos y contratos que se ejecuten o celebren en el marco de
                    las relaciones comerciales entre los usuarios de este sitio
                    y la Empresa y que hagan uso de esta Plataforma, incluyendo
                    también a la Política de Privacidad y Política de Cookies de
                    la Empresa.
                  </p>
                  <p>
                    El Usuario debe revisar y comprender todas las condiciones
                    establecidas en los Términos y Condiciones, así como a toda
                    información proporcionada por referencia, con anterioridad a
                    hacer uso de la Plataforma y/o con anterioridad a la
                    adquisición servicios de la Empresa.
                  </p>
                  <p>
                    Si el Usuario no acepta estos Términos y Condiciones, la
                    Empresa no podrá brindarle sus servicios, incluyendo el
                    acceso y uso de la Plataforma.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  II. <u>Capacidad legal</u>
                </div>
                <div>
                  <p>
                    La Plataforma sólo está disponible para personas que tengan
                    capacidad legal para contratar, es decir, para quienes
                    cuenten con facultades, de manera directa o delegada,
                    suficientes para realizar actos en representación del
                    titular de la cuenta. No podrán utilizar los servicios las
                    personas que no tengan esa capacidad. En ese sentido, los
                    Usuarios de la Plataforma actúan en representación de sí
                    mismos.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  III. <u>Registro y uso de la Plataforma</u>
                </div>
              </article>
              <article>
                <div className="font-bold">3.1. Registro</div>
                <div>
                  <p>
                    El Usuario que desee registrarse y hacer uso de Chamba Sí
                    Hay debe entablar, de manera previa, una relación comercial
                    con la Empresa, esto es, a través de la creación de una
                    cuenta única a partir de la cual proporcionará toda la
                    información necesaria para tales efectos y utilizar la
                    Plataforma (p. ej. Datos de contacto, información comercial,
                    entre otros) y, por su parte, Empresa le concederá acceso y
                    licencia no exclusiva de uso de la Plataforma.
                  </p>
                  <p>
                    Al registrarse, el Usuario obtendrá una clave (en adelante,
                    la “Clave”) que, en conjunto con su correo electrónico, (en
                    adelante, la “Cuenta”) le permitirá ingresar a su Cuenta
                    registrada en la Plataforma. La Clave es proporcionada para
                    uso personal del Usuario y cualquier uso de la misma será
                    única y exclusiva responsabilidad del mismo.
                  </p>
                  <p>
                    Asimismo, una vez registrado, el Usuario se hará responsable
                    por la información que ingrese a la Plataforma, ya que esta
                    será utilizada por la Empresa para brindarle servicios en el
                    marco de su relación comercial. En ese sentido, la Empresa
                    no se hace responsable por la información proporcionada por
                    el Usuario y, en consecuencia, cualquier efecto generado por
                    dicha información será de responsabilidad del Usuario.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  3.2. Uso permitido de la Plataforma
                </div>
                <div>
                  <p>
                    a) <u>Niveles y funcionalidades</u>
                  </p>
                  <p>
                    En función al nivel de servicios contratados con la Empresa,
                    el Usuario podrá tener acceso y hacer uso de uno o más
                    módulos de información y funcionalidades, de acuerdo a las
                    siguientes categorías: <br />• Gratuito: el registro como
                    Usuario le da acceso gratuito a las funcionalidades
                    descritas en la sección 5.1. de estos Términos y
                    Condiciones. a los catálogos. <br />• Premium: el registro
                    como Usuario y suscripción a la esta modalidad le otorga,
                    además de los accesos del nivel gratuito, otorga al Usuario
                    una preferencia de posición y/o mayor visibilidad en los
                    catálogos descritos en la sección 5.1 de estos Términos y
                    Condiciones.
                  </p>
                  <p>
                    b) <u>Reglas de Uso</u>
                  </p>
                  <p>
                    Los Usuarios tienen prohibido utilizar la Plataforma para
                    transmitir, distribuir, almacenar o destruir material (i) en
                    violación de la normativa vigente, (ii) de forma que se
                    infrinjan derechos de terceros o se viole la
                    confidencialidad, sensibilidad, honor, privacidad, imagen u
                    otros derechos personales de otras personas, y (iii) que
                    infrinja o viole estos Términos y Condiciones
                  </p>
                  <p>
                    Asimismo, los Usuarios tienen prohibido violar o intentar
                    violar la seguridad de la Plataforma, incluyendo pero no
                    limitándose a: (i) el acceso a datos que no estén destinados
                    a tal Usuario o entrar en un servidor o cuenta o portal cuyo
                    acceso no está expresamente autorizado al Usuario, (ii)
                    evaluar o probar la vulnerabilidad de un sistema o red, o
                    violar las medidas de seguridad, (iii) impedir o intentar
                    impedir (total o parcialmente) la prestación de los
                    servicios de la Plataforma a cualquier Usuario, anfitrión o
                    red, incluyendo, pero sin limitación, mediante el envío de
                    virus a la Plataforma, o mediante saturación o ataques de
                    denegación de los servicios, o de cualquier otra manera (iv)
                    enviar correos no pedidos (spam), incluyendo pero sin
                    limitarse promociones y/o publicidad de productos o
                    servicios, o (v) falsificar cualquier cabecera de paquete
                    TCP/IP o cualquier parte de la información de la cabecera de
                    cualquier correo electrónico o en mensajes de foros de
                    debate, y (vi) cualquier otra acción, omisión o maniobra
                    tendiente a afectar el normal y correcto funcionamiento de
                    la Plataforma y la prestación de los servicios, y/o que
                    tenga por finalidad o resultado causar algún daño a
                    cualquier tercero.
                  </p>
                  <p>
                    La Plataforma sólo podrá ser utilizada con fines lícitos,
                    para acceder a información referida a los servicios
                    disponibles a través de la misma y para utilizar dichos
                    servicios. Sin perjuicio de las demás prohibiciones de uso
                    indicadas en estos Términos y Condiciones, la Empresa
                    prohíbe específicamente cualquier utilización de la
                    Plataforma para:
                  </p>
                  <p>
                    - Anunciar datos biográficos incompletos, falsos o
                    inexactos. - Registrar más de una cuenta correspondiente a
                    un mismo Usuario.
                    <br />- Usar cualquier mecanismo para impedir o intentar
                    impedir el adecuado funcionamiento de la Plataforma o
                    cualquier actividad que se esté realizando en ella. <br />-
                    Revelar o compartir su contraseña con terceras personas, o
                    usar su contraseña para propósitos no autorizados. <br />-
                    El uso o intento de uso de cualquier máquina, software,
                    herramienta, agente u otro mecanismo para navegar o buscar
                    en esta Plataforma que sean distintos a las herramientas de
                    búsqueda provistos por la Empresa en la Plataforma. <br />-
                    Utilizar la Plataforma y los servicios brindados por la
                    Empresa de una manera contraria o en violación a estos
                    Términos y Condiciones.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  IV. <u>Modificaciones de los Términos y Condiciones</u>
                </div>
                <div>
                  <p>
                    La Empresa podrá modificar los Términos y Condiciones en
                    cualquier momento, haciendo público en la Plataforma los
                    términos modificados. Todos los términos modificados
                    entrarán en vigencia a los 5 (cinco) días hábiles después de
                    su publicación.
                  </p>
                  <p>
                    El Usuario brindará su consentimiento y aceptará los nuevos
                    Términos y Condiciones de la misma manera en la que ha
                    aceptado la versión anterior, según lo descrito en la
                    sección I para el caso de modificaciones sustanciales a los
                    Términos y Condiciones. En caso el Usuario no acepte la
                    nueva versión de los Términos y Condiciones, no podrán
                    brindársele los servicios, de acuerdo a con lo indicado en
                    la sección I.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  V. <u>Aspectos generales sobre la Plataforma</u>
                </div>
              </article>
              <article>
                <div className="font-bold">5.1. Descripción</div>
                <div>
                  <p>
                    A través de Chamba Sí Hay, la Empresa pone a servicio del
                    Usuario una plataforma de búsqueda de oportunidades
                    laborales, a través de las siguientes funcionalidades:
                  </p>
                  <p>
                    a) Búsqueda de empleo
                    <br />- Encuentra ofertas de trabajo en diferentes áreas,
                    industrias y ubicaciones. <br />- Filtra por palabras clave,
                    tipo de puesto, experiencia, salario y otros criterios.{" "}
                    <br />- Recibe notificaciones personalizadas cuando se
                    publiquen nuevas ofertas que coincidan con tu perfil.
                  </p>
                  <p>
                    b) Postulación a empleos <br />- Sube tu CV y carta de
                    presentación en un solo lugar. <br />- Postula a múltiples
                    ofertas con un solo clic. <br />- Sigue el estado de tus
                    postulaciones en tiempo real.
                  </p>
                  <p>
                    c) Gestión de perfil <br />- Crea y actualiza tu perfil
                    profesional. <br />- Recibe recomendaciones de trabajos que
                    se ajustan a tu perfil. <br />- Mantente al día con las
                    últimas noticias del mercado laboral.
                  </p>
                  <p>
                    d) Catálogos <br />- Forma parte de los catálogos de talento
                    a los cuales tendrán acceso las empresas que también
                    participan de la Plataforma y se encuentran en búsqueda del
                    candidato ideal para las posiciones que requieran. <br />-
                    Estos catálogos están compuestos por personas y
                    profesionales que comparten un mismo perfil y/o área de
                    expertise. Las empresas que forman parte de la Plataforma
                    pueden buscar entre los catálogos y visualizar a los
                    Usuarios, pudiendo contactar directamente al candidato de su
                    interés.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">5.2. El Contenido</div>
                <div>
                  <p>
                    a) <u>Contenido del Usuario</u>: El Usuario podrá
                    proporcionar datos a los servicios de la Plataforma (el
                    "Input", también denominado “Contenido”). El Usuario declara
                    ser titular del Input. Por su parte, la Empresa puede
                    utilizar el Contenido para proporcionar y mantener los
                    servicios de la Plataforma, cumplir con la ley aplicable y
                    hacer cumplir nuestras políticas. El Usuario responsable del
                    Contenido, asegurando que no infringe ninguna ley aplicable
                    ni estos Términos y Condiciones.
                  </p>
                  <p>
                    b) <u>Vínculos a sitios de terceros</u>: La Plataforma
                    contiene vínculos y enlaces a otros sitios de Internet. La
                    Empresa no respalda ni controla ni administra los contenidos
                    ni servicios de estos sitios web. En ese sentido, la Empresa
                    no es responsable del contenido de los sitios web de
                    terceros, ni de los servicios prestados en los mismos, y no
                    hace ninguna afirmación ni representación relativa al
                    contenido o su exactitud en estos sitios web de terceros ni
                    de los servicios allí prestados. Si el Usuario decide
                    acceder a sitios web de terceras partes vinculados, lo hace
                    a su propio riesgo, y bajo su propia responsabilidad,
                    debiendo respetar los términos y condiciones y políticas de
                    privacidad de los mismos.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">5.3. Tarifas y Pagos</div>
                <div>
                  <p>
                    a) <u>Tarifas y facturación</u>: El Usuario abonará todas
                    las tarifas cargadas a su cuenta (las "Tarifas") de acuerdo
                    con los precios y condiciones en la página de tarifas
                    aplicable, o según lo acordado de otra manera por escrito
                    con la Empresa. El Usuario proporcionará información de
                    facturación completa y precisa, incluido un método de pago
                    válido y autorizado. La Empresa utilizará el método de pago
                    indicado por el Usuario para realizar los cargos periódicos
                    según lo acordado, pero podrá cambiar razonablemente la
                    fecha en que se realiza el cargo. El Usuario autoriza a la
                    Empresa, así como a su procesador de pagos externo, realizar
                    el cargo de las Tarifas mediante el método de pago escogido
                    por el Usuario. Si la Empresa no puede completar el pago,
                    cursará un aviso al Usuario por escrito y podrá suspender el
                    acceso a los servicios de la Plataforma hasta que se reciba
                    el pago. Las Tarifas se pagan en soles peruanos. Los pagos
                    no son reembolsables, excepto según lo establecido en estos
                    Términos y Condiciones.
                  </p>
                  <p>
                    b) <u>Impuestos</u>: A menos que se indique lo contrario,
                    las Tarifas incluyen impuestos.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  VI. <u>Propiedad Intelectual</u>
                </div>
                <div>
                  <p>
                    El Usuario reconoce expresamente que la propiedad de la
                    Plataforma, y todos los derechos de propiedad intelectual,
                    secretos comerciales y cualquier otro derecho, título o
                    participación, vinculado directamente con el mismo, es de
                    titularidad de la Empresa. En ese sentido, el Usuario queda
                    prohibido de copiar, traducir, desensamblar o descompilar,
                    ni crear o tratar de crear por medio de ingeniería
                    reversiva, o de cualquier otra forma, el código fuente a
                    partir del código objeto de la Plataforma.
                  </p>
                  <p>
                    Por otro lado, todo el contenido incluido o puesto a
                    disposición del Usuario en Chamba Sí Hay, incluyendo textos,
                    gráficas, logos, íconos, imágenes, y cualquier otra
                    información (la "Información Propietaria"), es de propiedad
                    de la Empresa. La compilación de la Información Propietaria
                    es propiedad exclusiva de la Empresa y, en tal sentido, el
                    Usuario debe abstenerse de extraer y/o reutilizar partes de
                    la Información Propietaria.
                  </p>
                  <p>
                    Asimismo, las marcas, denominativas o figurativas, marcas de
                    servicio, diseños industriales y cualquier otro elemento de
                    propiedad intelectual que haga parte de la Información
                    Propietaria (la "Propiedad Industrial"), son de propiedad de
                    la Empresa y, por tal razón, están protegidas por las leyes
                    y los tratados internacionales de derecho de marcas,
                    patentes, modelos y diseños industriales.
                  </p>
                  <p>
                    El uso indebido y la reproducción total o parcial de la
                    Información Propietaria, la Propiedad Industrial y la
                    Plataforma quedan prohibidos.
                  </p>
                  <p>
                    El incumplimiento de las obligaciones contenidas en la
                    presente cláusula, producirá la resolución automática de la
                    relación entre la Empresa y el Usuario, de conformidad a lo
                    indicado en la Cláusula Novena de estos Términos y
                    Condiciones; ello sin perjuicio de las acciones legales que
                    puedan iniciarse por los daños y perjuicios derivados de
                    dicho incumplimiento.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  VII. <u>Confidencialidad y Protección de Datos Personales</u>
                </div>
              </article>
              <article>
                <div className="font-bold">7.1. Confidencialidad</div>
                <div>
                  <p>
                    Tanto el Usuario como la Empresa se obligan de manera
                    expresa a guardar la más absoluta confidencialidad respecto
                    de la información y documentación a la que tengan acceso
                    como consecuencia de la ejecución de estos Términos y
                    Condiciones y de la relación comercial entre ambas,
                    comprometiéndose a no divulgarla y/o usarla para fines
                    distintos a los previstos en estos Términos y Condiciones,
                    salvo que cuente con autorización previa y por escrito de la
                    otra parte.
                  </p>
                  <p>
                    Ambas partes se comprometen a emplear los medios necesarios
                    y tomar todas las precauciones razonables para proteger la
                    información indicada en el párrafo anterior y que no sea
                    divulgada ni cedida a terceros, adoptando las medidas de
                    seguridad necesarias para evitar su pérdida, robo o
                    sustracción.
                  </p>
                  <p>
                    Esta cláusula no será de aplicación y, en consecuencia, no
                    se considerará confidencial, aquella información que: (i) al
                    momento de su revelación o con posterioridad esté disponible
                    al público en general de cualquier forma; (ii) sea conocida
                    legítimamente por cualquier tercero con anterioridad a la
                    revelación por parte de quien la da a conocer; (iii) sea
                    conocida legítimamente a través de una fuente diferente de
                    la parte que la da a conocer; o, (iv) haya sido desarrollada
                    independientemente por la parte receptora.
                  </p>
                  <p>
                    La obligación de confidencialidad establecida en la presente
                    cláusula permanecerá vigente durante la vigencia de la
                    relación comercial entre el Usuario y la Empresa, y aún
                    durante los cinco (5) años posteriores a la terminación de
                    la misma.
                  </p>
                  <p>
                    En caso el Usuario o sus representantes fuesen requeridos
                    por autoridad competente a revelar alguna información que se
                    encuentre comprendida dentro de los alcances de esta
                    cláusula, deberá notificar de esta circunstancia a la
                    Empresa dentro de las veinticuatro (24) horas de recibido
                    dicho requerimiento. Por su parte, si la Empresa fuese
                    requerida a revelar alguna información respecto del Usuario
                    dentro de los alcances de esta cláusula, cursará una
                    notificación al Usuario dentro de los cinco (5) días
                    hábiles. Si la parte requerida estuviese obligada a revelar
                    dicha información bajo responsabilidad o sanción, ésta podrá
                    revelar únicamente la parte correspondiente de la
                    información que se le requiera sin que ello constituya
                    incumplimiento de estos Términos y Condiciones. En tal caso,
                    la parte obligada a revelar información confidencial deberá
                    realizar sus mejores esfuerzos para asegurar que la
                    información será tratada confidencialmente.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  7.2. Protección de Datos Personales
                </div>
                <div>
                  <p>
                    La información personal que el Usuario pueda proporcionar a
                    la Empresa antes o una vez concretada la relación comercial
                    y para el uso de los servicios de la Plataforma será tratada
                    de conformidad con la Política de Privacidad de la Empresa,
                    disponible en el siguiente enlace:
                  </p>
                  <p>
                    Por otro lado, si el Usuario utiliza los servicios de la
                    Plataforma para procesar datos personales, deberá asegurarse
                    de haber obtenido previamente el consentimiento informado
                    por parte de los titulares de dicha información personal o,
                    en todo caso, haber obtenido las autorizaciones necesarias
                    para el procesamiento de dichos datos como un encargado de
                    tratamiento. Adicionalmente, el Usuario declara que
                    procesará esos datos de acuerdo con la Ley Nro. 29733 y su
                    reglamento.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  VIII. <u>Indemnidad y Garantía</u>
                </div>
                <div>
                  <p>
                    El Usuario se compromete a indemnizar y eximir de toda
                    responsabilidad a la Empresa, sus filiales, sus empleados,
                    accionistas, locadores de servicios, directores, proveedores
                    y representantes por todas las responsabilidades,
                    reclamaciones y gastos que surjan de o se relacionen con su
                    uso o uso indebido, o acceso a la Plataforma, el contenido,
                    la violación de estos Términos y Condiciones, o la
                    infracción por parte del Usuario, o de cualquier tercero que
                    utilice su identidad en la Plataforma, de cualquier
                    propiedad intelectual u otro derecho de cualquier persona o
                    entidad. La Empresa se reserva el derecho de asumir la
                    defensa y el control exclusivo de cualquier asunto sujeto a
                    una indemnización por parte del Usuario, en cuyo caso nos
                    ayudará y cooperará para hacer valer cualquier defensa
                    disponible.
                  </p>
                  <p>
                    Por otro lado, la Empresa no garantiza que la Plataforma
                    vaya a operar en forma ininterrumpida, o de que estará libre
                    de defectos o errores menores que no afecten materialmente
                    su ejecución, o que los módulos contenidos en la Plataforma
                    estén diseñados para cumplir con todos los requerimientos de
                    negocios del Usuario. En ese sentido, el Usuario libera de
                    responsabilidad a la Empresa frente a cualquier daño que
                    pudiera generarse como consecuencia de defectos o errores en
                    la operación de la Plataforma, salvo que en la generación de
                    dicho daño haya mediado una conducta dolosa o con culpa
                    inexcusable por parte de la Empresa.
                  </p>
                  <p>
                    Sin perjuicio de lo anterior, la Empresa desplegará sus
                    mejores esfuerzos por resolver cualquier observación
                    relacionada al funcionamiento de la Plataforma, para lo cual
                    el Usuario deberá reportar el incidente a la siguiente
                    dirección de correo electrónico [incluir correo de persona
                    encargada del servicio técnico ], a fin de asignarle un
                    especialista técnico que se encargará de atender la
                    observación.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  IX. <u>Incumplimiento y Resolución</u>
                </div>
                <div>
                  <p>
                    De detectarse un incumplimiento a las obligaciones previstas
                    en estos Términos y Condiciones por parte del Usuario, la
                    Empresa procederá a: (i) exigir el cumplimiento de la
                    obligación inejecutada por escrito, otorgándole un plazo de
                    subsanación de cinco (5) días calendario; (ii) vencido el
                    plazo sin que el Usuario cumpla con su obligación, se
                    resolverá la relación comercial entre las partes de pleno
                    derecho, de conformidad con el artículo 1430° del Código
                    Civil.
                  </p>
                  <p>
                    Sin perjuicio de lo anterior, la Empresa podrá terminar las
                    relaciones comerciales con el Usuario de forma automática,
                    de conformidad con lo establecido en el artículo 1430 del
                    Código Civil, bastando para ello que remita una comunicación
                    por escrito al Usuario ejerciendo su facultad resolutoria,
                    en caso el Usuario incumpliese cualesquiera de las
                    obligaciones previstas en las Cláusulas Tercera, Sexta y
                    Séptima de estos Términos y Condiciones.
                  </p>
                  <p>
                    De producirse la resolución de las relaciones entre las
                    partes, la Empresa procederá a eliminar la Cuenta del
                    Usuario y a impedirle el acceso a la Plataforma, en cuyo
                    caso el Usuario no tendrá derecho a ningún reembolso
                    respecto a los pagos efectuados por el uso de Chamba Sí Hay.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  X. <u>Indivisibilidad del acuerdo</u>
                </div>
                <div>
                  <p>
                    Si se encuentra que alguna disposición de estos Términos y
                    Condiciones no se puede cumplir o es inválida, dicha
                    disposición se limitará o eliminará en la medida mínima
                    necesaria para que estos Términos y Condiciones permanezcan
                    en plena vigencia y efecto y sean aplicables. El hecho de
                    que ninguna de las partes ejerza de ningún modo ningún
                    derecho previsto en el presente documento no se considerará
                    una renuncia a ningún otro derecho en virtud del presente.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  XI. <u>No renuncia</u>
                </div>
                <div>
                  <p>
                    La incapacidad de la Empresa para hacer cumplir cualquier
                    parte de estos Términos y Condiciones no constituirá una
                    renuncia a sus derechos de aplicar posteriormente esa o
                    cualquier otra parte de estos Términos y Condiciones. La
                    renuncia al cumplimiento en cualquier caso en particular no
                    significa que la Empresa renuncia al cumplimiento en el
                    futuro. Para que cualquier renuncia al cumplimiento de estos
                    Términos y Condiciones sea vinculante, se enviará al Usuario
                    un aviso electrónico de dicha renuncia
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  XII. <u>Cesión</u>
                </div>
                <div>
                  <p>
                    Estos Términos y Condiciones son personales para el Usuario,
                    y no son asignables, transferibles o sublicenciables,
                    excepto que cuente con el consentimiento previo por escrito
                    de la Empresa. La Empresa puede asignar, transferir o
                    delegar cualquiera de sus derechos y obligaciones bajo el
                    presente sin consentimiento.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  XIII. <u>Agencia</u>
                </div>
                <div>
                  <p>
                    No se crea ninguna agencia, sociedad, Joint Venture, o
                    relación de laboral como resultado de estos Términos y
                    Condiciones y ninguna de las partes tiene autoridad de
                    ningún tipo para vincular a la otra en ningún aspecto.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  XIV. <u>Avisos</u>
                </div>
                <div>
                  <p>
                    A menos que se especifique lo contrario, todos los avisos
                    bajo estos Términos y Condiciones serán por medios
                    electrónicos y se considerarán debidamente entregados cuando
                    se reciban o se publiquen en la Plataforma o en la dirección
                    de correo electrónico registrada por el Usuario.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  XV. <u>Ley aplicable y jurisdicción</u>
                </div>
                <div>
                  <p>
                    Estos Términos y Condiciones se regirán e interpretarán de
                    acuerdo con las leyes de la República del Perú. El Usuario
                    acepta que cualquier disputa que surja de o se relacione con
                    el objeto de estos Términos y Condiciones se regirá por la
                    jurisdicción exclusiva y el lugar de los tribunales del
                    Distrito Judicial de Lima, Cercado.
                  </p>
                </div>
              </article>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default TermsAndConditionsMain;
