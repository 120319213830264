import { APIMiddleware } from "../middleware/api-middleware";
import { apiUrls } from "../common/api-urls";
import { SignInRequest } from "../types/request/sign-in-request";
import { SignUpRequest } from "../types/request/sign-up-request";
import { SignUpCompanyRequest } from "../types/request/sign-up-company-request";
import { ValidateGoogleRequest } from "../types/request/validate-google-request";

const { AUTH_URL } = apiUrls;

export function signIn(signInRequest: SignInRequest) {
  const url = `${AUTH_URL}/user/login`;
  return APIMiddleware.post(url, {
    data: { email: signInRequest.email, password: signInRequest.password },
    noAuthToken: true,
  });
}

export function validateGoogle(validateGoogleRequest: ValidateGoogleRequest) {
  const url = `${AUTH_URL}/google/callback/user`;
  return APIMiddleware.post(url, {
    data: {
      token: validateGoogleRequest.token,
      email: validateGoogleRequest.email,
      name: validateGoogleRequest.name,
    },
    noAuthToken: true,
  });
}

export function signInGoogle() {
  const url = `${AUTH_URL}/google/user`;
  return APIMiddleware.get(url, {
    noAuthToken: true,
  });
}

export function signInCompany(signInRequest: SignInRequest) {
  const url = `${AUTH_URL}/company/login`;
  return APIMiddleware.post(url, {
    data: { email: signInRequest.email, password: signInRequest.password },
    noAuthToken: true,
  });
}

export function signUp(signUpRequest: SignUpRequest) {
  const url = `${AUTH_URL}/user/register`;
  return APIMiddleware.post(url, {
    data: {
      firstName: signUpRequest.firstName,
      lastName: signUpRequest.lastName,
      email: signUpRequest.email,
      password: signUpRequest.password,
      careerId: signUpRequest.careerId,
      seniorityId: signUpRequest.seniorityId,
      district: signUpRequest.district,
      phoneNumber: signUpRequest.phoneNumber,
      acceptsAdvertising: signUpRequest.acceptsAdvertising,
      authStrategy: signUpRequest.authStrategy,
    },
    noAuthToken: true,
  });
}

export function signUpCompany(signUpCompanyRequest: SignUpCompanyRequest) {
  const url = `${AUTH_URL}/company/register`;
  return APIMiddleware.post(url, {
    data: {
      name: signUpCompanyRequest.name,
      ruc: signUpCompanyRequest.ruc,
      email: signUpCompanyRequest.email,
      password: signUpCompanyRequest.password,
      businessName: signUpCompanyRequest.businessName,
      taxStatus: signUpCompanyRequest.taxStatus,
      address: signUpCompanyRequest.address,
      postalCode: signUpCompanyRequest.postalCode,
      phone: signUpCompanyRequest.phone,
      industry: signUpCompanyRequest.industry,
      acceptsAdvertising: signUpCompanyRequest.acceptsAdvertising,
    },
    noAuthToken: true,
  });
}

export function forgotPasswordApplicant(email: string) {
  const url = `${AUTH_URL}/user/forgot-password`;
  return APIMiddleware.post(url, {
    data: { email },
    noAuthToken: true,
  });
}

export function forgotPasswordCompany(email: string) {
  const url = `${AUTH_URL}/company/forgot-password`;
  return APIMiddleware.post(url, {
    data: { email },
    noAuthToken: true,
  });
}

export function resetUserPassword(data: any) {
  const url = `${AUTH_URL}/user/reset-password`;
  return APIMiddleware.post(url, {
    data: data,
    noAuthToken: true,
  });
}

export function resetCompanyPassword(data: any) {
  const url = `${AUTH_URL}/company/reset-password`;
  return APIMiddleware.post(url, {
    data: data,
    noAuthToken: true,
  });
}
