import { useState } from "react";
import { resultType } from "../common/result-type";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../common/notification-types";
import { UpdateAdvertisementStatusRequest } from "../types/request/update-advertisement-status-request";
import { updateJobAdvertisement } from "../api/job-advertisements.api";

export const useUpdateAdvertisement = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/admin/advertisements");
    setShowNotificationModal(true);
    setNotificationTitle("Anuncio actualizado");
    setNotificationText("El estado del anuncio ha sido actualizado.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const modifyAdvertisement = async (
    id: number,
    req: UpdateAdvertisementStatusRequest
  ) => {
    setLoading(true);
    try {
      await updateJobAdvertisement(id, req);
      showSuccessMessage();
    } catch (ex) {
      setMessage(
        "Hubo un error al actualizar el anuncio. Por favor, inténtelo más tarde."
      );
      setType(resultType.error);
    } finally {
      setLoading(false);
    }
  };

  return {
    modifyAdvertisement,
    loading,
    message,
    setMessage,
    type,
  };
};
