import React from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";

interface AccountModalProps {
  showAccountModal: boolean;
  handleClose: (() => void) | undefined;
}

const AccountModal: React.FC<AccountModalProps> = ({
  showAccountModal,
  handleClose,
}) => {
  return (
    <Modal
      show={showAccountModal}
      onHide={handleClose}
      centered
      data-bs-theme="light"
    >
      <ModalHeader closeButton>Realiza el pago a nuestras cuentas</ModalHeader>
      <ModalBody>
        <div className="text-justify">
          1. Transfiere monto del plan que desea adquirir a nuestro número de
          cuenta Interbank soles
          <br />
          CC: 2003 0059 65070
          <br />
          CCI: 00320000300596507032
          <br />
          Chamba Si Hay S.A.C.
          <br /> <br />
          2. ⁠Al realizar el pago, escribenos al correo de ventas{" "}
          <a href="mailto:a.comercialcsh@gmail.com">
            a.comercialcsh@gmail.com
          </a>{" "}
          o a nuestro WhatsApp{" "}
          <a
            href="https://wa.me/+51914388605?text=Hola!,%20quisiera%20adquirir%20un%20plan"
            target="_blank"
          >
            +51 914 388 605
          </a>{" "}
          indicándonos el servicio que adquiriste, y envíanos la constancia de
          pago.
          <br /> <br /> 3. ⁠Nuestro equipo de ventas verificará la
          transferencia. En cuanto recepcionemos el pago, procederemos a activar
          el servicio que adquiriste.
          <br />
          <br />
          <span className="font-small">
            * La activación del servicio por el pago realizado está sujeto a la
            recepción del monto transferido
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AccountModal;
