import React from "react";

interface SocialMediaIconProps {
  src: string;
  url: string;
}

const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({ src, url }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="m-1">
      <img alt="social-media-icon" src={src} width={40} height={40} />
    </a>
  );
};

export default SocialMediaIcon;
