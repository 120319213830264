import { FC, ReactNode, createContext, useContext, useState } from "react";
import { FilterContextState } from "./FilterContextState";
import { getIndustries } from "../../api/industries.api";
import { advancedFilterTypes } from "../../common/advanced-filter-types";

const initialState: FilterContextState = {
  industries: [],
  filterType: advancedFilterTypes.jobOffers,
  setDegreeFilter: () => {},
  setFilterType: () => {},
  loadIndustries: () => {},
  setExperienceFilter: () => {},
  setContractFilter: () => {},
  setSectorFilter: () => {},
  setExcelenceFilter: () => {},
  setCareerFilter: () => {},
  setLanguageFilter: () => {},
  setSalaryFilter: () => {},
  setTimeExperienceFilter: () => {},
  setDistrictFilter: () => {},
  setSavedFilter: () => {},
  buildQueryFilter: () => {},
  buildApplicantsQueryFilter: () => {},
  buildApplicantsByOfferQueryFilter: () => {},
  changeSearchQuery: () => {},
};

const FilterContext = createContext<FilterContextState>(initialState);

interface FilterProviderProps {
  children: ReactNode;
}

export const FilterProvider: FC<FilterProviderProps> = ({ children }) => {
  //states
  const [experienceFilter, setExperienceFilter] = useState<string[]>([]);
  const [contractFilter, setContractFilter] = useState<string[]>([]);
  const [languageFilter, setLanguageFilter] = useState<string[]>([]);
  const [salaryFilter, setSalaryFilter] = useState<any[]>([]);
  const [timeExperienceFilter, setTimeExperienceFilter] = useState<any[]>([]);
  const [districtFilter, setDistrictFilter] = useState<string>("");
  const [degreeFilter, setDegreeFilter] = useState<string>("");
  const [filterType, setFilterType] = useState<string>(
    advancedFilterTypes.jobOffers
  );
  const [sectorFilter, setSectorFilter] = useState<number[]>([]);
  const [careerFilter, setCareerFilter] = useState<number>(0);
  const [excelenceFilter, setExcelenceFilter] = useState<boolean>(false);
  const [industries, setIndustries] = useState<any[]>([]);
  const [loadingIndustries, setLoadingIndustries] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const [savedFilter, setSavedFilter] = useState<boolean>(false);

  const buildQueryFilter = (): any => {
    const filter: any = {};
    filter.experienceFilter = experienceFilter;
    filter.contractFilter = contractFilter;
    filter.sectorFilter = sectorFilter;
    filter.careerFilter = careerFilter;
    filter.excelenceFilter = excelenceFilter;
    filter.search = search;
    return filter;
  };

  const buildApplicantsQueryFilter = (): any => {
    const filter: any = {};
    filter.experienceFilter = experienceFilter;
    filter.sectorFilter = sectorFilter;
    filter.careerFilter = careerFilter;
    filter.excelenceFilter = excelenceFilter;
    filter.languageFilter = languageFilter;
    filter.districtFilter = districtFilter;
    filter.salaryFilter = salaryFilter;
    filter.timeExperienceFilter = timeExperienceFilter;
    return filter;
  };

  const buildApplicantsByOfferQueryFilter = (): any => {
    const filter: any = {};
    filter.careerFilter = careerFilter;
    filter.expectedSalaryFilter = salaryFilter;
    filter.timeExperienceFilter = timeExperienceFilter;
    filter.degreeFilter = degreeFilter;
    filter.savedFilter = savedFilter;
    return filter;
  };

  const loadIndustries = async (): Promise<void> => {
    setLoadingIndustries(true);
    try {
      const response = await getIndustries();
      setIndustries(
        response.data.map((i: any) => ({ id: i.id, label: i.name }))
      );
    } catch (error) {
      console.error("Error loading industries", error);
    } finally {
      setLoadingIndustries(false);
    }
  };

  const changeSearchQuery = (value: string) => {
    setSearch(value);
  };

  const modalContextValue = {
    experienceFilter,
    contractFilter,
    sectorFilter,
    careerFilter,
    excelenceFilter,
    languageFilter,
    salaryFilter,
    timeExperienceFilter,
    districtFilter,
    savedFilter,
    search,
    industries,
    loadingIndustries,
    filterType,
    degreeFilter,
    setDegreeFilter,
    setFilterType,
    loadIndustries,
    setExperienceFilter,
    setContractFilter,
    setLanguageFilter,
    setSalaryFilter,
    setSectorFilter,
    setTimeExperienceFilter,
    setCareerFilter,
    setExcelenceFilter,
    setDistrictFilter,
    setSavedFilter,
    buildQueryFilter,
    buildApplicantsQueryFilter,
    buildApplicantsByOfferQueryFilter,
    changeSearchQuery,
  };

  return (
    <FilterContext.Provider value={modalContextValue}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};
