import React from "react";
import "./Slogan.css";
import { Col, Image, Row } from "react-bootstrap";
import { Fade, Slide } from "react-awesome-reveal";
import DotsDecorator from "../dots-decorator/DotsDecorator";
import applicant from "../../../assets/images/slogan/applicant.png";
import SearchComponent from "./search-component/SearchComponent";
import { isMobile } from "react-device-detect";
import FormButton from "../../form-button/FormButton";
import { useNavigate } from "react-router-dom";

const Slogan: React.FC = () => {
  const navigate = useNavigate();

  return (
    <section id="slogan">
      <Row>
        <Col xs={12} sm={6} md={6} lg={6}>
          <Slide direction="left" triggerOnce={true}>
            <div className="center-slogan">
              <h1 className="title">
                TRANSFORMANDO EL TALENTO EN OPORTUNIDADES
              </h1>
              <DotsDecorator height={20} width={20} />
            </div>
          </Slide>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6}>
          <Slide direction="right" triggerOnce={true}>
            <div className="p-5">
              <Image alt="postulante" src={applicant} fluid />
            </div>
          </Slide>
        </Col>
      </Row>
      <div className="action-buttons justify-content-md-center text-center align-items-center w-100">
        <Fade delay={500} triggerOnce={true}>
          {isMobile ? (
            <FormButton
              type="link"
              buttonText="Iniciar sesión"
              variant="main"
              onClick={() =>
                navigate("/auth?userType=applicant&authType=login")
              }
              mainFont={true}
            />
          ) : (
            <SearchComponent />
          )}
        </Fade>
      </div>
    </section>
  );
};

export default Slogan;
