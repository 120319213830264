import React, { ReactNode } from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import NotificationModal from "../components/notification-modal/NotificationModal";

interface PageContainerProps {
  element: ReactNode;
}

const PageContainer: React.FC<PageContainerProps> = ({ element }) => {
  return (
    <>
      <Header />
      {element}
      <Footer />
      <NotificationModal />
    </>
  );
};

export default PageContainer;
