import { APIMiddleware } from "../middleware/api-middleware";
import { apiUrls } from "../common/api-urls";
import { BuyApplicantPlanRequest } from "../types/request/buy-applicant-plan-request";

const { USER_PURCHASES_URL } = apiUrls;

export function buyApplicantPlan(buyApplicantPlan: BuyApplicantPlanRequest) {
  const url = `${USER_PURCHASES_URL}/buy-career-boost`;
  return APIMiddleware.post(url, {
    data: {
      careerId: buyApplicantPlan.careerId,
      price: buyApplicantPlan.price,
      token: buyApplicantPlan.token,
      careerBoostId: buyApplicantPlan.careerBoostId,
      billType: buyApplicantPlan.billType,
      billDetails: buyApplicantPlan.billDetails,
    },
  });
}

export function getUserPurchases(page: number, take: number) {
  const url = `${USER_PURCHASES_URL}/list?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function updateUserPurchase(id: number, billGenerated: boolean) {
  const url = `${USER_PURCHASES_URL}/bill-generated/${id}`;
  return APIMiddleware.patch(url, { data: { billGenerated } });
}

export function getUserPurchase(id: number) {
  const url = `${USER_PURCHASES_URL}/${id}`;
  return APIMiddleware.get(url);
}
