import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";
import { UpdateAdvertisementStatusRequest } from "../types/request/update-advertisement-status-request";

const { ADVERTISEMENTS_URL } = apiUrls;

export function getJobAdvertisements(page: number, take: number, filters?: any) {
  const url = `${ADVERTISEMENTS_URL}/job-advertisements?page=${page}&take=${take}`;
  return APIMiddleware.post(url, { noAuthToken: true, data: filters });
}

export function getJobAdvertisementsList(
  page: number,
  take: number,
  filter: string
) {
  const url = `${ADVERTISEMENTS_URL}/list?page=${page}&take=${take}&filter=${filter}`;
  return APIMiddleware.get(url);
}

export function getJobAdvertisement(id: number) {
  const url = `${ADVERTISEMENTS_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function updateJobAdvertisement(
  id: number,
  req: UpdateAdvertisementStatusRequest
) {
  const url = `${ADVERTISEMENTS_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      status: req.status,
    },
  });
}

export function removeAdvertisement(id: number) {
  const url = `${ADVERTISEMENTS_URL}/${id}`;
  return APIMiddleware.delete(url);
}
