import React from "react";
import FormInput from "../../form-input/FormInput";
import { Col, Row } from "react-bootstrap";

interface LoginFormProps {
  email?: string;
  setEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  password?: string;
  setPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  email,
  setEmail,
  password,
  setPassword,
}) => {
  return (
    <React.Fragment>
      <Row className="justify-content-center pt-4">
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Correo electrónico"}
            type={"email"}
            name={"email"}
            maxLength={100}
            required={true}
            value={email}
            placeholder={"example@gmail.com"}
            onChange={setEmail}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Contraseña"}
            type={"password"}
            name={"password"}
            maxLength={100}
            required={true}
            value={password}
            placeholder={""}
            onChange={setPassword}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LoginForm;
