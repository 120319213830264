import React from "react";
import "./Catalog.css";
import { Col, Row } from "react-bootstrap";
import students from "../../../assets/images/slogan/students.png";
import excelence from "../../../assets/images/slogan/excelence.png";
import professionals from "../../../assets/images/slogan/professionals.png";
import CatalogButton from "../../catalog-button/CatalogButton";
import { Fade } from "react-awesome-reveal";

const Catalog: React.FC = () => {
  return (
    <section id="catalog">
      <Row className="p-5 mt-5">
        <Col>
          <Fade delay={250} triggerOnce={true}>
            <CatalogButton
              buttonText={"estudiantes"}
              variant={"main"}
              icon={students}
              url={"/catalogue"}
              iconHeight={40}
              iconWidth={50}
            />
          </Fade>
        </Col>
        <Col>
          <Fade delay={500} triggerOnce={true}>
            <CatalogButton
              buttonText={"excelencia"}
              variant={"main"}
              icon={excelence}
              url={"/catalogue"}
              iconHeight={60}
              iconWidth={40}
            />
          </Fade>
        </Col>
        <Col>
          <Fade delay={750} triggerOnce={true}>
            <CatalogButton
              buttonText={"profesionales"}
              variant={"main"}
              icon={professionals}
              url={"/catalogue"}
              iconHeight={40}
              iconWidth={50}
            />
          </Fade>
        </Col>
      </Row>
    </section>
  );
};

export default Catalog;
