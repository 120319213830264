import { useState } from "react";
import { resultType } from "../common/result-type";
import { updateApplicantInformation } from "../api/applicant.api";
import { useNotificationContext } from "../context/NotificationContext";
import { notificationTypes } from "../common/notification-types";
import { useNavigate } from "react-router-dom";

export const useUpdateInformation = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/applicant");
    setShowNotificationModal(true);
    setNotificationTitle("Actualización de perfil exitosa");
    setNotificationText("La información de perfil fue actualizada.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = () => {
    setMessage("Hubo un error al actualizar la información.");
    setType(resultType.error);
  };

  const updateInformation = async (formData: FormData) => {
    setLoading(true);

    try {
      await updateApplicantInformation(formData);
      setLoading(false);
      showSuccessMessage();
    } catch (ex) {
      setLoading(false);
      showErrorMessage();
    }
  };

  return {
    updateInformation,
    loading,
    message,
    setMessage,
    type,
  };
};
