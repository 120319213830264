import React, { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import FormCheckbox from "../form-checkbox/FormCheckbox";
import { isMobile } from "react-device-detect";

interface FilterCheckboxProps {
  label: string;
  options: string[];
  handleFilter?: (f: any[]) => void;
}

interface FilterOption {
  optionName: string;
  enabled: boolean;
}

const whiteArrowSvg =
  "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E";

const FilterCheckbox: React.FC<FilterCheckboxProps> = ({
  label,
  options,
  handleFilter,
}) => {
  const initialFilters = options.map((optionName) => ({
    optionName,
    enabled: false,
  }));

  const [isExpanded, setIsExpanded] = useState(!isMobile);
  const [filters, setFilters] = useState<FilterOption[]>(initialFilters);

  const handleCheckboxChange = (index: number) => {
    const newFilters = [...filters];
    newFilters[index].enabled = !newFilters[index].enabled;
    setFilters(newFilters);
    if (handleFilter) handleFilter(newFilters);
  };

  const toggleFilters = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="ps-2 pe-2">
      <Row className="font-bold mt-2">
        <Col xs={10} sm={10} md={10} lg={10} className="p-0">
          {label}
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} className="p-0">
          <Image
            className={`cursor-pointer ${isExpanded ? "normal" : "rotate"}`}
            alt="accordion-arrow"
            src={whiteArrowSvg}
            width={24}
            height={24}
            onClick={toggleFilters}
          />
        </Col>
      </Row>
      <Row className={`mt-2 options-content ${isExpanded ? "" : "collapsed"}`}>
        {filters.map((filter, index) => {
          return (
            <FormCheckbox
              key={filter.optionName}
              type="checkbox"
              label={filter.optionName}
              checked={filter.enabled}
              onChange={() => handleCheckboxChange(index)}
            />
          );
        })}
      </Row>
    </div>
  );
};

export default FilterCheckbox;
