import React, { useEffect, useState } from "react";
import FormInput from "../../../form-input/FormInput";
import { Col, Row } from "react-bootstrap";
import FormAutocomplete from "../../../form-autocomplete/FormAutocomplete";
import { getCareers } from "../../../../api/careers.api";
import { Career } from "../../../../types/response/career";
import FormSelect from "../../../form-select/FormSelect";
import { seniorities } from "../../../../common/seniorities";
import { districts } from "../../../../common/districts";

interface ApplicantCompleteInformationFormProps {
  careerId?: number;
  setCareerId: (id: number) => void;
  seniorityId?: number;
  setSeniorityId: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  district?: string;
  setDistrict: (label: string) => void;
  phoneNumber?: string;
  setPhoneNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ApplicantCompleteInformationForm: React.FC<
  ApplicantCompleteInformationFormProps
> = ({
  careerId,
  setCareerId,
  seniorityId = 0,
  setSeniorityId,
  district,
  setDistrict,
  phoneNumber,
  setPhoneNumber,
}) => {
  const districtOptions = districts.filter((x: any) => x.provinceId === 128);

  const [careersOptions, setCareersOptions] = useState<Career[]>([]);

  const loadCareers = async () => {
    try {
      const response = await getCareers();
      const careers = response.data;
      const formattedCareers = careers.map((career: any) => ({
        id: career.id,
        label: career.name,
      }));
      setCareersOptions(formattedCareers);
    } catch (error) {
      console.error("Error loading careers", error);
    }
  };

  useEffect(() => {
    loadCareers();
  }, []);

  return (
    <React.Fragment>
      <Row className="pt-4">
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormAutocomplete
            id="career"
            label="Carrera"
            options={careersOptions}
            initialValue={""}
            onChange={(selected: any[]) => {
              if (selected.length > 0) setCareerId(selected[0].id);
            }}
            required={true}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormSelect
            label={"Categoría de perfil"}
            name={"Experiencia"}
            required={true}
            value={seniorityId}
            options={seniorities}
            onChange={setSeniorityId}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormAutocomplete
            id="district"
            label="Distrito"
            options={districtOptions}
            initialValue={""}
            onChange={(selected: any[]) => {
              if (selected.length > 0) setDistrict(selected[0].label);
            }}
            required={true}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label={"Teléfono"}
            type={"text"}
            name={"phoneNumber"}
            maxLength={9}
            minLength={9}
            required={true}
            value={phoneNumber}
            placeholder={""}
            onChange={setPhoneNumber}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ApplicantCompleteInformationForm;
