import { useState } from "react";
import { resultType } from "../common/result-type";
import { CreateStudyRequest } from "../types/request/create-study-request";
import { createApplicantStudy, updateApplicantStudy } from "../api/study.api";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../common/notification-types";

export const useAddStudy = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/applicant");
    setShowNotificationModal(true);
    setNotificationTitle("Formación académica agregada");
    setNotificationText("Sigue completando tu perfil para los reclutadores.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const addStudy = async (req: CreateStudyRequest) => {
    setLoading(true);
    try {
      await createApplicantStudy(req);
      setLoading(false);
      showSuccessMessage();
    } catch (ex) {
      setLoading(false);
      setMessage("Hubo un error al crear formación académica.");
      setType(resultType.error);
    }
  };

  const updateStudy = async (id: number, req: CreateStudyRequest) => {
    setLoading(true);
    try {
      await updateApplicantStudy(id, req);
      setLoading(false);
      showSuccessMessage();
    } catch (ex) {
      setLoading(false);
      setMessage("Hubo un error al editar formación académica.");
      setType(resultType.error);
    }
  };

  return {
    addStudy,
    updateStudy,
    loading,
    message,
    setMessage,
    type,
  };
};
