import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Loading from "../../../components/loading/Loading";
import EntityTable from "../../../components/admin/entity-table/EntityTable";
import { useNavigate } from "react-router-dom";
import DataTableButtons from "../../../components/admin/data-table-buttons/DataTableButtons";
import { utils } from "../../../common/utils";
import { useNotificationContext } from "../../../context/NotificationContext";
import { notificationTypes } from "../../../common/notification-types";
import { getCompanies, removeCompany } from "../../../api/company.api";
import { CompanyReponse } from "../../../types/response/company-response";
import { debounce } from "../../../common/debounce";

const AdminCompanies: React.FC = () => {
  const [companies, setCompanies] = useState<CompanyReponse[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [itemsCount, setItemsCount] = useState(1);

  const [item, setItem] = useState<number | undefined>();

  const [showRemove, setShowRemove] = useState(false);

  const take = 10;

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const showSuccessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Operación exitosa");
    setNotificationText("Se eliminó a la empresa.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("¡Ups! Algo salió mal");
    setNotificationText(
      "Hubo un inconveniente al eliminar la empresa. Por favor, inténtelo más tarde."
    );
    setNotificationType(notificationTypes.ERROR);
  };

  const debouncedSetFilter = useCallback(
    debounce((filterValue) => {
      setFilter(filterValue);
      setPage(1);
    }, 500),
    []
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSetFilter(value);
  };

  const loadCompanies = async () => {
    try {
      const response = await getCompanies(page, take, filter);
      const paginatedData = response.data;
      setCompanies(paginatedData.data);
      setItemsCount(paginatedData.meta.itemCount);
    } catch (error) {
      console.error("Error loading companies", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCompanies();
  }, [page, filter]);

  const deleteCompany = async () => {
    if (item) {
      try {
        await removeCompany(item);
        loadCompanies();
        showSuccessMessage();
      } catch (error) {
        showErrorMessage();
      }
    }
  };

  const renderCompanies = () => {
    return companies.map((company) => (
      <tr key={company.id}>
        <td>{company.id}</td>
        <td>{company.name}</td>
        <td>{company.email}</td>
        <td>{company.taxStatus}</td>
        <td>{company.ruc}</td>
        <td>{utils.formatDate(company.createdAt)}</td>
        <td>
          <DataTableButtons
            itemId={company.id}
            handleView={(id: number) => navigate(`/admin/companies/${id}`)}
            handleDelete={(id: number) => {
              setItem(id);
              setShowRemove(true);
            }}
          />
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <EntityTable
          title={"Empresas"}
          headers={[
            "ID",
            "Nombre",
            "Email",
            "Condición Fiscal",
            "Documento",
            "Fecha de creación",
            "Acciones",
          ]}
          renderData={renderCompanies}
          handleFilterChange={handleFilterChange}
          page={page}
          setPage={setPage}
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
          deleteButton={deleteCompany}
          deleteHeader={"¿Está seguro que desea eliminar a la empresa?"}
          deleteMessage={
            "Una vez eliminada, la información será removida de manera permanente"
          }
          showRemove={showRemove}
          setShowRemove={setShowRemove}
        />
      )}
    </Container>
  );
};

export default AdminCompanies;
