import { removeToken } from "../service/token-service";
import { removeUserData } from "../service/user-service";
import { useAuthContext } from "./useAuthContext";

export const useLogout = () => {
  const { dispatch } = useAuthContext();

  const logout = async () => {
    await removeToken();
    await removeUserData();
    dispatch({ type: "LOGOUT" });
  };

  return { logout };
};
