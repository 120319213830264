import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./SearchBar.css";
import { Career } from "../../types/response/career";
import { getCareers } from "../../api/careers.api";
import { districts } from "../../common/districts";
import SearchBarAutocomplete from "./search-bar-autocomplete/SearchBarAutocomplete";
import searchBarIcon from "../../assets/images/icons/search-icon.png";
import { advancedFilterTypes } from "../../common/advanced-filter-types";
import SearchBarInput from "./search-bar-input/SearchBarInput";
import { useFilterContext } from "../../context/FilterContext/FilterContext";
import { useJobAdvertisementsContext } from "../../context/JobAdvertisementsContext/JobAdvertisementsContext";
import { studyTypes } from "../../common/study-types";

interface SearchBarProps {
  type: "jobOffers" | "candidates" | "applications";
}

const SearchBar: React.FC<SearchBarProps> = ({ type }) => {
  const [careerId, setCareerId] = useState(1);
  const [district, setDistrict] = useState("");
  const [grade, setGrade] = useState("");

  const [careersOptions, setCareersOptions] = useState<Career[]>([]);

  const {
    search,
    changeSearchQuery,
    setCareerFilter,
    setDistrictFilter,
    filterType,
    setFilterType,
    setDegreeFilter,
  } = useFilterContext();

  useEffect(() => {
    const loadCareers = async () => {
      try {
        const response = await getCareers();
        const careers = response.data;
        const formattedCareers = careers.map((career: any) => ({
          id: career.id,
          label: career.name,
        }));
        setCareersOptions(formattedCareers);
      } catch (error) {
        console.error("Error loading careers", error);
      }
    };
    setFilterType(type);
    loadCareers();
  }, []);

  const districtOptions = districts.filter((x: any) => x.provinceId === 128);

  const { loadJobAdvertisements } = useJobAdvertisementsContext();

  const handleSearch = async () => {
    await loadJobAdvertisements(4, true);
  };

  return (
    <div>
      <Container fluid className="p-0">
        <Row className="align-items-center bg-second">
          <Col xs={0} md={2} />
          <Col xs={12} md={4}>
            <SearchBarAutocomplete
              id="career"
              label="Carrera"
              options={careersOptions}
              initialValue={""}
              placeholder={"Ámbito profesional"}
              onChange={(selected: any[]) => {
                if (selected.length > 0) {
                  setCareerId(selected[0].id);
                  if (selected[0].id === 1) {
                    setCareerFilter(0);
                  } else {
                    setCareerFilter(selected[0].id);
                  }
                }
              }}
            />
          </Col>
          <Col xs={12} md={4}>
            {filterType === advancedFilterTypes.candidates ? (
              <SearchBarAutocomplete
                id="district"
                label="Ubicación"
                options={districtOptions}
                initialValue={district}
                placeholder={"Distrito"}
                onChange={(selected: any[]) => {
                  if (selected.length > 0) {
                    setDistrict(selected[0].label);
                    setDistrictFilter(selected[0].label);
                  }
                }}
              />
            ) : filterType === advancedFilterTypes.jobOffers ? (
              <SearchBarInput
                label="Palabra clave"
                name="keyword"
                placeholder="Puesto de trabajo / ubicación"
                maxLength={100}
                value={search}
                onChange={(e) => {
                  changeSearchQuery(e.target.value);
                }}
              />
            ) : (
              <SearchBarAutocomplete
                id="grade"
                label="Grado de instrucción"
                options={studyTypes}
                initialValue={grade}
                placeholder={"Grado"}
                onChange={(selected: any[]) => {
                  if (selected.length > 0) {
                    setGrade(selected[0].label);
                    setDegreeFilter(selected[0].label);
                  }
                }}
              />
            )}
          </Col>
          <Col xs={12} md={2} className="search-bar-icon-container">
            <Button variant="white" onClick={handleSearch}>
              <Image
                src={searchBarIcon}
                alt="search-icon"
                width={40}
                height={40}
              />
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SearchBar;
