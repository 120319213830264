import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";
import { CreateStudyRequest } from "../types/request/create-study-request";

const { STUDY_URL } = apiUrls;

export function createApplicantStudy(createStudyRequest: CreateStudyRequest) {
  const url = `${STUDY_URL}`;
  return APIMiddleware.post(url, {
    data: {
      monthFrom: createStudyRequest.monthFrom,
      yearFrom: createStudyRequest.yearFrom,
      monthTo: createStudyRequest.monthTo,
      yearTo: createStudyRequest.yearTo,
      isCurrent: createStudyRequest.isCurrent,
      institution: createStudyRequest.institution,
      field: createStudyRequest.field,
      type: createStudyRequest.type,
    },
  });
}

export function getApplicantStudy(id: number) {
  const url = `${STUDY_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function deleteApplicantStudy(id: number) {
  const url = `${STUDY_URL}/${id}`;
  return APIMiddleware.delete(url);
}

export function updateApplicantStudy(
  id: number,
  createStudyRequest: CreateStudyRequest
) {
  const url = `${STUDY_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      monthFrom: createStudyRequest.monthFrom,
      yearFrom: createStudyRequest.yearFrom,
      monthTo: createStudyRequest.monthTo,
      yearTo: createStudyRequest.yearTo,
      isCurrent: createStudyRequest.isCurrent,
      institution: createStudyRequest.institution,
      field: createStudyRequest.field,
      type: createStudyRequest.type,
    },
  });
}
