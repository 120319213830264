import React, { useEffect } from "react";
import JobAdvertisementItem from "../job-advertisement-item/JobAdvertisementItem";
import { PaginationControl } from "react-bootstrap-pagination-control";
import Loading from "../loading/Loading";
import { useJobAdvertisementsContext } from "../../context/JobAdvertisementsContext/JobAdvertisementsContext";

export interface AdvertisementSummarized {
  id: number;
  companyLogo: string;
  companyName: string;
  title: string;
  description: string;
  seniority: string;
  district: string;
  isRemote: boolean;
  isHybrid: boolean;
  isAnonymous: boolean;
  daysAgo: number;
}

const JobAdvertisements: React.FC = () => {
  const take = 12;

  const {
    loadingJobAdvertisements,
    jobAdvertisements,
    jobAdvertisementsItemsCount,
    jobAdvertisementsPage,
    changeJobAdvertisementsPage,
    loadJobAdvertisements,
  } = useJobAdvertisementsContext();

  useEffect(() => {
    const callJobAdvertisements = async () => {
      await loadJobAdvertisements(take);
    };

    callJobAdvertisements();
  }, [jobAdvertisementsPage]);

  return (
    <div className="mt-3 mb-3 ps-2 pe-2">
      {loadingJobAdvertisements ? (
        <Loading />
      ) : jobAdvertisements.length === 0 ? (
        <div className="content-card mt-3 p-3 text-center">
          No se encontraron anuncios que cumplan con los criterios de búsqueda
        </div>
      ) : (
        <>
          {jobAdvertisements.map((item) => (
            <JobAdvertisementItem key={item.id} item={item} />
          ))}
          <div className="mt-4">
            <PaginationControl
              page={jobAdvertisementsPage}
              total={jobAdvertisementsItemsCount}
              limit={take}
              changePage={(page) => {
                changeJobAdvertisementsPage(page);
              }}
              ellipsis={1}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default JobAdvertisements;
