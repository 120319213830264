import { Col, Image, Row } from "react-bootstrap";
import addIcon from "../../../assets/images/profile/profile-image-add-icon.png";
import React from "react";
import { Experience } from "../../../types/response/applicant-profile-response";
import ExperienceItem from "../experience-item/ExperienceItem";
import { useNavigate } from "react-router-dom";
import Loading from "../../loading/Loading";

interface ApplicantExperienceProps {
  experiences: Experience[];
  reloadExperiences: () => Promise<void>;
  loading: boolean;
  nonEditable?: boolean;
}

const ApplicantExperience: React.FC<ApplicantExperienceProps> = ({
  experiences,
  reloadExperiences,
  loading,
  nonEditable,
}) => {
  const navigate = useNavigate();

  const handleAddExperience = () => {
    navigate("/save-experience");
  };

  return (
    <div className="mb-4">
      <Row>
        <Col xs={10} md={10} lg={10} className="text-start p-0 fw-bold">
          <h5 className="fw-bold">Experiencia laboral</h5>
        </Col>
        <Col xs={2} md={2} lg={2} className="text-end">
          {!nonEditable && (
            <Image
              alt="add-icon"
              src={addIcon}
              width={24}
              height={24}
              className="cursor-pointer"
              onClick={handleAddExperience}
            />
          )}
        </Col>
      </Row>
      <hr className="hr-profile-details" />
      {loading ? (
        <Loading />
      ) : (
        experiences.map((experience) => (
          <ExperienceItem
            key={experience.id}
            experience={experience}
            reloadExperiences={reloadExperiences}
            nonEditable={nonEditable}
          />
        ))
      )}
    </div>
  );
};

export default ApplicantExperience;
