import React from "react";
import "./DotsDecorator.css";

type DotsDecoratorProps = {
  height: number;
  width: number;
  center?: boolean;
  color?: "--primary" | "--white";
};

const DotsDecorator: React.FC<DotsDecoratorProps> = ({
  height,
  width,
  center,
  color = "--primary",
}) => {
  const numberOfDots = 6;

  const dots = [...Array(numberOfDots)].map((_, index) => (
    <span
      key={index}
      className="dot"
      style={{
        height: `${height}px`,
        width: `${width}px`,
        backgroundColor: `var(${color})`,
      }}
    ></span>
  ));

  const containerStyle = center ? { justifyContent: "center" } : {};

  return (
    <div className="dots-container" style={containerStyle}>
      {dots}
    </div>
  );
};

export default DotsDecorator;
