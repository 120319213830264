import React, { useState } from "react";
import { Col } from "react-bootstrap";
import FormInput from "../../../components/form-input/FormInput";
import GenericForm from "../../../components/generic-form/GenericForm";
import FormSelect from "../../../components/form-select/FormSelect";
import { UpdateCompanyRequest } from "../../../types/request/update-company-request";
import { useUpdateCompany } from "../../../hooks/useUpdateCompany";
import { documentTypes } from "../../../common/document-types";

interface AdminCompaniesFormProps {
  item: UpdateCompanyRequest;
}

const AdminCompaniesForm: React.FC<AdminCompaniesFormProps> = ({ item }) => {
  const [name, setName] = useState(item.name);
  const [taxStatus, setTaxStatus] = useState(item.taxStatus);
  const [ruc, setRuc] = useState(item.ruc);
  const [advFree, setAdvFree] = useState(item.advFree);
  const [advCsh, setAdvCsh] = useState(item.advCsh);
  const [advBlack, setAdvBlack] = useState(item.advBlack);
  const [viewProfessional, setViewProfessional] = useState(
    item.viewProfessional
  );
  const [viewExcelence, setViewExcelence] = useState(item.viewExcelence);

  const { updateCompanyUser, loading, message, setMessage, type } =
    useUpdateCompany();

  const handleSave = async (e: any) => {
    e.preventDefault();
    await updateCompanyUser(item.id, {
      id: item.id,
      name,
      taxStatus,
      ruc,
      advFree,
      advCsh,
      advBlack,
      viewProfessional,
      viewExcelence,
    });
  };

  return (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={"Editar usuario"}
      loading={loading}
      buttonText={"Actualizar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Nombre de la empresa"}
          type={"text"}
          name={"name"}
          maxLength={100}
          required={true}
          value={name}
          placeholder={""}
          onChange={(e) => setName(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormSelect
          label={"Condición Fiscal"}
          required
          name={"taxStatus"}
          value={taxStatus}
          options={documentTypes}
          onChange={(e) => setTaxStatus(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Documento"}
          type={"text"}
          name={"document"}
          maxLength={11}
          minLength={5}
          required={true}
          value={ruc}
          placeholder={""}
          onChange={(e) => setRuc(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Anuncios gratuitos"}
          type={"text"}
          name={"advFree"}
          maxLength={100}
          required={true}
          value={advFree}
          placeholder={""}
          onChange={(e) => setAdvFree(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Anuncios Chamba Sí Hay"}
          type={"text"}
          name={"advCsh"}
          maxLength={100}
          required={true}
          value={advCsh}
          placeholder={""}
          onChange={(e) => setAdvCsh(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Anuncios Black"}
          type={"text"}
          name={"advBlack"}
          maxLength={100}
          required={true}
          value={advBlack}
          placeholder={""}
          onChange={(e) => setAdvBlack(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Vistas profesionales"}
          type={"text"}
          name={"viewProfessional"}
          maxLength={100}
          required={true}
          value={viewProfessional}
          placeholder={""}
          onChange={(e) => setViewProfessional(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Vistas excelencia"}
          type={"text"}
          name={"viewExcelence"}
          maxLength={100}
          required={true}
          value={viewExcelence}
          placeholder={""}
          onChange={(e) => setViewExcelence(parseInt(e.target.value))}
        />
      </Col>
    </GenericForm>
  );
};

export default AdminCompaniesForm;
