import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

export interface SelectOption {
  id: number | string;
  label: string;
  name?: string;
}

export interface FormSelectProps {
  label?: string;
  name: string;
  placeholder?: string;
  required: boolean;
  value: number | string;
  disabled?: boolean;
  options: SelectOption[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const FormSelect: React.FC<FormSelectProps> = ({
  label,
  name,
  required,
  value,
  disabled,
  options,
  onChange,
}) => {
  return (
    <Form.Group className="form-group">
      <FloatingLabel controlId={name} label={label}>
        <Form.Select
          className="form-input-select"
          value={value}
          disabled={disabled}
          onChange={onChange}
          required={required}
        >
          <option value=""></option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.label || option.name}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </Form.Group>
  );
};

export default FormSelect;
