export const salaryOptions = [
  { id: "1025-1500", label: "1025 a 1500" },
  { id: "1500-2500", label: "1500 a 2500" },
  { id: "2500-3500", label: "2500 a 3500" },
  { id: "3500-5000", label: "3500 a 5000" },
  { id: "5000-7000", label: "5000 a 7000" },
  { id: "7000-10000", label: "7000 a 10000" },
  { id: "10000-15000", label: "10000 a 15000" },
  { id: "15000-1500000", label: "15000 a más" },
];
