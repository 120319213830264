import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Loading from "../../../components/loading/Loading";
import EntityTable from "../../../components/admin/entity-table/EntityTable";
import { useNavigate } from "react-router-dom";
import DataTableButtons from "../../../components/admin/data-table-buttons/DataTableButtons";
import { getUsers, removeUser } from "../../../api/user.api";
import { UserReponse } from "../../../types/response/user-response";
import { utils } from "../../../common/utils";
import { useNotificationContext } from "../../../context/NotificationContext";
import { notificationTypes } from "../../../common/notification-types";
import { debounce } from "../../../common/debounce";

const AdminUsers: React.FC = () => {
  const [users, setUsers] = useState<UserReponse[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [itemsCount, setItemsCount] = useState(1);

  const [item, setItem] = useState<number | undefined>();

  const [showRemove, setShowRemove] = useState(false);

  const take = 10;

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const showSuccessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Operación exitosa");
    setNotificationText("Se eliminó al usuario.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("¡Ups! Algo salió mal");
    setNotificationText(
      "Hubo un inconveniente al eliminar el usuario. Por favor, inténtelo más tarde."
    );
    setNotificationType(notificationTypes.ERROR);
  };

  const debouncedSetFilter = useCallback(
    debounce((filterValue) => {
      setFilter(filterValue);
      setPage(1);
    }, 500),
    []
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSetFilter(value);
  };

  const loadUsers = async () => {
    try {
      const response = await getUsers(page, take, filter);
      const paginatedData = response.data;
      setUsers(paginatedData.data);
      setItemsCount(paginatedData.meta.itemCount);
    } catch (error) {
      console.error("Error loading users", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, [page, filter]);

  const deleteUser = async () => {
    if (item) {
      try {
        await removeUser(item);
        loadUsers();
        showSuccessMessage();
      } catch (error) {
        showErrorMessage();
      }
    }
  };

  const renderUsers = () => {
    return users.map((user) => (
      <tr key={user.id}>
        <td>{user.id}</td>
        <td>{user.fullName}</td>
        <td>{user.email}</td>
        <td>{user.career}</td>
        <td>{user.seniority === "student" ? "Estudiante" : "Profesional"}</td>
        <td>{user.isExcelence ? "Excelencia" : "Regular"}</td>
        <td>{utils.formatDate(user.createdAt)}</td>
        <td>
          <DataTableButtons
            itemId={user.id}
            handleView={(id: number) => navigate(`/admin/users/${id}`)}
            handleDelete={(id: number) => {
              setItem(id);
              setShowRemove(true);
            }}
          />
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <EntityTable
          title={"Postulantes"}
          headers={[
            "ID",
            "Nombre",
            "Email",
            "Carrera",
            "Experiencia",
            "Tipo",
            "Fecha de creación",
            "Acciones",
          ]}
          renderData={renderUsers}
          handleFilterChange={handleFilterChange}
          page={page}
          setPage={setPage}
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
          deleteButton={deleteUser}
          deleteHeader={"¿Está seguro que desea eliminar al usuario?"}
          deleteMessage={
            "Una vez eliminado, la información será removida de manera permanente"
          }
          showRemove={showRemove}
          setShowRemove={setShowRemove}
        />
      )}
    </Container>
  );
};

export default AdminUsers;
