import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";
import { ApplyJobRequest } from "../types/request/apply-job-request";

const { APPLICATION_URL } = apiUrls;

export function getJobOffer(id: number) {
  const url = `${APPLICATION_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function applyToAdvertisement(id: number, req: ApplyJobRequest) {
  const url = `${APPLICATION_URL}/${id}`;
  return APIMiddleware.post(url, { data: req });
}

export function getJobOfferApplicants(
  id: number,
  page: number,
  take: number,
  filters?: any
) {
  const url = `${APPLICATION_URL}/applicants/${id}?page=${page}&take=${take}`;
  return APIMiddleware.post(url, { data: filters });
}

export function getApplicantInformation(id: number) {
  const url = `${APPLICATION_URL}/applicant-information/${id}`;
  return APIMiddleware.get(url);
}

export function getApplicantExperiences(id: number) {
  const url = `${APPLICATION_URL}/applicant-experiences/${id}`;
  return APIMiddleware.get(url);
}

export function getApplicantStudies(id: number) {
  const url = `${APPLICATION_URL}/applicant-studies/${id}`;
  return APIMiddleware.get(url);
}

export function getApplicantLanguages(id: number) {
  const url = `${APPLICATION_URL}/applicant-languages/${id}`;
  return APIMiddleware.get(url);
}

export function getApplicantAnswers(id: number) {
  const url = `${APPLICATION_URL}/applicant-answers/${id}`;
  return APIMiddleware.get(url);
}

export function toggleSaveApplicant(id: number) {
  const url = `${APPLICATION_URL}/save-applicant/${id}`;
  return APIMiddleware.post(url);
}
