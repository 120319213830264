import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import AdminCompanyPurchasesForm from "./AdminCompanyPurchasesForm";
import { CompanyPurchases } from "../../../types/response/company-purchases";
import { getCompanyPurchase } from "../../../api/company-purchases.api";

const AdminCompanyPurchasesSave: React.FC = () => {
  const [companyPurchase, setCompanyPurchase] = useState<CompanyPurchases>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadCompanyPurchase = async (id: string) => {
      try {
        const companyPurchaseData = (await getCompanyPurchase(parseInt(id)))
          .data;
        setCompanyPurchase(companyPurchaseData);
      } catch (error) {
        console.error("Error loading company purchase:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadCompanyPurchase(id);
    }
  }, [id]);

  return loading ? (
    <Loading />
  ) : (
    companyPurchase && <AdminCompanyPurchasesForm item={companyPurchase} />
  );
};

export default AdminCompanyPurchasesSave;
