import { useEffect } from "react";
import { experienceTime } from "../../common/experienceTime";
import { salaryOptions } from "../../common/salaryOptions";
import { useFilterContext } from "../../context/FilterContext/FilterContext";
import FilterCheckbox from "../filter-checkbox/FilterCheckbox";
import { advancedFilterTypes } from "../../common/advanced-filter-types";
import ToggleSavedApplicants from "../toggle-saved-applicants/ToggleSavedApplicants";

const AdvancedFiltersApplications = () => {
  const {
    setSalaryFilter,
    setTimeExperienceFilter,
    setFilterType,
    savedFilter,
    setSavedFilter,
  } = useFilterContext();

  const handleTimeExperience = (filter: any[]) => {
    const nextTimeExperienceFilter: any[] = [];
    filter.forEach((f: any) => {
      if (f.enabled) {
        const timeExperience = experienceTime.find(
          (v) => v.label === f.optionName
        );
        if (timeExperience) {
          const timeExperienceParsed = timeExperience.id.split("-");
          nextTimeExperienceFilter.push({
            min: timeExperienceParsed[0],
            max: timeExperienceParsed[1],
          });
        }
      }
    });
    setTimeExperienceFilter(nextTimeExperienceFilter);
  };

  const handleSalary = (filter: any[]) => {
    const nextSalaryFilter: any[] = [];
    filter.forEach((f: any) => {
      if (f.enabled) {
        const salary = salaryOptions.find((v) => v.label === f.optionName);
        if (salary) {
          const salaryParsed = salary.id.split("-");
          nextSalaryFilter.push({ min: salaryParsed[0], max: salaryParsed[1] });
        }
      }
    });
    setSalaryFilter(nextSalaryFilter);
  };

  useEffect(() => {
    setFilterType(advancedFilterTypes.applications);
  }, []);

  return (
    <>
      <ToggleSavedApplicants
        savedFilter={savedFilter}
        setSavedFilter={setSavedFilter}
      />
      <div className="bg-main generic-card mt-3 mb-3 ps-2 pe-2 pt-1 text-white">
        <div className="p-2">
          <span className="text-center font-bold">Mejora tu búsqueda</span>
        </div>
        <FilterCheckbox
          label={"Salario (soles)"}
          options={salaryOptions.map((salary) => salary.label)}
          handleFilter={handleSalary}
        />
        <hr className="hr-profile-details" />
        <FilterCheckbox
          label={"Tiempo"}
          options={experienceTime.map((time) => time.label)}
          handleFilter={handleTimeExperience}
        />
      </div>
    </>
  );
};

export default AdvancedFiltersApplications;
