import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";

interface DataTableButtonsProps {
  itemId: number;
  handleGo?: (id: number) => void;
  handleView: (id: number) => void;
  handleDelete?: (id: number) => void;
}

const DataTableButtons: React.FC<DataTableButtonsProps> = ({
  itemId,
  handleGo,
  handleView,
  handleDelete,
}) => {
  return (
    <Row className="justify-content-center text-center">
      {handleGo && (
        <Col>
          <FontAwesomeIcon
            onClick={() => handleGo(itemId)}
            size="lg"
            icon={faEye}
            className="cursor-pointer"
          />
        </Col>
      )}
      <Col>
        <FontAwesomeIcon
          onClick={() => handleView(itemId)}
          size="lg"
          icon={faEdit}
          className="cursor-pointer"
        />
      </Col>
      {handleDelete && (
        <Col>
          <FontAwesomeIcon
            onClick={() => handleDelete(itemId)}
            size="lg"
            icon={faTrash}
            className="cursor-pointer"
          />
        </Col>
      )}
    </Row>
  );
};

export default DataTableButtons;
