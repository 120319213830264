import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import AdminIndustriesForm from "./AdminIndustriesForm";
import { Industry } from "../../../types/response/industry";
import { getIndustry } from "../../../api/industries.api";

const AdminIndustriesSave: React.FC = () => {
  const [industry, setIndustry] = useState<Industry>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadIndustry = async (id: string) => {
      try {
        const industryData = (await getIndustry(parseInt(id))).data;
        setIndustry(industryData);
      } catch (error) {
        console.error("Error loading industry:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadIndustry(id);
    }
  }, [id]);

  return loading ? (
    <Loading />
  ) : (
    industry && <AdminIndustriesForm item={industry} />
  );
};

export default AdminIndustriesSave;
