import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ApplicantProfileInformation from "../ApplicantProfileInformation";
import ApplicationAnswers from "../ApplicationAnswers";
import ApplicantProfileCurriculum from "../ApplicantProfileCurriculum";

const ProfileViewApplication: React.FC = () => {
  const { id } = useParams();

  const applicationId = parseInt(id ?? "");

  return (
    <div className="p-4 applicant-section">
      <Row>
        <Col md={4} lg={4}>
          <ApplicantProfileInformation applicationId={applicationId} />
          <ApplicationAnswers applicationId={applicationId} />
        </Col>
        <Col md={8} lg={8}>
          <ApplicantProfileCurriculum applicationId={applicationId} />
        </Col>
      </Row>
    </div>
  );
};

export default ProfileViewApplication;
