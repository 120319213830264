import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import companyAnonymous from "../../assets/images/profile/company-anonymous.png";
import BadgeSeniority from "../company/badge-seniority/BadgeSeniority";
import "./JobAdvertisementItem.css";
import BadgeDistrict from "../badge-district/BadgeDistrict";
import { AdvertisementSummarized } from "../job-advertisements/JobAdvertisements";
import { useNavigate } from "react-router-dom";

interface JobAdvertisementItemProps {
  item: AdvertisementSummarized;
}

const JobAdvertisementItem: React.FC<JobAdvertisementItemProps> = ({
  item,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="content-card p-4 mb-4 cursor-pointer"
      onClick={() => navigate(`/job-details/${item.id}`)}
    >
      <Row>
        <Col
          xs={12}
          sm={2}
          md={2}
          lg={2}
          className="advertisement-company-logo p-0"
        >
          {item.isAnonymous || item.companyLogo === "" ? (
            <Image src={companyAnonymous} width={120} height={120} rounded />
          ) : (
            <Image src={item.companyLogo} width={120} height={120} rounded />
          )}
        </Col>
        <Col
          xs={12}
          sm={9}
          md={9}
          lg={9}
          className="p-0 job-advertisement-content"
        >
          <Row className="mt-2">
            <h5 className="font-bold mb-1">{item.title}</h5>
          </Row>
          <Row>
            <p className="mb-1 color-main">{item.companyName}</p>
          </Row>
          <Row>
            <p className="mb-1 job-advertisement-description">
              {item.description}
            </p>
          </Row>
          <Row className="mt-2 ps-2">
            <BadgeSeniority
              seniority={
                item.seniority as "student" | "excelence" | "professional"
              }
            />
            <BadgeDistrict district={item.district} />
          </Row>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} className="p-0 position-relative">
          <div className="days-ago-container">
            {item.daysAgo > 0
              ? `hace ${item.daysAgo} día${item.daysAgo > 1 ? "s" : ""}`
              : "hoy"}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default JobAdvertisementItem;
