import React, { useEffect, useState } from "react";
import {
  faBullhorn,
  faCartShopping,
  faEye,
  faIndustry,
  faMailBulk,
  faMedal,
  faMoneyBill,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Image } from "react-bootstrap";
import SidebarItem from "./sidebar-item/SidebarItem";
import logo from "../../assets/images/logo/logo.png";
import searchIcon from "../../assets/images/icons/search-icon.png";
import { useAuthContext } from "../../hooks/useAuthContext";
import { UserData } from "../../context/AuthContext";
import "./Sidebar.css";
import { useLocation } from "react-router-dom";

interface SidebarProps {
  isCollapsed: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed }) => {
  const { user } = useAuthContext();
  const [userData, setUserData] = useState<UserData | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (user) {
      setUserData({ name: user.name, role: user.role });
    } else {
      setUserData(null);
    }
  }, [user]);

  const sidebarItems = [
    { name: "Usuarios", icon: faUser, url: "/admin/users" },
    { name: "Empresas", icon: faUsers, url: "/admin/companies" },
    { name: "Anuncios", icon: faBullhorn, url: "/admin/advertisements" },
    { name: "Excelencia", icon: faMedal, url: "/admin/upgrade-requests" },
    {
      name: "Planes (usuario)",
      icon: faCartShopping,
      url: "/admin/user-plans",
    },
    {
      name: "Planes (empresa)",
      icon: faMoneyBill,
      url: "/admin/company-plans",
    },
    {
      name: "Planes (perfiles)",
      icon: faEye,
      url: "/admin/company-packages",
    },
    {
      name: "Compras (usuario)",
      icon: faCartShopping,
      url: "/admin/user-purchases",
    },
    {
      name: "Compras (empresa)",
      icon: faMoneyBill,
      url: "/admin/company-purchases",
    },
    {
      name: "Compras (perfiles)",
      icon: faEye,
      url: "/admin/profile-purchases",
    },
    {
      name: "Industrias",
      icon: faIndustry,
      url: "/admin/industries",
    },
    {
      name: "Correos",
      icon: faMailBulk,
      url: "/admin/news",
    },
  ];

  const sidebarClass = isCollapsed ? "sidebar-collapsed" : "sidebar-expanded";

  return (
    <div
      className={`${sidebarClass} col-auto col-md-3 col-xl-2 px-0 p-0 bg-main`}
    >
      <div className="d-flex flex-column flex-shrink-0 p-3 text-white vh-100">
        <a
          href="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          <img alt="Chamba Sí Hay Logo" src={logo} width={133} height={47} />
        </a>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          {sidebarItems.map((item) => (
            <SidebarItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              url={item.url}
              active={location.pathname.includes(item.url)}
            />
          ))}
        </ul>
        <hr />
        <div>
          <div className="d-flex align-items-center text-white">
            <Image
              src={searchIcon}
              width={47}
              height={47}
              alt="Avatar image"
              className="me-2"
            />
            <strong>{userData && userData.name}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
