import { useState } from "react";
import { updateProfilePicture } from "../api/applicant.api";
import { updateCompanyPicture } from "../api/company.api";
import { useNotificationContext } from "../context/NotificationContext";
import { notificationTypes } from "../common/notification-types";

export const useUpdateProfilePicture = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const showSuccessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Actualización de foto exitosa");
    setNotificationText("La foto de perfil fue actualizada.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("¡Ups! Algo salió mal");
    setNotificationText("Hubo un error al actualizar su foto de perfil.");
    setNotificationType(notificationTypes.ERROR);
  };

  const updatePicture = async (formData: FormData, profileType?: string) => {
    setIsLoading(true);

    try {
      profileType === "company"
        ? await updateCompanyPicture(formData)
        : await updateProfilePicture(formData);
      setIsLoading(false);
      showSuccessMessage();
    } catch (ex) {
      setIsLoading(false);
      showErrorMessage();
    }
  };

  return {
    updatePicture,
    isLoading,
  };
};
