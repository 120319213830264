import { Col, Image, Row } from "react-bootstrap";
import ProfileImage from "../profile-image/ProfileImage";
import ProfileDetails from "../profile-details/ProfileDetails";
import { getProfile } from "../../api/applicant.api";
import React, { useEffect, useState } from "react";
import { utils } from "../../common/utils";

import addressIcon from "../../assets/images/profile/address-icon.png";
import phoneIcon from "../../assets/images/profile/phone-icon.png";
import careerIcon from "../../assets/images/profile/career-icon.png";
import industryIcon from "../../assets/images/profile/industry-icon.png";
import billIcon from "../../assets/images/profile/bill-icon.png";
import cvIcon from "../../assets/images/profile/cv-icon.png";
import webIcon from "../../assets/images/profile/website-icon.png";
import emailIcon from "../../assets/images/profile/email-icon.png";
import employeesIcon from "../../assets/images/profile/employees-icon.png";
import Loading from "../loading/Loading";
import { getCompanyInformation } from "../../api/company.api";
import { roles } from "../../common/roles";

interface ProfileInformationProps {
  profileType: "applicant" | "company";
}

const ProfileInformation: React.FC<ProfileInformationProps> = ({
  profileType,
}) => {
  const [companyInformation, setCompanyInformation] = useState<any>();
  const [companyItems, setCompanyItems] = useState<any>();

  const [applicantInformation, setApplicantInformation] = useState<any>();
  const [applicantItems, setApplicantItems] = useState<any>();

  const [loading, setLoading] = useState(true);

  const loadProfileInformation = async () => {
    try {
      const applicantInformation = (await getProfile()).data;
      setApplicantInformation(applicantInformation);
      setApplicantItems([
        {
          icon: addressIcon,
          value: utils.getAddress(
            applicantInformation.address,
            applicantInformation.district
          ),
        },
        { icon: phoneIcon, value: applicantInformation.phoneNumber },
        { icon: careerIcon, value: applicantInformation.career },
        {
          icon: industryIcon,
          value: utils.getSeniority(
            applicantInformation.seniorityId,
            applicantInformation.isExcelence
          ),
        },
        {
          icon: billIcon,
          value: utils.getSalaryWithCurrency(
            applicantInformation.desiredSalary
          ),
        },
      ]);
    } catch (error) {
      console.error("Error loading profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadCompanyInformation = async () => {
    try {
      const companyInformation = (await getCompanyInformation()).data;
      setCompanyInformation(companyInformation);
      setCompanyItems([
        { icon: addressIcon, value: companyInformation.address || "-" },
        { icon: webIcon, value: companyInformation.web || "-" },
        { icon: emailIcon, value: companyInformation.email || "-" },
        { icon: phoneIcon, value: companyInformation.phoneNumber || "-" },
        { icon: employeesIcon, value: companyInformation.employees || "-" },
        { icon: industryIcon, value: companyInformation.industry || "-" },
      ]);
    } catch (error) {
      console.error("Error loading profile:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    profileType === roles.company
      ? loadCompanyInformation()
      : loadProfileInformation();
  }, []);

  return (
    <div className="bg-main generic-card p-4">
      <Row>
        <ProfileImage
          profileType={profileType}
          profileImage={
            profileType === roles.company && companyInformation
              ? companyInformation.profileImage
              : applicantInformation
              ? applicantInformation.profileImage
              : ""
          }
          loading={loading}
          isExcelence={applicantInformation && applicantInformation.isExcelence}
        />
      </Row>
      <Row className="pt-4 text-white">
        {loading ? (
          <Loading variant={"white"} />
        ) : (
          <Row className="p-0">
            <Col xs={10} sm={10} md={10} lg={10} className="p-0">
              <h4 className="p-0">
                {profileType === roles.company
                  ? companyInformation.businessName
                  : utils.getFullName(
                      applicantInformation.lastName,
                      applicantInformation.firstName
                    )}
              </h4>
            </Col>
            {profileType === "applicant" &&
              applicantInformation.cvURL !== null &&
              applicantInformation.cvURL !== "" && (
                <Col xs={2} sm={2} md={2} lg={2} className="p-0">
                  <Image
                    className="profile-icon-image cursor-pointer"
                    src={cvIcon}
                    width={40}
                    height={40}
                    onClick={() =>
                      window.open(applicantInformation.cvURL, "_blank")
                    }
                  />
                </Col>
              )}
          </Row>
        )}
      </Row>
      <Row className="pt-2">
        {loading ? (
          <Loading variant={"white"} />
        ) : (
          <ProfileDetails
            profileType={profileType}
            label={
              profileType === "applicant"
                ? "Información personal"
                : "Información de la empresa"
            }
            description={
              profileType === roles.company
                ? companyInformation.description
                : applicantInformation.description
            }
            items={
              profileType === roles.company ? companyItems : applicantItems
            }
            loading={loading}
          />
        )}
      </Row>
    </div>
  );
};

export default ProfileInformation;
