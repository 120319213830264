import { APIMiddleware } from "../middleware/api-middleware";
import { apiUrls } from "../common/api-urls";
import { UpdateCompanyPackageRequest } from "../types/request/update-company-package-request";

const { PACKAGES_URL } = apiUrls;

export function getCompanyPackages() {
  const url = `${PACKAGES_URL}`;
  return APIMiddleware.get(url);
}

export function getPackageList(page: number, take: number) {
  const url = `${PACKAGES_URL}/list?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function getCompanyPackage(id: number) {
  const url = `${PACKAGES_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function updateCompanyPackage(
  id: number,
  req: UpdateCompanyPackageRequest
) {
  const url = `${PACKAGES_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      name: req.name,
      price: req.price,
      forSale: req.forSale,
      salePrice: req.salePrice,
      profileTalent: req.profileTalent,
      profileExperience: req.profileExperience,
    },
  });
}
