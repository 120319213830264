import { Col, Form, Row } from "react-bootstrap";
import "./PrivacyPolicy.css";

interface PrivacyPolicyProps {
  userType: "applicant" | "company" | null;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({
  userType,
  checked,
  onChange,
}) => {
  return (
    <Row className="p-4 pb-0">
      <Col>
        <Form.Check
          type={"checkbox"}
          id={"terms-checkbox"}
          label={
            <>
              Acepto las condiciones de uso y la{" "}
              <a
                href="http://www.chambasihay.com.pe/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  cursor: "pointer",
                  color: userType === "applicant" ? "#FFF" : "#000",
                  textDecoration: "underline",
                }}
              >
                política de privacidad
              </a>
            </>
          }
          className={`${
            userType === "applicant" ? "text-white" : "text-black"
          }`}
          checked={checked}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};

export default PrivacyPolicy;
