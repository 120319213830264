import React from "react";
import { Form } from "react-bootstrap";

export interface FormCheckboxProps {
  type?: "switch" | "checkbox";
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  type = "switch",
  label,
  checked,
  onChange,
  disabled
}) => {
  return (
    <Form.Group className="form-group">
      <Form.Check
        type={type}
        id="custom-switch"
        checked={checked}
        label={label}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default FormCheckbox;
