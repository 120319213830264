import React, { useEffect, useState } from "react";
import { ApplicantAnswers } from "../../types/response/applicant-answers";
import { getApplicantAnswers } from "../../api/application.api";
import { Row } from "react-bootstrap";
import DotsDecorator from "../home/dots-decorator/DotsDecorator";
import Loading from "../loading/Loading";

interface ApplicationAnswersProps {
  applicationId: number;
}

const ApplicationAnswers: React.FC<ApplicationAnswersProps> = ({
  applicationId,
}) => {
  const [applicationAnswers, setApplicationAnswers] = useState<
    ApplicantAnswers[]
  >([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadApplicationsSummarized = async () => {
      try {
        const response = await getApplicantAnswers(applicationId);
        setApplicationAnswers(response.data);
      } catch (error) {
        console.error("Error loading application answers", error);
      } finally {
        setLoading(false);
      }
    };

    loadApplicationsSummarized();
  }, []);

  return loading ? (
    <Loading />
  ) : applicationAnswers.length === 0 ? (
    <></>
  ) : (
    <div className="content-card p-4 mt-4 mb-4">
      <Row className="justify-content-center mb-4">
        <h4 className="text-center">Respuestas</h4>
        <DotsDecorator width={10} height={10} center={true} />
      </Row>
      {applicationAnswers.map((answer, index) => (
        <div key={`${answer.answer}-${index}`} className="mb-3">
          <div className="font-bold">{answer.question}</div>
          <div>{answer.answer}</div>
        </div>
      ))}
    </div>
  );
};

export default ApplicationAnswers;
