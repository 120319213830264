import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./JobAdvertisementDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faBusinessTime,
  faClock,
  faGraduationCap,
  faIndustry,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { JobOfferDetail } from "../../types/response/job-offer-details";
import { getJobOffer } from "../../api/application.api";
import Loading from "../loading/Loading";
import FormInput from "../form-input/FormInput";
import FormButton from "../form-button/FormButton";
import { useApply } from "../../hooks/useApply";

const JobAdvertisementDetails: React.FC = () => {
  const [jobOffer, setJobOffer] = useState<JobOfferDetail>();
  const [loading, setLoading] = useState(false);

  const [expectedSalary, setExpectedSalary] = useState(0);
  const [advertisementAnswers, setAdvertisementAnswers] = useState(
    Array(5).fill({ answer: { answer: "", questionId: 0 } })
  );

  const handleAnswerValue = (
    value: string,
    questionId: number,
    index: number
  ) => {
    const nextQuestions = advertisementAnswers.map((q, i) => {
      if (i !== index) {
        return q;
      } else {
        return {
          ...q,
          answer: { questionId, value },
        };
      }
    });
    setAdvertisementAnswers(nextQuestions);
  };

  const { id } = useParams();

  useEffect(() => {
    const loadJobOffer = async (id: string) => {
      try {
        const industryData = (await getJobOffer(parseInt(id))).data;
        setJobOffer(industryData);
      } catch (error) {
        console.error("Error loading industry:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadJobOffer(id);
    }
  }, [id]);

  const handleApply = (e: any) => {
    e.preventDefault();
    if (id)
      applyJobOffer(parseInt(id), { expectedSalary, advertisementAnswers });
  };

  const { applyJobOffer, loading: isLoading } = useApply();

  return loading ? (
    <Loading />
  ) : (
    jobOffer && (
      <div className="p-4 bg-gray-soft">
        <Row>
          <Col xs={12} sm={9} md={9} lg={9} className="p-0">
            <Card className="p-4 job-card">
              <Card.Header className="job-card-header">
                <h5 className="color-main">{jobOffer.title}</h5>
                <p className="color-gray-strong">{jobOffer.companyName}</p>
              </Card.Header>
              <Card.Body>
                <div>
                  <FontAwesomeIcon icon={faClock} width={16} />
                  <span className="ms-2">
                    Publicado{" "}
                    {jobOffer.daysAgo > 0
                      ? `hace ${jobOffer.daysAgo} día${
                          jobOffer.daysAgo ?? 0 > 1 ? "s" : ""
                        }`
                      : "hoy"}
                  </span>
                </div>
                <div>
                  <FontAwesomeIcon icon={faLocationDot} width={16} />
                  <span className="ms-2">{jobOffer.district}</span>
                </div>
                <div className="mt-2 job-offer-description">
                  {jobOffer.description}
                </div>
              </Card.Body>
              <Card.Footer className="job-card-footer">
                <Row>
                  <Col xs={6} sm={6} md={3} lg={3} className="p-0">
                    <div className="mb-2">
                      <FontAwesomeIcon icon={faGraduationCap} width={16} />
                      <span className="ms-2">
                        {jobOffer.seniority === "student"
                          ? "Practicante"
                          : "Profesional"}
                      </span>
                    </div>
                    <div className="mb-2">
                      <FontAwesomeIcon icon={faIndustry} width={16} />
                      <span className="ms-2">{jobOffer.industry}</span>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={3} lg={3} className="p-0">
                    <div className="mb-2">
                      <FontAwesomeIcon icon={faBuilding} width={16} />
                      <span className="ms-2">{jobOffer.location}</span>
                    </div>
                    <div className="mb-2">
                      <FontAwesomeIcon icon={faBusinessTime} width={16} />
                      <span className="ms-2">{jobOffer.contract}</span>
                    </div>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <Card className="p-4 job-card">
              <Card.Body className="job-card-body">
                <Form onSubmit={handleApply}>
                  <div className="mb-2">
                    <h5 className="color-main">Postula</h5>
                  </div>
                  <div className="mb-2">
                    <div>Sueldo esperado:</div>
                    <FormInput
                      label={"Sueldo neto pretendido"}
                      type={"number"}
                      name={"expectedSalary"}
                      value={expectedSalary}
                      maxLength={100}
                      required={true}
                      onChange={(e) =>
                        setExpectedSalary(parseFloat(e.target.value))
                      }
                    />
                  </div>
                  {jobOffer.questions.length > 0 &&
                    jobOffer.questions.map((question, index) => {
                      return (
                        question.question.length > 0 && (
                          <div key={question.id} className="mb-2">
                            <div>{question.question}</div>
                            <FormInput
                              label={"Respuesta"}
                              type={"text"}
                              name={""}
                              maxLength={400}
                              required={true}
                              onChange={(e) =>
                                handleAnswerValue(
                                  e.target.value,
                                  question.id,
                                  index
                                )
                              }
                            />
                          </div>
                        )
                      );
                    })}
                  <div className="mt-4">
                    <FormButton
                      variant="second"
                      type={"submit"}
                      buttonText={"Postular"}
                      loading={isLoading}
                    />
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    )
  );
};

export default JobAdvertisementDetails;
