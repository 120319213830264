import React from "react";
import { Spinner } from "react-bootstrap";
import "./Loading.css";

interface LoadingProps {
  variant?: string;
}

const Loading: React.FC<LoadingProps> = ({ variant }) => {
  return (
    <div id="spinner-container">
      <Spinner animation="border" variant={variant ?? "main"} />
    </div>
  );
};

export default Loading;
