import Sidebar from "../components/sidebar/Sidebar";
import React, { ReactNode, useState } from "react";
import NotificationModal from "../components/notification-modal/NotificationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

interface AdminPageContainerProps {
  element: ReactNode;
}

const AdminPageContainer: React.FC<AdminPageContainerProps> = ({ element }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row flex-nowrap">
          <Sidebar isCollapsed={isSidebarCollapsed} />
          <div className="col py-3">
            <div>
              <FontAwesomeIcon
                icon={faBars}
                size={"lg"}
                className="cursor-pointer"
                onClick={toggleSidebar}
              />
            </div>
            <div>{element}</div>
          </div>
        </div>
      </div>
      <NotificationModal />
    </>
  );
};

export default AdminPageContainer;
