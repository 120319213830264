import React, { useEffect, useState } from "react";
import GenericForm from "../../generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormDatePicker from "../../form-date-picker/FormDatePicker";
import FormCheckbox from "../../form-checkbox/FormCheckbox";
import { useAddStudy } from "../../../hooks/useAddStudy";
import { useNavigate, useParams } from "react-router-dom";
import { getApplicantStudy } from "../../../api/study.api";
import FormInput from "../../form-input/FormInput";
import FormSelect from "../../form-select/FormSelect";
import { studyTypes } from "../../../common/study-types";
import Loading from "../../loading/Loading";

const SaveStudy: React.FC = () => {
  const [institution, setInstitution] = useState("");
  const [field, setField] = useState("");
  const [type, setType] = useState("");
  const [isCurrent, setIsCurrent] = useState(false);
  const [yearFrom, setYearFrom] = useState(0);
  const [monthFrom, setMonthFrom] = useState(0);
  const [yearTo, setYearTo] = useState(0);
  const [monthTo, setMonthTo] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  const { addStudy, updateStudy, loading } = useAddStudy();

  const navigate = useNavigate();

  useEffect(() => {
    const loadStudy = async (id: string) => {
      try {
        const experienceData = (await getApplicantStudy(parseInt(id))).data;
        setInstitution(experienceData.institution);
        setField(experienceData.field);
        setType(experienceData.type);
        setIsCurrent(experienceData.isCurrent);
        setYearFrom(experienceData.yearFrom);
        setMonthFrom(experienceData.monthFrom);
        setYearTo(experienceData.yearTo);
        setMonthTo(experienceData.monthTo);
      } catch (error) {
        console.error("Error loading studies:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      loadStudy(id);
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const handleSaveStudy = async (e: any) => {
    e.preventDefault();
    const studyData = {
      institution,
      field,
      type,
      isCurrent,
      yearFrom,
      monthFrom,
      yearTo,
      monthTo,
    };
    id ? await updateStudy(parseInt(id), studyData) : await addStudy(studyData);
    navigate("/applicant");
  };

  const handleChangeCurrentStudy = (e: any) => {
    const isChecked = e.target.checked;
    setIsCurrent(isChecked);
    if (isChecked) {
      setYearTo(0);
      setMonthTo(0);
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <GenericForm
        handleSubmit={handleSaveStudy}
        formTitle="Agregar estudio"
        loading={loading}
        buttonText={id ? "Actualizar" : "Agregar"}
      >
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label="Institución"
            type="text"
            name="institution"
            maxLength={100}
            required={true}
            value={institution}
            onChange={(e) => setInstitution(e.target.value)}
            placeholder={""}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormSelect
            label={"Grado de estudio"}
            name={"type"}
            required={true}
            value={type}
            options={studyTypes}
            onChange={(e) => setType(e.target.value)}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label="Área de estudio"
            type="text"
            name="field"
            maxLength={100}
            required={true}
            value={field}
            onChange={(e) => setField(e.target.value)}
            placeholder={""}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormDatePicker
            labelMonth="Mes de inicio"
            labelYear="Año de inicio"
            month={monthFrom}
            setMonth={(e) => setMonthFrom(parseInt(e.target.value))}
            year={yearFrom}
            setYear={(e) => setYearFrom(parseInt(e.target.value))}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormDatePicker
            labelMonth="Mes de fin"
            labelYear="Año de fin"
            month={monthTo}
            setMonth={(e) => setMonthTo(parseInt(e.target.value))}
            year={yearTo}
            setYear={(e) => setYearTo(parseInt(e.target.value))}
            disabled={isCurrent}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4"></Col>
        <Col xs={12} md={6} lg={6} className="mb-4 text-white">
          <FormCheckbox
            type="checkbox"
            label="Al presente"
            checked={isCurrent}
            onChange={(e) => handleChangeCurrentStudy(e)}
          />
        </Col>
      </GenericForm>
    </div>
  );
};

export default SaveStudy;
