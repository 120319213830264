import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";

export interface FormAutocompleteProps {
  id: string;
  label: string;
  initialValue?: string;
  disabled?: boolean;
  options: Option[];
  onChange: (selected: Option[]) => void;
  required?: boolean;
}

const FormAutocomplete: React.FC<FormAutocompleteProps> = ({
  id,
  label,
  initialValue,
  options,
  disabled,
  onChange,
  required = false,
}) => {
  return (
    <Form.Group className="form-group">
      <FloatingLabel
        className={"floating-label-typeahead"}
        controlId={label}
        label={label}
      >
        <Typeahead
          className="form-input-typeahead"
          id={id}
          onChange={onChange}
          options={options}
          defaultInputValue={initialValue ?? ""}
          disabled={disabled}
          placeholder={""}
          inputProps={{ required: required }}
        />
      </FloatingLabel>
    </Form.Group>
  );
};

export default FormAutocomplete;
