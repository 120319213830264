import { RouterProvider } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthContextProvider } from "./context/AuthContext";
import router from "./routes/Router";
import "./App.css";
import { NotificationProvider } from "./context/NotificationContext";
import { FilterProvider } from "./context/FilterContext/FilterContext";
import { JobAdvertisementsProvider } from "./context/JobAdvertisementsContext/JobAdvertisementsContext";
import { ApplicantsProvider } from "./context/ApplicantsContext/ApplicantsContext";

function App() {
  return (
    <AuthContextProvider>
      <GoogleOAuthProvider clientId="379291369289-4i08rbla43dop7068imfclobe7utj90g.apps.googleusercontent.com">
        <NotificationProvider>
          <FilterProvider>
            <JobAdvertisementsProvider>
              <ApplicantsProvider>
                <RouterProvider router={router} />
              </ApplicantsProvider>
            </JobAdvertisementsProvider>
          </FilterProvider>
        </NotificationProvider>
      </GoogleOAuthProvider>
    </AuthContextProvider>
  );
}

export default App;
