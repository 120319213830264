import React from "react";
import { Col, Row } from "react-bootstrap";
import FormSelect from "../form-select/FormSelect";
import { months } from "../../common/months";
import { years } from "../../common/years";

interface FormDatePickerProps {
  labelMonth: string;
  labelYear: string;
  month: number;
  setMonth: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  year: number;
  setYear: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
}

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  labelMonth,
  labelYear,
  month,
  setMonth,
  year,
  setYear,
  disabled = false,
}) => {
  return (
    <>
      <Row>
        <Col className="ps-0">
          <FormSelect
            label={labelMonth}
            name="month"
            required={false}
            value={month}
            options={months}
            onChange={setMonth}
            disabled={disabled}
          />
        </Col>
        <Col className="ps-0 pe-0">
          <FormSelect
            label={labelYear}
            name="year"
            required={false}
            value={year}
            options={years}
            onChange={setYear}
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  );
};

export default FormDatePicker;
