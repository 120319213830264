import React, { useState } from "react";
import GenericForm from "../../../components/generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormInput from "../../../components/form-input/FormInput";
import { Industry } from "../../../types/response/industry";
import { useUpdateIndustry } from "../../../hooks/useUpdateIndustry";

interface AdminIndustriesFormProps {
  item: Industry;
}

const AdminIndustriesForm: React.FC<AdminIndustriesFormProps> = ({ item }) => {
  const [name, setName] = useState(item.name);

  const handleSave = async (e: any) => {
    e.preventDefault();
    await modifyIndustry(item.id, {
      id: item.id,
      name,
    });
  };

  const { modifyIndustry, loading, message, setMessage, type } =
    useUpdateIndustry();

  return (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={"Editar industria"}
      loading={loading}
      buttonText={"Actualizar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={12} lg={12} className="mb-4">
        <FormInput
          label={"Nombre"}
          type={"text"}
          name={"name"}
          maxLength={100}
          required={true}
          value={name}
          placeholder={""}
          onChange={(e) => setName(e.target.value)}
        />
      </Col>
    </GenericForm>
  );
};

export default AdminIndustriesForm;
