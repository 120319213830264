import React, { useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Language } from "../../../types/response/applicant-profile-response";

import editIcon from "../../../assets/images/icons/edit-icon.png";
import deleteIcon from "../../../assets/images/icons/delete-icon.png";
import { useNavigate } from "react-router-dom";
import RemoveElement from "../../remove-element/RemoveElement";
import { deleteApplicantLanguages } from "../../../api/language.api";
import { useNotificationContext } from "../../../context/NotificationContext";
import { notificationTypes } from "../../../common/notification-types";

interface ApplicantLanguageProps {
  language: Language;
  reloadLanguages: () => Promise<void>;
  nonEditable?: boolean;
}

const LanguageItem: React.FC<ApplicantLanguageProps> = ({
  language,
  reloadLanguages,
  nonEditable,
}) => {
  const [showRemove, setShowRemove] = useState(false);

  const navigate = useNavigate();

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const handleEditLanguage = () => {
    navigate("/save-language/" + language.id);
  };

  const handleDeleteLanguage = () => {
    setShowRemove(true);
  };

  const showSucessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Operación exitosa");
    setNotificationText("El idioma fue eliminado.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const deleteLanguage = async () => {
    await deleteApplicantLanguages(language.id);
    setShowRemove(false);
    reloadLanguages();
    showSucessMessage();
  };

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col
            xs={10}
            md={10}
            lg={10}
            className="text-start p-0 fw-bold text-main mt-2"
          >
            <h5>{language.language}</h5>
          </Col>
          {!nonEditable && (
            <Col xs={2} md={2} lg={2} className="text-end">
              <Row>
                <Col className="p-0">
                  <Image
                    src={editIcon}
                    alt="edit-icon"
                    width={18}
                    height={18}
                    className="cursor-pointer"
                    onClick={handleEditLanguage}
                  />
                </Col>
                <Col className="p-0">
                  <Image
                    src={deleteIcon}
                    alt="delete-icon"
                    width={18}
                    height={18}
                    className="cursor-pointer"
                    onClick={handleDeleteLanguage}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <p className="mb-0">Oral: {language.speakingLevel}</p>
        <p>Escrito: {language.writingLevel}</p>
      </div>
      <RemoveElement
        showRemoveModal={showRemove}
        handleCloseRemoveModal={() => setShowRemove(false)}
        modalHeader={"¿Está seguro?"}
        modalBody={
          "Una vez eliminado el idioma, la información desaparecerá para los reclutadores"
        }
        removeElement={deleteLanguage}
      />
    </React.Fragment>
  );
};

export default LanguageItem;
