import React, { useState } from "react";
import GenericForm from "../../generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormInput from "../../form-input/FormInput";
import { useSendExcelenceRequest } from "../../../hooks/useSendExcelenceRequest";

const ApplicantUpgrade: React.FC = () => {
  const [file, setFile] = useState<File>();

  const handleSendRequest = async (e: any) => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      await sendRequest(formData);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  const { sendRequest, loading, message, setMessage, type } =
    useSendExcelenceRequest();

  return (
    <GenericForm
      handleSubmit={handleSendRequest}
      formTitle={"Solicita mejorar a excelencia"}
      loading={loading}
      buttonText={"Enviar"}
      message={message}
      setMessage={setMessage}
      type={type}
      guidance={`¡Nos complace mucho tu interés en unirte al exclusivo grupo de Excelencia Académica (EA) y formar parte de nuestro catálogo "Talentos EA"! Por favor, envía una certificación que valide tu pertenencia al grupo EA. Una vez recibida y autenticada, mejoraremos tu perfil en nuestra plataforma de manera gratuita. Estamos entusiasmados por dar la bienvenida a tu talento a nuestra comunidad y destacar tus logros sin ningún costo.
        *Válido para estudiantes pertenecientes quinto superior, décimo superior y excelencia académica`}
    >
      <Col xs={12} md={12} lg={12} className="mb-4">
        <FormInput
          label={"Documento de prueba"}
          type={"file"}
          name={""}
          maxLength={100}
          required={true}
          value={file}
          placeholder={""}
          accept={"application/pdf"}
          onChange={handleFileChange}
        />
      </Col>
    </GenericForm>
  );
};

export default ApplicantUpgrade;
