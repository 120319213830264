import RemoveElement from "../../remove-element/RemoveElement";
import DataTableHeader from "../data-table-header/DataTableHeader";
import DataTableTitle from "../data-table-title/DataTableTitle";
import DataTable from "../data-table/DataTable";
import React from "react";

interface EntityTableProps {
  title: string;
  createLabel?: string;
  createClick?: () => void;
  headers: any;
  renderData: any;
  handleFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  itemsCount: number;
  setItemsCount: React.Dispatch<React.SetStateAction<number>>;
  deleteButton?: any;
  deleteHeader?: string;
  deleteMessage?: string;
  showRemove?: boolean;
  setShowRemove?: any;
}

const EntityTable: React.FC<EntityTableProps> = ({
  title,
  createLabel,
  createClick,
  headers,
  renderData,
  handleFilterChange,
  page,
  setPage,
  itemsCount,
  deleteButton,
  deleteHeader,
  deleteMessage,
  showRemove,
  setShowRemove,
}) => {
  return (
    <>
      <DataTableTitle
        title={title}
        createLabel={createLabel}
        createClick={createClick}
      />
      {handleFilterChange && (
        <DataTableHeader handleFilterChange={handleFilterChange} />
      )}
      <DataTable
        headers={headers}
        renderData={renderData}
        itemsCount={itemsCount}
        take={10}
        page={page}
        setPage={setPage}
      />
      {showRemove && setShowRemove && deleteHeader && deleteMessage && (
        <RemoveElement
          showRemoveModal={showRemove}
          handleCloseRemoveModal={() => setShowRemove(false)}
          modalHeader={deleteHeader}
          modalBody={deleteMessage}
          removeElement={() => {
            setShowRemove(false);
            deleteButton();
          }}
        />
      )}
    </>
  );
};

export default EntityTable;
