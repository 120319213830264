import React from "react";
import { Button, Spinner } from "react-bootstrap";
import "./FormButton.css";

interface FormButtonProps {
  type: "submit" | "link";
  buttonText: string;
  variant: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  mainFont?: boolean;
}

const FormButton: React.FC<FormButtonProps> = ({
  type,
  loading = false,
  disabled = false,
  buttonText,
  variant,
  onClick = () => {},
  mainFont = false,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (type === "link" && onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <div className="text-center">
      <Button
        type={type === "submit" ? "submit" : "button"}
        className="form-button"
        variant={variant}
        disabled={loading || disabled}
        onClick={handleClick}
      >
        {loading ? (
          <Spinner animation="border" variant="light" size="sm" />
        ) : (
          <span className={`${mainFont ? "link-button" : ""}`}>
            {buttonText}
          </span>
        )}
      </Button>
    </div>
  );
};

export default FormButton;
