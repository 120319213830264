import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";

export interface FormInputProps {
  label: string;
  type: "text" | "password" | "email" | "textarea" | "file" | "number";
  name: string;
  minLength?: number;
  maxLength: number;
  required: boolean;
  autoComplete?: string;
  capitalize?: boolean;
  value?: string | File | number;
  placeholder?: string;
  accept?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  type,
  name,
  minLength,
  maxLength,
  required,
  autoComplete,
  capitalize = false,
  value,
  placeholder,
  accept,
  disabled,
  readOnly,
  onChange,
}) => {
  const [inputType, setInputType] = useState(type);
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
    setInputType(isPasswordVisible ? "password" : "text");
  };

  const validateNumberInput = (value: string) => {
    const isValid = /^[0-9]*(\.[0-9]{0,2})?$/.test(value);
    return isValid;
  };

  const capitalizeWords = (input: string) => {
    return input.toLowerCase().replace(/\b(\w)/g, (char) => char.toUpperCase());
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "number") {
      const { value } = e.target;
      if (!validateNumberInput(value)) {
        e.preventDefault();
        return;
      }
    } else if (type === "text" && capitalize) {
      const { value } = e.target;
      // e.target.value = capitalizeWords(value);
    }
    if (onChange) onChange(e);
  };

  const commonProps = {
    name,
    minLength,
    maxLength,
    required,
    autoComplete,
    placeholder,
    disabled,
    readOnly,
    onChange: handleOnChange,
  };

  return (
    <Form.Group className="form-group">
      <FloatingLabel controlId={name} label={label}>
        {type === "textarea" ? (
          <Form.Control
            className="form-input"
            as="textarea"
            {...commonProps}
            value={value as string}
          />
        ) : type === "file" ? (
          <Form.Control
            className="form-input"
            type={type}
            accept={accept}
            {...commonProps}
          />
        ) : (
          <>
            <Form.Control
              className="form-input"
              type={inputType}
              {...commonProps}
              value={value as string}
            />
            {type === "password" && (
              <FontAwesomeIcon
                icon={isPasswordVisible ? faEyeSlash : faEye}
                size={"lg"}
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#006cc4",
                }}
              />
            )}
          </>
        )}
      </FloatingLabel>
    </Form.Group>
  );
};

export default FormInput;
