import { APIMiddleware } from "../middleware/api-middleware";
import { apiUrls } from "../common/api-urls";
import { BuyProfilePackageRequest } from "../types/request/buy-profile-package-request";

const { PROFILE_PURCHASES_URL } = apiUrls;

export function buyProfilePackage(buyProfilePackage: BuyProfilePackageRequest) {
  const url = `${PROFILE_PURCHASES_URL}/buy-profile-package`;
  return APIMiddleware.post(url, {
    data: {
      price: buyProfilePackage.price,
      token: buyProfilePackage.token,
      packageId: buyProfilePackage.packageId,
      billType: buyProfilePackage.billType,
      billDetails: buyProfilePackage.billDetails,
    },
  });
}

export function getProfilePurchases(page: number, take: number) {
  const url = `${PROFILE_PURCHASES_URL}/list?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function updateProfilePurchase(id: number, billGenerated: boolean) {
  const url = `${PROFILE_PURCHASES_URL}/bill-generated/${id}`;
  return APIMiddleware.patch(url, { data: { billGenerated } });
}

export function getProfilePurchase(id: number) {
  const url = `${PROFILE_PURCHASES_URL}/${id}`;
  return APIMiddleware.get(url);
}
