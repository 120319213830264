import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import DotsDecorator from "../../home/dots-decorator/DotsDecorator";
import ApplicantExperience from "../applicant-experience/ApplicantExperience";
import ApplicantStudies from "../applicant-studies/ApplicantStudies";
import ApplicantLanguages from "../applicant-languages/ApplicantLanguages";
import {
  Experience,
  Language,
  Study,
} from "../../../types/response/applicant-profile-response";
import {
  getExperiences,
  getLanguages,
  getStudies,
} from "../../../api/applicant.api";

const ApplicantCurriculum: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [studies, setStudies] = useState<Study[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);

  const loadExperiences = async () => {
    try {
      const experienceData = (await getExperiences()).data;
      setExperiences(experienceData);
    } catch (error) {
      console.error("Error loading experiences:", error);
    }
  };

  const loadStudies = async () => {
    try {
      const studiesData = (await getStudies()).data;
      setStudies(studiesData);
    } catch (error) {
      console.error("Error loading studies:", error);
    }
  };

  const loadLanguages = async () => {
    try {
      const languagesData = (await getLanguages()).data;
      setLanguages(languagesData);
    } catch (error) {
      console.error("Error loading languages:", error);
    }
  };

  const loadData = async () => {
    setLoading(true);
    await Promise.all([loadExperiences(), loadStudies(), loadLanguages()]);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="content-card mb-4 p-4">
      <Row className="justify-content-center">
        <h4 className="text-center">Mi Currículum</h4>
        <DotsDecorator width={10} height={10} center={true} />
      </Row>
      <div className="pt-4">
        <ApplicantExperience
          experiences={experiences}
          reloadExperiences={loadExperiences}
          loading={loading}
        />
        <ApplicantStudies
          studies={studies}
          reloadStudies={loadStudies}
          loading={loading}
        />
        <ApplicantLanguages
          languages={languages}
          reloadLanguages={loadLanguages}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ApplicantCurriculum;
