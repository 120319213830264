import React, { useEffect, useState } from "react";
import GenericForm from "../../generic-form/GenericForm";
import { Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import FormInput from "../../form-input/FormInput";
import Loading from "../../loading/Loading";
import { useAddLanguage } from "../../../hooks/useAddLanguage";
import { getApplicantLanguage } from "../../../api/language.api";
import { languageLevels } from "../../../common/language-levels";
import FormSelect from "../../form-select/FormSelect";

const SaveLanguage: React.FC = () => {
  const [language, setLanguage] = useState("");
  const [speakingLevel, setSpeakingLevel] = useState("");
  const [writingLevel, setWritingLevel] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  const { addLanguage, updateLanguage, loading } = useAddLanguage();

  const navigate = useNavigate();

  useEffect(() => {
    const loadLanguage = async (id: string) => {
      try {
        const languageData = (await getApplicantLanguage(parseInt(id))).data;
        setLanguage(languageData.language);
        setSpeakingLevel(languageData.speakingLevel);
        setWritingLevel(languageData.writingLevel);
      } catch (error) {
        console.error("Error loading language:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      loadLanguage(id);
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const handleSaveLanguage = async (e: any) => {
    e.preventDefault();
    const languageData = {
      language,
      speakingLevel,
      writingLevel,
    };
    id
      ? await updateLanguage(parseInt(id), languageData)
      : await addLanguage(languageData);
    navigate("/applicant");
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <GenericForm
        handleSubmit={handleSaveLanguage}
        formTitle="Agregar idioma"
        loading={loading}
        buttonText={id ? "Actualizar" : "Agregar"}
      >
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormInput
            label="Idioma"
            type="text"
            name="language"
            maxLength={100}
            required={true}
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            placeholder={""}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormSelect
            label={"Nivel de habla"}
            name={"speakingLevel"}
            required={true}
            value={speakingLevel}
            options={languageLevels}
            onChange={(e) => setSpeakingLevel(e.target.value)}
          />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-4">
          <FormSelect
            label={"Nivel de escritura"}
            name={"writingLevel"}
            required={true}
            value={writingLevel}
            options={languageLevels}
            onChange={(e) => setWritingLevel(e.target.value)}
          />
        </Col>
      </GenericForm>
    </div>
  );
};

export default SaveLanguage;
