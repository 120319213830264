import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import EntityTable from "../../../components/admin/entity-table/EntityTable";
import { Plan } from "../../../types/response/plan-response";
import DataTableButtons from "../../../components/admin/data-table-buttons/DataTableButtons";
import { getPlanList } from "../../../api/plans.api";

const AdminCompanyPlans: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);

  const take = 10;

  useEffect(() => {
    const loadPlans = async () => {
      try {
        const response = await getPlanList(page, take);
        const paginatedData = response.data;
        setPlans(paginatedData.data);
        setItemsCount(paginatedData.meta.itemCount);
      } catch (error) {
        console.error("Error loading upgrade requests", error);
      } finally {
        setLoading(false);
      }
    };

    loadPlans();
  }, [page]);

  const renderPlans = () => {
    return plans.map((plan) => (
      <tr key={plan.id}>
        <td>{plan.id}</td>
        <td>{plan.name}</td>
        <td>S/. {plan.price}</td>
        <td>{plan.visibilityPeriod} días</td>
        <td>
          <DataTableButtons
            itemId={plan.id}
            handleView={(id: number) => navigate(`/admin/company-plans/${id}`)}
          />
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <EntityTable
          title={"Planes para empresas"}
          headers={["ID", "Nombre", "Precio", "Visibilidad", "Acciones"]}
          renderData={renderPlans}
          page={page}
          setPage={setPage}
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
        />
      )}
    </Container>
  );
};

export default AdminCompanyPlans;
