import React, { useState } from "react";
import { Plan } from "../../../types/response/plan-response";
import GenericForm from "../../../components/generic-form/GenericForm";
import { Col } from "react-bootstrap";
import FormInput from "../../../components/form-input/FormInput";
import { useUserPlan } from "../../../hooks/useUserPlan";
import FormCheckbox from "../../../components/form-checkbox/FormCheckbox";

interface AdminUserPlansFormProps {
  item: Plan;
}

const AdminUserPlansForm: React.FC<AdminUserPlansFormProps> = ({ item }) => {
  const [name, setName] = useState(item.name);
  const [price, setPrice] = useState(item.price);
  const [forSale, setForSale] = useState(item.forSale);
  const [salePrice, setSalePrice] = useState(item.salePrice);

  const handleSave = async (e: any) => {
    e.preventDefault();
    await updatePlan(item.id, {
      name,
      price,
      forSale,
      salePrice,
    });
  };

  const { updatePlan, loading, message, setMessage, type } = useUserPlan();

  return (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={"Editar información del plan"}
      loading={loading}
      buttonText={"Actualizar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Nombre"}
          type={"text"}
          name={"name"}
          maxLength={100}
          required={true}
          value={name}
          placeholder={""}
          onChange={(e) => setName(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Precio"}
          type={"number"}
          name={"price"}
          maxLength={100}
          required={true}
          value={price}
          placeholder={""}
          onChange={(e) => setPrice(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4 text-white">
        <FormCheckbox
          type="switch"
          label={"En oferta"}
          checked={forSale}
          onChange={(e) => setForSale(e.target.checked)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Precio de oferta"}
          type={"number"}
          name={"salePrice"}
          maxLength={100}
          required={true}
          value={salePrice}
          placeholder={""}
          onChange={(e) => setSalePrice(e.target.value)}
        />
      </Col>
    </GenericForm>
  );
};

export default AdminUserPlansForm;
