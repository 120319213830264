import { useState } from "react";
import { resultType } from "../common/result-type";
import {
  createAdvertisementApi,
  updateAdvertisementApi,
} from "../api/company.api";
import { CreateAdvertisementRequest } from "../types/request/create-advertisement-request";
import { UpdateAdvertisementRequest } from "../types/request/update-advertisement-request";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";

export const useCreateAdvertisement = () => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const createAdvertisement = async (request: CreateAdvertisementRequest) => {
    setLoading(true);

    try {
      await createAdvertisementApi(request);
      setLoading(false);
      setShowNotificationModal(true);
      setNotificationTitle("Operación exitosa");
      setNotificationText("Puesto de trabajo creado.");
      setNotificationType(resultType.success);
      setType(resultType.success);
      navigate("/company");
    } catch (ex: any) {
      setLoading(false);
      setShowNotificationModal(true);
      setNotificationTitle("Ha ocurrido un error");
      setNotificationText(
        ex.response?.data?.message ||
          "Hubo un error al crear puesto de trabajo."
      );
      setNotificationType(resultType.error);
      setType(resultType.error);
    }
  };

  const updateAdvertisement = async (
    request: UpdateAdvertisementRequest,
    urlCode: string
  ) => {
    setLoading(true);

    try {
      await updateAdvertisementApi(request, urlCode);
      setLoading(false);
      setShowNotificationModal(true);
      setNotificationTitle("Operación exitosa");
      setNotificationText("Puesto de trabajo actualizado.");
      setNotificationType(resultType.success);
      setType(resultType.success);
      navigate("/company");
    } catch (ex) {
      setLoading(false);
      setShowNotificationModal(true);
      setNotificationTitle("Ha ocurrido un error");
      setNotificationText("Hubo un error al actualizar puesto de trabajo.");
      setNotificationType(resultType.error);
      setType(resultType.error);
    }
  };

  return {
    createAdvertisement,
    updateAdvertisement,
    loading,
    message,
    setMessage,
    type,
  };
};
