import React from "react";
import { Image } from "react-bootstrap";

interface StepIconProps {
  src: string;
  description: string;
}

const StepIcon: React.FC<StepIconProps> = ({ src, description }) => {
  return (
    <div>
      <Image alt="step-icon" src={src} width={150} height={150} />
      <p className="mt-5">{description}</p>
    </div>
  );
};

export default StepIcon;
