import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import logo from "../../assets/images/logo/logo-primary.png";

const PrivacyPolicyMain: React.FC = () => {
  return (
    <Row className="justify-content-center pt-4">
      <Col xs={12} md={6}>
        <div>
          <a className="d-flex justify-content-center mb-4" href="/">
            <img alt="Logo Chamba Sí Hay" src={logo} width={133} height={47} />
          </a>
        </div>
        <Card className="shadow-sm mb-4">
          <Card.Body>
            <p className="text-center">
              <strong>
                <u>POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES</u>
              </strong>
            </p>
            <div className="text-justify">
              <article>
                <p>
                  Mediante el presente documento, CHAMBA SI HAY S.A.C. (la
                  “Empresa” o “Nosotros”), identificada con Registro Único de
                  Contribuyente No. 20612226424, con domicilio en, Lima, Perú,
                  informa y solicita el consentimiento a los Usuarios de la
                  plataforma “Chamba Sí Hay”, su dominio web{" "}
                  <a href="https://chambasihay.com.pe/">
                    https://ChambaSiHay.com.pe/
                  </a>
                  , sus subdominios y/u otros dominios (URLs) relacionados (en
                  adelante, "Chamba Sí Hay" o la "Plataforma") sobre su Política
                  de Privacidad de Datos Personales. La Empresa se compromete a
                  proteger y respetar la privacidad de sus datos de carácter
                  personal (a continuación “Datos Personales”) de acuerdo con lo
                  establecido en la Ley Nro. 29733, Ley de Protección de Datos
                  Personales (la “Ley”) y el Decreto Supremo Nro. 003-2013/JUS,
                  Reglamento de la Ley de Protección de Datos Personales (el
                  “Reglamento”).
                </p>
                <p>
                  En esta Política de Privacidad de Datos Personales la Empresa
                  describirá a los Usuarios el tratamiento de los Datos
                  Personales que efectuará al utilizar los servicios brindados a
                  través de la Plataforma.{" "}
                </p>
                <p>
                  Mediante el presente documento, la Empresa proporciona a los
                  Usuarios los recursos técnicos adecuados para que, con
                  carácter previo a la entrega de sus Datos Personales, puedan
                  acceder a este aviso y a la demás información relevante, a
                  efectos de brindar su consentimiento.
                </p>
              </article>
              <article>
                <div className="font-bold">
                  I. <u>Manifestación de voluntad</u>
                </div>
                <div>
                  <p>
                    Si Usted no está de acuerdo o tuviese alguna duda sobre
                    nuestra Política de Privacidad de Datos Personales y los
                    términos en los cuales se recopilarán y tratarán sus Datos
                    Personales, no podrá utilizar la Plataforma ni gozar de sus
                    funcionalidades.
                  </p>
                  <p>
                    Caso contrario, al llenar sus datos y dar clic en «Aceptar»,
                    manifestando de esta forma su voluntad a través del presente
                    medio electrónico, de conformidad con el artículo 141 del
                    Código Civil Peruano y, de esta forma, otorga su
                    consentimiento libre, previo, expreso, informado e
                    inequívoco para el tratamiento de todos los Datos Personales
                    que libre y voluntariamente proveerá a la Empresa en el
                    marco de su relación comercial con nosotros, así como los
                    que le sean requeridos para el acceso e interacción con los
                    servicios ofrecidos y las comunicaciones derivadas de los
                    mismos.
                  </p>
                  <p>
                    Si Usted autoriza a su hijo/a o pupilo/a menor de dieciocho
                    (18) años a ser usuario de la Plataforma, Usted brinda a la
                    Empresa su consentimiento para recopilar y tratar los datos
                    personales del menor y los suyos que nos sean entregados
                    mediante el procedimiento descrito en la presente Política
                    de Privacidad. Asimismo, si Usted toma conocimiento de que
                    su hijo/a o pupilo/a nos ha proporcionado información
                    personal sin su consentimiento, comuníquese con nosotros
                    utilizando la dirección de correo electrónico señalada a
                    continuación para que podamos tomar medidas para eliminar
                    dicha información y finalizar cualquier cuenta que se haya
                    creado con nosotros:
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  II.{" "}
                  <u>
                    Finalidad de la recopilación y tratamiento de los Datos
                    Personales
                  </u>
                </div>
                <div>
                  <p>
                    La Empresa tratará sus Datos Personales para operar,
                    proporcionar, desarrollar y mejorar la Plataforma,
                    incluyendo las siguientes finalidades :
                  </p>
                  <p>
                    (i) Proporcionar y administrar la Plataforma permitiéndole
                    hacer uso de los servicios de búsqueda de empleo,
                    postulación, gestión de perfil, entre otros relacionados al
                    servicio de reclutamiento.
                  </p>
                  <p>
                    (ii) Proporcionar funciones de compra en la Plataforma, así
                    como compartir su información con proveedores de pago y
                    tramitación de transacciones y otros proveedores de
                    servicios para procesar sus solicitudes.
                  </p>
                  <p>(iii) Procesar cualquier solicitud o reclamo. </p>
                  <p>
                    (iv) Brindar a los Usuarios de Chamba Sí Hay una experiencia
                    personalizada de uso de los servicios brindados a través de
                    la Plataforma.{" "}
                  </p>
                  <p>
                    (v) Gestión, administración, prestación, ampliación y mejora
                    de la Plataforma (por ejemplo, a través del diseño de nuevas
                    funcionalidades o add-ons){" "}
                  </p>
                  <p>
                    (vi) Estudio de la utilización de la Plataforma por parte de
                    los Usuarios.{" "}
                  </p>
                  <p>
                    (vii) Mantener y mejorar la seguridad, la protección y la
                    estabilidad de la Plataforma identificando y solucionando
                    cuestiones o problemas técnicos o de seguridad (errores
                    técnicos, detección de abusos, fraudes y actividades
                    ilegales).{" "}
                  </p>
                  <p>
                    (viii) Facilitar la realización de encuestas o sondeos que
                    cumplan determinados criterios realizados por auditores
                    independientes.{" "}
                  </p>
                  <p>
                    (ix) Envío de actualizaciones de nuestros Términos y
                    Condiciones y Política de Privacidad.{" "}
                  </p>
                  <p>(x) Desarrollo comercial de la Empresa. </p>
                  <p>
                    (xi) Promocionar la Plataforma o los servicios de terceros
                    mediante comunicaciones de marketing, promociones o
                    concursos.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  III. <u>Datos personales recopilados</u>
                </div>
                <div>
                  <p>
                    La Empresa recopila dos tipos de datos personales: (i) los
                    que Usted nos envía directamente cuando utiliza la
                    Plataforma y (ii) los que se recopilan automáticamente a
                    través de herramientas informáticas sobre cómo Usted accede
                    y utiliza la Plataforma.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  3.1. Información proporcionada por el Usuario
                </div>
                <div>
                  <p>
                    La Empresa recopila los Datos Personales que Usted nos envía
                    directamente cuando Usted (i) realiza una transacción en la
                    Plataforma, (ii) se registra como usuario de Chamba Sí Hay,
                    (iii) envía un correo electrónico a nuestra área de atención
                    al cliente, (iv) se registra a nuestro newsletter para
                    recibir noticias de nuevas funcionalidades de la Plataforma
                    y/o promociones de ventas. Los Datos Personales que se le
                    solicitarán son:
                  </p>
                  <p>
                    a){" "}
                    <u>
                      En el proceso de transacción en la Plataforma se le
                      solicitarán los siguientes Datos Personales
                    </u>
                    : nombre completo, fecha de nacimiento, dirección de correo
                    electrónico, dirección de facturación, número de teléfono,
                    detalles de la tarjeta de pago (crédito o débito).
                  </p>
                  <p>
                    b){" "}
                    <u>
                      En el proceso de registro como usuario de CHAMBA SÍ HAY se
                      le solicitarán los siguientes datos personales
                    </u>
                    : nombre completo, fecha de nacimiento, dirección de correo
                    electrónico, número de teléfono, expectativas salariales,
                    antecedentes laborales, antecedentes educativos,
                  </p>
                  <p>
                    c){" "}
                    <u>Comunicación con nuestra área de atención al cliente</u>:
                    correo electrónico, número de teléfono, y aquella requerida
                    en el formulario que aparecerá en esta sección de la
                    Plataforma.
                  </p>
                  <p>
                    d){" "}
                    <u>
                      En el proceso de atención al cliente o registro a nuestro
                      newsletter se le solicitarán los siguientes datos
                      personales
                    </u>
                    : nombre completo y correo electrónico.
                  </p>
                  <p>
                    Si elige no proporcionar dichos Datos Personales, la Empresa
                    no podrá completar las transacciones que usted realice,
                    darle acceso a Chamba Sí Hay, ni responder a sus
                    solicitudes.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  3.2. Información recopilada automáticamente
                </div>
                <div>
                  <p>
                    La Empresa también recopila automáticamente información
                    sobre cómo usted accede y utiliza la Plataforma, tales como:
                  </p>
                  <p>
                    a) <u>Información técnica</u>: Recopilamos cierta
                    información sobre el dispositivo y la conexión a la red
                    cuando usted accede a la Plataforma. Esta información
                    incluye el modelo de su dispositivo, el sistema operativo,
                    la dirección IP y el idioma del sistema. También recopilamos
                    información relacionada con el servicio, el diagnóstico y el
                    rendimiento, incluidos los informes de colisiones y los
                    registros de rendimiento.
                  </p>
                  <p>
                    b) <u>Información sobre la ubicación</u>: Recopilamos
                    automáticamente información sobre su ubicación aproximada
                    (por ejemplo, país, estado o ciudad) basándonos en su
                    información técnica (p. ej. La tarjeta SIM o su dirección
                    IP).
                  </p>
                  <p>
                    c) <u>Información de uso</u>: Recopilamos información sobre
                    la forma en la que usted utiliza la Plataforma, incluida la
                    información sobre las instrucciones y solicitudes que
                    formula, frecuencia de uso y configuración.
                  </p>
                  <p>
                    d)<u>Información inferida</u>: Podemos vincular o combinar
                    la información personal proporcionada por Usted y la
                    información que recopilamos automáticamente. Esto nos
                    permite brindarle una experiencia personalizada
                    independientemente de cómo interactúa con nosotros, así como
                    también para mantener la seguridad de la Plataforma.
                  </p>
                  <p>
                    e)
                    <u>
                      <i>Cookies</i>
                    </u>
                    : Utilizamos cookies y tecnologías de seguimiento similares
                    para operar y proporcionar la Plataforma. Por ejemplo,
                    utilizamos cookies para recordar sus preferencias de idioma,
                    frecuencia de uso de la Plataforma, y por motivos de
                    seguridad. También utilizamos estas tecnologías con fines de
                    marketing. Para obtener más información sobre nuestro uso de
                    cookies, consulte nuestra Política de Cookies. Obtendremos
                    su consentimiento para el uso de cookies según lo exija la
                    Ley.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  IV. <u>Tratamiento de los Datos Personales</u>
                </div>
                <div>
                  <p>
                    Los Datos Personales recogidos serán objeto de tratamiento
                    automatizado e incorporados al correspondiente Banco de
                    Datos Personales de los que la Empresa es titular y
                    responsable (en adelante, el “Banco”). El Banco tiene como
                    nombre USUARIOS y se encontrará registrado ante la Autoridad
                    Nacional de Datos Personales.
                  </p>
                  <p>
                    La Empresa proporciona a los Usuarios los recursos técnicos
                    adecuados para que, con carácter previo, puedan acceder a
                    este aviso sobre la Política de Privacidad de Datos
                    Personales o a cualquier otra información relevante y puedan
                    prestar su consentimiento a fin de que la Empresa proceda al
                    tratamiento automatizado de sus Datos Personales.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  V. <u>Derechos de los Usuarios</u>
                </div>
                <div>
                  <p>
                    Usted es titular de los derechos de información, acceso,
                    actualización, inclusión, rectificación, supresión,
                    cancelación, tratamiento objetivo y oposición sobre sus
                    Datos Personales, según son regulados en la Ley y el
                    Reglamento. Usted podrá ejercitar directamente algunos de
                    sus derechos a través de la propia Plataforma:
                  </p>
                  <p>
                    - <u>Acceso a la información</u>: Los controles dentro de la
                    Plataforma le permiten (i) acceder a su información que está
                    disponible en su perfil de Usuario, (ii) acceder a la
                    presente política que le permite conocer las condiciones y
                    generalidades del tratamiento de su información, y (iii)
                    solicitar una copia de su información al seleccionar la
                    opción de descarga.
                  </p>
                  <p>
                    - <u>Actualización y rectificación</u>: Usted podrá
                    actualizar, cambiar y/o corregir gran parte de su
                    información a través de los controles y ajustes de la
                    Plataforma, a través de las opciones de su perfil.
                  </p>
                  <p>
                    - <u>Supresión y cancelación</u>: Los controles dentro de la
                    Plataforma le permiten (i) eliminar la información de su
                    perfil de Usuario, (ii) eliminar o suspender íntegramente su
                    cuenta.
                  </p>
                  <p>
                    Para el ejercicio del resto de sus derechos, así como
                    también para la revocatoria del consentimiento sobre las
                    condiciones de la presente Política de Privacidad de Datos
                    Personales, deberá comunicarse con la Empresa escribiendo a
                    al correo electrónico.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  VI. <u>Medidas de seguridad de Datos Personales</u>
                </div>
                <div>
                  <p>
                    La Empresa garantiza la privacidad, confidencialidad y
                    seguridad de los Datos Personales recopilados, de acuerdo
                    con las exigencias de la Ley y su Reglamento. En tal
                    sentido, la Empresa ha adoptado los niveles de seguridad de
                    protección de los Datos Personales legalmente requeridos.
                  </p>
                  <p>
                    Para ello, la Empresa mantiene medidas de seguridad
                    técnicas, administrativas y físicas apropiadas diseñadas
                    para proteger su información del acceso no autorizado, robo,
                    divulgación, modificación o pérdida. Asimismo, revisamos
                    periódicamente nuestras medidas de seguridad para tener en
                    cuenta las nuevas tecnologías y métodos disponibles. No
                    obstante, el Usuario debe ser consciente de que las medidas
                    de seguridad en Internet no son inexpugnables y deberá
                    resguardar sus credenciales de acceso de manera
                    confidencial.
                  </p>
                  <p>
                    Conservamos la información durante el tiempo necesario para
                    ofrecer la Plataforma y para los demás fines establecidos en
                    la presente Política de privacidad. También conservamos la
                    información cuando es necesario para cumplir con las
                    obligaciones contractuales y legales, cuando existe un
                    interés comercial legítimo para hacerlo (como la mejora y el
                    desarrollo de la Plataforma, y la mejora de su seguridad y
                    estabilidad), y para el ejercicio o la defensa de
                    reclamaciones legales.
                  </p>
                  <p>
                    Conservaremos los Datos Personales que recopilamos sobre
                    Usted hasta cinco (5) años, después de que nos solicite la
                    cancelación de su cuenta en la Plataforma.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  VII. <u>Transferencia de Datos Personales</u>
                </div>
                <div>
                  <p>
                    Podemos compartir sus Datos Personales con las siguientes
                    personas o entidades para las siguientes finalidades:
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">7.1. Proveedores de servicios</div>
                <div>
                  <p>
                    • Alojamiento en la nube y entrega de contenidos.
                    <br />• Asistencia técnica y al cliente.
                    <br />• Moderación de contenidos.
                    <br />• Marketing.
                    <br />• Análisis y pagos en línea.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">7.2. Socios comerciales</div>
                <div>
                  <p>
                    a) <u>Plataformas de socios</u>: Compartimos cierta
                    información (como la información que el Usuario proporciona,
                    información técnica e información del uso) con nuestros
                    socios comerciales cuya plataforma o servicios están
                    integrados en la Plataforma, para ofrecerle una experiencia
                    fluida.
                  </p>
                  <p>
                    b){" "}
                    <u>
                      Proveedores de pago y tramitación de transacciones y otros
                      proveedores de servicios
                    </u>
                    : al realizar una compra en la Plataforma compartimos la
                    información de compra con los proveedores de pago y
                    tramitación de transacciones y otros proveedores de
                    servicios.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">7.3. Empresas vinculadas</div>
                <div>
                  <p>
                    Esto es las empresas. Compartimos la información que
                    proporciona el Usuario, la información recopilada
                    automáticamente
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">7.4. Terceros</div>
                <div>
                  <p>
                    Compartimos su información en otro número limitado de
                    circunstancias como las siguientes:
                  </p>
                  <p>
                    a) <u>Auditoras independientes</u>: Compartimos su
                    información con auditores independientes para facilitar la
                    realización de sondeos, estudios o encuestas que cumplan
                    determinados criterios como el conocimiento colectivo de los
                    Usuarios, uso de información y tendencias sociales.
                  </p>
                  <p>
                    b) <u>Operaciones corporativas</u>: Su información puede ser
                    revelada a terceros en relación con una operación
                    corporativa, como una fusión, venta de activos o acciones,
                    reorganización, financiación, cambio de control o
                    adquisición de todo o parte de nuestro negocio.
                  </p>
                  <p>
                    c) <u>Obligaciones legales</u>: Podemos compartir la
                    información proporcionada por el Usuario a solicitud de
                    entidades públicas que invoquen la ejecución de una
                    competencia legalmente asignada, conforme el artículo 14 de
                    la Ley, como por ejemplo: la Policía Nacional del Perú,
                    Ministerio Público, Autoridades Administrativas y
                    Judiciales, etc.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  VIII. <u>Actualizaciones a la Política de Privacidad</u>
                </div>
                <div>
                  <p>
                    Podemos modificar o actualizar esta Política de privacidad
                    según consideremos conveniente. Le notificaremos cualquier
                    cambio importante en esta política mediante un aviso a
                    través de la Plataforma o por otros medios. La fecha de
                    «Última actualización» que aparece en la parte superior de
                    esta política refleja la fecha de entrada en vigor de dichos
                    cambios.
                  </p>
                </div>
              </article>
              <article>
                <div className="font-bold">
                  IX. <u>Contacto</u>
                </div>
                <div>
                  <p>
                    Puede ponerse en contacto con nosotros en relación con esta
                    Política de privacidad a través del siguiente correo
                    electrónico: .
                  </p>
                  <p>
                    También puede ponerse en contacto con nosotros en la
                    dirección física indicada en la sección introductoria de
                    esta política.
                  </p>
                </div>
              </article>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default PrivacyPolicyMain;
