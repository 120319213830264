import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminCareersForm from "./AdminCareersForm";
import { CareerResponse } from "../../../types/response/career-response";

const AdminCareersSave: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [career, setCareer] = useState<CareerResponse>({
    id: 0,
    name: "",
    industryId: 0,
  });

  useEffect(() => {
    if (id) {
      setCareer((prevCareer) => ({
        ...prevCareer,
        industryId: parseInt(id, 10),
      }));
    }
  }, [id]);

  return <AdminCareersForm item={career} />;
};

export default AdminCareersSave;
