import React, { useEffect, useState } from "react";
import { getCatalogue } from "../../api/catalogue.api";
import Loading from "../loading/Loading";
import ApplicantCatalogueItem from "../applicant-catalogue-item/ApplicantCatalogueItem";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { Row } from "react-bootstrap";
import { useAuthContext } from "../../hooks/useAuthContext";
import ProfileViewWarningModal from "../profile-view-warning-modal/ProfileViewWarningModal";
import { useNavigate } from "react-router-dom";
import { checkProfile } from "../../api/profile-view.api";
import ProfileViewInsufficientModal from "../profile-view-insufficient-modal/ProfileViewInsufficientModal";
import { CheckProfileDto } from "../../types/response/check-profile";
import { useApplicantsContext } from "../../context/ApplicantsContext/ApplicantsContext";
import ProfileViewNotAuthorizedModal from "../profile-view-not-authorized-modal/ProfileViewNotAuthorizedModal";

export interface ApplicantSummarized {
  id: number;
  profileImage: string;
  fullName: string;
  career: string;
  description: string;
  seniority: string;
  district: string;
  isExcelence: boolean;
}

const ApplicantsCatalogue: React.FC = () => {
  const [showUnauthorized, setShowUnauthorized] = useState(false);

  const take = 12;

  const { user } = useAuthContext();

  const {
    loadingApplicants,
    applicants,
    applicantsItemsCount,
    applicantsPage,
    changeApplicantsPage,
    loadApplicants,
  } = useApplicantsContext();

  const navigate = useNavigate();

  const handleClickProfile = async (item: ApplicantSummarized) => {
    if (user?.role === "company") {
      navigate(`/profile-view/applicant/${item.id}`);
    } else {
      setShowUnauthorized(true);
    }
  };

  useEffect(() => {
    const callApplicants = async () => {
      await loadApplicants(take);
    };

    callApplicants();
  }, [applicantsPage]);

  return (
    <div className="mt-3 mb-3 ps-2 pe-2">
      {loadingApplicants ? (
        <Loading />
      ) : applicants.length === 0 ? (
        <div className="content-card mt-3 p-3 text-center">
          No se encontraron postulantes que cumplan con los criterios de
          búsqueda
        </div>
      ) : (
        <>
          <Row className="p-0">
            {applicants.map((item) => (
              <ApplicantCatalogueItem
                key={item.id}
                item={item}
                onClick={() => handleClickProfile(item)}
                blurData={
                  item.seniority === "professional" ||
                  item.isExcelence ||
                  user?.role !== "company"
                }
              />
            ))}
          </Row>
          <div className="mt-4">
            <PaginationControl
              page={applicantsPage}
              total={applicantsItemsCount}
              limit={take}
              changePage={(page) => {
                changeApplicantsPage(page);
              }}
              ellipsis={1}
            />
          </div>
          <ProfileViewNotAuthorizedModal
            showProfileUnauthorizedModal={showUnauthorized}
            handleCloseProfileUnauthorizedModal={() =>
              setShowUnauthorized(false)
            }
          />
        </>
      )}
    </div>
  );
};

export default ApplicantsCatalogue;
