import { APIMiddleware } from "../middleware/api-middleware";
import { apiUrls } from "../common/api-urls";
import { UpdateNewRequest } from "../types/request/update-new-request";

const { NEWS_URL } = apiUrls;

export function getNewsList(page: number, take: number) {
  const url = `${NEWS_URL}/list?page=${page}&take=${take}`;
  return APIMiddleware.get(url);
}

export function getNew(id: number) {
  const url = `${NEWS_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function updateNew(id: number, req: UpdateNewRequest) {
  const url = `${NEWS_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      subject: req.subject,
      body: req.body,
      description: req.description,
      enabled: req.enabled,
    },
  });
}
