import React, { useEffect, useState } from "react";
import { Package } from "../../../types/response/package-response";
import { useParams } from "react-router-dom";
import { getCompanyPackage } from "../../../api/packages.api";
import Loading from "../../../components/loading/Loading";
import AdminProfileViewPackagesForm from "./AdminProfileViewPackagesForm";

const AdminProfileViewPackagesSave: React.FC = () => {
  const [plan, setPlan] = useState<Package>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadCompanyPlan = async (id: string) => {
      try {
        const planData = (await getCompanyPackage(parseInt(id))).data;
        setPlan(planData);
      } catch (error) {
        console.error("Error loading company package:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadCompanyPlan(id);
    }
  }, [id]);

  return loading ? (
    <Loading />
  ) : (
    plan && <AdminProfileViewPackagesForm item={plan} />
  );
};

export default AdminProfileViewPackagesSave;
