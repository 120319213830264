import { useState } from "react";
import { useNotificationContext } from "../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../common/notification-types";
import { postUpgradeRequestDecision } from "../api/upgrade-requests";

export const useUpgradeRequest = () => {
  const [loading, setLoading] = useState(false);

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const navigate = useNavigate();

  const showSuccessMessage = () => {
    navigate("/admin/upgrade-requests");
    setShowNotificationModal(true);
    setNotificationTitle("Solicitud aprobada");
    setNotificationText("Su decisión ha sido notificada.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = () => {
    navigate("/admin/upgrade-requests");
    setShowNotificationModal(true);
    setNotificationTitle("¡Ups! Algo salió mal");
    setNotificationText("Por favor, inténtelo más tarde.");
    setNotificationType(notificationTypes.ERROR);
  };

  const approveRequest = async (id: number) => {
    setLoading(true);
    try {
      await postUpgradeRequestDecision(id, "approved");
      showSuccessMessage();
    } catch (ex) {
      showErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  const rejectRequest = async (id: number) => {
    setLoading(true);
    try {
      await postUpgradeRequestDecision(id, "rejected");
      showSuccessMessage();
    } catch (ex) {
      showErrorMessage();
    } finally {
      setLoading(false);
    }
  };
  return {
    approveRequest,
    rejectRequest,
    loading,
  };
};
