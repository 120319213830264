import { SignInRequest } from "../types/request/sign-in-request";
import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import {
  signIn,
  signInCompany,
  signUp,
  signUpCompany,
  validateGoogle,
} from "../api/auth.api";
import { storeToken } from "../service/token-service";
import { storeUserData } from "../service/user-service";
import { SignUpRequest } from "../types/request/sign-up-request";
import { ValidateGoogleRequest } from "../types/request/validate-google-request";
import { SignUpCompanyRequest } from "../types/request/sign-up-company-request";

export const useAuthenticate = () => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const { dispatch } = useAuthContext();

  const login = async (signInRequest: SignInRequest) => {
    setLoading(true);

    try {
      const response = await signIn(signInRequest);
      await storeToken(response.access_token);
      await storeUserData({ name: response.name, role: response.role });
      dispatch({
        type: "LOGIN",
        payload: {
          token: response.access_token,
          name: response.name,
          role: response.role,
        },
      });
    } catch (ex) {
      setError("Estas credenciales no coinciden con nuestros registros.");
    } finally {
      setLoading(false);
    }
  };

  const loginCompany = async (signInRequest: SignInRequest) => {
    setLoading(true);

    try {
      const response = await signInCompany(signInRequest);
      await storeToken(response.access_token);
      await storeUserData({ name: response.name, role: response.role });
      dispatch({
        type: "LOGIN",
        payload: {
          token: response.access_token,
          name: response.name,
          role: response.role,
        },
      });
    } catch (ex) {
      setError("Estas credenciales no coinciden con nuestros registros.");
    } finally {
      setLoading(false);
    }
  };

  const validateGoogleAuth = async (
    validateGoogleRequest: ValidateGoogleRequest
  ) => {
    setLoadingGoogle(true);
    try {
      const response = await validateGoogle(validateGoogleRequest);
      await storeToken(response.access_token);
      await storeUserData({ name: response.name, role: response.role });
      dispatch({
        type: "LOGIN",
        payload: {
          token: response.access_token,
          name: response.name,
          role: response.role,
        },
      });
    } catch (ex) {
      setError("Hubo un error con la autenticación de Google.");
    } finally {
      setLoadingGoogle(false);
    }
  };

  const register = async (signUpRequest: SignUpRequest) => {
    setLoading(true);

    try {
      if (signUpRequest.password !== signUpRequest.confirmPassword) {
        setError("Las contraseñas no coinciden");
      } else {
        const response = await signUp(signUpRequest);
        await storeToken(response.access_token);
        await storeUserData({ name: response.name, role: response.role });
        dispatch({
          type: "LOGIN",
          payload: {
            token: response.access_token,
            name: response.name,
            role: response.role,
          },
        });
      }
    } catch (ex) {
      setError("El correo ya se encuentra registrado.");
    } finally {
      setLoading(false);
    }
  };

  const registerCompany = async (signUpRequest: SignUpCompanyRequest) => {
    setLoading(true);

    try {
      if (signUpRequest.password !== signUpRequest.confirmPassword) {
        setError("Las contraseñas no coinciden");
      } else {
        const response = await signUpCompany(signUpRequest);
        await storeToken(response.access_token);
        await storeUserData({ name: response.name, role: response.role });
        dispatch({
          type: "LOGIN",
          payload: {
            token: response.access_token,
            name: response.name,
            role: response.role,
          },
        });
      }
    } catch (ex) {
      setError("El correo ya se encuentra registrado.");
    } finally {
      setLoading(false);
    }
  };

  return {
    login,
    register,
    validateGoogleAuth,
    loginCompany,
    registerCompany,
    loading,
    loadingGoogle,
    error,
    setError,
  };
};
