import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ApplicantsContextState } from "./ApplicantsContextState";
import { useFilterContext } from "../FilterContext/FilterContext";
import { getCatalogue } from "../../api/catalogue.api";
import { advancedFilterTypes } from "../../common/advanced-filter-types";
import { getJobOfferApplicants } from "../../api/application.api";

const initialState: ApplicantsContextState = {
  loadingApplicants: false,
  loadingApplicantsByJobOffer: false,
  applicants: [],
  applicantsByJobOffer: [],
  applicantsItemsCount: 0,
  applicantsPage: 1,
  applicantsByJobOfferItemsCount: 0,
  applicantsByJobOfferPage: 1,
  applicantsByJobOfferTake: 1,
  idJobOffer: 0,
  changeApplicantsPage: () => {},
  changeIdJobOffer: () => {},
  changeApplicantsByJobOfferPage: () => {},
  loadApplicants: () => {},
  loadApplicantsByJobOffer: () => {},
};

const ApplicantsContext = createContext<ApplicantsContextState>(initialState);

interface ApplicantsProviderProps {
  children: ReactNode;
}

export const ApplicantsProvider: FC<ApplicantsProviderProps> = ({
  children,
}) => {
  //states
  const [applicants, setApplicants] = useState<any[]>([]);
  const [applicantsByJobOffer, setApplicantsByJobOffer] = useState<any[]>([]);
  const [applicantsByJobOfferItemsCount, setApplicantsByJobOfferItemsCount] =
    useState<number>(0);
  const [applicantsByJobOfferPage, setApplicantsByJobOfferPage] =
    useState<number>(1);
  const [idJobOffer, setIdJobOffer] = useState<number>(0);
  const [applicantsItemsCount, setApplicantsItemsCount] = useState<number>(0);
  const [applicantsPage, setApplicantsPage] = useState<number>(1);
  const [applicantsByJobOfferTake, setApplicantsByJobOfferTake] =
    useState<number>(12);
  const [loadingApplicants, setLoadingApplicants] = useState<boolean>(false);
  const [loadingApplicantsByJobOffer, setLoadingApplicantsByJobOffer] =
    useState<boolean>(false);

  const {
    experienceFilter,
    sectorFilter,
    careerFilter,
    excelenceFilter,
    languageFilter,
    salaryFilter,
    timeExperienceFilter,
    districtFilter,
    savedFilter,
    filterType,
    degreeFilter,
    buildApplicantsQueryFilter,
    buildApplicantsByOfferQueryFilter,
  } = useFilterContext();

  const changeApplicantsPage = (page: number = 1): void => {
    setApplicantsPage(page);
  };

  const changeApplicantsByJobOfferPage = (page: number = 1): void => {
    setApplicantsByJobOfferPage(page);
  };

  const changeIdJobOffer = (idOffer: number): void => {
    setIdJobOffer(idOffer);
  };

  const loadApplicants = async (
    take: number = 12,
    reload?: boolean
  ): Promise<void> => {
    setLoadingApplicants(true);
    if (reload) setApplicantsPage(1);
    try {
      const filters = buildApplicantsQueryFilter();
      const response = await getCatalogue(applicantsPage, take, filters);
      setApplicants(response.data);
      setApplicantsItemsCount(response.meta.itemCount);
    } catch (error) {
      console.error("Error loading applicants", error);
    } finally {
      setLoadingApplicants(false);
    }
  };

  const loadApplicantsByJobOffer = async (
    idOffer: number,
    silent: boolean,
    reload?: boolean
  ): Promise<void> => {
    if (!silent) setLoadingApplicantsByJobOffer(true);
    if (reload) setApplicantsByJobOfferPage(1);
    try {
      const filters = buildApplicantsByOfferQueryFilter();
      const response = await getJobOfferApplicants(
        idOffer,
        applicantsByJobOfferPage,
        applicantsByJobOfferTake,
        filters
      );
      setApplicantsByJobOffer(response.data);
      setApplicantsByJobOfferItemsCount(response.meta.itemCount);
    } catch (error) {
      console.error("Error loading applicants by job offer", error);
    } finally {
      setLoadingApplicantsByJobOffer(false);
    }
  };

  useEffect(() => {
    if (filterType === advancedFilterTypes.candidates) loadApplicants(12, true);
  }, [
    experienceFilter,
    excelenceFilter,
    sectorFilter,
    timeExperienceFilter,
    languageFilter,
    careerFilter,
    districtFilter,
    savedFilter,
    salaryFilter,
  ]);

  useEffect(() => {
    if (filterType === advancedFilterTypes.applications)
      loadApplicantsByJobOffer(idJobOffer, false);
  }, [
    timeExperienceFilter,
    careerFilter,
    salaryFilter,
    savedFilter,
    degreeFilter,
  ]);

  const filterContextValue = {
    applicants,
    applicantsByJobOffer,
    applicantsItemsCount,
    loadingApplicants,
    loadingApplicantsByJobOffer,
    applicantsPage,
    applicantsByJobOfferItemsCount,
    applicantsByJobOfferPage,
    applicantsByJobOfferTake,
    idJobOffer,
    changeIdJobOffer,
    loadApplicantsByJobOffer,
    loadApplicants,
    changeApplicantsPage,
    changeApplicantsByJobOfferPage,
  };

  return (
    <ApplicantsContext.Provider value={filterContextValue}>
      {children}
    </ApplicantsContext.Provider>
  );
};

export const useApplicantsContext = () => {
  return useContext(ApplicantsContext);
};
