import React from "react";
import { Button, ButtonGroup, Image } from "react-bootstrap";
import searchIcon from "../../../../assets/images/icons/search-icon.png";
import "./SearchComponent.css";
import { useNavigate } from "react-router-dom";

const SearchComponent: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ButtonGroup size="lg" className="mb-2">
      <Button
        variant="white"
        className="search-button sb-first text-start"
        onClick={() => navigate("/job-offers")}
      >
        <div>
          <span className="ms-3">Ver ofertas</span>
          <br />
          <span className="sb-detail ms-3">Últimas ofertas de mi carrera</span>
        </div>
      </Button>
      <Button
        variant="white"
        className="search-button sb-second text-start"
        onClick={() => navigate("/catalogue")}
      >
        <span>Ver catálogos</span>
        <br />
        <span className="sb-detail">
          De estudiantes, excelencia y profesionales
        </span>
      </Button>
      <Button variant="white" className="sb-icon">
        <Image src={searchIcon} fluid width={40} height={40} className="me-3" />
      </Button>
    </ButtonGroup>
  );
};

export default SearchComponent;
