import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./BillDetailsSwitch.css";

interface BillType {
  billType: "receipt" | "bill" | null;
  setBillType: (userType: "receipt" | "bill") => void;
}

const BillDetailsSwitch: React.FC<BillType> = ({ billType, setBillType }) => {
  const [isChecked, setIsChecked] = useState(billType === "bill");

  const handleSwitchChange = () => {
    const newBillType = isChecked ? "receipt" : "bill";
    setIsChecked(!isChecked);
    setBillType(newBillType);
  };

  return (
    <div className="text-black mt-3">
      <Row>
        <Col className="text-end">
          <span
            className="cursor-pointer"
            onClick={() => setBillType("receipt")}
          >
            Boleta
          </span>
        </Col>
        <Col className="text-center">
          <Form.Check
            type="switch"
            id="bill-switch"
            checked={isChecked}
            onChange={handleSwitchChange}
          />
        </Col>
        <Col className="text-start">
          <span className="cursor-pointer" onClick={() => setBillType("bill")}>
            Factura
          </span>
        </Col>
      </Row>
      <Row className="mt-2">
        <hr className="hr-auth" />
      </Row>
    </div>
  );
};

export default BillDetailsSwitch;
