import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";

import React from "react";

interface RemoveElementProps {
  showRemoveModal: boolean;
  handleCloseRemoveModal: (() => void) | undefined;
  modalHeader: string;
  modalBody: string;
  removeElement: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const RemoveElement: React.FC<RemoveElementProps> = ({
  showRemoveModal,
  handleCloseRemoveModal,
  modalHeader,
  modalBody,
  removeElement,
}) => {
  return (
    <Modal
      show={showRemoveModal}
      onHide={handleCloseRemoveModal}
      centered
      data-bs-theme="light"
    >
      <ModalHeader closeButton>{modalHeader}</ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
      <ModalFooter>
        <Button variant="danger" onClick={handleCloseRemoveModal}>
          Cancelar
        </Button>
        <Button variant="second" onClick={removeElement}>
          Eliminar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveElement;
