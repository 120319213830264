import Dexie, { Table } from "dexie";

interface Token {
  id?: number;
  token: string;
}

interface User {
  id?: number;
  userData: any;
}

class ChambaSiHayStorage extends Dexie {
  tokens!: Table<Token>;
  users!: Table<User>;

  constructor() {
    super("ChambaSiHayStorage");
    this.version(1).stores({
      tokens: "++id,token",
      users: "++id,userData",
    });
  }
}

const db = new ChambaSiHayStorage();
export default db;
