import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface SidebarItemProps {
  name: string;
  icon: IconDefinition;
  url: string;
  active: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  name,
  icon,
  url,
  active = false,
}) => {
  return (
    <div className="sidebar-item">
      <li className="nav-item text-white">
        <a
          href={url}
          className={`nav-link ${active ? "active" : ""}`}
          aria-current="page"
        >
          <FontAwesomeIcon icon={icon} />
          <span className="ms-2">{name}</span>
        </a>
      </li>
    </div>
  );
};

export default SidebarItem;
