import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import Loading from "../../../components/loading/Loading";
import EntityTable from "../../../components/admin/entity-table/EntityTable";
import DataTableButtons from "../../../components/admin/data-table-buttons/DataTableButtons";
import { useNotificationContext } from "../../../context/NotificationContext";
import { notificationTypes } from "../../../common/notification-types";
import { utils } from "../../../common/utils";
import { JobAdvertisementResponse } from "../../../types/response/job-advertisement-response";
import {
  getJobAdvertisementsList,
  removeAdvertisement,
} from "../../../api/job-advertisements.api";
import { debounce } from "../../../common/debounce";

const AdminAdvertisements: React.FC = () => {
  const [jobAdvertisements, setJobAdvertisements] = useState<
    JobAdvertisementResponse[]
  >([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [itemsCount, setItemsCount] = useState(1);

  const [item, setItem] = useState<number | undefined>();

  const [showRemove, setShowRemove] = useState(false);

  const take = 10;

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const showSuccessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Operación exitosa");
    setNotificationText("Se eliminó el anuncio.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("¡Ups! Algo salió mal");
    setNotificationText("Hubo un problema al eliminar el anuncio.");
    setNotificationType(notificationTypes.ERROR);
  };

  const deleteAdvertisement = async () => {
    if (item) {
      try {
        await removeAdvertisement(item);
        loadAdvertisements();
        showSuccessMessage();
      } catch (error) {
        showErrorMessage();
      }
    }
  };

  const debouncedSetFilter = useCallback(
    debounce((filterValue) => {
      setFilter(filterValue);
      setPage(1);
    }, 500),
    []
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSetFilter(value);
  };

  const loadAdvertisements = async () => {
    try {
      const response = await getJobAdvertisementsList(page, take, filter);
      const paginatedData = response.data;
      setJobAdvertisements(paginatedData.data);
      setItemsCount(paginatedData.meta.itemCount);
    } catch (error) {
      console.error("Error loading advertisements", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAdvertisements();
  }, [page, filter]);

  const renderAdvertisements = () => {
    return jobAdvertisements.map((jobAdv) => (
      <tr key={jobAdv.id}>
        <td>{jobAdv.id}</td>
        <td>{jobAdv.title}</td>
        <td>{jobAdv.description}</td>
        <td>{jobAdv.applicantsNumber}</td>
        <td>{utils.getAdvertisementStatus(jobAdv.status)}</td>
        <td>{utils.formatDate(jobAdv.createdAt)}</td>
        <td>
          <DataTableButtons
            itemId={jobAdv.id}
            handleView={(id: number) => navigate(`/admin/advertisements/${id}`)}
            handleDelete={(id: number) => {
              setItem(id);
              setShowRemove(true);
            }}
          />
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <EntityTable
          title={"Anuncios"}
          headers={[
            "ID",
            "Título",
            "Descripción",
            "Número de postulantes",
            "Estado",
            "Fecha de creación",
            "Acciones",
          ]}
          renderData={renderAdvertisements}
          handleFilterChange={handleFilterChange}
          page={page}
          setPage={setPage}
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
          deleteButton={deleteAdvertisement}
          deleteHeader={"¿Está seguro que desea eliminar el anuncio?"}
          deleteMessage={
            "Una vez eliminado, desaparecerá el anuncio para los postulantes"
          }
          showRemove={showRemove}
          setShowRemove={setShowRemove}
        />
      )}
    </Container>
  );
};

export default AdminAdvertisements;
