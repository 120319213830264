import React from "react";
import SearchBar from "../search-bar/SearchBar";
import { Col, Row } from "react-bootstrap";
import AdvancedFilters from "../advanced-filters/AdvancedFilters";
import { advancedFilterTypes } from "../../common/advanced-filter-types";
import JobAdvertisements from "../job-advertisements/JobAdvertisements";

const JobOffers: React.FC = () => {
  return (
    <div className="bg-gray-soft">
      <SearchBar
        type={advancedFilterTypes.jobOffers as "jobOffers" | "candidates"}
      />
      <Row>
        <Col xs={12} md={2}>
          <AdvancedFilters
            type={advancedFilterTypes.jobOffers as "jobOffers" | "candidates"}
          />
        </Col>
        <Col xs={12} md={10}>
          <JobAdvertisements />
        </Col>
      </Row>
    </div>
  );
};

export default JobOffers;
