import React, { useState } from "react";
import { Experience } from "../../../types/response/applicant-profile-response";
import { getMonthName } from "../../../common/months";
import { Col, Image, Row } from "react-bootstrap";

import editIcon from "../../../assets/images/icons/edit-icon.png";
import deleteIcon from "../../../assets/images/icons/delete-icon.png";
import { useNavigate } from "react-router-dom";
import RemoveElement from "../../remove-element/RemoveElement";
import { deleteApplicantExperience } from "../../../api/experience.api";
import { useNotificationContext } from "../../../context/NotificationContext";
import { notificationTypes } from "../../../common/notification-types";

interface ApplicantExperienceProps {
  experience: Experience;
  reloadExperiences: () => Promise<void>;
  nonEditable?: boolean;
}

const ExperienceItem: React.FC<ApplicantExperienceProps> = ({
  experience,
  reloadExperiences,
  nonEditable,
}) => {
  const [showRemove, setShowRemove] = useState(false);

  const navigate = useNavigate();

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const handleEditExperience = () => {
    navigate("/save-experience/" + experience.id);
  };

  const handleDeleteExperience = () => {
    setShowRemove(true);
  };

  const showSucessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Operación exitosa");
    setNotificationText("La experiencia laboral fue removida.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const deleteExperience = async () => {
    await deleteApplicantExperience(experience.id);
    setShowRemove(false);
    reloadExperiences();
    showSucessMessage();
  };

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col
            xs={10}
            md={10}
            lg={10}
            className="text-start p-0 fw-bold text-main mt-2"
          >
            <h5>{experience.role}</h5>
          </Col>
          {!nonEditable && (
            <Col xs={2} md={2} lg={2} className="text-end">
              <Row>
                <Col className="p-0">
                  <Image
                    src={editIcon}
                    alt="edit-icon"
                    width={18}
                    height={18}
                    className="cursor-pointer"
                    onClick={handleEditExperience}
                  />
                </Col>
                <Col className="p-0">
                  <Image
                    src={deleteIcon}
                    alt="delete-icon"
                    width={18}
                    height={18}
                    className="cursor-pointer"
                    onClick={handleDeleteExperience}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <p className="fw-bold mb-0">{experience.companyName}</p>
        <p className="mb-0 text-gray">
          {getMonthName(experience.monthFrom)} {experience.yearFrom} -{" "}
          {experience.isCurrent
            ? "Al presente"
            : getMonthName(experience.monthTo) + " " + experience.yearTo}
        </p>
        <p className="item-description">{experience.description}</p>
      </div>
      <RemoveElement
        showRemoveModal={showRemove}
        handleCloseRemoveModal={() => setShowRemove(false)}
        modalHeader={"¿Está seguro?"}
        modalBody={
          "Una vez eliminada la experiencia laboral, la información desaparecerá para los reclutadores"
        }
        removeElement={deleteExperience}
      />
    </React.Fragment>
  );
};

export default ExperienceItem;
