import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import Loading from "../../../components/loading/Loading";
import EntityTable from "../../../components/admin/entity-table/EntityTable";
import DataTableButtons from "../../../components/admin/data-table-buttons/DataTableButtons";
import { CareerResponse } from "../../../types/response/career-response";
import { getCareersList, removeCareer } from "../../../api/careers.api";
import { useNotificationContext } from "../../../context/NotificationContext";
import { notificationTypes } from "../../../common/notification-types";
import { debounce } from "../../../common/debounce";

const AdminCareers: React.FC = () => {
  const [careers, setCareers] = useState<CareerResponse[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [itemsCount, setItemsCount] = useState(1);

  const [item, setItem] = useState<number | undefined>();

  const [showRemove, setShowRemove] = useState(false);

  const take = 10;

  const {
    setShowNotificationModal,
    setNotificationTitle,
    setNotificationText,
    setNotificationType,
  } = useNotificationContext();

  const showSuccessMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("Operación exitosa");
    setNotificationText("Se eliminó la carrera.");
    setNotificationType(notificationTypes.SUCCESS);
  };

  const showErrorMessage = () => {
    setShowNotificationModal(true);
    setNotificationTitle("¡Ups! Algo salió mal");
    setNotificationText("Hubo un problema al eliminar la carrera.");
    setNotificationType(notificationTypes.ERROR);
  };

  const debouncedSetFilter = useCallback(
    debounce((filterValue) => {
      setFilter(filterValue);
      setPage(1);
    }, 500),
    []
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSetFilter(value);
  };

  const deleteCareer = async () => {
    if (item) {
      try {
        await removeCareer(item);
        if (id) loadCareers(id);
        showSuccessMessage();
      } catch (error) {
        showErrorMessage();
      }
    }
  };

  const { id } = useParams();

  const loadCareers = async (id: string) => {
    try {
      const response = await getCareersList(parseInt(id), page, take, filter);
      const paginatedData = response.data;
      setCareers(paginatedData.data);
      setItemsCount(paginatedData.meta.itemCount);
    } catch (error) {
      console.error("Error loading careers", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadCareers(id);
    }
  }, [id, page, filter]);

  const renderIndustries = () => {
    return careers.map((career) => (
      <tr key={career.id}>
        <td>{career.id}</td>
        <td>{career.name}</td>
        <td>
          <DataTableButtons
            itemId={career.id}
            handleView={(id: number) =>
              navigate(`/admin/industries/careers/edit/${id}`)
            }
            handleDelete={(id: number) => {
              setItem(id);
              setShowRemove(true);
            }}
          />
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <EntityTable
          title={"Carreras"}
          createLabel={"Nueva carrera"}
          createClick={() => navigate(`/admin/industries/careers/create/${id}`)}
          headers={["ID", "Nombre", "Acciones"]}
          renderData={renderIndustries}
          handleFilterChange={handleFilterChange}
          page={page}
          setPage={setPage}
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
          deleteButton={deleteCareer}
          deleteHeader={"¿Está seguro que desea eliminar la careera?"}
          deleteMessage={
            "Una vez eliminada, desaparecerá la opción de escogerla para los usuarios"
          }
          showRemove={showRemove}
          setShowRemove={setShowRemove}
        />
      )}
    </Container>
  );
};

export default AdminCareers;
