import React from "react";
import Slogan from "../../components/home/slogan/Slogan";
import Steps from "../../components/home/steps/Steps";
import Statistics from "../../components/home/statistics/Statistics";
import Catalog from "../../components/home/catalog/Catalog";

const Home: React.FC = () => {
  return (
    <React.Fragment>
      <Slogan />
      <Steps />
      <Statistics />
      <Catalog />
    </React.Fragment>
  );
};

export default Home;
