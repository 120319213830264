import { Image, Row, Spinner } from "react-bootstrap";
import googleIcon from "../../../../assets/images/icons/google-icon.png";

interface GoogleRegisterProps {
  authType: "login" | "register" | null;
  handleGoogleAuth: any;
  loading?: boolean;
}

const GoogleRegister: React.FC<GoogleRegisterProps> = ({
  authType,
  handleGoogleAuth,
  loading,
}) => {
  return (
    <>
      <div className="text-white mt-4">
        <hr className="hr-auth" />
        <Row className="justify-content-center mt-4">
          {authType === "login" ? "O inicia sesión con" : "O regístrate con"}
        </Row>
        <Row className="justify-content-center m-4">
          <div onClick={handleGoogleAuth}>
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              <Image
                className="cursor-pointer"
                src={googleIcon}
                width={40}
                height={40}
              />
            )}
          </div>
        </Row>
      </div>
    </>
  );
};

export default GoogleRegister;
