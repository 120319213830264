import React, { useState } from "react";
import { ApplicationUsersInfo } from "../../types/response/application-user-info";
import applicantAnonymous from "../../assets/images/profile/applicant-anonymous.png";
import { Col, Image, Row } from "react-bootstrap";
import BadgeSeniority from "../company/badge-seniority/BadgeSeniority";
import { utils } from "../../common/utils";
import "./AdvertisementApplicationItem.css";
import SavedIcon from "../saved-icon/SavedIcon";
import { toggleSaveApplicant } from "../../api/application.api";
import Loading from "../loading/Loading";

interface AdvertisementApplicationItemProps {
  item: ApplicationUsersInfo;
  onSave: (applicationId: number) => void;
}

const AdvertisementApplicationItem: React.FC<
  AdvertisementApplicationItemProps
> = ({ item, onSave }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSaveApplication = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (loading) return;
    setLoading(true);
    await onSave(item.applicationId);
    setLoading(false);
  };

  return (
    <Col xs={12} sm={6} md={4} lg={4} className="mb-4">
      <div
        className="application-card p-4"
        onClick={() =>
          window.open(`/profile-view/application/${item.applicationId}`)
        }
      >
        <Row className="p-0">
          <Col xs={6} sm={6} md={6} lg={6} className="p-0">
            <div className="p-0">
              {item.userProfileImage === "" ||
              item.userProfileImage === null ? (
                <Image
                  src={applicantAnonymous}
                  width={120}
                  height={120}
                  rounded
                />
              ) : (
                <Image
                  src={item.userProfileImage}
                  width={120}
                  height={120}
                  rounded
                />
              )}
            </div>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} className="p-0 text-end">
            <div className="color-main me-1">
              <SavedIcon
                active={item.saved}
                onClick={handleSaveApplication}
                loading={loading}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-2 p-0">
          <h5 className="p-0 font-bold applicant-name">
            <span>{item.userFullname}</span>
          </h5>
          <h5 className="p-0">
            ({utils.getSalaryWithCurrency(item.expectedSalary)})
          </h5>
        </Row>
        <Row className="mt-1 p-0">
          <p className="p-0 color-main mb-0">{item.userCareer}</p>
        </Row>
        <Row className="mt-1 p-0">
          <p className="p-0 mb-0 application-description">
            {item.userDescription}
          </p>
        </Row>
        <Row className="mt-2">
          <BadgeSeniority
            seniority={
              item.userSeniority as "student" | "excelence" | "professional"
            }
          />
        </Row>
      </div>
    </Col>
  );
};

export default AdvertisementApplicationItem;
