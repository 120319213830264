import React, { useEffect, useState } from "react";
import { Card, Col, Form, Image, Row } from "react-bootstrap";
import FormButton from "../form-button/FormButton";
import { useAuthenticate } from "../../hooks/useAuthenticate";
import "./AuthForm.css";
import logo from "../../assets/images/logo/logo.png";
import logoPrimary from "../../assets/images/logo/logo-primary.png";
import AuthUserFormSwitch from "./auth-user-form-switch/AuthUserFormSwitch";
import AuthTypeFormSwitch from "./auth-type-form-switch/AuthTypeFormSwitch";
import LoginForm from "./login-form/LoginForm";
import ApplicantRegisterForm from "./register-form/applicant/ApplicantRegisterForm";
import CompanyRegisterForm from "./register-form/company/CompanyRegisterForm";
import PrivacyPolicy from "./privacy-policy/PrivacyPolicy";
import GoogleRegister from "./register-form/applicant/GoogleRegister";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import FormResult from "../form-result/FormResult";
import ForgotPasswordForm from "./forgot-password-form/ForgotPasswordForm";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import AcceptsAdvertisement from "./accepts-advertisement/AcceptsAdvertisement";
import ApplicantCompleteInformationForm from "./register-form/applicant/ApplicantCompleteInformationForm";

const AuthForm: React.FC = () => {
  const location = useLocation();

  const [userType, setUserType] = useState<"applicant" | "company">(
    "applicant"
  );
  const [authType, setAuthType] = useState<"login" | "register">("login");

  const { user } = useAuthContext();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const [careerId, setCareerId] = useState<number>();
  const [seniorityId, setSeniorityId] = useState<number>();
  const [district, setDistrict] = useState<string>("");

  const [companyName, setCompanyName] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [taxStatus, setTaxStatus] = useState<string>("");
  const [document, setDocument] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");

  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [acceptsAdvertising, setAcceptsAdvertising] = useState<boolean>(false);

  const [forgotPassword, setForgotPassword] = useState<boolean>(false);

  const [registerStep, setRegisterStep] = useState<number>(1);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      user.role === "company" ? navigate("/company") : navigate("/applicant");
    }
  }, [user, navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userTypeParam = queryParams.get("userType");
    const authTypeParam = queryParams.get("authType");
    if (userTypeParam === "applicant" || userTypeParam === "company") {
      setUserType(userTypeParam);
    }
    if (authTypeParam === "login" || authTypeParam === "register") {
      setAuthType(authTypeParam);
    }
  }, [location]);

  const {
    login,
    register,
    validateGoogleAuth,
    loginCompany,
    registerCompany,
    loading,
    loadingGoogle,
    error,
    setError,
  } = useAuthenticate();

  const {
    sendRecoveryEmail,
    loading: loadingForgotPassword,
    message,
    setMessage,
    type,
  } = useForgotPassword();

  const handleApplicantLogin = async () => {
    await login({ email, password });
  };

  const handleNextRegisterStep = () => {
    password !== confirmPassword
      ? setError("Las contraseñas no coinciden")
      : setRegisterStep(2);
  };

  const handleApplicantRegister = async () => {
    await register({
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      careerId: careerId ?? 2,
      seniorityId: seniorityId ?? 1,
      district,
      phoneNumber,
      acceptsAdvertising,
      authStrategy: "username_password",
    });
  };

  const handleCompanyLogin = async () => {
    await loginCompany({ email, password });
  };

  const handleCompanyRegister = async () => {
    await registerCompany({
      address,
      email,
      industry,
      name: companyName,
      businessName,
      password,
      confirmPassword,
      phone: phoneNumber,
      postalCode,
      ruc: document,
      taxStatus,
      acceptsAdvertising,
    });
  };

  const handleForgotPassword = async () => {
    await sendRecoveryEmail({ email, userType });
  };

  const handleGoogleAuth = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res) => res.data);

      await validateGoogleAuth({
        token: access_token,
        email: userInfo.email,
        name: userInfo.name,
      });

      if (authType === "register") navigate("/applicant/information");
    },
    onError: (e) => console.log("Login Failed", e),
    scope: "openid profile email",
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const actionType = forgotPassword
      ? "forgotPassword"
      : userType === "applicant" &&
        authType === "register" &&
        registerStep === 1
      ? "next"
      : authType;

    if (!userType || !authType) return;

    const actionMap = {
      applicant: {
        login: handleApplicantLogin,
        register: handleApplicantRegister,
        next: handleNextRegisterStep,
        forgotPassword: handleForgotPassword,
      },
      company: {
        login: handleCompanyLogin,
        register: handleCompanyRegister,
        next: () => {},
        forgotPassword: handleForgotPassword,
      },
    };

    const action = actionMap[userType][actionType];
    if (action) {
      await action();
    }
  };

  return (
    <section className={`${userType === "company" ? "section-company" : ""}`}>
      <Row className="justify-content-center p-4">
        <Col xs={12} md={6} lg={6}>
          <Card
            className={`${
              userType === "applicant" ? "card-applicant" : "card-company"
            } shadow-sm mb-4`}
          >
            <Card.Body>
              <Form
                onSubmit={onSubmit}
                className="justify-content-center pt-3 text-center"
              >
                {error && (
                  <Row className="p-3">
                    <FormResult
                      message={error ?? ""}
                      setMessage={setError || (() => {})}
                      type={"error"}
                    />
                  </Row>
                )}
                <Image
                  alt="logo"
                  src={userType === "applicant" ? logo : logoPrimary}
                  width={133}
                  height={47}
                />
                <AuthUserFormSwitch
                  userType={userType}
                  setUserType={setUserType}
                />
                {!forgotPassword ? (
                  <>
                    <AuthTypeFormSwitch
                      userType={userType}
                      authType={authType}
                      setAuthType={setAuthType}
                    />
                    {authType === "login" ? (
                      <LoginForm
                        email={email}
                        setEmail={(e) => setEmail(e.target.value)}
                        password={password}
                        setPassword={(e) => setPassword(e.target.value)}
                      />
                    ) : userType === "applicant" ? (
                      <React.Fragment>
                        {registerStep === 1 ? (
                          <ApplicantRegisterForm
                            firstName={firstName}
                            setFirstName={(e) => setFirstName(e.target.value)}
                            lastName={lastName}
                            setLastName={(e) => setLastName(e.target.value)}
                            email={email}
                            setEmail={(e) => setEmail(e.target.value)}
                            password={password}
                            setPassword={(e) => setPassword(e.target.value)}
                            confirmPassword={confirmPassword}
                            setConfirmPassword={(e) =>
                              setConfirmPassword(e.target.value)
                            }
                          />
                        ) : (
                          <ApplicantCompleteInformationForm
                            careerId={careerId}
                            setCareerId={setCareerId}
                            seniorityId={seniorityId}
                            setSeniorityId={(e) =>
                              setSeniorityId(parseInt(e.target.value))
                            }
                            district={district}
                            setDistrict={setDistrict}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={(e) =>
                              setPhoneNumber(e.target.value)
                            }
                          />
                        )}
                        {registerStep === 2 && (
                          <>
                            <PrivacyPolicy
                              checked={privacyPolicy}
                              onChange={(e) =>
                                setPrivacyPolicy(e.target.checked)
                              }
                              userType={userType}
                            />
                            <AcceptsAdvertisement
                              checked={acceptsAdvertising}
                              onChange={(e) =>
                                setAcceptsAdvertising(e.target.checked)
                              }
                              userType={userType}
                            />
                          </>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ApplicantRegisterForm
                          firstName={firstName}
                          setFirstName={(e) => setFirstName(e.target.value)}
                          lastName={lastName}
                          setLastName={(e) => setLastName(e.target.value)}
                          email={email}
                          setEmail={(e) => setEmail(e.target.value)}
                          password={password}
                          setPassword={(e) => setPassword(e.target.value)}
                          confirmPassword={confirmPassword}
                          setConfirmPassword={(e) =>
                            setConfirmPassword(e.target.value)
                          }
                        />
                        <CompanyRegisterForm
                          companyName={companyName}
                          setCompanyName={(e) => setCompanyName(e.target.value)}
                          businessName={businessName}
                          setBusinessName={(e) =>
                            setBusinessName(e.target.value)
                          }
                          taxStatus={taxStatus}
                          setTaxStatus={(e) => setTaxStatus(e.target.value)}
                          document={document}
                          setDocument={(e) => setDocument(e.target.value)}
                          address={address}
                          setAddress={(e) => setAddress(e.target.value)}
                          postalCode={postalCode}
                          setPostalCode={(e) => setPostalCode(e.target.value)}
                          phoneNumber={phoneNumber}
                          setPhoneNumber={(e) => setPhoneNumber(e.target.value)}
                          industry={industry}
                          setIndustry={(e) => setIndustry(e.target.value)}
                        />
                        <PrivacyPolicy
                          checked={privacyPolicy}
                          onChange={(e) => setPrivacyPolicy(e.target.checked)}
                          userType={userType}
                        />
                        <AcceptsAdvertisement
                          checked={acceptsAdvertising}
                          onChange={(e) =>
                            setAcceptsAdvertising(e.target.checked)
                          }
                          userType={userType}
                        />
                      </React.Fragment>
                    )}
                    <FormButton
                      type="submit"
                      loading={loading}
                      buttonText={
                        authType === "login"
                          ? "Inicia sesión"
                          : userType === "applicant" && registerStep === 1
                          ? "Siguiente"
                          : "Regístrate"
                      }
                      variant="second"
                      disabled={
                        !(userType === "applicant" && registerStep === 1) &&
                        !privacyPolicy &&
                        authType === "register"
                      }
                    />
                    {authType === "login" && (
                      <div
                        className={`${
                          userType === "applicant" ? "text-white" : ""
                        } cursor-pointer action-label mt-3`}
                        onClick={() => setForgotPassword(true)}
                      >
                        Recuperar contraseña
                      </div>
                    )}
                    {userType === "applicant" && (
                      <GoogleRegister
                        authType={authType}
                        handleGoogleAuth={handleGoogleAuth}
                        loading={loadingGoogle}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <ForgotPasswordForm
                      email={email}
                      setEmail={(e) => setEmail(e.target.value)}
                      message={message}
                      setMessage={setMessage}
                      type={type}
                      userType={userType}
                    />
                    <FormButton
                      type="submit"
                      loading={loadingForgotPassword}
                      buttonText={"Recuperar contraseña"}
                      variant="second"
                    />
                    <div
                      className={`${
                        userType === "applicant" ? "text-white" : ""
                      } cursor-pointer action-label mt-3`}
                      onClick={() => setForgotPassword(false)}
                    >
                      Volver a inicio de sesión
                    </div>
                  </>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default AuthForm;
