import { Row } from "react-bootstrap";
import DotsDecorator from "../../home/dots-decorator/DotsDecorator";
import { useEffect, useState } from "react";
import { getCompanyApplicationsSummarized } from "../../../api/applicant.api";
import Loading from "../../loading/Loading";
import ApplicationItem from "../../application-item/ApplicationItem";
import { PaginationControl } from "react-bootstrap-pagination-control";

interface CompanyApplicationSummarized {
  id: number;
  title: string;
  daysAgo: number;
  companyName: string;
  applicantName: string;
}

const MyApplicants: React.FC = () => {
  const [companyApplications, setCompanyApplications] = useState<
    CompanyApplicationSummarized[]
  >([]);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);

  const take = 5;

  useEffect(() => {
    const loadCompanyApplicationsSummarized = async () => {
      try {
        const response = await getCompanyApplicationsSummarized(page, take);
        const paginatedData = response.data;
        setCompanyApplications(paginatedData.data);
        setItemsCount(paginatedData.meta.itemCount);
      } catch (error) {
        console.error("Error loading company applications summarized", error);
      } finally {
        setLoading(false);
      }
    };

    loadCompanyApplicationsSummarized();
  }, [page]);

  return (
    <div className="content-card p-4 mt-4 mb-4">
      <Row className="justify-content-center mb-4">
        <h4 className="text-center">Últimos postulantes</h4>
        <DotsDecorator width={10} height={10} center={true} />
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <>
          {companyApplications.length === 0 ? (
            <div className="content-card mt-4 p-3 text-center">
              Aún no cuentas con postulantes
            </div>
          ) : (
            <>
              {companyApplications.map((item) => (
                <ApplicationItem
                  key={item.id}
                  id={item.id}
                  label={`${item.applicantName} ha postulado a "${item.title}"`}
                  days={item.daysAgo}
                />
              ))}
              <div className="mt-4">
                <PaginationControl
                  page={page}
                  total={itemsCount}
                  limit={take}
                  changePage={(page) => {
                    setPage(page);
                  }}
                  ellipsis={1}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MyApplicants;
