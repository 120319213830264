import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import { getJobAdvertisement } from "../../../api/job-advertisements.api";
import AdminAdvertisementForm from "./AdminAdvertisementForm";
import { JobAdvertisementResponse } from "../../../types/response/job-advertisement-response";

const AdminAdvertisementsSave: React.FC = () => {
  const [advertisement, setAdvertisement] =
    useState<JobAdvertisementResponse>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadAdvertisement = async (id: string) => {
      try {
        const advertisementData = (await getJobAdvertisement(parseInt(id)))
          .data;
        setAdvertisement(advertisementData);
      } catch (error) {
        console.error("Error loading advertisement:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadAdvertisement(id);
    }
  }, [id]);

  return loading ? (
    <Loading />
  ) : (
    advertisement && <AdminAdvertisementForm item={advertisement} />
  );
};

export default AdminAdvertisementsSave;
