import React, { ReactNode } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { Navigate } from "react-router-dom";
import Loading from "../components/loading/Loading";

interface ProtectedProps {
  children: ReactNode;
  roles: string[];
}

const Protected: React.FC<ProtectedProps> = ({ children, roles }) => {
  const { user, loading } = useAuthContext();

  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "70vh" }}
      >
        <Loading />
      </div>
    );
  } else if (user) {
    if (roles.includes(user.role)) {
      return <>{children}</>;
    } else {
      return <Navigate to="/home" />;
    }
  } else {
    return <Navigate to="/auth" />;
  }
};

export default Protected;
