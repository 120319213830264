import React from "react";
import { Card, Col, Form, Image, Row } from "react-bootstrap";
import FormResult from "../form-result/FormResult";
import FormButton from "../form-button/FormButton";
import goBackIcon from "../../assets/images/icons/go-back-icon.png";
import { useNavigate } from "react-router-dom";

interface GenericFormProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  formTitle: string;
  message?: string;
  setMessage?: React.Dispatch<React.SetStateAction<string>>;
  type?: string;
  loading: boolean;
  buttonText: string;
  children: React.ReactNode;
  readOnly?: boolean;
  guidance?: string;
}

const GenericForm: React.FC<GenericFormProps> = ({
  handleSubmit,
  formTitle,
  message,
  setMessage,
  type,
  loading,
  buttonText,
  children,
  readOnly,
  guidance,
}) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Row className="justify-content-center p-4">
        <Col xs={12} md={6} lg={6}>
          <Card className="bg-main">
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="text-center">
                  <h4 className="form-title-font pt-4">{formTitle}</h4>
                  <Image
                    className="go-back-form-icon cursor-pointer"
                    alt="go-back-icon"
                    src={goBackIcon}
                    width={40}
                    height={40}
                    onClick={() => navigate(-1)}
                  />
                </div>
                {message && (
                  <Row className="p-3">
                    <FormResult
                      message={message ?? ""}
                      setMessage={setMessage || (() => {})}
                      type={type ?? ""}
                    />
                  </Row>
                )}
                {guidance && (
                  <Row className="p-3 text-white">
                    <h6 className="text-justify">{guidance}</h6>
                  </Row>
                )}
                <Row className="pt-4">{children}</Row>
                {!readOnly && (
                  <Row className="justify-content-center">
                    <FormButton
                      type="submit"
                      loading={loading}
                      buttonText={buttonText}
                      variant="second"
                    />
                  </Row>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default GenericForm;
