import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import FormInput from "../../form-input/FormInput";
import { getProfile } from "../../../api/applicant.api";
import Loading from "../../loading/Loading";
import { useUpdateInformation } from "../../../hooks/useUpdateInformation";
import { getCareers } from "../../../api/careers.api";
import FormSelect from "../../form-select/FormSelect";
import { seniorities } from "../../../common/seniorities";
import FormAutocomplete from "../../form-autocomplete/FormAutocomplete";
import { districts } from "../../../common/districts";
import GenericForm from "../../generic-form/GenericForm";
import { Career } from "../../../types/response/career";

const ApplicantInformation: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [careerId, setCareerId] = useState(1);
  const [careerName, setCareerName] = useState("");
  const [seniorityId, setSeniorityId] = useState(1);
  const [desiredSalary, setDesiredSalary] = useState("");
  const [file, setFile] = useState<File>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  const [careersOptions, setCareersOptions] = useState<Career[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const { updateInformation, loading, message, setMessage, type } =
    useUpdateInformation();

  const loadProfileInformation = async () => {
    try {
      const profileData = (await getProfile()).data;
      setFirstName(profileData.firstName);
      setLastName(profileData.lastName);
      setEmail(profileData.email);
      setDescription(profileData.description);
      setAddress(profileData.address);
      setDistrict(profileData.district);
      setPhoneNumber(profileData.phoneNumber);
      setCareerName(profileData.career);
      setCareerId(profileData.careerId);
      setSeniorityId(profileData.seniorityId);
      setDesiredSalary(profileData.desiredSalary);
    } catch (error) {
      console.error("Error loading profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCareers = async () => {
    try {
      const response = await getCareers();
      const careers = response.data;
      const formattedCareers = careers.map((career: any) => ({
        id: career.id,
        label: career.name,
      }));
      setCareersOptions(formattedCareers);
    } catch (error) {
      console.error("Error loading careers", error);
    }
  };

  useEffect(() => {
    loadProfileInformation();
    loadCareers();
  }, []);

  const districtOptions = districts.filter((x: any) => x.provinceId === 128);

  const handleSave = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    const applicant = {
      firstName,
      lastName,
      careerId,
      seniorityId,
      phoneNumber,
      address,
      district,
      description,
      desiredSalary,
      newCV: file ? true : false,
    };
    if (file) formData.append("file", file);
    formData.append("applicant", JSON.stringify(applicant));
    await updateInformation(formData);
    window.scrollTo(0, 0);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <GenericForm
      handleSubmit={handleSave}
      formTitle={"Editar información personal"}
      loading={loading}
      buttonText={"Actualizar"}
      message={message}
      setMessage={setMessage}
      type={type}
    >
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Nombre"}
          type={"text"}
          name={"firstname"}
          maxLength={100}
          required={true}
          value={firstName}
          placeholder={""}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Apellido"}
          type={"text"}
          name={"lastname"}
          maxLength={100}
          required={true}
          value={lastName}
          placeholder={""}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Correo electrónico"}
          type={"email"}
          name={"email"}
          maxLength={100}
          required={true}
          value={email}
          placeholder={""}
          onChange={(e) => setEmail(e.target.value)}
          disabled={true}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Teléfono"}
          type={"text"}
          name={"phoneNumber"}
          maxLength={9}
          minLength={9}
          required={true}
          value={phoneNumber}
          placeholder={""}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormAutocomplete
          id="career"
          label="Carrera"
          options={careersOptions}
          initialValue={careerName}
          onChange={(selected: any[]) => {
            if (selected.length > 0) setCareerId(selected[0].id);
          }}
          required={true}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormSelect
          label={"Categoría de perfil"}
          name={"Experiencia"}
          required={true}
          value={seniorityId}
          options={seniorities}
          onChange={(e) => setSeniorityId(parseInt(e.target.value))}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Dirección"}
          type={"text"}
          name={"address"}
          maxLength={100}
          required={false}
          value={address}
          placeholder={""}
          onChange={(e) => setAddress(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormAutocomplete
          id="district"
          label="Distrito"
          options={districtOptions}
          initialValue={district}
          onChange={(selected: any[]) => {
            if (selected.length > 0) setDistrict(selected[0].label);
          }}
          required={true}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Salario pretendido"}
          type={"number"}
          name={"desiredSalary"}
          maxLength={100}
          required={false}
          value={desiredSalary}
          placeholder={""}
          onChange={(e) => setDesiredSalary(e.target.value)}
        />
      </Col>
      <Col xs={12} md={6} lg={6} className="mb-4">
        <FormInput
          label={"Cambiar currículum"}
          type={"file"}
          name={""}
          maxLength={100}
          required={false}
          value={file}
          placeholder={""}
          accept={"application/pdf"}
          onChange={handleFileChange}
        />
      </Col>
      <Col xs={12} md={12} lg={12} className="mb-4">
        <FormInput
          label={"Descripción"}
          type={"textarea"}
          name={"description"}
          maxLength={1000}
          required={false}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={""}
        />
      </Col>
    </GenericForm>
  );
};

export default ApplicantInformation;
