import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUpgradeRequest } from "../../../api/upgrade-requests";
import Loading from "../../../components/loading/Loading";
import AdminUpgradeRequestsForm from "./AdminUpgradeRequestsForm";
import { UpgradeRequest } from "../../../types/response/upgrade-request";

const AdminUpgradeRequestsSave: React.FC = () => {
  const [upgradeRequest, setUpgradeRequest] = useState<UpgradeRequest>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadUpgradeRequest = async (id: string) => {
      try {
        const upgradeRequestData = (await getUpgradeRequest(parseInt(id))).data;
        setUpgradeRequest(upgradeRequestData);
      } catch (error) {
        console.error("Error loading upgrade request:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadUpgradeRequest(id);
    }
  }, [id]);

  return loading ? (
    <Loading />
  ) : (
    upgradeRequest && <AdminUpgradeRequestsForm item={upgradeRequest} />
  );
};

export default AdminUpgradeRequestsSave;
