import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import AdminNewsForm from "./AdminNewsForm";
import { getNew } from "../../../api/news.api";
import { New } from "../../../types/response/new";

const AdminNewsSave: React.FC = () => {
  const [news, setNews] = useState<New>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const loadNew = async (id: string) => {
      try {
        const newsData = (await getNew(parseInt(id))).data;
        setNews(newsData);
      } catch (error) {
        console.error("Error loading news:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      loadNew(id);
    }
  }, [id]);

  return loading ? <Loading /> : news && <AdminNewsForm item={news} />;
};

export default AdminNewsSave;
