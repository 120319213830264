import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getToken } from "../service/token-service";

interface DefaultConfig extends AxiosRequestConfig {
  noAuthToken?: boolean;
  isFormData?: boolean;
}

const defaultConfig: DefaultConfig = {
  responseType: "json",
};

export const APIMiddleware = {
  async get(
    url: string,
    config: DefaultConfig = defaultConfig
  ): Promise<AxiosResponse> {
    const { params, responseType, noAuthToken } = config;
    const headers = noAuthToken
      ? { "Content-Type": "application/json" }
      : await getHeaders();
    try {
      return axios.get(url, { params, headers, responseType });
    } catch (error) {
      console.error("Error in GET request:", error);
      throw error;
    }
  },

  async post(
    url: string,
    config: DefaultConfig & { data?: any } = defaultConfig
  ): Promise<any> {
    const { params, responseType, data, noAuthToken, isFormData } = config;
    const headers = noAuthToken
      ? { "Content-Type": "application/json" }
      : await getHeaders(isFormData);

    try {
      const response = await axios.post(url, data, {
        params,
        headers,
        responseType,
      });
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
      throw error;
    }
  },

  async put(
    url: string,
    config: DefaultConfig & { data?: any } = defaultConfig
  ): Promise<any> {
    const { params, responseType, data, isFormData } = config;
    const headers = await getHeaders(isFormData);
    try {
      const response = await axios.put(url, data, {
        params,
        headers,
        responseType,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error;
    }
  },

  async patch(
    url: string,
    config: DefaultConfig & { data?: any } = defaultConfig
  ): Promise<any> {
    const { params, responseType, data, isFormData } = config;
    const headers = await getHeaders(isFormData);
    try {
      const response = await axios.patch(url, data, {
        params,
        headers,
        responseType,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PATCH request:", error);
      throw error;
    }
  },

  async delete(
    url: string,
    config: DefaultConfig = defaultConfig
  ): Promise<AxiosResponse> {
    const { params, responseType, noAuthToken } = config;
    const headers = noAuthToken
      ? { "Content-Type": "application/json" }
      : await getHeaders();
    try {
      return axios.delete(url, { params, headers, responseType });
    } catch (error) {
      console.error("Error in DELETE request:", error);
      throw error;
    }
  },
};

async function getHeaders(
  isFormData: boolean = false
): Promise<Record<string, string>> {
  try {
    const token = await getToken();
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      };
    } else {
      throw new Error("Token not available.");
    }
  } catch (error) {
    console.error("Error fetching token:", error);
    throw error;
  }
}
