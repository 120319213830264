import React, { useEffect, useState } from "react";
import {
  Experience,
  Language,
  Study,
} from "../../types/response/applicant-profile-response";
import {
  getApplicantExperiences,
  getApplicantLanguages,
  getApplicantStudies,
} from "../../api/application.api";
import { Row } from "react-bootstrap";
import DotsDecorator from "../home/dots-decorator/DotsDecorator";
import ApplicantExperience from "../applicant/applicant-experience/ApplicantExperience";
import ApplicantStudies from "../applicant/applicant-studies/ApplicantStudies";
import ApplicantLanguages from "../applicant/applicant-languages/ApplicantLanguages";
import {
  getProfileViewStudies,
  getProfileViewExperiences,
  getProfileViewLanguages,
} from "../../api/profile-view.api";

interface ApplicantProfileCurriculumProps {
  applicationId?: number;
  applicantId?: number;
}

const ApplicantProfileCurriculum: React.FC<ApplicantProfileCurriculumProps> = ({
  applicationId,
  applicantId,
}) => {
  const [loading, setLoading] = useState(true);

  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [studies, setStudies] = useState<Study[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);

  const loadExperiences = async () => {
    try {
      const experienceData = applicationId
        ? (await getApplicantExperiences(applicationId)).data
        : applicantId
        ? (await getProfileViewExperiences(applicantId)).data
        : [];
      setExperiences(experienceData);
    } catch (error) {
      console.error("Error loading experiences:", error);
    }
  };

  const loadStudies = async () => {
    try {
      const studiesData = applicationId
        ? (await getApplicantStudies(applicationId)).data
        : applicantId
        ? (await getProfileViewStudies(applicantId)).data
        : [];
      setStudies(studiesData);
    } catch (error) {
      console.error("Error loading studies:", error);
    }
  };

  const loadLanguages = async () => {
    try {
      const languagesData = applicationId
        ? (await getApplicantLanguages(applicationId)).data
        : applicantId
        ? (await getProfileViewLanguages(applicantId)).data
        : [];
      setLanguages(languagesData);
    } catch (error) {
      console.error("Error loading languages:", error);
    }
  };

  const loadData = async () => {
    setLoading(true);
    await Promise.all([loadExperiences(), loadStudies(), loadLanguages()]);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="content-card mb-4 p-4">
      <Row className="justify-content-center">
        <h4 className="text-center">Currículum del postulante</h4>
        <DotsDecorator width={10} height={10} center={true} />
      </Row>
      <div className="pt-4">
        <ApplicantExperience
          experiences={experiences}
          reloadExperiences={loadExperiences}
          loading={loading}
          nonEditable={true}
        />
        <ApplicantStudies
          studies={studies}
          reloadStudies={loadStudies}
          loading={loading}
          nonEditable={true}
        />
        <ApplicantLanguages
          languages={languages}
          reloadLanguages={loadLanguages}
          loading={loading}
          nonEditable={true}
        />
      </div>
    </div>
  );
};

export default ApplicantProfileCurriculum;
