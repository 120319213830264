import { Option } from "react-bootstrap-typeahead/types/types";

export const districts: Option[] = [
  // DISTRITOS DE AMAZONAS - Provincia de Chachapoyas
  { id: 1, label: "Chachapoyas", provinceId: 1 },
  { id: 2, label: "Asunción", provinceId: 1 },
  { id: 3, label: "Balsas", provinceId: 1 },
  { id: 4, label: "Cheto", provinceId: 1 },
  { id: 5, label: "Chiliquín", provinceId: 1 },
  { id: 6, label: "Chuquibamba", provinceId: 1 },
  { id: 7, label: "Granada", provinceId: 1 },
  { id: 8, label: "Huancas", provinceId: 1 },
  { id: 9, label: "Jalca Grande", provinceId: 1 },
  { id: 10, label: "Leimebamba", provinceId: 1 },
  { id: 11, label: "Levanto", provinceId: 1 },
  { id: 12, label: "Magdalena", provinceId: 1 },
  { id: 13, label: "Mariscal Castilla", provinceId: 1 },
  { id: 14, label: "Molinopampa", provinceId: 1 },
  { id: 15, label: "Montevideo", provinceId: 1 },
  { id: 16, label: "Olleros", provinceId: 1 },
  { id: 17, label: "Quinjalca", provinceId: 1 },
  { id: 18, label: "San Francisco de Daguas", provinceId: 1 },
  { id: 19, label: "San Isidro de Maino", provinceId: 1 },
  { id: 20, label: "Soloco", provinceId: 1 },
  { id: 21, label: "Sonche", provinceId: 1 },

  // DISTRITOS DE AMAZONAS - Provincia de Bagua
  { id: 22, label: "Bagua", provinceId: 2 },
  { id: 23, label: "La Peca", provinceId: 2 },
  { id: 24, label: "Aramango", provinceId: 2 },
  { id: 25, label: "Copallín", provinceId: 2 },
  { id: 26, label: "El Parco", provinceId: 2 },
  { id: 27, label: "Imaza", provinceId: 2 },

  // DISTRITOS DE AMAZONAS - Provincia de Bongará
  { id: 28, label: "Jumbilla", provinceId: 3 },
  { id: 29, label: "Chisquilla", provinceId: 3 },
  { id: 30, label: "Churuja", provinceId: 3 },
  { id: 31, label: "Corosha", provinceId: 3 },
  { id: 32, label: "Cuispes", provinceId: 3 },
  { id: 33, label: "Florida", provinceId: 3 },
  { id: 34, label: "Jazán", provinceId: 3 },
  { id: 35, label: "Recta", provinceId: 3 },
  { id: 36, label: "San Carlos", provinceId: 3 },
  { id: 37, label: "Shipasbamba", provinceId: 3 },
  { id: 38, label: "Valera", provinceId: 3 },
  { id: 39, label: "Yambrasbamba", provinceId: 3 },

  // DISTRITOS DE AMAZONAS - Provincia de Condorcanqui
  { id: 40, label: "El Cenepa", provinceId: 4 },
  { id: 41, label: "Nieva", provinceId: 4 },
  { id: 42, label: "Río Santiago", provinceId: 4 },

  // DISTRITOS DE AMAZONAS - Provincia de Luya
  { id: 43, label: "Camporredondo", provinceId: 5 },
  { id: 44, label: "Cocabamba", provinceId: 5 },
  { id: 45, label: "Colcamar", provinceId: 5 },
  { id: 46, label: "Conila", provinceId: 5 },
  { id: 47, label: "Inguilpata", provinceId: 5 },
  { id: 48, label: "Lámud", provinceId: 5 },
  { id: 49, label: "Longuita", provinceId: 5 },
  { id: 50, label: "Lonya Chico", provinceId: 5 },
  { id: 51, label: "Luya", provinceId: 5 },
  { id: 52, label: "Luya Viejo", provinceId: 5 },
  { id: 53, label: "María", provinceId: 5 },
  { id: 54, label: "Ocalli", provinceId: 5 },
  { id: 55, label: "Ocumal", provinceId: 5 },
  { id: 56, label: "Pisuquía", provinceId: 5 },
  { id: 57, label: "Providencia", provinceId: 5 },
  { id: 58, label: "San Cristóbal", provinceId: 5 },
  { id: 59, label: "San Francisco del Yeso", provinceId: 5 },
  { id: 60, label: "San Jerónimo", provinceId: 5 },
  { id: 61, label: "San Juan de Lopecancha", provinceId: 5 },
  { id: 62, label: "Santa Catalina", provinceId: 5 },
  { id: 63, label: "Santo Tomás", provinceId: 5 },
  { id: 64, label: "Tingo", provinceId: 5 },
  { id: 65, label: "Trita", provinceId: 5 },

  // DISTRITOS DE AMAZONAS - Provincia de Rodríguez de Mendoza
  { id: 66, label: "San Nicolás", provinceId: 6 },
  { id: 67, label: "Chirimoto", provinceId: 6 },
  { id: 68, label: "Cochamal", provinceId: 6 },
  { id: 69, label: "Huambo", provinceId: 6 },
  { id: 70, label: "Limabamba", provinceId: 6 },
  { id: 71, label: "Longar", provinceId: 6 },
  { id: 72, label: "Mariscal Benavides", provinceId: 6 },
  { id: 73, label: "Mílpuc", provinceId: 6 },
  { id: 74, label: "Omia", provinceId: 6 },
  { id: 75, label: "Santa Rosa", provinceId: 6 },
  { id: 76, label: "Totora", provinceId: 6 },
  { id: 77, label: "Vista Alegre", provinceId: 6 },

  // DISTRITOS DE AMAZONAS - Provincia de Utcubamba
  { id: 78, label: "Bagua Grande", provinceId: 7 },
  { id: 79, label: "Cajaruro", provinceId: 7 },
  { id: 80, label: "Cumba", provinceId: 7 },
  { id: 81, label: "El Milagro", provinceId: 7 },
  { id: 82, label: "Jamalca", provinceId: 7 },
  { id: 83, label: "Lonya Grande", provinceId: 7 },
  { id: 84, label: "Yamón", provinceId: 7 },

  // DISTRITOS DE ÁNCASH - Provincia de Huaraz
  { id: 85, label: "Huaraz", provinceId: 8 },
  { id: 86, label: "Cochabamba", provinceId: 8 },
  { id: 87, label: "Colcabamba", provinceId: 8 },
  { id: 88, label: "Huanchay", provinceId: 8 },
  { id: 89, label: "Independencia", provinceId: 8 },
  { id: 90, label: "Jangas", provinceId: 8 },
  { id: 91, label: "La Libertad", provinceId: 8 },
  { id: 92, label: "Olleros", provinceId: 8 },
  { id: 93, label: "Pampas Grande", provinceId: 8 },
  { id: 94, label: "Pariacoto", provinceId: 8 },
  { id: 95, label: "Pira", provinceId: 8 },
  { id: 96, label: "Tarica", provinceId: 8 },

  // DISTRITOS DE ÁNCASH - Provincia de Aija
  { id: 97, label: "Aija", provinceId: 9 },
  { id: 98, label: "Coris", provinceId: 9 },
  { id: 99, label: "Huacllán", provinceId: 9 },
  { id: 100, label: "La Merced", provinceId: 9 },
  { id: 101, label: "Succha", provinceId: 9 },

  // DISTRITOS DE ÁNCASH - Provincia de Antonio Raymondi
  { id: 102, label: "Llamellín", provinceId: 10 },
  { id: 103, label: "Aczo", provinceId: 10 },
  { id: 104, label: "Chaccho", provinceId: 10 },
  { id: 105, label: "Chingas", provinceId: 10 },
  { id: 106, label: "Mirgas", provinceId: 10 },
  { id: 107, label: "San Juan de Rontoy", provinceId: 10 },

  // DISTRITOS DE ÁNCASH - Provincia de Asunción
  { id: 108, label: "Chacas", provinceId: 11 },
  { id: 109, label: "Acochaca", provinceId: 11 },

  // DISTRITOS DE ÁNCASH - Provincia de Bolognesi
  { id: 110, label: "Abelardo Pardo Lezameta", provinceId: 12 },
  { id: 111, label: "Antonio Raimondi", provinceId: 12 },
  { id: 112, label: "Aquía", provinceId: 12 },
  { id: 113, label: "Cajacay", provinceId: 12 },
  { id: 114, label: "Canis", provinceId: 12 },
  { id: 115, label: "Chiquián", provinceId: 12 },
  { id: 116, label: "Cólquioc", provinceId: 12 },
  { id: 117, label: "Huallanca", provinceId: 12 },
  { id: 118, label: "Huasta", provinceId: 12 },
  { id: 119, label: "Huayllacayán", provinceId: 12 },
  { id: 120, label: "La Primavera", provinceId: 12 },
  { id: 121, label: "Mangas", provinceId: 12 },
  { id: 122, label: "Pacllón", provinceId: 12 },
  { id: 123, label: "San Miguel de Corpanqui", provinceId: 12 },
  { id: 124, label: "Ticllos", provinceId: 12 },

  // DISTRITOS DE ÁNCASH - Provincia de Carhuaz
  { id: 125, label: "Carhuaz", provinceId: 13 },
  { id: 126, label: "Acopampa", provinceId: 13 },
  { id: 127, label: "Amashca", provinceId: 13 },
  { id: 128, label: "Anta", provinceId: 13 },
  { id: 129, label: "Ataquero", provinceId: 13 },
  { id: 130, label: "Marcará", provinceId: 13 },
  { id: 131, label: "Pariahuanca", provinceId: 13 },
  { id: 132, label: "San Miguel de Aco", provinceId: 13 },
  { id: 133, label: "Shilla", provinceId: 13 },
  { id: 134, label: "Tinco", provinceId: 13 },
  { id: 135, label: "Yungar", provinceId: 13 },

  // DISTRITOS DE ÁNCASH - Provincia de Carlos Fermín Fitzcarrald
  { id: 136, label: "San Luis", provinceId: 14 },
  { id: 137, label: "San Nicolás", provinceId: 14 },
  { id: 138, label: "Yauya", provinceId: 14 },

  // DISTRITOS DE ÁNCASH - Provincia de Casma
  { id: 139, label: "Casma", provinceId: 15 },
  { id: 140, label: "Buenavista Alta", provinceId: 15 },
  { id: 141, label: "Comandante Noel", provinceId: 15 },
  { id: 142, label: "Yaután", provinceId: 15 },

  // DISTRITOS DE ÁNCASH - Provincia de Corongo
  { id: 143, label: "Corongo", provinceId: 16 },
  { id: 144, label: "Aco", provinceId: 16 },
  { id: 145, label: "Bambas", provinceId: 16 },
  { id: 146, label: "Cusca", provinceId: 16 },
  { id: 147, label: "La Pampa", provinceId: 16 },
  { id: 148, label: "Yánac", provinceId: 16 },
  { id: 149, label: "Yupán", provinceId: 16 },

  // DISTRITOS DE ÁNCASH - Provincia de Huari
  { id: 150, label: "Huari", provinceId: 17 },
  { id: 151, label: "Anra", provinceId: 17 },
  { id: 152, label: "Cajay", provinceId: 17 },
  { id: 153, label: "Chavín de Huántar", provinceId: 17 },
  { id: 154, label: "Huacachi", provinceId: 17 },
  { id: 155, label: "Huacchis", provinceId: 17 },
  { id: 156, label: "Huachis", provinceId: 17 },
  { id: 157, label: "Huántar", provinceId: 17 },
  { id: 158, label: "Masin", provinceId: 17 },
  { id: 159, label: "Paucas", provinceId: 17 },
  { id: 160, label: "Pontó", provinceId: 17 },
  { id: 161, label: "Rahuapampa", provinceId: 17 },
  { id: 162, label: "Rapayán", provinceId: 17 },
  { id: 163, label: "San Marcos", provinceId: 17 },
  { id: 164, label: "San Pedro de Chaná", provinceId: 17 },
  { id: 165, label: "Uco", provinceId: 17 },

  // DISTRITOS DE ÁNCASH - Provincia de Huarmey
  { id: 166, label: "Huarmey", provinceId: 18 },
  { id: 167, label: "Cochapeti", provinceId: 18 },
  { id: 168, label: "Culebras", provinceId: 18 },
  { id: 169, label: "Huayán", provinceId: 18 },
  { id: 170, label: "Malvas", provinceId: 18 },

  // DISTRITOS DE ÁNCASH - Provincia de Huaylas
  { id: 171, label: "Caraz", provinceId: 19 },
  { id: 172, label: "Huallanca", provinceId: 19 },
  { id: 173, label: "Huata", provinceId: 19 },
  { id: 174, label: "Huaylas", provinceId: 19 },
  { id: 175, label: "Mato", provinceId: 19 },
  { id: 176, label: "Pamparomás", provinceId: 19 },
  { id: 177, label: "Yuracmarca", provinceId: 19 },

  // DISTRITOS DE ÁNCASH - Provincia de Mariscal Luzuriaga
  { id: 178, label: "Piscobamba", provinceId: 20 },
  { id: 179, label: "Casca", provinceId: 20 },
  { id: 180, label: "Eleazar Guzmán Barrón", provinceId: 20 },
  { id: 181, label: "Fidel Olivas Escudero", provinceId: 20 },
  { id: 182, label: "Llama", provinceId: 20 },
  { id: 183, label: "Llumpa", provinceId: 20 },
  { id: 184, label: "Musga", provinceId: 20 },
  { id: 185, label: "Lucma", provinceId: 20 },

  // DISTRITOS DE ÁNCASH - Provincia de Ocros
  { id: 186, label: "Ocros", provinceId: 21 },
  { id: 187, label: "Acas", provinceId: 21 },
  { id: 188, label: "Cajamarquilla", provinceId: 21 },
  { id: 189, label: "Carhuapampa", provinceId: 21 },
  { id: 190, label: "Cochas", provinceId: 21 },
  { id: 191, label: "Congas", provinceId: 21 },
  { id: 192, label: "Llipa", provinceId: 21 },
  { id: 193, label: "San Cristóbal de Raján", provinceId: 21 },
  { id: 194, label: "San Pedro", provinceId: 21 },
  { id: 195, label: "Santiago de Chilcas", provinceId: 21 },

  // DISTRITOS DE ÁNCASH - Provincia de Pallasca
  { id: 196, label: "Cabana", provinceId: 22 },
  { id: 197, label: "Bolognesi", provinceId: 22 },
  { id: 198, label: "Conchucos", provinceId: 22 },
  { id: 199, label: "Huacaschuque", provinceId: 22 },
  { id: 200, label: "Huandoval", provinceId: 22 },
  { id: 201, label: "Lacabamba", provinceId: 22 },
  { id: 202, label: "Llapo", provinceId: 22 },
  { id: 203, label: "Pallasca", provinceId: 22 },
  { id: 204, label: "Pampas", provinceId: 22 },
  { id: 205, label: "Santa Rosa", provinceId: 22 },
  { id: 206, label: "Tauca", provinceId: 22 },

  // DISTRITOS DE ÁNCASH - Provincia de Pomabamba
  { id: 207, label: "Pomabamba", provinceId: 23 },
  { id: 208, label: "Huayllán", provinceId: 23 },
  { id: 209, label: "Parobamba", provinceId: 23 },
  { id: 210, label: "Quinuabamba", provinceId: 23 },

  // DISTRITOS DE ÁNCASH - Provincia de Recuay
  { id: 211, label: "Recuay", provinceId: 24 },
  { id: 212, label: "Cátac", provinceId: 24 },
  { id: 213, label: "Cotaparaco", provinceId: 24 },
  { id: 214, label: "Huayllapampa", provinceId: 24 },
  { id: 215, label: "Llacllín", provinceId: 24 },
  { id: 216, label: "Marca", provinceId: 24 },
  { id: 217, label: "Pampas Chico", provinceId: 24 },
  { id: 218, label: "Pararín", provinceId: 24 },
  { id: 219, label: "Tapacocha", provinceId: 24 },
  { id: 220, label: "Ticapampa", provinceId: 24 },

  // DISTRITOS DE ÁNCASH - Provincia del Santa
  { id: 221, label: "Chimbote", provinceId: 25 },
  { id: 222, label: "Cáceres del Perú", provinceId: 25 },
  { id: 223, label: "Coishco", provinceId: 25 },
  { id: 224, label: "Macate", provinceId: 25 },
  { id: 225, label: "Moro", provinceId: 25 },
  { id: 226, label: "Nepeña", provinceId: 25 },
  { id: 227, label: "Nuevo Chimbote", provinceId: 25 },
  { id: 228, label: "Samanco", provinceId: 25 },
  { id: 229, label: "Santa", provinceId: 25 },

  // DISTRITOS DE ÁNCASH - Provincia de Sihuas
  { id: 230, label: "Sihuas", provinceId: 26 },
  { id: 231, label: "Acobamba", provinceId: 26 },
  { id: 232, label: "Alfonso Ugarte", provinceId: 26 },
  { id: 233, label: "Cashapampa", provinceId: 26 },
  { id: 234, label: "Chingalpo", provinceId: 26 },
  { id: 235, label: "Huayllabamba", provinceId: 26 },
  { id: 236, label: "Quiches", provinceId: 26 },
  { id: 237, label: "Ragash", provinceId: 26 },
  { id: 238, label: "San Juan", provinceId: 26 },
  { id: 239, label: "Sicsibamba", provinceId: 26 },

  // DISTRITOS DE ÁNCASH - Provincia de Yungay
  { id: 240, label: "Yungay", provinceId: 27 },
  { id: 241, label: "Cascapara", provinceId: 27 },
  { id: 242, label: "Mancos", provinceId: 27 },
  { id: 243, label: "Matacoto", provinceId: 27 },
  { id: 244, label: "Quillo", provinceId: 27 },
  { id: 245, label: "Ranrahirca", provinceId: 27 },
  { id: 246, label: "Shupluy", provinceId: 27 },
  { id: 247, label: "Yanama", provinceId: 27 },

  // DISTRITOS DE APURÍMAC - Provincia de Abancay
  { id: 248, label: "Abancay", provinceId: 28 },
  { id: 249, label: "Americas", provinceId: 28 },
  { id: 250, label: "Chacoche", provinceId: 28 },
  { id: 251, label: "Circa", provinceId: 28 },
  { id: 252, label: "Curahuasi", provinceId: 28 },
  { id: 253, label: "Huanipaca", provinceId: 28 },
  { id: 254, label: "Lambrama", provinceId: 28 },
  { id: 255, label: "Pichirhua", provinceId: 28 },
  { id: 256, label: "San Pedro de Cachora", provinceId: 28 },
  { id: 257, label: "Tamburco", provinceId: 28 },

  // DISTRITOS DE APURÍMAC - Provincia de Andahuaylas
  { id: 258, label: "Andahuaylas", provinceId: 29 },
  { id: 259, label: "San Jerónimo", provinceId: 29 },
  { id: 260, label: "Talavera", provinceId: 29 },
  { id: 261, label: "Santa María de Chicmo", provinceId: 29 },
  { id: 262, label: "Pacucha", provinceId: 29 },
  { id: 263, label: "Kishuará", provinceId: 29 },
  { id: 264, label: "Andarapa", provinceId: 29 },
  { id: 265, label: "Huancarama", provinceId: 29 },
  { id: 266, label: "José María Arguedas", provinceId: 29 },
  { id: 267, label: "Huancaray", provinceId: 29 },
  { id: 268, label: "Turpo", provinceId: 29 },
  { id: 269, label: "Pacobamba", provinceId: 29 },
  { id: 270, label: "San Antonio de Cachi", provinceId: 29 },
  { id: 271, label: "Pampachiri", provinceId: 29 },
  { id: 272, label: "Tumay Huaraca", provinceId: 29 },
  { id: 273, label: "Kaquiabamba", provinceId: 29 },
  { id: 274, label: "San Miguel de Chaccrampa", provinceId: 29 },
  { id: 275, label: "Chiara", provinceId: 29 },
  { id: 276, label: "Pomacocha", provinceId: 29 },
  { id: 277, label: "Huayana", provinceId: 29 },

  // DISTRITOS DE APURÍMAC - Provincia de Antabamba
  { id: 278, label: "Antabamba", provinceId: 30 },
  { id: 279, label: "El Oro", provinceId: 30 },
  { id: 280, label: "Huaquirca", provinceId: 30 },
  { id: 281, label: "Juan Espinoza Medrano", provinceId: 30 },
  { id: 282, label: "Oropesa", provinceId: 30 },
  { id: 283, label: "Pachaconas", provinceId: 30 },
  { id: 284, label: "Sabaino", provinceId: 30 },

  // DISTRITOS DE APURÍMAC - Provincia de Aymaraes
  { id: 285, label: "Chalhuanca", provinceId: 31 },
  { id: 286, label: "Capaya", provinceId: 31 },
  { id: 287, label: "Caraybamba", provinceId: 31 },
  { id: 288, label: "Chapimarca", provinceId: 31 },
  { id: 289, label: "Colcabamba", provinceId: 31 },
  { id: 290, label: "Cotaruse", provinceId: 31 },
  { id: 291, label: "Ihuayllo", provinceId: 31 },
  { id: 292, label: "Justo Apu Sahuaraura", provinceId: 31 },
  { id: 293, label: "Lucre", provinceId: 31 },
  { id: 294, label: "Pocohuanca", provinceId: 31 },
  { id: 295, label: "San Juan de Chacña", provinceId: 31 },
  { id: 296, label: "Sañayca", provinceId: 31 },
  { id: 297, label: "Soraya", provinceId: 31 },
  { id: 298, label: "Tapairihua", provinceId: 31 },
  { id: 299, label: "Tintay", provinceId: 31 },
  { id: 300, label: "Toraya", provinceId: 31 },
  { id: 301, label: "Yanaca", provinceId: 31 },

  // DISTRITOS DE APURÍMAC - Provincia de Cotabambas
  { id: 302, label: "Tambobamba", provinceId: 32 },
  { id: 303, label: "Cotabambas", provinceId: 32 },
  { id: 304, label: "Coyllurqui", provinceId: 32 },
  { id: 305, label: "Haquira", provinceId: 32 },
  { id: 306, label: "Mara", provinceId: 32 },
  { id: 307, label: "Chalhuahuacho", provinceId: 32 },

  // DISTRITOS DE APURÍMAC - Provincia de Chincheros
  { id: 308, label: "Chincheros", provinceId: 33 },
  { id: 309, label: "Ancohuallo", provinceId: 33 },
  { id: 310, label: "Cocharcas", provinceId: 33 },
  { id: 311, label: "Huaccana", provinceId: 33 },
  { id: 312, label: "Ocobamba", provinceId: 33 },
  { id: 313, label: "Ongoy", provinceId: 33 },
  { id: 314, label: "Uranmarca", provinceId: 33 },
  { id: 315, label: "Ranracancha", provinceId: 33 },
  { id: 316, label: "Rocchac", provinceId: 33 },
  { id: 317, label: "El Porvenir", provinceId: 33 },
  { id: 318, label: "Los Chankas", provinceId: 33 },
  { id: 319, label: "Ahuayro", provinceId: 33 },

  // DISTRITOS DE APURÍMAC - Provincia de Grau
  { id: 320, label: "Chuquibambilla", provinceId: 34 },
  { id: 321, label: "Curpahuasi", provinceId: 34 },
  { id: 322, label: "Huayllati", provinceId: 34 },
  { id: 323, label: "Mamara", provinceId: 34 },
  { id: 324, label: "Mariscal Gamarra", provinceId: 34 },
  { id: 325, label: "Micaela Bastidas", provinceId: 34 },
  { id: 326, label: "Pataypampa", provinceId: 34 },
  { id: 327, label: "Progreso", provinceId: 34 },
  { id: 328, label: "San Antonio", provinceId: 34 },
  { id: 329, label: "Santa Rosa", provinceId: 34 },
  { id: 330, label: "Turpay", provinceId: 34 },
  { id: 331, label: "Vilcabamba", provinceId: 34 },
  { id: 332, label: "Virundo", provinceId: 34 },
  { id: 333, label: "Curasco", provinceId: 34 },

  // DISTRITOS DE AREQUIPA - Provincia de Arequipa
  { id: 334, label: "Arequipa", provinceId: 35 },
  { id: 335, label: "Alto Selva Alegre", provinceId: 35 },
  { id: 336, label: "Cayma", provinceId: 35 },
  { id: 337, label: "Cerro Colorado", provinceId: 35 },
  { id: 338, label: "Characato", provinceId: 35 },
  { id: 339, label: "Chiguata", provinceId: 35 },
  { id: 340, label: "Jacobo Hunter", provinceId: 35 },
  { id: 341, label: "José Luis Bustamante y Rivero", provinceId: 35 },
  { id: 342, label: "La Joya", provinceId: 35 },
  { id: 343, label: "Mariano Melgar", provinceId: 35 },
  { id: 344, label: "Miraflores", provinceId: 35 },
  { id: 345, label: "Mollebaya", provinceId: 35 },
  { id: 346, label: "Paucarpata", provinceId: 35 },
  { id: 347, label: "Pocsi", provinceId: 35 },
  { id: 348, label: "Polobaya", provinceId: 35 },
  { id: 349, label: "Quequeña", provinceId: 35 },
  { id: 350, label: "Sabandía", provinceId: 35 },
  { id: 351, label: "Sachaca", provinceId: 35 },
  { id: 352, label: "San Juan de Siguas", provinceId: 35 },
  { id: 353, label: "San Juan de Tarucani", provinceId: 35 },
  { id: 354, label: "Santa Isabel de Siguas", provinceId: 35 },
  { id: 355, label: "Santa Rita de Siguas", provinceId: 35 },
  { id: 356, label: "Socabaya", provinceId: 35 },
  { id: 357, label: "Tiabaya", provinceId: 35 },
  { id: 358, label: "Uchumayo", provinceId: 35 },
  { id: 359, label: "Vitor", provinceId: 35 },
  { id: 360, label: "Yanahuara", provinceId: 35 },
  { id: 361, label: "Yarabamba", provinceId: 35 },
  { id: 362, label: "Yura", provinceId: 35 },

  // DISTRITOS DE AREQUIPA - Provincia de Camaná
  { id: 363, label: "Camaná", provinceId: 36 },
  { id: 364, label: "José María Quimper", provinceId: 36 },
  { id: 365, label: "Mariano Nicolás Valcarcel", provinceId: 36 },
  { id: 366, label: "Mariscal Cáceres", provinceId: 36 },
  { id: 367, label: "Nicolás de Piérola", provinceId: 36 },
  { id: 368, label: "Ocoña", provinceId: 36 },
  { id: 369, label: "Quilca", provinceId: 36 },
  { id: 370, label: "Samuel Pastor", provinceId: 36 },

  // DISTRITOS DE AREQUIPA - Provincia de Caravelí
  { id: 371, label: "Caravelí", provinceId: 37 },
  { id: 372, label: "Acarí", provinceId: 37 },
  { id: 373, label: "Atico", provinceId: 37 },
  { id: 374, label: "Atiquipa", provinceId: 37 },
  { id: 375, label: "Bella Unión", provinceId: 37 },
  { id: 376, label: "Cahuacho", provinceId: 37 },
  { id: 377, label: "Chala", provinceId: 37 },
  { id: 378, label: "Cháparra", provinceId: 37 },
  { id: 379, label: "Huanuhuanu", provinceId: 37 },
  { id: 380, label: "Jaquí", provinceId: 37 },
  { id: 381, label: "Lomas", provinceId: 37 },
  { id: 382, label: "Quicacha", provinceId: 37 },
  { id: 383, label: "Yauca", provinceId: 37 },

  // DISTRITOS DE AREQUIPA - Provincia de Castilla
  { id: 384, label: "Aplao", provinceId: 38 },
  { id: 385, label: "Andahua", provinceId: 38 },
  { id: 386, label: "Ayo", provinceId: 38 },
  { id: 387, label: "Chachas", provinceId: 38 },
  { id: 388, label: "Chilcaymarca", provinceId: 38 },
  { id: 389, label: "Choco", provinceId: 38 },
  { id: 390, label: "Huancarqui", provinceId: 38 },
  { id: 391, label: "Machaguay", provinceId: 38 },
  { id: 392, label: "Orcopampa", provinceId: 38 },
  { id: 393, label: "Pampacolca", provinceId: 38 },
  { id: 394, label: "Tipán", provinceId: 38 },
  { id: 395, label: "Uñón", provinceId: 38 },
  { id: 396, label: "Uraca", provinceId: 38 },
  { id: 397, label: "Viraco", provinceId: 38 },

  // DISTRITOS DE AREQUIPA - Provincia de Caylloma
  { id: 398, label: "Achoma", provinceId: 39 },
  { id: 399, label: "Cabanaconde", provinceId: 39 },
  { id: 400, label: "Callalli", provinceId: 39 },
  { id: 401, label: "Caylloma", provinceId: 39 },
  { id: 402, label: "Chivay", provinceId: 39 },
  { id: 403, label: "Coporaque", provinceId: 39 },
  { id: 404, label: "Huambo", provinceId: 39 },
  { id: 405, label: "Huanca", provinceId: 39 },
  { id: 406, label: "Ichupampa", provinceId: 39 },
  { id: 407, label: "Lari", provinceId: 39 },
  { id: 408, label: "Lluta", provinceId: 39 },
  { id: 409, label: "Maca", provinceId: 39 },
  { id: 410, label: "Madrigal", provinceId: 39 },
  { id: 411, label: "Majes", provinceId: 39 },
  { id: 412, label: "San Antonio de Chuca", provinceId: 39 },
  { id: 413, label: "Sibayo", provinceId: 39 },
  { id: 414, label: "Tapay", provinceId: 39 },
  { id: 415, label: "Tisco", provinceId: 39 },
  { id: 416, label: "Tuti", provinceId: 39 },
  { id: 417, label: "Yanque", provinceId: 39 },

  // DISTRITOS DE AREQUIPA - Provincia de Condesuyos
  { id: 418, label: "Chuquibamba", provinceId: 40 },
  { id: 419, label: "Andaray", provinceId: 40 },
  { id: 420, label: "Cayarani", provinceId: 40 },
  { id: 421, label: "Chichas", provinceId: 40 },
  { id: 422, label: "Iray", provinceId: 40 },
  { id: 423, label: "Río Grande", provinceId: 40 },
  { id: 424, label: "Salamanca", provinceId: 40 },
  { id: 425, label: "Yanaquihua", provinceId: 40 },

  // DISTRITOS DE AREQUIPA - Provincia de Islay
  { id: 426, label: "Mollendo", provinceId: 41 },
  { id: 427, label: "Cocachacra", provinceId: 41 },
  { id: 428, label: "Deán Valdivia", provinceId: 41 },
  { id: 429, label: "Islay", provinceId: 41 },
  { id: 430, label: "Mejía", provinceId: 41 },
  { id: 431, label: "Punta de Bombón", provinceId: 41 },

  // DISTRITOS DE AREQUIPA - Provincia de La Unión
  { id: 432, label: "Cotahuasi", provinceId: 42 },
  { id: 433, label: "Alca", provinceId: 42 },
  { id: 434, label: "Charcana", provinceId: 42 },
  { id: 435, label: "Huaynacotas", provinceId: 42 },
  { id: 436, label: "Pampamarca", provinceId: 42 },
  { id: 437, label: "Puyca", provinceId: 42 },
  { id: 438, label: "Quechualla", provinceId: 42 },
  { id: 439, label: "Sayla", provinceId: 42 },
  { id: 440, label: "Tauría", provinceId: 42 },
  { id: 441, label: "Tomepampa", provinceId: 42 },
  { id: 442, label: "Toro", provinceId: 42 },

  // DISTRITOS DE AYACUCHO - Provincia de Huamanga
  { id: 443, label: "Acocro", provinceId: 43 },
  { id: 444, label: "Acos Vinchos", provinceId: 43 },
  { id: 445, label: "Andrés Avelino Cáceres Dorregaray", provinceId: 43 },
  { id: 446, label: "Ayacucho", provinceId: 43 },
  { id: 447, label: "Carmen Alto", provinceId: 43 },
  { id: 448, label: "Chiara", provinceId: 43 },
  { id: 449, label: "Jesús Nazareno", provinceId: 43 },
  { id: 450, label: "Ocros", provinceId: 43 },
  { id: 451, label: "Pacaycasa", provinceId: 43 },
  { id: 452, label: "Quinua", provinceId: 43 },
  { id: 453, label: "San José de Ticllas", provinceId: 43 },
  { id: 454, label: "San Juan Bautista", provinceId: 43 },
  { id: 455, label: "Santiago de Pischa", provinceId: 43 },
  { id: 456, label: "Socos", provinceId: 43 },
  { id: 457, label: "Tambillo", provinceId: 43 },
  { id: 458, label: "Vinchos", provinceId: 43 },

  // DISTRITOS DE AYACUCHO - Provincia de Cangallo
  { id: 459, label: "Cangallo", provinceId: 44 },
  { id: 460, label: "Chuschi", provinceId: 44 },
  { id: 461, label: "Los Morochucos", provinceId: 44 },
  { id: 462, label: "María Parado de Bellido", provinceId: 44 },
  { id: 463, label: "Paras", provinceId: 44 },
  { id: 464, label: "Totos", provinceId: 44 },

  // DISTRITOS DE AYACUCHO - Provincia de Huanca Sancos
  { id: 465, label: "Carapo", provinceId: 45 },
  { id: 466, label: "Sacsamarca", provinceId: 45 },
  { id: 467, label: "Sancos", provinceId: 45 },
  { id: 468, label: "Santiago de Lucanamarca", provinceId: 45 },

  // DISTRITOS DE AYACUCHO - Provincia de Huanta
  { id: 469, label: "Huanta", provinceId: 46 },
  { id: 470, label: "Ayahuanco", provinceId: 46 },
  { id: 471, label: "Huamanguilla", provinceId: 46 },
  { id: 472, label: "Iguaín", provinceId: 46 },
  { id: 473, label: "Llochegua", provinceId: 46 },
  { id: 474, label: "Luricocha", provinceId: 46 },
  { id: 475, label: "Santillana", provinceId: 46 },
  { id: 476, label: "Sivia", provinceId: 46 },
  { id: 477, label: "Canayre", provinceId: 46 },
  { id: 478, label: "Uchuraccay", provinceId: 46 },
  { id: 479, label: "Pucacolpa", provinceId: 46 },
  { id: 480, label: "Chaca", provinceId: 46 },
  { id: 481, label: "Putis", provinceId: 46 },

  // DISTRITOS DE AYACUCHO - Provincia de La Mar
  { id: 482, label: "San Miguel", provinceId: 47 },
  { id: 483, label: "Anco", provinceId: 47 },
  { id: 484, label: "Ayna", provinceId: 47 },
  { id: 485, label: "Chilcas", provinceId: 47 },
  { id: 486, label: "Chungui", provinceId: 47 },
  { id: 487, label: "Luis Carranza", provinceId: 47 },
  { id: 488, label: "Santa Rosa", provinceId: 47 },
  { id: 489, label: "Tambo", provinceId: 47 },
  { id: 490, label: "Samugari", provinceId: 47 },
  { id: 491, label: "Anchihuay", provinceId: 47 },
  { id: 492, label: "Oronccoy", provinceId: 47 },
  { id: 493, label: "Unión Progreso", provinceId: 47 },
  { id: 494, label: "Río Magdalena", provinceId: 47 },
  { id: 495, label: "Ninabamba", provinceId: 47 },
  { id: 496, label: "Patibamba", provinceId: 47 },

  // DISTRITOS DE AYACUCHO - Provincia de Lucanas
  { id: 497, label: "Puquio", provinceId: 48 },
  { id: 498, label: "Aucará", provinceId: 48 },
  { id: 499, label: "Cabana", provinceId: 48 },
  { id: 500, label: "Carmen Salcedo", provinceId: 48 },
  { id: 501, label: "Chaviña", provinceId: 48 },
  { id: 502, label: "Chipao", provinceId: 48 },
  { id: 503, label: "Huac-Huas", provinceId: 48 },
  { id: 504, label: "Laramate", provinceId: 48 },
  { id: 505, label: "Leoncio Prado", provinceId: 48 },
  { id: 506, label: "Llauta", provinceId: 48 },
  { id: 507, label: "Lucanas", provinceId: 48 },
  { id: 508, label: "Ocaña", provinceId: 48 },
  { id: 509, label: "Otoca", provinceId: 48 },
  { id: 510, label: "Saisa", provinceId: 48 },
  { id: 511, label: "San Cristóbal", provinceId: 48 },
  { id: 512, label: "San Juan", provinceId: 48 },
  { id: 513, label: "San Pedro", provinceId: 48 },
  { id: 514, label: "San Pedro de Palco", provinceId: 48 },
  { id: 515, label: "Sancos", provinceId: 48 },
  { id: 516, label: "Santa Ana de Huaycahuacho", provinceId: 48 },
  { id: 517, label: "Santa Lucía", provinceId: 48 },

  // DISTRITOS DE AYACUCHO - Provincia de Parinacochas
  { id: 518, label: "Coracora", provinceId: 49 },
  { id: 519, label: "Chumpi", provinceId: 49 },
  { id: 520, label: "Coronel Castañeda", provinceId: 49 },
  { id: 521, label: "Pacapausa", provinceId: 49 },
  { id: 522, label: "Pullo", provinceId: 49 },
  { id: 523, label: "Puyusca", provinceId: 49 },
  { id: 524, label: "San Francisco de Ravacayco", provinceId: 49 },
  { id: 525, label: "Upahuacho", provinceId: 49 },

  // DISTRITOS DE AYACUCHO - Provincia de Páucar del Sara Sara
  { id: 526, label: "Pauza", provinceId: 50 },
  { id: 527, label: "Colta", provinceId: 50 },
  { id: 528, label: "Corculla", provinceId: 50 },
  { id: 529, label: "Lampa", provinceId: 50 },
  { id: 530, label: "Marcabamba", provinceId: 50 },
  { id: 531, label: "Oyolo", provinceId: 50 },
  { id: 532, label: "Pararca", provinceId: 50 },
  { id: 533, label: "San Javier de Alpabamba", provinceId: 50 },
  { id: 534, label: "San José de Ushua", provinceId: 50 },
  { id: 535, label: "Sara Sara", provinceId: 50 },

  // DISTRITOS DE AYACUCHO - Provincia de Sucre
  { id: 536, label: "Querobamba", provinceId: 51 },
  { id: 537, label: "Belén", provinceId: 51 },
  { id: 538, label: "Chalcos", provinceId: 51 },
  { id: 539, label: "Chilcayoc", provinceId: 51 },
  { id: 540, label: "Huacaña", provinceId: 51 },
  { id: 541, label: "Morcolla", provinceId: 51 },
  { id: 542, label: "Paico", provinceId: 51 },
  { id: 543, label: "San Pedro de Larcay", provinceId: 51 },
  { id: 544, label: "San Salvador de Quije", provinceId: 51 },
  { id: 545, label: "Santiago de Paucaray", provinceId: 51 },
  { id: 546, label: "Soras", provinceId: 51 },

  // DISTRITOS DE AYACUCHO - Provincia de Víctor Fajardo
  { id: 547, label: "Huancapi", provinceId: 52 },
  { id: 548, label: "Alcamenca", provinceId: 52 },
  { id: 549, label: "Apongo", provinceId: 52 },
  { id: 550, label: "Asquipata", provinceId: 52 },
  { id: 551, label: "Canaria", provinceId: 52 },
  { id: 552, label: "Cayara", provinceId: 52 },
  { id: 553, label: "Colca", provinceId: 52 },
  { id: 554, label: "Huamanquiquia", provinceId: 52 },
  { id: 555, label: "Huancaraylla", provinceId: 52 },
  { id: 556, label: "Sarhua", provinceId: 52 },
  { id: 557, label: "Vilcanchos", provinceId: 52 },

  // DISTRITOS DE AYACUCHO - Provincia de Vilcashuamán
  { id: 558, label: "Vilcashuamán", provinceId: 53 },
  { id: 559, label: "Accomarca", provinceId: 53 },
  { id: 560, label: "Carhuanca", provinceId: 53 },
  { id: 561, label: "Concepción", provinceId: 53 },
  { id: 562, label: "Huambalpa", provinceId: 53 },
  { id: 563, label: "Independencia", provinceId: 53 },
  { id: 564, label: "Saurama", provinceId: 53 },
  { id: 565, label: "Vischongo", provinceId: 53 },

  // DISTRITOS DE CAJAMARCA - Provincia de Cajabamba
  { id: 578, label: "Cajabamba", provinceId: 55 },
  { id: 579, label: "Cachachi", provinceId: 55 },
  { id: 580, label: "Condebamba", provinceId: 55 },
  { id: 581, label: "Sitacocha", provinceId: 55 },

  // DISTRITOS DE CAJAMARCA - Provincia de Celendín
  { id: 582, label: "Celendín", provinceId: 56 },
  { id: 583, label: "Chumuch", provinceId: 56 },
  { id: 584, label: "Cortegana", provinceId: 56 },
  { id: 585, label: "Huasmin", provinceId: 56 },
  { id: 586, label: "Jorge Chávez", provinceId: 56 },
  { id: 587, label: "José Gálvez", provinceId: 56 },
  { id: 588, label: "Miguel Iglesias", provinceId: 56 },
  { id: 589, label: "Oxamarca", provinceId: 56 },
  { id: 590, label: "Sorochuco", provinceId: 56 },
  { id: 591, label: "Sucre", provinceId: 56 },
  { id: 592, label: "Utco", provinceId: 56 },
  { id: 593, label: "La Libertad de Pallan", provinceId: 56 },

  // DISTRITOS DE CAJAMARCA - Provincia de Chota
  { id: 594, label: "Chota", provinceId: 57 },
  { id: 595, label: "Anguia", provinceId: 57 },
  { id: 596, label: "Chadin", provinceId: 57 },
  { id: 597, label: "Chalamarca", provinceId: 57 },
  { id: 598, label: "Chiguirip", provinceId: 57 },
  { id: 599, label: "Chimban", provinceId: 57 },
  { id: 600, label: "Choropampa", provinceId: 57 },
  { id: 601, label: "Cochabamba", provinceId: 57 },
  { id: 602, label: "Conchan", provinceId: 57 },
  { id: 603, label: "Huambos", provinceId: 57 },
  { id: 604, label: "Lajas", provinceId: 57 },
  { id: 605, label: "Llama", provinceId: 57 },
  { id: 606, label: "Miracosta", provinceId: 57 },
  { id: 607, label: "Paccha", provinceId: 57 },
  { id: 608, label: "Pion", provinceId: 57 },
  { id: 609, label: "Querocoto", provinceId: 57 },
  { id: 610, label: "San Juan de Licupis", provinceId: 57 },
  { id: 611, label: "Tacabamba", provinceId: 57 },
  { id: 612, label: "Tocmoche", provinceId: 57 },
  { id: 613, label: "Choros", provinceId: 57 },

  // DISTRITOS DE CAJAMARCA - Provincia de Contumazá
  { id: 614, label: "Contumazá", provinceId: 58 },
  { id: 615, label: "Chilete", provinceId: 58 },
  { id: 616, label: "Cupisnique", provinceId: 58 },
  { id: 617, label: "Guzmango", provinceId: 58 },
  { id: 618, label: "San Benito", provinceId: 58 },
  { id: 619, label: "Santa Cruz de Toledo", provinceId: 58 },
  { id: 620, label: "Tantarica", provinceId: 58 },
  { id: 621, label: "Yonan", provinceId: 58 },

  // DISTRITOS DE CAJAMARCA - Provincia de Cutervo
  { id: 622, label: "Cutervo", provinceId: 59 },
  { id: 623, label: "Callayuc", provinceId: 59 },
  { id: 624, label: "Choros", provinceId: 59 },
  { id: 625, label: "Cujillo", provinceId: 59 },
  { id: 626, label: "La Ramada", provinceId: 59 },
  { id: 627, label: "Pimpingos", provinceId: 59 },
  { id: 628, label: "Querocotillo", provinceId: 59 },
  { id: 629, label: "San Andrés de Cutervo", provinceId: 59 },
  { id: 630, label: "San Juan de Cutervo", provinceId: 59 },
  { id: 631, label: "San Luis de Lucma", provinceId: 59 },
  { id: 632, label: "Santa Cruz", provinceId: 59 },
  { id: 633, label: "Santo Domingo de la Capilla", provinceId: 59 },
  { id: 634, label: "Santo Tomas", provinceId: 59 },
  { id: 635, label: "Socota", provinceId: 59 },
  { id: 636, label: "Toribio Casanova", provinceId: 59 },

  // DISTRITOS DE CAJAMARCA - Provincia de Hualgayoc
  { id: 637, label: "Bambamarca", provinceId: 60 },
  { id: 638, label: "Chugur", provinceId: 60 },
  { id: 639, label: "Hualgayoc", provinceId: 60 },

  // DISTRITOS DE CAJAMARCA - Provincia de Jaén
  { id: 640, label: "Jaén", provinceId: 61 },
  { id: 641, label: "Bellavista", provinceId: 61 },
  { id: 642, label: "Chontali", provinceId: 61 },
  { id: 643, label: "Colasay", provinceId: 61 },
  { id: 644, label: "Huabal", provinceId: 61 },
  { id: 645, label: "Las Pirias", provinceId: 61 },
  { id: 646, label: "Pomahuaca", provinceId: 61 },
  { id: 647, label: "Pucara", provinceId: 61 },
  { id: 648, label: "Sallique", provinceId: 61 },
  { id: 649, label: "San Felipe", provinceId: 61 },
  { id: 650, label: "San José del Alto", provinceId: 61 },
  { id: 651, label: "Santa Rosa", provinceId: 61 },

  // DISTRITOS DE CAJAMARCA - Provincia de San Ignacio
  { id: 652, label: "San Ignacio", provinceId: 62 },
  { id: 653, label: "Chirinos", provinceId: 62 },
  { id: 654, label: "Huarango", provinceId: 62 },
  { id: 655, label: "La Coipa", provinceId: 62 },
  { id: 656, label: "Namballe", provinceId: 62 },
  { id: 657, label: "San José de Lourdes", provinceId: 62 },
  { id: 658, label: "Tabaconas", provinceId: 62 },

  // DISTRITOS DE CAJAMARCA - Provincia de San Marcos
  { id: 659, label: "Pedro Gálvez", provinceId: 63 },
  { id: 660, label: "Chancay", provinceId: 63 },
  { id: 661, label: "Eduardo Villanueva", provinceId: 63 },
  { id: 662, label: "Gregorio Pita", provinceId: 63 },
  { id: 663, label: "Ichocan", provinceId: 63 },
  { id: 664, label: "José Manuel Quiroz", provinceId: 63 },
  { id: 665, label: "José Sabogal", provinceId: 63 },

  // DISTRITOS DE CAJAMARCA - Provincia de San Miguel
  { id: 666, label: "San Miguel", provinceId: 64 },
  { id: 667, label: "Bolívar", provinceId: 64 },
  { id: 668, label: "Calquis", provinceId: 64 },
  { id: 669, label: "Catilluc", provinceId: 64 },
  { id: 670, label: "El Prado", provinceId: 64 },
  { id: 671, label: "La Florida", provinceId: 64 },
  { id: 672, label: "Llapa", provinceId: 64 },
  { id: 673, label: "Nanchoc", provinceId: 64 },
  { id: 674, label: "Niepos", provinceId: 64 },
  { id: 675, label: "San Gregorio", provinceId: 64 },
  { id: 676, label: "San Silvestre de Cochan", provinceId: 64 },
  { id: 677, label: "Tongod", provinceId: 64 },
  { id: 678, label: "Unión Agua Blanca", provinceId: 64 },

  // DISTRITOS DE CAJAMARCA - Provincia de San Pablo
  { id: 679, label: "San Pablo", provinceId: 65 },
  { id: 680, label: "San Bernardino", provinceId: 65 },
  { id: 681, label: "San Luis", provinceId: 65 },
  { id: 682, label: "Tumbaden", provinceId: 65 },

  // DISTRITOS DE CAJAMARCA - Provincia de Santa Cruz
  { id: 683, label: "Santa Cruz", provinceId: 66 },
  { id: 684, label: "Andabamba", provinceId: 66 },
  { id: 685, label: "Catache", provinceId: 66 },
  { id: 686, label: "Chancaybaños", provinceId: 66 },
  { id: 687, label: "La Esperanza", provinceId: 66 },
  { id: 688, label: "Ninabamba", provinceId: 66 },
  { id: 689, label: "Pulan", provinceId: 66 },
  { id: 690, label: "Saucepampa", provinceId: 66 },
  { id: 691, label: "Sexi", provinceId: 66 },
  { id: 692, label: "Uticyacu", provinceId: 66 },
  { id: 693, label: "Yauyucan", provinceId: 66 },

  // DISTRITOS DE CALLAO - Provincia Constitucional del Callao
  { id: 694, label: "Callao", provinceId: 67 },
  { id: 695, label: "Bellavista", provinceId: 67 },
  { id: 696, label: "Carmen de la Legua Reynoso", provinceId: 67 },
  { id: 697, label: "La Perla", provinceId: 67 },
  { id: 698, label: "La Punta", provinceId: 67 },
  { id: 699, label: "Ventanilla", provinceId: 67 },
  { id: 700, label: "Mi Perú", provinceId: 67 },

  // DISTRITOS DE CUSCO - Provincia de Cusco
  { id: 701, label: "Cusco", provinceId: 68 },
  { id: 702, label: "Ccorca", provinceId: 68 },
  { id: 703, label: "Poroy", provinceId: 68 },
  { id: 704, label: "San Jerónimo", provinceId: 68 },
  { id: 705, label: "San Sebastián", provinceId: 68 },
  { id: 706, label: "Santiago", provinceId: 68 },
  { id: 707, label: "Saylla", provinceId: 68 },
  { id: 708, label: "Wanchaq", provinceId: 68 },

  // DISTRITOS DE CUSCO - Provincia de Acomayo
  { id: 709, label: "Acomayo", provinceId: 69 },
  { id: 710, label: "Acopia", provinceId: 69 },
  { id: 711, label: "Acos", provinceId: 69 },
  { id: 712, label: "Mosoc Llacta", provinceId: 69 },
  { id: 713, label: "Pomacanchi", provinceId: 69 },
  { id: 714, label: "Rondocan", provinceId: 69 },
  { id: 715, label: "Sangarara", provinceId: 69 },

  // DISTRITOS DE CUSCO - Provincia de Anta
  { id: 716, label: "Anta", provinceId: 70 },
  { id: 717, label: "Ancahuasi", provinceId: 70 },
  { id: 718, label: "Cachimayo", provinceId: 70 },
  { id: 719, label: "Chinchaypujio", provinceId: 70 },
  { id: 720, label: "Huarocondo", provinceId: 70 },
  { id: 721, label: "Limatambo", provinceId: 70 },
  { id: 722, label: "Mollepata", provinceId: 70 },
  { id: 723, label: "Pucyura", provinceId: 70 },
  { id: 724, label: "Zurite", provinceId: 70 },

  // DISTRITOS DE CUSCO - Provincia de Calca
  { id: 725, label: "Calca", provinceId: 71 },
  { id: 726, label: "Coya", provinceId: 71 },
  { id: 727, label: "Lamay", provinceId: 71 },
  { id: 728, label: "Lares", provinceId: 71 },
  { id: 729, label: "Pisac", provinceId: 71 },
  { id: 730, label: "San Salvador", provinceId: 71 },
  { id: 731, label: "Taray", provinceId: 71 },
  { id: 732, label: "Yanatile", provinceId: 71 },

  // DISTRITOS DE CUSCO - Provincia de Canas
  { id: 733, label: "Yanaoca", provinceId: 72 },
  { id: 734, label: "Checca", provinceId: 72 },
  { id: 735, label: "Kunturkanki", provinceId: 72 },
  { id: 736, label: "Langui", provinceId: 72 },
  { id: 737, label: "Layo", provinceId: 72 },
  { id: 738, label: "Pampamarca", provinceId: 72 },
  { id: 739, label: "Quehue", provinceId: 72 },
  { id: 740, label: "Tupac Amaru", provinceId: 72 },

  // DISTRITOS DE CUSCO - Provincia de Canchis
  { id: 741, label: "Sicuani", provinceId: 73 },
  { id: 742, label: "Checacupe", provinceId: 73 },
  { id: 743, label: "Combapata", provinceId: 73 },
  { id: 744, label: "Marangani", provinceId: 73 },
  { id: 745, label: "Pitumarca", provinceId: 73 },
  { id: 746, label: "San Pablo", provinceId: 73 },
  { id: 747, label: "San Pedro", provinceId: 73 },
  { id: 748, label: "Tinta", provinceId: 73 },

  // DISTRITOS DE CUSCO - Provincia de Chumbivilcas
  { id: 749, label: "Santo Tomas", provinceId: 74 },
  { id: 750, label: "Capacmarca", provinceId: 74 },
  { id: 751, label: "Chamaca", provinceId: 74 },
  { id: 752, label: "Colquemarca", provinceId: 74 },
  { id: 753, label: "Livitaca", provinceId: 74 },
  { id: 754, label: "Llusco", provinceId: 74 },
  { id: 755, label: "Quiñota", provinceId: 74 },
  { id: 756, label: "Velille", provinceId: 74 },

  // DISTRITOS DE CUSCO - Provincia de Espinar
  { id: 757, label: "Espinar", provinceId: 75 },
  { id: 758, label: "Condoroma", provinceId: 75 },
  { id: 759, label: "Coporaque", provinceId: 75 },
  { id: 760, label: "Ocoruro", provinceId: 75 },
  { id: 761, label: "Pallpata", provinceId: 75 },
  { id: 762, label: "Pichigua", provinceId: 75 },
  { id: 763, label: "Suyckutambo", provinceId: 75 },
  { id: 764, label: "Alto Pichigua", provinceId: 75 },

  // DISTRITOS DE CUSCO - Provincia de La Convención
  { id: 765, label: "Santa Ana", provinceId: 76 },
  { id: 766, label: "Echarate", provinceId: 76 },
  { id: 767, label: "Huayopata", provinceId: 76 },
  { id: 768, label: "Maranura", provinceId: 76 },
  { id: 769, label: "Ocobamba", provinceId: 76 },
  { id: 770, label: "Quellouno", provinceId: 76 },
  { id: 771, label: "Kimbiri", provinceId: 76 },
  { id: 772, label: "Santa Teresa", provinceId: 76 },
  { id: 773, label: "Vilcabamba", provinceId: 76 },
  { id: 774, label: "Pichari", provinceId: 76 },
  { id: 775, label: "Inkawasi", provinceId: 76 },
  { id: 776, label: "Villa Virgen", provinceId: 76 },
  { id: 777, label: "Villa Kintiarina", provinceId: 76 },
  { id: 778, label: "Megantoni", provinceId: 76 },

  // DISTRITOS DE CUSCO - Provincia de Paruro
  { id: 779, label: "Paruro", provinceId: 77 },
  { id: 780, label: "Accha", provinceId: 77 },
  { id: 781, label: "Ccapi", provinceId: 77 },
  { id: 782, label: "Colcha", provinceId: 77 },
  { id: 783, label: "Huanoquite", provinceId: 77 },
  { id: 784, label: "Omacha", provinceId: 77 },
  { id: 785, label: "Paccaritambo", provinceId: 77 },
  { id: 786, label: "Pillpinto", provinceId: 77 },
  { id: 787, label: "Yaurisque", provinceId: 77 },

  // DISTRITOS DE CUSCO - Provincia de Paucartambo
  { id: 788, label: "Paucartambo", provinceId: 78 },
  { id: 789, label: "Caicay", provinceId: 78 },
  { id: 790, label: "Challabamba", provinceId: 78 },
  { id: 791, label: "Colquepata", provinceId: 78 },
  { id: 792, label: "Huancarani", provinceId: 78 },
  { id: 793, label: "Kosñipata", provinceId: 78 },

  // DISTRITOS DE CUSCO - Provincia de Quispicanchi
  { id: 794, label: "Urcos", provinceId: 79 },
  { id: 795, label: "Andahuaylillas", provinceId: 79 },
  { id: 796, label: "Camanti", provinceId: 79 },
  { id: 797, label: "Ccarhuayo", provinceId: 79 },
  { id: 798, label: "Ccatca", provinceId: 79 },
  { id: 799, label: "Cusipata", provinceId: 79 },
  { id: 800, label: "Huaro", provinceId: 79 },
  { id: 801, label: "Lucre", provinceId: 79 },
  { id: 802, label: "Marcapata", provinceId: 79 },
  { id: 803, label: "Ocongate", provinceId: 79 },
  { id: 804, label: "Oropesa", provinceId: 79 },
  { id: 805, label: "Quiquijana", provinceId: 79 },

  // DISTRITOS DE CUSCO - Provincia de Urubamba
  { id: 806, label: "Urubamba", provinceId: 80 },
  { id: 807, label: "Chinchero", provinceId: 80 },
  { id: 808, label: "Huayllabamba", provinceId: 80 },
  { id: 809, label: "Machupicchu", provinceId: 80 },
  { id: 810, label: "Maras", provinceId: 80 },
  { id: 811, label: "Ollantaytambo", provinceId: 80 },
  { id: 812, label: "Yucay", provinceId: 80 },

  // DISTRITOS DE HUANCAVELICA - Provincia de Huancavelica
  { id: 813, label: "Huancavelica", provinceId: 81 },
  { id: 814, label: "Acobambilla", provinceId: 81 },
  { id: 815, label: "Acoria", provinceId: 81 },
  { id: 816, label: "Conayca", provinceId: 81 },
  { id: 817, label: "Cuenca", provinceId: 81 },
  { id: 818, label: "Huachocolpa", provinceId: 81 },
  { id: 819, label: "Huayllahuara", provinceId: 81 },
  { id: 820, label: "Izcuchaca", provinceId: 81 },
  { id: 821, label: "Laria", provinceId: 81 },
  { id: 822, label: "Manta", provinceId: 81 },
  { id: 823, label: "Mariscal Cáceres", provinceId: 81 },
  { id: 824, label: "Moya", provinceId: 81 },
  { id: 825, label: "Nuevo Occoro", provinceId: 81 },
  { id: 826, label: "Palca", provinceId: 81 },
  { id: 827, label: "Pilchaca", provinceId: 81 },
  { id: 828, label: "Vilca", provinceId: 81 },
  { id: 829, label: "Yauli", provinceId: 81 },
  { id: 830, label: "Ascensión", provinceId: 81 },
  { id: 831, label: "Huando", provinceId: 81 },

  // DISTRITOS DE HUANCAVELICA - Provincia de Acobamba
  { id: 832, label: "Acobamba", provinceId: 82 },
  { id: 833, label: "Andabamba", provinceId: 82 },
  { id: 834, label: "Anta", provinceId: 82 },
  { id: 835, label: "Caja", provinceId: 82 },
  { id: 836, label: "Marcas", provinceId: 82 },
  { id: 837, label: "Paucara", provinceId: 82 },
  { id: 838, label: "Pomacocha", provinceId: 82 },
  { id: 839, label: "Rosario", provinceId: 82 },

  // DISTRITOS DE HUANCAVELICA - Provincia de Angaraes
  { id: 840, label: "Lircay", provinceId: 83 },
  { id: 841, label: "Anchonga", provinceId: 83 },
  { id: 842, label: "Callanmarca", provinceId: 83 },
  { id: 843, label: "Ccochaccasa", provinceId: 83 },
  { id: 844, label: "Chincho", provinceId: 83 },
  { id: 845, label: "Congalla", provinceId: 83 },
  { id: 846, label: "Huanca-Huanca", provinceId: 83 },
  { id: 847, label: "Huayllay Grande", provinceId: 83 },
  { id: 848, label: "Julcamarca", provinceId: 83 },
  { id: 849, label: "San Antonio de Antaparco", provinceId: 83 },
  { id: 850, label: "Santo Tomas de Pata", provinceId: 83 },
  { id: 851, label: "Secclla", provinceId: 83 },

  // DISTRITOS DE HUANCAVELICA - Provincia de Castrovirreyna
  { id: 852, label: "Castrovirreyna", provinceId: 84 },
  { id: 853, label: "Arma", provinceId: 84 },
  { id: 854, label: "Aurahua", provinceId: 84 },
  { id: 855, label: "Capillas", provinceId: 84 },
  { id: 856, label: "Chupamarca", provinceId: 84 },
  { id: 857, label: "Cocas", provinceId: 84 },
  { id: 858, label: "Huachos", provinceId: 84 },
  { id: 859, label: "Huamatambo", provinceId: 84 },
  { id: 860, label: "Mollepampa", provinceId: 84 },
  { id: 861, label: "San Juan", provinceId: 84 },
  { id: 862, label: "Santa Ana", provinceId: 84 },
  { id: 863, label: "Tantara", provinceId: 84 },
  { id: 864, label: "Ticrapo", provinceId: 84 },

  // DISTRITOS DE HUANCAVELICA - Provincia de Churcampa
  { id: 865, label: "Churcampa", provinceId: 85 },
  { id: 866, label: "Anco", provinceId: 85 },
  { id: 867, label: "Chinchihuasi", provinceId: 85 },
  { id: 868, label: "El Carmen", provinceId: 85 },
  { id: 869, label: "La Merced", provinceId: 85 },
  { id: 870, label: "Locroja", provinceId: 85 },
  { id: 871, label: "Paucarbamba", provinceId: 85 },
  { id: 872, label: "San Miguel de Mayocc", provinceId: 85 },
  { id: 873, label: "San Pedro de Coris", provinceId: 85 },
  { id: 874, label: "Pachamarca", provinceId: 85 },
  { id: 875, label: "Cosme", provinceId: 85 },

  // DISTRITOS DE HUANCAVELICA - Provincia de Huaytará
  { id: 876, label: "Huaytará", provinceId: 86 },
  { id: 877, label: "Ayavi", provinceId: 86 },
  { id: 878, label: "Córdova", provinceId: 86 },
  { id: 879, label: "Huayacundo Arma", provinceId: 86 },
  { id: 880, label: "Laramarca", provinceId: 86 },
  { id: 881, label: "Ocoyo", provinceId: 86 },
  { id: 882, label: "Pilpichaca", provinceId: 86 },
  { id: 883, label: "Querco", provinceId: 86 },
  { id: 884, label: "Quito-Arma", provinceId: 86 },
  { id: 885, label: "San Antonio de Cusicancha", provinceId: 86 },
  { id: 886, label: "San Francisco de Sangayaico", provinceId: 86 },
  { id: 887, label: "San Isidro", provinceId: 86 },
  { id: 888, label: "Santiago de Chocorvos", provinceId: 86 },
  { id: 889, label: "Santiago de Quirahuara", provinceId: 86 },
  { id: 890, label: "Santo Domingo de Capillas", provinceId: 86 },
  { id: 891, label: "Tambo", provinceId: 86 },

  // DISTRITOS DE HUANCAVELICA - Provincia de Tayacaja
  { id: 892, label: "Pampas", provinceId: 87 },
  { id: 893, label: "Acostambo", provinceId: 87 },
  { id: 894, label: "Acraquia", provinceId: 87 },
  { id: 895, label: "Ahuaycha", provinceId: 87 },
  { id: 896, label: "Colcabamba", provinceId: 87 },
  { id: 897, label: "Daniel Hernández", provinceId: 87 },
  { id: 898, label: "Huachocolpa", provinceId: 87 },
  { id: 899, label: "Huaribamba", provinceId: 87 },
  { id: 900, label: "Ñahuimpuquio", provinceId: 87 },
  { id: 901, label: "Pazos", provinceId: 87 },
  { id: 902, label: "Quishuar", provinceId: 87 },
  { id: 903, label: "Salcabamba", provinceId: 87 },
  { id: 904, label: "Salcahuasi", provinceId: 87 },
  { id: 905, label: "San Marcos de Rocchac", provinceId: 87 },
  { id: 906, label: "Surcubamba", provinceId: 87 },
  { id: 907, label: "Tintay Puncu", provinceId: 87 },
  { id: 908, label: "Quichuas", provinceId: 87 },
  { id: 909, label: "Andaymarca", provinceId: 87 },
  { id: 910, label: "Roble", provinceId: 87 },
  { id: 911, label: "Pichos", provinceId: 87 },
  { id: 912, label: "Santiago de Tucuma", provinceId: 87 },

  // DISTRITOS DE HUÁNUCO - Provincia de Huánuco
  { id: 913, label: "Huánuco", provinceId: 88 },
  { id: 914, label: "Amarilis", provinceId: 88 },
  { id: 915, label: "Chinchao", provinceId: 88 },
  { id: 916, label: "Churubamba", provinceId: 88 },
  { id: 917, label: "Margos", provinceId: 88 },
  { id: 918, label: "Quisqui (Kichki)", provinceId: 88 },
  { id: 919, label: "San Francisco de Cayrán", provinceId: 88 },
  { id: 920, label: "San Pedro de Chaulán", provinceId: 88 },
  { id: 921, label: "Santa María del Valle", provinceId: 88 },
  { id: 922, label: "Yarumayo", provinceId: 88 },
  { id: 923, label: "Pillco Marca", provinceId: 88 },
  { id: 924, label: "Yacus", provinceId: 88 },
  { id: 925, label: "San Pablo de Pillao", provinceId: 88 },

  // DISTRITOS DE HUÁNUCO - Provincia de Ambo
  { id: 926, label: "Ambo", provinceId: 89 },
  { id: 927, label: "Cayna", provinceId: 89 },
  { id: 928, label: "Colpas", provinceId: 89 },
  { id: 929, label: "Conchamarca", provinceId: 89 },
  { id: 930, label: "Huacar", provinceId: 89 },
  { id: 931, label: "San Francisco", provinceId: 89 },
  { id: 932, label: "San Rafael", provinceId: 89 },
  { id: 933, label: "Tomay Kichwa", provinceId: 89 },

  // DISTRITOS DE HUÁNUCO - Provincia de Dos de Mayo
  { id: 934, label: "La Unión", provinceId: 90 },
  { id: 935, label: "Chuquis", provinceId: 90 },
  { id: 936, label: "Marías", provinceId: 90 },
  { id: 937, label: "Pachas", provinceId: 90 },
  { id: 938, label: "Quivilla", provinceId: 90 },
  { id: 939, label: "Ripán", provinceId: 90 },
  { id: 940, label: "Shunqui", provinceId: 90 },
  { id: 941, label: "Sillapata", provinceId: 90 },
  { id: 942, label: "Yanas", provinceId: 90 },

  // DISTRITOS DE HUÁNUCO - Provincia de Huacaybamba
  { id: 943, label: "Huacaybamba", provinceId: 91 },
  { id: 944, label: "Canchabamba", provinceId: 91 },
  { id: 945, label: "Cochabamba", provinceId: 91 },
  { id: 946, label: "Pinra", provinceId: 91 },

  // DISTRITOS DE HUÁNUCO - Provincia de Huamalíes
  { id: 947, label: "Llata", provinceId: 92 },
  { id: 948, label: "Arancay", provinceId: 92 },
  { id: 949, label: "Chavín de Pariarca", provinceId: 92 },
  { id: 950, label: "Jacas Grande", provinceId: 92 },
  { id: 951, label: "Jircan", provinceId: 92 },
  { id: 952, label: "Miraflores", provinceId: 92 },
  { id: 953, label: "Monzón", provinceId: 92 },
  { id: 954, label: "Punchao", provinceId: 92 },
  { id: 955, label: "Puños", provinceId: 92 },
  { id: 956, label: "Singa", provinceId: 92 },
  { id: 957, label: "Tantamayo", provinceId: 92 },

  // DISTRITOS DE HUÁNUCO - Provincia de Leoncio Prado
  { id: 958, label: "Rupa-Rupa", provinceId: 93 },
  { id: 959, label: "Daniel Alomía Robles", provinceId: 93 },
  { id: 960, label: "Hermílio Valdizán", provinceId: 93 },
  { id: 961, label: "José Crespo y Castillo", provinceId: 93 },
  { id: 962, label: "Luyando", provinceId: 93 },
  { id: 963, label: "Mariano Dámaso Beraún", provinceId: 93 },
  { id: 964, label: "Pucayacu", provinceId: 93 },
  { id: 965, label: "Castillo Grande", provinceId: 93 },
  { id: 966, label: "Pueblo Nuevo", provinceId: 93 },
  { id: 967, label: "Santo Domingo de Anda", provinceId: 93 },

  // DISTRITOS DE HUÁNUCO - Provincia de Marañón
  { id: 968, label: "Huacrachuco", provinceId: 94 },
  { id: 969, label: "Cholon", provinceId: 94 },
  { id: 970, label: "San Buenaventura", provinceId: 94 },
  { id: 971, label: "La Morada", provinceId: 94 },
  { id: 972, label: "Santa Rosa de Alto Yanajanca", provinceId: 94 },

  // DISTRITOS DE HUÁNUCO - Provincia de Pachitea
  { id: 973, label: "Panao", provinceId: 95 },
  { id: 974, label: "Chaglla", provinceId: 95 },
  { id: 975, label: "Molino", provinceId: 95 },
  { id: 976, label: "Umari", provinceId: 95 },

  // DISTRITOS DE HUÁNUCO - Provincia de Puerto Inca
  { id: 977, label: "Puerto Inca", provinceId: 96 },
  { id: 978, label: "Codo del Pozuzo", provinceId: 96 },
  { id: 979, label: "Honoria", provinceId: 96 },
  { id: 980, label: "Tournavista", provinceId: 96 },
  { id: 981, label: "Yuyapichis", provinceId: 96 },

  // DISTRITOS DE HUÁNUCO - Provincia de Lauricocha
  { id: 982, label: "Jesús", provinceId: 97 },
  { id: 983, label: "Baños", provinceId: 97 },
  { id: 984, label: "Jivia", provinceId: 97 },
  { id: 985, label: "Queropalca", provinceId: 97 },
  { id: 986, label: "Rondos", provinceId: 97 },
  { id: 987, label: "San Francisco de Asís", provinceId: 97 },
  { id: 988, label: "San Miguel de Cauri", provinceId: 97 },

  // DISTRITOS DE HUÁNUCO - Provincia de Yarowilca
  { id: 989, label: "Chavinillo", provinceId: 98 },
  { id: 990, label: "Cahuac", provinceId: 98 },
  { id: 991, label: "Chacabamba", provinceId: 98 },
  { id: 992, label: "Aparicio Pomares", provinceId: 98 },
  { id: 993, label: "Jacas Chico", provinceId: 98 },
  { id: 994, label: "Obas", provinceId: 98 },
  { id: 995, label: "Pampamarca", provinceId: 98 },
  { id: 996, label: "Choras", provinceId: 98 },

  // DISTRITOS DE ICA - Provincia de Ica
  { id: 997, label: "Ica", provinceId: 99 },
  { id: 998, label: "La Tinguiña", provinceId: 99 },
  { id: 999, label: "Los Aquijes", provinceId: 99 },
  { id: 1000, label: "Ocucaje", provinceId: 99 },
  { id: 1001, label: "Pachacutec", provinceId: 99 },
  { id: 1002, label: "Parcona", provinceId: 99 },
  { id: 1003, label: "Pueblo Nuevo", provinceId: 99 },
  { id: 1004, label: "Salas", provinceId: 99 },
  { id: 1005, label: "San José de los Molinos", provinceId: 99 },
  { id: 1006, label: "San Juan Bautista", provinceId: 99 },
  { id: 1007, label: "Santiago", provinceId: 99 },
  { id: 1008, label: "Subtanjalla", provinceId: 99 },
  { id: 1009, label: "Tate", provinceId: 99 },
  { id: 1010, label: "Yauca del Rosario", provinceId: 99 },

  // DISTRITOS DE ICA - Provincia de Chincha
  { id: 1011, label: "Chincha Alta", provinceId: 100 },
  { id: 1012, label: "Alto Larán", provinceId: 100 },
  { id: 1013, label: "Chavín", provinceId: 100 },
  { id: 1014, label: "Chincha Baja", provinceId: 100 },
  { id: 1015, label: "El Carmen", provinceId: 100 },
  { id: 1016, label: "Grocio Prado", provinceId: 100 },
  { id: 1017, label: "Pueblo Nuevo", provinceId: 100 },
  { id: 1018, label: "San Juan de Yanac", provinceId: 100 },
  { id: 1019, label: "San Pedro de Huacarpana", provinceId: 100 },
  { id: 1020, label: "Sunampe", provinceId: 100 },
  { id: 1021, label: "Tambo de Mora", provinceId: 100 },

  // DISTRITOS DE ICA - Provincia de Nasca
  { id: 1022, label: "Nasca", provinceId: 101 },
  { id: 1023, label: "Changuillo", provinceId: 101 },
  { id: 1024, label: "El Ingenio", provinceId: 101 },
  { id: 1025, label: "Marcona", provinceId: 101 },
  { id: 1026, label: "Vista Alegre", provinceId: 101 },

  // DISTRITOS DE ICA - Provincia de Palpa
  { id: 1027, label: "Palpa", provinceId: 102 },
  { id: 1028, label: "Llipata", provinceId: 102 },
  { id: 1029, label: "Río Grande", provinceId: 102 },
  { id: 1030, label: "Santa Cruz", provinceId: 102 },
  { id: 1031, label: "Tibillo", provinceId: 102 },

  // DISTRITOS DE ICA - Provincia de Pisco
  { id: 1032, label: "Pisco", provinceId: 103 },
  { id: 1033, label: "Huancano", provinceId: 103 },
  { id: 1034, label: "Humay", provinceId: 103 },
  { id: 1035, label: "Independencia", provinceId: 103 },
  { id: 1036, label: "Paracas", provinceId: 103 },
  { id: 1037, label: "San Andrés", provinceId: 103 },
  { id: 1038, label: "San Clemente", provinceId: 103 },
  { id: 1039, label: "Tupac Amaru Inca", provinceId: 103 },

  // DISTRITOS DE JUNÍN - Provincia de Junín
  { id: 1040, label: "Junín", provinceId: 108 },
  { id: 1041, label: "Carhuamayo", provinceId: 108 },
  { id: 1042, label: "Ondores", provinceId: 108 },
  { id: 1043, label: "Ulcumayo", provinceId: 108 },

  // DISTRITOS DE JUNÍN - Provincia de Satipo
  { id: 1044, label: "Satipo", provinceId: 109 },
  { id: 1045, label: "Coviriali", provinceId: 109 },
  { id: 1046, label: "Llaylla", provinceId: 109 },
  { id: 1047, label: "Mazamari", provinceId: 109 },
  { id: 1048, label: "Pampa Hermosa", provinceId: 109 },
  { id: 1049, label: "Pangoa", provinceId: 109 },
  { id: 1050, label: "Río Negro", provinceId: 109 },
  { id: 1051, label: "Río Tambo", provinceId: 109 },
  { id: 1052, label: "Vizcatán del Ene", provinceId: 109 },

  // DISTRITOS DE JUNÍN - Provincia de Tarma
  { id: 1053, label: "Tarma", provinceId: 110 },
  { id: 1054, label: "Acobamba", provinceId: 110 },
  { id: 1055, label: "Huaricolca", provinceId: 110 },
  { id: 1056, label: "Huasahuasi", provinceId: 110 },
  { id: 1057, label: "La Unión", provinceId: 110 },
  { id: 1058, label: "Palca", provinceId: 110 },
  { id: 1059, label: "Palcamayo", provinceId: 110 },
  { id: 1060, label: "San Pedro de Cajas", provinceId: 110 },
  { id: 1061, label: "Tapo", provinceId: 110 },

  // DISTRITOS DE JUNÍN - Provincia de Yauli
  { id: 1062, label: "La Oroya", provinceId: 111 },
  { id: 1063, label: "Chacapalpa", provinceId: 111 },
  { id: 1064, label: "Huay-Huay", provinceId: 111 },
  { id: 1065, label: "Marcapomacocha", provinceId: 111 },
  { id: 1066, label: "Morococha", provinceId: 111 },
  { id: 1067, label: "Paccha", provinceId: 111 },
  { id: 1068, label: "Santa Bárbara de Carhuacayan", provinceId: 111 },
  { id: 1069, label: "Santa Rosa de Sacco", provinceId: 111 },
  { id: 1070, label: "Suitucancha", provinceId: 111 },
  { id: 1071, label: "Yauli", provinceId: 111 },

  // DISTRITOS DE JUNÍN - Provincia de Chupaca
  { id: 1072, label: "Chupaca", provinceId: 112 },
  { id: 1073, label: "Ahuac", provinceId: 112 },
  { id: 1074, label: "Chongos Bajo", provinceId: 112 },
  { id: 1075, label: "Huachac", provinceId: 112 },
  { id: 1076, label: "Huamancaca Chico", provinceId: 112 },
  { id: 1077, label: "San Juan de Iscos", provinceId: 112 },
  { id: 1078, label: "San Juan de Jarpa", provinceId: 112 },
  { id: 1079, label: "Tres de Diciembre", provinceId: 112 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Trujillo
  { id: 1080, label: "Trujillo", provinceId: 113 },
  { id: 1081, label: "El Porvenir", provinceId: 113 },
  { id: 1082, label: "Florencia de Mora", provinceId: 113 },
  { id: 1083, label: "Huanchaco", provinceId: 113 },
  { id: 1084, label: "La Esperanza", provinceId: 113 },
  { id: 1085, label: "Laredo", provinceId: 113 },
  { id: 1086, label: "Moche", provinceId: 113 },
  { id: 1087, label: "Poroto", provinceId: 113 },
  { id: 1088, label: "Salaverry", provinceId: 113 },
  { id: 1089, label: "Simbal", provinceId: 113 },
  { id: 1090, label: "Victor Larco Herrera", provinceId: 113 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Ascope
  { id: 1091, label: "Ascope", provinceId: 114 },
  { id: 1092, label: "Chicama", provinceId: 114 },
  { id: 1093, label: "Chocope", provinceId: 114 },
  { id: 1094, label: "Magdalena de Cao", provinceId: 114 },
  { id: 1095, label: "Paiján", provinceId: 114 },
  { id: 1096, label: "Rázuri", provinceId: 114 },
  { id: 1097, label: "Santiago de Cao", provinceId: 114 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Bolívar
  { id: 1098, label: "Bolívar", provinceId: 115 },
  { id: 1099, label: "Bambamarca", provinceId: 115 },
  { id: 1100, label: "Condormarca", provinceId: 115 },
  { id: 1101, label: "Longotea", provinceId: 115 },
  { id: 1102, label: "Uchumarca", provinceId: 115 },
  { id: 1103, label: "Ucuncha", provinceId: 115 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Chepén
  { id: 1104, label: "Chepén", provinceId: 116 },
  { id: 1105, label: "Pacanga", provinceId: 116 },
  { id: 1106, label: "Pueblo Nuevo", provinceId: 116 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Julcán
  { id: 1107, label: "Julcán", provinceId: 117 },
  { id: 1108, label: "Carabamba", provinceId: 117 },
  { id: 1109, label: "Huaso", provinceId: 117 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Otuzco
  { id: 1110, label: "Otuzco", provinceId: 118 },
  { id: 1111, label: "Agallpampa", provinceId: 118 },
  { id: 1112, label: "Charat", provinceId: 118 },
  { id: 1113, label: "Huaranchal", provinceId: 118 },
  { id: 1114, label: "La Cuesta", provinceId: 118 },
  { id: 1115, label: "Mache", provinceId: 118 },
  { id: 1116, label: "Paranday", provinceId: 118 },
  { id: 1117, label: "Salpo", provinceId: 118 },
  { id: 1118, label: "Sinsicap", provinceId: 118 },
  { id: 1119, label: "Usquil", provinceId: 118 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Pacasmayo
  { id: 1120, label: "San Pedro de Lloc", provinceId: 119 },
  { id: 1121, label: "Guadalupe", provinceId: 119 },
  { id: 1122, label: "Jequetepeque", provinceId: 119 },
  { id: 1123, label: "Pacasmayo", provinceId: 119 },
  { id: 1124, label: "San José", provinceId: 119 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Pataz
  { id: 1125, label: "Tayabamba", provinceId: 120 },
  { id: 1126, label: "Buldibuyo", provinceId: 120 },
  { id: 1127, label: "Chillia", provinceId: 120 },
  { id: 1128, label: "Huancaspata", provinceId: 120 },
  { id: 1129, label: "Huaylillas", provinceId: 120 },
  { id: 1130, label: "Huayo", provinceId: 120 },
  { id: 1131, label: "Ongón", provinceId: 120 },
  { id: 1132, label: "Parcoy", provinceId: 120 },
  { id: 1133, label: "Pataz", provinceId: 120 },
  { id: 1134, label: "Pías", provinceId: 120 },
  { id: 1135, label: "Santiago de Challas", provinceId: 120 },
  { id: 1136, label: "Taurija", provinceId: 120 },
  { id: 1137, label: "Urpay", provinceId: 120 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Sánchez Carrión
  { id: 1138, label: "Huamachuco", provinceId: 121 },
  { id: 1139, label: "Chugay", provinceId: 121 },
  { id: 1140, label: "Cochorco", provinceId: 121 },
  { id: 1141, label: "Curgos", provinceId: 121 },
  { id: 1142, label: "Marcabal", provinceId: 121 },
  { id: 1143, label: "Sanagorán", provinceId: 121 },
  { id: 1144, label: "Sarin", provinceId: 121 },
  { id: 1145, label: "Sartimbamba", provinceId: 121 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Santiago de Chuco
  { id: 1146, label: "Santiago de Chuco", provinceId: 122 },
  { id: 1147, label: "Angasmarca", provinceId: 122 },
  { id: 1148, label: "Cachicadán", provinceId: 122 },
  { id: 1149, label: "Mollebamba", provinceId: 122 },
  { id: 1150, label: "Mollepata", provinceId: 122 },
  { id: 1151, label: "Quiruvilca", provinceId: 122 },
  { id: 1152, label: "Santa Cruz de Chuca", provinceId: 122 },
  { id: 1153, label: "Sitabamba", provinceId: 122 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Gran Chimú
  { id: 1154, label: "Cascas", provinceId: 123 },
  { id: 1155, label: "Lucma", provinceId: 123 },
  { id: 1156, label: "Marmot", provinceId: 123 },
  { id: 1157, label: "Sayapullo", provinceId: 123 },

  // DISTRITOS DE LA LIBERTAD - Provincia de Virú
  { id: 1158, label: "Virú", provinceId: 124 },
  { id: 1159, label: "Chao", provinceId: 124 },
  { id: 1160, label: "Guadalupito", provinceId: 124 },

  // DISTRITOS DE LAMBAYEQUE - Provincia de Chiclayo
  { id: 1161, label: "Chiclayo", provinceId: 125 },
  { id: 1162, label: "Chongoyape", provinceId: 125 },
  { id: 1163, label: "Eten", provinceId: 125 },
  { id: 1164, label: "Eten Puerto", provinceId: 125 },
  { id: 1165, label: "José Leonardo Ortiz", provinceId: 125 },
  { id: 1166, label: "La Victoria", provinceId: 125 },
  { id: 1167, label: "Lagunas", provinceId: 125 },
  { id: 1168, label: "Monsefú", provinceId: 125 },
  { id: 1169, label: "Nueva Arica", provinceId: 125 },
  { id: 1170, label: "Oyotún", provinceId: 125 },
  { id: 1171, label: "Pátapo", provinceId: 125 },
  { id: 1172, label: "Picsi", provinceId: 125 },
  { id: 1173, label: "Pimentel", provinceId: 125 },
  { id: 1174, label: "Reque", provinceId: 125 },
  { id: 1175, label: "Santa Rosa", provinceId: 125 },
  { id: 1176, label: "Saña", provinceId: 125 },
  { id: 1177, label: "Cayaltí", provinceId: 125 },

  // DISTRITOS DE LAMBAYEQUE - Provincia de Ferreñafe
  { id: 1178, label: "Ferreñafe", provinceId: 126 },
  { id: 1179, label: "Cañaris", provinceId: 126 },
  { id: 1180, label: "Incahuasi", provinceId: 126 },
  { id: 1181, label: "Manuel Antonio Mesones Muro", provinceId: 126 },

  // DISTRITOS DE LAMBAYEQUE - Provincia de Lambayeque
  { id: 1182, label: "Lambayeque", provinceId: 127 },
  { id: 1183, label: "Chochope", provinceId: 127 },
  { id: 1184, label: "Illimo", provinceId: 127 },
  { id: 1185, label: "Jayanca", provinceId: 127 },
  { id: 1186, label: "Mochumi", provinceId: 127 },
  { id: 1187, label: "Mórrope", provinceId: 127 },
  { id: 1188, label: "Pacora", provinceId: 127 },
  { id: 1189, label: "Salas", provinceId: 127 },
  { id: 1190, label: "San José", provinceId: 127 },
  { id: 1191, label: "Tucume", provinceId: 127 },

  // DISTRITOS DE LIMA - Provincia de Lima
  { id: 1192, label: "Lima", provinceId: 128 },
  { id: 1193, label: "Ancón", provinceId: 128 },
  { id: 1194, label: "Ate", provinceId: 128 },
  { id: 1195, label: "Barranco", provinceId: 128 },
  { id: 1196, label: "Breña", provinceId: 128 },
  { id: 1197, label: "Carabayllo", provinceId: 128 },
  { id: 1198, label: "Chaclacayo", provinceId: 128 },
  { id: 1199, label: "Chorrillos", provinceId: 128 },
  { id: 1200, label: "Cieneguilla", provinceId: 128 },
  { id: 1201, label: "Comas", provinceId: 128 },
  { id: 1202, label: "El Agustino", provinceId: 128 },
  { id: 1203, label: "Independencia", provinceId: 128 },
  { id: 1204, label: "Jesús María", provinceId: 128 },
  { id: 1205, label: "La Molina", provinceId: 128 },
  { id: 1206, label: "La Victoria", provinceId: 128 },
  { id: 1207, label: "Lince", provinceId: 128 },
  { id: 1208, label: "Los Olivos", provinceId: 128 },
  { id: 1209, label: "Lurigancho", provinceId: 128 },
  { id: 1210, label: "Lurín", provinceId: 128 },
  { id: 1211, label: "Magdalena del Mar", provinceId: 128 },
  { id: 1212, label: "Miraflores", provinceId: 128 },
  { id: 1213, label: "Pachacámac", provinceId: 128 },
  { id: 1214, label: "Pucusana", provinceId: 128 },
  { id: 1215, label: "Pueblo Libre", provinceId: 128 },
  { id: 1216, label: "Puente Piedra", provinceId: 128 },
  { id: 1217, label: "Punta Hermosa", provinceId: 128 },
  { id: 1218, label: "Punta Negra", provinceId: 128 },
  { id: 1219, label: "Rímac", provinceId: 128 },
  { id: 1220, label: "San Bartolo", provinceId: 128 },
  { id: 1221, label: "San Borja", provinceId: 128 },
  { id: 1222, label: "San Isidro", provinceId: 128 },
  { id: 1223, label: "San Juan de Lurigancho", provinceId: 128 },
  { id: 1224, label: "San Juan de Miraflores", provinceId: 128 },
  { id: 1225, label: "San Luis", provinceId: 128 },
  { id: 1226, label: "San Martín de Porres", provinceId: 128 },
  { id: 1227, label: "San Miguel", provinceId: 128 },
  { id: 1228, label: "Santa Anita", provinceId: 128 },
  { id: 1229, label: "Santa María del Mar", provinceId: 128 },
  { id: 1230, label: "Santa Rosa", provinceId: 128 },
  { id: 1231, label: "Santiago de Surco", provinceId: 128 },
  { id: 1232, label: "Surquillo", provinceId: 128 },
  { id: 1233, label: "Villa El Salvador", provinceId: 128 },
  { id: 1234, label: "Villa María del Triunfo", provinceId: 128 },
  // agregar departamentos a los distritos de Lima
  { id: 1752, label: "Callao", provinceId: 128 },
  { id: 1753, label: "Áncash", provinceId: 128 },
  { id: 1754, label: "Apurímac", provinceId: 128 },
  { id: 1755, label: "Arequipa", provinceId: 128 },
  { id: 1756, label: "Ayacucho", provinceId: 128 },
  { id: 1757, label: "Cajamarca", provinceId: 128 },
  { id: 1758, label: "Cusco", provinceId: 128 },
  { id: 1759, label: "Huancavelica", provinceId: 128 },
  { id: 1760, label: "Huánuco", provinceId: 128 },
  { id: 1761, label: "Ica", provinceId: 128 },
  { id: 1762, label: "Junín", provinceId: 128 },
  { id: 1763, label: "La Libertad", provinceId: 128 },
  { id: 1764, label: "Lambayeque", provinceId: 128 },
  { id: 1765, label: "Lima", provinceId: 128 },
  { id: 1766, label: "Loreto", provinceId: 128 },
  { id: 1767, label: "Madre de Dios", provinceId: 128 },
  { id: 1768, label: "Moquegua", provinceId: 128 },
  { id: 1769, label: "Pasco", provinceId: 128 },
  { id: 1770, label: "Piura", provinceId: 128 },
  { id: 1771, label: "Puno", provinceId: 128 },
  { id: 1772, label: "San Martín", provinceId: 128 },
  { id: 1773, label: "Tacna", provinceId: 128 },
  { id: 1774, label: "Tumbes", provinceId: 128 },
  { id: 1775, label: "Ucayali", provinceId: 128 },
  { id: 1776, label: "Amazonas", provinceId: 128 },

  // DISTRITOS DE LIMA - Provincia de Barranca
  { id: 1235, label: "Barranca", provinceId: 129 },
  { id: 1236, label: "Paramonga", provinceId: 129 },
  { id: 1237, label: "Pativilca", provinceId: 129 },
  { id: 1238, label: "Supe", provinceId: 129 },
  { id: 1239, label: "Supe Puerto", provinceId: 129 },

  // DISTRITOS DE LIMA - Provincia de Cajatambo
  { id: 1240, label: "Cajatambo", provinceId: 130 },
  { id: 1241, label: "Copa", provinceId: 130 },
  { id: 1242, label: "Gorgor", provinceId: 130 },
  { id: 1243, label: "Huancapón", provinceId: 130 },
  { id: 1244, label: "Manás", provinceId: 130 },

  // DISTRITOS DE LIMA - Provincia de Canta
  { id: 1245, label: "Canta", provinceId: 131 },
  { id: 1246, label: "Arahuay", provinceId: 131 },
  { id: 1247, label: "Huamantanga", provinceId: 131 },
  { id: 1248, label: "Huaros", provinceId: 131 },
  { id: 1249, label: "Lachaqui", provinceId: 131 },
  { id: 1250, label: "San Buenaventura", provinceId: 131 },
  { id: 1251, label: "Santa Rosa de Quives", provinceId: 131 },

  // DISTRITOS DE LIMA - Provincia de Cañete
  { id: 1252, label: "San Vicente de Cañete", provinceId: 132 },
  { id: 1253, label: "Asia", provinceId: 132 },
  { id: 1254, label: "Calango", provinceId: 132 },
  { id: 1255, label: "Cerro Azul", provinceId: 132 },
  { id: 1256, label: "Chilca", provinceId: 132 },
  { id: 1257, label: "Coayllo", provinceId: 132 },
  { id: 1258, label: "Imperial", provinceId: 132 },
  { id: 1259, label: "Lunahuaná", provinceId: 132 },
  { id: 1260, label: "Mala", provinceId: 132 },
  { id: 1261, label: "Nuevo Imperial", provinceId: 132 },
  { id: 1262, label: "Pacarán", provinceId: 132 },
  { id: 1263, label: "Quilmaná", provinceId: 132 },
  { id: 1264, label: "San Antonio", provinceId: 132 },
  { id: 1265, label: "San Luis", provinceId: 132 },
  { id: 1266, label: "Santa Cruz de Flores", provinceId: 132 },
  { id: 1267, label: "Zúñiga", provinceId: 132 },

  // DISTRITOS DE LIMA - Provincia de Huaral
  { id: 1268, label: "Huaral", provinceId: 133 },
  { id: 1269, label: "Atavillos Alto", provinceId: 133 },
  { id: 1270, label: "Atavillos Bajo", provinceId: 133 },
  { id: 1271, label: "Aucallama", provinceId: 133 },
  { id: 1272, label: "Chancay", provinceId: 133 },
  { id: 1273, label: "Ihuari", provinceId: 133 },
  { id: 1274, label: "Lampián", provinceId: 133 },
  { id: 1275, label: "Pacaraos", provinceId: 133 },
  { id: 1276, label: "San Miguel de Acos", provinceId: 133 },
  { id: 1277, label: "Santa Cruz de Andamarca", provinceId: 133 },
  { id: 1278, label: "Sumbilca", provinceId: 133 },
  { id: 1279, label: "Veintisiete de Noviembre", provinceId: 133 },

  // DISTRITOS DE LIMA - Provincia de Huarochirí
  { id: 1280, label: "Matucana", provinceId: 134 },
  { id: 1281, label: "Antioquía", provinceId: 134 },
  { id: 1282, label: "Callahuanca", provinceId: 134 },
  { id: 1283, label: "Carampoma", provinceId: 134 },
  { id: 1284, label: "Chicla", provinceId: 134 },
  { id: 1285, label: "Cuenca", provinceId: 134 },
  { id: 1286, label: "Huachupampa", provinceId: 134 },
  { id: 1287, label: "Huanza", provinceId: 134 },
  { id: 1288, label: "Huarochirí", provinceId: 134 },
  { id: 1289, label: "Lahuaytambo", provinceId: 134 },
  { id: 1290, label: "Langa", provinceId: 134 },
  { id: 1291, label: "Laraos", provinceId: 134 },
  { id: 1292, label: "Mariatana", provinceId: 134 },
  { id: 1293, label: "Ricardo Palma", provinceId: 134 },
  { id: 1294, label: "San Andrés de Tupicocha", provinceId: 134 },
  { id: 1295, label: "San Antonio", provinceId: 134 },
  { id: 1296, label: "San Bartolomé", provinceId: 134 },
  { id: 1297, label: "San Damian", provinceId: 134 },
  { id: 1298, label: "San Juan de Iris", provinceId: 134 },
  { id: 1299, label: "San Juan de Tantaranche", provinceId: 134 },
  { id: 1300, label: "San Lorenzo de Quinti", provinceId: 134 },
  { id: 1301, label: "San Mateo", provinceId: 134 },
  { id: 1302, label: "San Mateo de Otao", provinceId: 134 },
  { id: 1303, label: "San Pedro de Casta", provinceId: 134 },
  { id: 1304, label: "San Pedro de Huancayre", provinceId: 134 },
  { id: 1305, label: "Sangallaya", provinceId: 134 },
  { id: 1306, label: "Santa Cruz de Cocachacra", provinceId: 134 },
  { id: 1307, label: "Santa Eulalia", provinceId: 134 },
  { id: 1308, label: "Santiago de Anchucaya", provinceId: 134 },
  { id: 1309, label: "Santiago de Tuna", provinceId: 134 },
  { id: 1310, label: "Santo Domingo de los Olleros", provinceId: 134 },
  { id: 1311, label: "Surco", provinceId: 134 },
  { id: 1312, label: "Viñac", provinceId: 134 },

  // DISTRITOS DE LIMA - Provincia de Huaura
  { id: 1313, label: "Huacho", provinceId: 135 },
  { id: 1314, label: "Ambar", provinceId: 135 },
  { id: 1315, label: "Caleta de Carquín", provinceId: 135 },
  { id: 1316, label: "Checras", provinceId: 135 },
  { id: 1317, label: "Hualmay", provinceId: 135 },
  { id: 1318, label: "Huaura", provinceId: 135 },
  { id: 1319, label: "Leoncio Prado", provinceId: 135 },
  { id: 1320, label: "Paccho", provinceId: 135 },
  { id: 1321, label: "Santa Leonor", provinceId: 135 },
  { id: 1322, label: "Santa María", provinceId: 135 },
  { id: 1323, label: "Sayan", provinceId: 135 },
  { id: 1324, label: "Vegueta", provinceId: 135 },

  // DISTRITOS DE LIMA - Provincia de Oyón
  { id: 1325, label: "Oyón", provinceId: 136 },
  { id: 1326, label: "Andajes", provinceId: 136 },
  { id: 1327, label: "Caujul", provinceId: 136 },
  { id: 1328, label: "Cochamarca", provinceId: 136 },
  { id: 1329, label: "Naván", provinceId: 136 },
  { id: 1330, label: "Pachangara", provinceId: 136 },

  // DISTRITOS DE LIMA - Provincia de Yauyos
  { id: 1331, label: "Yauyos", provinceId: 137 },
  { id: 1332, label: "Alis", provinceId: 137 },
  { id: 1333, label: "Allauca", provinceId: 137 },
  { id: 1334, label: "Ayaviri", provinceId: 137 },
  { id: 1335, label: "Azángaro", provinceId: 137 },
  { id: 1336, label: "Cacra", provinceId: 137 },
  { id: 1337, label: "Carania", provinceId: 137 },
  { id: 1338, label: "Catahuasi", provinceId: 137 },
  { id: 1339, label: "Chocos", provinceId: 137 },
  { id: 1340, label: "Cochas", provinceId: 137 },
  { id: 1341, label: "Colonia", provinceId: 137 },
  { id: 1342, label: "Hongos", provinceId: 137 },
  { id: 1343, label: "Huampara", provinceId: 137 },
  { id: 1344, label: "Huancaya", provinceId: 137 },
  { id: 1345, label: "Huangáscar", provinceId: 137 },
  { id: 1346, label: "Huantán", provinceId: 137 },
  { id: 1347, label: "Huañec", provinceId: 137 },
  { id: 1348, label: "Laraos", provinceId: 137 },
  { id: 1349, label: "Lincha", provinceId: 137 },
  { id: 1350, label: "Madean", provinceId: 137 },
  { id: 1351, label: "Miraflores", provinceId: 137 },
  { id: 1352, label: "Omas", provinceId: 137 },
  { id: 1353, label: "Putinza", provinceId: 137 },
  { id: 1354, label: "Quinches", provinceId: 137 },
  { id: 1355, label: "Quinocay", provinceId: 137 },
  { id: 1356, label: "San Joaquín", provinceId: 137 },
  { id: 1357, label: "San Pedro de Pilas", provinceId: 137 },
  { id: 1358, label: "Tanta", provinceId: 137 },
  { id: 1359, label: "Tauripampa", provinceId: 137 },
  { id: 1360, label: "Tomas", provinceId: 137 },
  { id: 1361, label: "Tupe", provinceId: 137 },
  { id: 1362, label: "Viñac", provinceId: 137 },
  { id: 1363, label: "Vitis", provinceId: 137 },

  // DISTRITOS DE LORETO - Provincia de Maynas
  { id: 1364, label: "Maynas", provinceId: 138 },
  { id: 1365, label: "Iquitos", provinceId: 138 },
  { id: 1366, label: "Alto Nanay", provinceId: 138 },
  { id: 1367, label: "Fernando Lores", provinceId: 138 },
  { id: 1368, label: "Indiana", provinceId: 138 },
  { id: 1369, label: "Las Amazonas", provinceId: 138 },
  { id: 1370, label: "Mazán", provinceId: 138 },
  { id: 1371, label: "Napo", provinceId: 138 },
  { id: 1372, label: "Punchana", provinceId: 138 },
  { id: 1373, label: "Torres Causana", provinceId: 138 },
  { id: 1374, label: "Belén", provinceId: 138 },
  { id: 1375, label: "San Juan Bautista", provinceId: 138 },

  // DISTRITOS DE LORETO - Provincia de Alto Amazonas
  { id: 1376, label: "Alto Amazonas", provinceId: 139 },
  { id: 1377, label: "Yurimaguas", provinceId: 139 },
  { id: 1378, label: "Balsapuerto", provinceId: 139 },
  { id: 1379, label: "Jeberos", provinceId: 139 },
  { id: 1380, label: "Lagunas", provinceId: 139 },
  { id: 1381, label: "Santa Cruz", provinceId: 139 },

  // DISTRITOS DE LORETO - Provincia de Loreto
  { id: 1382, label: "Loreto", provinceId: 140 },
  { id: 1383, label: "Nauta", provinceId: 140 },
  { id: 1384, label: "Parinari", provinceId: 140 },
  { id: 1385, label: "Tigre", provinceId: 140 },
  { id: 1386, label: "Trompeteros", provinceId: 140 },
  { id: 1387, label: "Urarinas", provinceId: 140 },
  { id: 1388, label: "Manantay", provinceId: 140 },
  { id: 1389, label: "Ramón Castilla", provinceId: 140 },
  { id: 1390, label: "Mazán", provinceId: 140 },

  // DISTRITOS DE LORETO - Provincia de Mariscal Ramón Castilla
  { id: 1391, label: "Mariscal Ramón Castilla", provinceId: 141 },
  { id: 1392, label: "Pebas", provinceId: 141 },
  { id: 1393, label: "Yavari", provinceId: 141 },
  { id: 1394, label: "San Pablo", provinceId: 141 },
  { id: 1395, label: "Yavarí", provinceId: 141 },

  // DISTRITOS DE LORETO - Provincia de Requena
  { id: 1396, label: "Requena", provinceId: 142 },
  { id: 1397, label: "Alto Tapiche", provinceId: 142 },
  { id: 1398, label: "Capelo", provinceId: 142 },
  { id: 1399, label: "Emilio San Martín", provinceId: 142 },
  { id: 1400, label: "Maquia", provinceId: 142 },
  { id: 1401, label: "Puinahua", provinceId: 142 },
  { id: 1402, label: "Saquena", provinceId: 142 },
  { id: 1403, label: "Soplin Vargas", provinceId: 142 },
  { id: 1404, label: "Tapiche", provinceId: 142 },
  { id: 1405, label: "Jenaro Herrera", provinceId: 142 },
  { id: 1406, label: "Yaquerana", provinceId: 142 },

  // DISTRITOS DE LORETO - Provincia de Ucayali
  { id: 1407, label: "Ucayali", provinceId: 143 },
  { id: 1408, label: "Contamana", provinceId: 143 },
  { id: 1409, label: "Inahuaya", provinceId: 143 },
  { id: 1410, label: "Padre Márquez", provinceId: 143 },
  { id: 1411, label: "Pampa Hermosa", provinceId: 143 },
  { id: 1412, label: "Sarayacu", provinceId: 143 },
  { id: 1413, label: "Vargas Guerra", provinceId: 143 },

  // DISTRITOS DE LORETO - Provincia de Datem del Marañón
  { id: 1414, label: "Datem del Marañón", provinceId: 144 },
  { id: 1415, label: "Barranca", provinceId: 144 },
  { id: 1416, label: "Cahuapanas", provinceId: 144 },
  { id: 1417, label: "Manseriche", provinceId: 144 },
  { id: 1418, label: "Morona", provinceId: 144 },
  { id: 1419, label: "Pastaza", provinceId: 144 },
  { id: 1420, label: "Andoas", provinceId: 144 },

  // DISTRITOS DE LORETO - Provincia de Putumayo
  { id: 1421, label: "Putumayo", provinceId: 145 },
  { id: 1422, label: "Putumayo", provinceId: 145 },
  { id: 1423, label: "Rosa Panduro", provinceId: 145 },
  { id: 1424, label: "Teniente Manuel Clavero", provinceId: 145 },

  // DISTRITOS DE MADRE DE DIOS - Provincia de Tambopata
  { id: 1425, label: "Tambopata", provinceId: 146 },
  { id: 1426, label: "Tambopata", provinceId: 146 },
  { id: 1427, label: "Inambari", provinceId: 146 },
  { id: 1428, label: "Laberinto", provinceId: 146 },

  // DISTRITOS DE MADRE DE DIOS - Provincia de Manu
  { id: 1429, label: "Manu", provinceId: 147 },
  { id: 1430, label: "Fitzcarrald", provinceId: 147 },
  { id: 1431, label: "Madre de Dios", provinceId: 147 },

  // DISTRITOS DE MADRE DE DIOS - Provincia de Tahuamanu
  { id: 1432, label: "Tahuamanu", provinceId: 148 },
  { id: 1433, label: "Iñapari", provinceId: 148 },

  // DISTRITOS DE MOQUEGUA - Provincia de Mariscal Nieto
  { id: 1434, label: "Moquegua", provinceId: 149 },
  { id: 1435, label: "Carumas", provinceId: 149 },
  { id: 1436, label: "Cuchumbaya", provinceId: 149 },
  { id: 1437, label: "Samegua", provinceId: 149 },
  { id: 1438, label: "San Cristóbal", provinceId: 149 },
  { id: 1439, label: "Torata", provinceId: 149 },

  // DISTRITOS DE MOQUEGUA - Provincia de General Sánchez Cerro
  { id: 1440, label: "Omate", provinceId: 150 },
  { id: 1441, label: "Chojata", provinceId: 150 },
  { id: 1442, label: "Coalaque", provinceId: 150 },
  { id: 1443, label: "Ichuña", provinceId: 150 },
  { id: 1444, label: "La Capilla", provinceId: 150 },
  { id: 1445, label: "Lloque", provinceId: 150 },
  { id: 1446, label: "Matalaque", provinceId: 150 },
  { id: 1447, label: "Puquina", provinceId: 150 },

  // DISTRITOS DE MOQUEGUA - Provincia de Ilo
  { id: 1448, label: "Ilo", provinceId: 151 },
  { id: 1449, label: "El Algarrobal", provinceId: 151 },
  { id: 1450, label: "Pacocha", provinceId: 151 },

  // DISTRITOS DE PASCO - Provincia de Pasco
  { id: 1451, label: "Cerro de Pasco", provinceId: 152 },
  { id: 1452, label: "Chaupimarca", provinceId: 152 },
  { id: 1453, label: "Huachón", provinceId: 152 },
  { id: 1454, label: "Huariaca", provinceId: 152 },
  { id: 1455, label: "Huayllay", provinceId: 152 },
  { id: 1456, label: "Ninacaca", provinceId: 152 },
  { id: 1457, label: "Pallanchacra", provinceId: 152 },
  { id: 1458, label: "Paucartambo", provinceId: 152 },
  { id: 1459, label: "San Francisco de Asís de Yarusyacán", provinceId: 152 },
  { id: 1460, label: "Simón Bolívar", provinceId: 152 },
  { id: 1461, label: "Ticlacayán", provinceId: 152 },
  { id: 1462, label: "Tinyahuarco", provinceId: 152 },
  { id: 1463, label: "Vicco", provinceId: 152 },
  { id: 1464, label: "Yanacancha", provinceId: 152 },

  // DISTRITOS DE PASCO - Provincia de Daniel Alcides Carrión
  { id: 1465, label: "Yanahuanca", provinceId: 153 },
  { id: 1466, label: "Chacayán", provinceId: 153 },
  { id: 1467, label: "Goyllarisquizga", provinceId: 153 },
  { id: 1468, label: "Paucar", provinceId: 153 },
  { id: 1469, label: "San Pedro de Pillao", provinceId: 153 },
  { id: 1470, label: "Santa Ana de Tusi", provinceId: 153 },
  { id: 1471, label: "Tapuc", provinceId: 153 },

  // DISTRITOS DE PASCO - Provincia de Oxapampa
  { id: 1472, label: "Oxapampa", provinceId: 154 },
  { id: 1473, label: "Chontabamba", provinceId: 154 },
  { id: 1474, label: "Huancabamba", provinceId: 154 },
  { id: 1475, label: "Palcazú", provinceId: 154 },
  { id: 1476, label: "Pozuzo", provinceId: 154 },
  { id: 1477, label: "Puerto Bermúdez", provinceId: 154 },
  { id: 1478, label: "Villa Rica", provinceId: 154 },

  // DISTRITOS DE PIURA - Provincia de Piura
  { id: 1479, label: "Piura", provinceId: 155 },
  { id: 1480, label: "Castilla", provinceId: 155 },
  { id: 1481, label: "Catacaos", provinceId: 155 },
  { id: 1482, label: "Cura Mori", provinceId: 155 },
  { id: 1483, label: "El Tallán", provinceId: 155 },
  { id: 1484, label: "La Arena", provinceId: 155 },
  { id: 1485, label: "La Unión", provinceId: 155 },
  { id: 1486, label: "Las Lomas", provinceId: 155 },
  { id: 1487, label: "Tambo Grande", provinceId: 155 },

  // DISTRITOS DE PIURA - Provincia de Ayabaca
  { id: 1488, label: "Ayabaca", provinceId: 156 },
  { id: 1489, label: "Frias", provinceId: 156 },
  { id: 1490, label: "Jililí", provinceId: 156 },
  { id: 1491, label: "Lagunas", provinceId: 156 },
  { id: 1492, label: "Montero", provinceId: 156 },
  { id: 1493, label: "Pacaipampa", provinceId: 156 },
  { id: 1494, label: "Paimas", provinceId: 156 },
  { id: 1495, label: "Sapillica", provinceId: 156 },

  // DISTRITOS DE PIURA - Provincia de Huancabamba
  { id: 1496, label: "Huancabamba", provinceId: 157 },
  { id: 1497, label: "Canchaque", provinceId: 157 },
  { id: 1498, label: "El Carmen de la Frontera", provinceId: 157 },
  { id: 1499, label: "Huarmaca", provinceId: 157 },
  { id: 1500, label: "Lalaquiz", provinceId: 157 },
  { id: 1501, label: "San Miguel de El Faique", provinceId: 157 },

  // DISTRITOS DE PIURA - Provincia de Morropón
  { id: 1502, label: "Chulucanas", provinceId: 158 },
  { id: 1503, label: "Buenos Aires", provinceId: 158 },
  { id: 1504, label: "Chalaco", provinceId: 158 },
  { id: 1505, label: "La Matanza", provinceId: 158 },
  { id: 1506, label: "Morropón", provinceId: 158 },
  { id: 1507, label: "Salitral", provinceId: 158 },

  // DISTRITOS DE PIURA - Provincia de Paita
  { id: 1508, label: "Paita", provinceId: 159 },
  { id: 1509, label: "Amotape", provinceId: 159 },
  { id: 1510, label: "Arenal", provinceId: 159 },
  { id: 1511, label: "Colán", provinceId: 159 },
  { id: 1512, label: "La Huaca", provinceId: 159 },
  { id: 1513, label: "Tamarindo", provinceId: 159 },

  // DISTRITOS DE PIURA - Provincia de Sullana
  { id: 1514, label: "Sullana", provinceId: 160 },
  { id: 1515, label: "Bellavista", provinceId: 160 },
  { id: 1516, label: "Ignacio Escudero", provinceId: 160 },
  { id: 1517, label: "Lancones", provinceId: 160 },
  { id: 1518, label: "Marcavelica", provinceId: 160 },
  { id: 1519, label: "Miguel Checa", provinceId: 160 },
  { id: 1520, label: "Querecotillo", provinceId: 160 },
  { id: 1521, label: "Salitral", provinceId: 160 },

  // DISTRITOS DE PIURA - Provincia de Talara
  { id: 1522, label: "Pariñas", provinceId: 161 },
  { id: 1523, label: "El Alto", provinceId: 161 },
  { id: 1524, label: "La Brea", provinceId: 161 },
  { id: 1525, label: "Lobitos", provinceId: 161 },
  { id: 1526, label: "Los Organos", provinceId: 161 },

  // DISTRITOS DE PIURA - Provincia de Sechura
  { id: 1527, label: "Sechura", provinceId: 162 },
  { id: 1528, label: "Bellavista de la Unión", provinceId: 162 },
  { id: 1529, label: "Bernal", provinceId: 162 },
  { id: 1530, label: "Cristo Nos Valga", provinceId: 162 },
  { id: 1531, label: "Vice", provinceId: 162 },
  { id: 1532, label: "Rinconada Llicuar", provinceId: 162 },
  { id: 1533, label: "Bellavista de la Unión", provinceId: 162 },

  // DISTRITOS DE PUNO - Provincia de Puno
  { id: 1534, label: "Puno", provinceId: 163 },
  { id: 1535, label: "Acora", provinceId: 163 },
  { id: 1536, label: "Amantani", provinceId: 163 },
  { id: 1537, label: "Atuncolla", provinceId: 163 },
  { id: 1538, label: "Capachica", provinceId: 163 },
  { id: 1539, label: "Chucuito", provinceId: 163 },
  { id: 1540, label: "Coata", provinceId: 163 },
  { id: 1541, label: "Huata", provinceId: 163 },
  { id: 1542, label: "Mañazo", provinceId: 163 },
  { id: 1543, label: "Paucarcolla", provinceId: 163 },
  { id: 1544, label: "Pichacani", provinceId: 163 },
  { id: 1545, label: "Plateria", provinceId: 163 },
  { id: 1546, label: "San Antonio", provinceId: 163 },
  { id: 1547, label: "Tiquillaca", provinceId: 163 },
  { id: 1548, label: "Vilque", provinceId: 163 },

  // DISTRITOS DE PUNO - Provincia de Azángaro
  { id: 1549, label: "Azángaro", provinceId: 164 },
  { id: 1550, label: "Achaya", provinceId: 164 },
  { id: 1551, label: "Arapa", provinceId: 164 },
  { id: 1552, label: "Asillo", provinceId: 164 },
  { id: 1553, label: "Caminaca", provinceId: 164 },
  { id: 1554, label: "Chupa", provinceId: 164 },
  { id: 1555, label: "José Domingo Choquehuanca", provinceId: 164 },
  { id: 1556, label: "Muñani", provinceId: 164 },
  { id: 1557, label: "Potoni", provinceId: 164 },
  { id: 1558, label: "Saman", provinceId: 164 },
  { id: 1559, label: "San Anton", provinceId: 164 },
  { id: 1560, label: "San José", provinceId: 164 },
  { id: 1561, label: "San Juan de Salinas", provinceId: 164 },
  { id: 1562, label: "Santiago de Pupuja", provinceId: 164 },
  { id: 1563, label: "Tirapata", provinceId: 164 },

  // DISTRITOS DE PUNO - Provincia de Carabaya
  { id: 1564, label: "Macusani", provinceId: 165 },
  { id: 1565, label: "Ajoyani", provinceId: 165 },
  { id: 1566, label: "Ayapata", provinceId: 165 },
  { id: 1567, label: "Coasa", provinceId: 165 },
  { id: 1568, label: "Corani", provinceId: 165 },
  { id: 1569, label: "Crucero", provinceId: 165 },
  { id: 1570, label: "Ituata", provinceId: 165 },
  { id: 1571, label: "Ollachea", provinceId: 165 },
  { id: 1572, label: "San Gaban", provinceId: 165 },
  { id: 1573, label: "Usicayos", provinceId: 165 },

  // DISTRITOS DE PUNO - Provincia de Chucuito
  { id: 1574, label: "Juli", provinceId: 166 },
  { id: 1575, label: "Desaguadero", provinceId: 166 },
  { id: 1576, label: "Huacullani", provinceId: 166 },
  { id: 1577, label: "Kelluyo", provinceId: 166 },
  { id: 1578, label: "Pisacoma", provinceId: 166 },
  { id: 1579, label: "Pomata", provinceId: 166 },
  { id: 1580, label: "Zepita", provinceId: 166 },

  // DISTRITOS DE PUNO - Provincia de El Collao
  { id: 1581, label: "Ilave", provinceId: 167 },
  { id: 1582, label: "Capazo", provinceId: 167 },
  { id: 1583, label: "Pilcuyo", provinceId: 167 },
  { id: 1584, label: "Santa Rosa", provinceId: 167 },

  // DISTRITOS DE PUNO - Provincia de Huancané
  { id: 1585, label: "Huancané", provinceId: 168 },
  { id: 1586, label: "Cojata", provinceId: 168 },
  { id: 1587, label: "Huatasani", provinceId: 168 },
  { id: 1588, label: "Inchupalla", provinceId: 168 },
  { id: 1589, label: "Pusi", provinceId: 168 },
  { id: 1590, label: "Rosaspata", provinceId: 168 },
  { id: 1591, label: "Taraco", provinceId: 168 },
  { id: 1592, label: "Vilque Chico", provinceId: 168 },

  // DISTRITOS DE PUNO - Provincia de Lampa
  { id: 1593, label: "Lampa", provinceId: 169 },
  { id: 1594, label: "Cabanilla", provinceId: 169 },
  { id: 1595, label: "Calapuja", provinceId: 169 },
  { id: 1596, label: "Nicacani", provinceId: 169 },
  { id: 1597, label: "Ocuviri", provinceId: 169 },
  { id: 1598, label: "Palca", provinceId: 169 },
  { id: 1599, label: "Paratía", provinceId: 169 },
  { id: 1600, label: "Pucará", provinceId: 169 },
  { id: 1601, label: "Santa Lucía", provinceId: 169 },

  // DISTRITOS DE PUNO - Provincia de Melgar
  { id: 1602, label: "Ayaviri", provinceId: 170 },
  { id: 1603, label: "Antauta", provinceId: 170 },
  { id: 1604, label: "Cupi", provinceId: 170 },
  { id: 1605, label: "Llalli", provinceId: 170 },
  { id: 1606, label: "Macari", provinceId: 170 },
  { id: 1607, label: "Nuñoa", provinceId: 170 },
  { id: 1608, label: "Orurillo", provinceId: 170 },
  { id: 1609, label: "Santa Rosa", provinceId: 170 },
  { id: 1610, label: "Umachiri", provinceId: 170 },

  // DISTRITOS DE PUNO - Provincia de Moho
  { id: 1611, label: "Moho", provinceId: 171 },
  { id: 1612, label: "Conima", provinceId: 171 },
  { id: 1613, label: "Huayrapata", provinceId: 171 },
  { id: 1614, label: "Tilali", provinceId: 171 },

  // DISTRITOS DE PUNO - Provincia de San Antonio de Putina
  { id: 1615, label: "Putina", provinceId: 172 },
  { id: 1616, label: "Ananea", provinceId: 172 },
  { id: 1617, label: "Pedro Vilca Apaza", provinceId: 172 },
  { id: 1618, label: "Quilcapuncu", provinceId: 172 },

  // DISTRITOS DE PUNO - Provincia de San Román
  { id: 1619, label: "Juliaca", provinceId: 173 },
  { id: 1620, label: "Cabana", provinceId: 173 },
  { id: 1621, label: "Cabanillas", provinceId: 173 },
  { id: 1622, label: "Caracoto", provinceId: 173 },

  // DISTRITOS DE PUNO - Provincia de Sandia
  { id: 1623, label: "Sandia", provinceId: 174 },
  { id: 1624, label: "Cuyocuyo", provinceId: 174 },
  { id: 1625, label: "Limbani", provinceId: 174 },
  { id: 1626, label: "Patambuco", provinceId: 174 },
  { id: 1627, label: "Phara", provinceId: 174 },
  { id: 1628, label: "Quiaca", provinceId: 174 },
  { id: 1629, label: "San Juan del Oro", provinceId: 174 },
  { id: 1630, label: "Yanahuaya", provinceId: 174 },

  // DISTRITOS DE PUNO - Provincia de Yunguyo
  { id: 1631, label: "Yunguyo", provinceId: 175 },
  { id: 1632, label: "Anapia", provinceId: 175 },
  { id: 1633, label: "Copani", provinceId: 175 },
  { id: 1634, label: "Cuturapi", provinceId: 175 },
  { id: 1635, label: "Ollaraya", provinceId: 175 },
  { id: 1636, label: "Tinicachi", provinceId: 175 },
  { id: 1637, label: "Unicachi", provinceId: 175 },

  // DISTRITOS DE SAN MARTÍN - Provincia de Moyobamba
  { id: 1638, label: "Moyobamba", provinceId: 176 },
  { id: 1639, label: "Calzada", provinceId: 176 },
  { id: 1640, label: "Habana", provinceId: 176 },
  { id: 1641, label: "Jepelacio", provinceId: 176 },
  { id: 1642, label: "Soritor", provinceId: 176 },
  { id: 1643, label: "Yantalo", provinceId: 176 },

  // DISTRITOS DE SAN MARTÍN - Provincia de Bellavista
  { id: 1644, label: "Bellavista", provinceId: 177 },
  { id: 1645, label: "Alto Biavo", provinceId: 177 },
  { id: 1646, label: "Bajo Biavo", provinceId: 177 },
  { id: 1647, label: "Huallaga", provinceId: 177 },
  { id: 1648, label: "San Pablo", provinceId: 177 },

  // DISTRITOS DE SAN MARTÍN - Provincia de El Dorado
  { id: 1649, label: "San José de Sisa", provinceId: 178 },
  { id: 1650, label: "Agua Blanca", provinceId: 178 },
  { id: 1651, label: "San Martín", provinceId: 178 },

  // DISTRITOS DE SAN MARTÍN - Provincia de Huallaga
  { id: 1652, label: "Saposoa", provinceId: 179 },
  { id: 1653, label: "Alto Saposoa", provinceId: 179 },
  { id: 1654, label: "El Eslabón", provinceId: 179 },
  { id: 1655, label: "Piscoyacu", provinceId: 179 },
  { id: 1656, label: "Sacanche", provinceId: 179 },
  { id: 1657, label: "Tingo de Saposoa", provinceId: 179 },

  // DISTRITOS DE SAN MARTÍN - Provincia de Lamas
  { id: 1658, label: "Lamas", provinceId: 180 },
  { id: 1659, label: "Alonso de Alvarado", provinceId: 180 },
  { id: 1660, label: "Barranquita", provinceId: 180 },
  { id: 1661, label: "Caynarachi", provinceId: 180 },
  { id: 1662, label: "Cuñumbuqui", provinceId: 180 },
  { id: 1663, label: "Pinto Recodo", provinceId: 180 },
  { id: 1664, label: "Rumisapa", provinceId: 180 },
  { id: 1665, label: "San Roque de Cumbaza", provinceId: 180 },
  { id: 1666, label: "Shanao", provinceId: 180 },

  // DISTRITOS DE SAN MARTÍN - Provincia de Mariscal Cáceres
  { id: 1667, label: "Juanjuí", provinceId: 181 },
  { id: 1668, label: "Campanilla", provinceId: 181 },
  { id: 1669, label: "Huicungo", provinceId: 181 },
  { id: 1670, label: "Pachiza", provinceId: 181 },

  // DISTRITOS DE SAN MARTÍN - Provincia de Picota
  { id: 1671, label: "Picota", provinceId: 182 },
  { id: 1672, label: "Buenos Aires", provinceId: 182 },
  { id: 1673, label: "Caspisapa", provinceId: 182 },
  { id: 1674, label: "Pilluana", provinceId: 182 },
  { id: 1675, label: "Pucacaca", provinceId: 182 },

  // DISTRITOS DE SAN MARTÍN - Provincia de Rioja
  { id: 1676, label: "Rioja", provinceId: 183 },
  { id: 1677, label: "Awajun", provinceId: 183 },
  { id: 1678, label: "Elias Soplin Vargas", provinceId: 183 },
  { id: 1679, label: "Nueva Cajamarca", provinceId: 183 },
  { id: 1680, label: "Pardo Miguel", provinceId: 183 },
  { id: 1681, label: "Posic", provinceId: 183 },
  { id: 1682, label: "San Fernando", provinceId: 183 },
  { id: 1683, label: "Yorongos", provinceId: 183 },
  { id: 1684, label: "Yuracyacu", provinceId: 183 },

  // DISTRITOS DE SAN MARTÍN - Provincia de San Martín
  { id: 1685, label: "Tarapoto", provinceId: 184 },
  { id: 1686, label: "Alberto Leveau", provinceId: 184 },
  { id: 1687, label: "Cacatachi", provinceId: 184 },
  { id: 1688, label: "Chazuta", provinceId: 184 },
  { id: 1689, label: "Chipurana", provinceId: 184 },
  { id: 1690, label: "El Porvenir", provinceId: 184 },
  { id: 1691, label: "Huimbayoc", provinceId: 184 },
  { id: 1692, label: "Juan Guerra", provinceId: 184 },
  { id: 1693, label: "La Banda de Shilcayo", provinceId: 184 },
  { id: 1694, label: "Morales", provinceId: 184 },
  { id: 1695, label: "Papaplaya", provinceId: 184 },
  { id: 1696, label: "San Antonio", provinceId: 184 },
  { id: 1697, label: "Sauce", provinceId: 184 },
  { id: 1698, label: "Shapaja", provinceId: 184 },

  // DISTRITOS DE SAN MARTÍN - Provincia de Tocache
  { id: 1699, label: "Tocache", provinceId: 185 },
  { id: 1700, label: "Nuevo Progreso", provinceId: 185 },
  { id: 1701, label: "Polvora", provinceId: 185 },
  { id: 1702, label: "Shunte", provinceId: 185 },
  { id: 1703, label: "Uchiza", provinceId: 185 },

  // DISTRITOS DE TACNA - Provincia de Tacna
  { id: 1704, label: "Tacna", provinceId: 186 },
  { id: 1705, label: "Alto de la Alianza", provinceId: 186 },
  { id: 1706, label: "Calana", provinceId: 186 },
  { id: 1707, label: "Ciudad Nueva", provinceId: 186 },
  { id: 1708, label: "Inclán", provinceId: 186 },
  { id: 1709, label: "Pachía", provinceId: 186 },
  { id: 1710, label: "Palca", provinceId: 186 },
  { id: 1711, label: "Pocollay", provinceId: 186 },
  { id: 1712, label: "Sama", provinceId: 186 },
  { id: 1713, label: "La Yarada los Palos", provinceId: 186 },

  // DISTRITOS DE TACNA - Provincia de Candarave
  { id: 1714, label: "Candarave", provinceId: 187 },
  { id: 1715, label: "Cairani", provinceId: 187 },
  { id: 1716, label: "Camilaca", provinceId: 187 },
  { id: 1717, label: "Curibaya", provinceId: 187 },
  { id: 1718, label: "Huanuara", provinceId: 187 },

  // DISTRITOS DE TACNA - Provincia de Jorge Basadre
  { id: 1719, label: "Locumba", provinceId: 188 },
  { id: 1720, label: "Ilabaya", provinceId: 188 },
  { id: 1721, label: "Ite", provinceId: 188 },

  // DISTRITOS DE TACNA - Provincia de Tarata
  { id: 1722, label: "Tarata", provinceId: 189 },
  { id: 1723, label: "Heroes Albarracín", provinceId: 189 },
  { id: 1724, label: "Estique", provinceId: 189 },
  { id: 1725, label: "Sitajara", provinceId: 189 },

  // DISTRITOS DE TUMBES - Provincia de Tumbes
  { id: 1726, label: "Tumbes", provinceId: 190 },
  { id: 1727, label: "Corrales", provinceId: 190 },
  { id: 1728, label: "La Cruz", provinceId: 190 },
  { id: 1729, label: "Pampas de Hospital", provinceId: 190 },
  { id: 1730, label: "San Jacinto", provinceId: 190 },
  { id: 1731, label: "San Juan de la Virgen", provinceId: 190 },

  // DISTRITOS DE TUMBES - Provincia de Contralmirante Villar
  { id: 1732, label: "Zorritos", provinceId: 191 },
  { id: 1733, label: "Casitas", provinceId: 191 },
  { id: 1734, label: "Canoas de Punta Sal", provinceId: 191 },

  // DISTRITOS DE TUMBES - Provincia de Zarumilla
  { id: 1735, label: "Zarumilla", provinceId: 192 },
  { id: 1736, label: "Aguas Verdes", provinceId: 192 },
  { id: 1737, label: "Matapalo", provinceId: 192 },
  { id: 1738, label: "Papayal", provinceId: 192 },

  // DISTRITOS DE UCAYALI - Provincia de Coronel Portillo
  { id: 1739, label: "Calleria", provinceId: 193 },
  { id: 1740, label: "Campoverde", provinceId: 193 },
  { id: 1741, label: "Iparia", provinceId: 193 },
  { id: 1742, label: "Masisea", provinceId: 193 },
  { id: 1743, label: "Yarinacocha", provinceId: 193 },

  // DISTRITOS DE UCAYALI - Provincia de Atalaya
  { id: 1744, label: "Raymondi", provinceId: 194 },
  { id: 1745, label: "Sepahua", provinceId: 194 },
  { id: 1746, label: "Tahuania", provinceId: 194 },
  { id: 1747, label: "Yurua", provinceId: 194 },

  // DISTRITOS DE UCAYALI - Provincia de Padre Abad
  { id: 1748, label: "Padre Abad", provinceId: 195 },
  { id: 1749, label: "Irazola", provinceId: 195 },
  { id: 1750, label: "Curimana", provinceId: 195 },

  // DISTRITOS DE UCAYALI - Provincia de Purús
  { id: 1751, label: "Purús", provinceId: 196 },
];
