import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import editIcon from "../../assets/images/profile/profile-details-edit-icon.png";
import ProfileItem from "../profile-item/ProfileItem";
import { useNavigate } from "react-router-dom";
import Loading from "../loading/Loading";

interface ProfileDetailsProps {
  label: string;
  description: string;
  items: ProfileItems[];
  loading: boolean;
  profileType: string;
  nonEditable?: boolean;
}

export interface ProfileItems {
  icon: string;
  value: string;
  blurInfo?: boolean;
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({
  label,
  description,
  items,
  loading,
  profileType,
  nonEditable,
}) => {
  const navigate = useNavigate();

  const handleEditInformation = () => {
    profileType === "company"
      ? navigate("/company/information")
      : navigate("/applicant/information");
  };

  return (
    <div className="text-white p-0">
      <Row>
        <Col xs={10} md={10} lg={10} className="text-start p-0">
          {label}
        </Col>
        <Col xs={2} md={2} lg={2} className="text-end">
          {!nonEditable && (
            <Image
              alt="edit-icon"
              src={editIcon}
              width={18}
              height={18}
              className="cursor-pointer"
              onClick={handleEditInformation}
            />
          )}
        </Col>
      </Row>
      <hr className="hr-profile-details" />
      <Row className="pt-2 pb-3 item-description">{description}</Row>
      {loading ? (
        <Loading variant={"white"} />
      ) : (
        items.map((item, index) => (
          <ProfileItem
            key={index}
            icon={item.icon}
            value={item.value}
            blurInfo={item.blurInfo}
          />
        ))
      )}
    </div>
  );
};

export default ProfileDetails;
