import React from "react";
import { Col, Row } from "react-bootstrap";
import ProfileInformation from "../profile-information/ProfileInformation";
import ApplicantCurriculum from "./applicant-curriculum/ApplicantCurriculum";
import ApplicantActivity from "./applicant-activity/ApplicantActivity";
import "./Applicant.css";
import ApplicantActions from "./applicant-actions/ApplicantActions";
import ApplicationsSummarized from "./applications-summarized/ApplicationsSummarized";

const Applicant: React.FC = () => {
  return (
    <div className="p-4 applicant-section">
      <Row>
        <Col md={4} lg={4}>
          <ProfileInformation profileType={"applicant"} />
          <ApplicationsSummarized />
        </Col>
        <Col md={6} lg={6}>
          <ApplicantCurriculum />
        </Col>
        <Col md={2} lg={2}>
          <ApplicantActivity />
          <ApplicantActions />
        </Col>
      </Row>
    </div>
  );
};

export default Applicant;
