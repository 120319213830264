import { Button, Row } from "react-bootstrap";
import DotsDecorator from "../../home/dots-decorator/DotsDecorator";
import { Advertisement } from "../Company";
import React from "react";
import AdvertisementItem from "../advertisement-item/AdvertisementItem";
import { useNavigate } from "react-router-dom";
import { PaginationControl } from "react-bootstrap-pagination-control";

interface CompanyAdvertisementsProps {
  advertisements: Advertisement[];
  page: number;
  total: number;
  limit: number;
  changePage: (p: number) => void;
  loadAdvertisements: () => Promise<void>;
}

const CompanyAdvertisements: React.FC<CompanyAdvertisementsProps> = ({
  advertisements,
  page,
  total,
  limit,
  changePage,
  loadAdvertisements,
}) => {
  const navigate = useNavigate();

  const handleAddAdvertisement = () => {
    navigate("/add-advertisement");
  };

  return (
    <div className="content-card p-4">
      <Row className="justify-content-center">
        <h4 className="text-center">Avisos disponibles</h4>
        <DotsDecorator width={10} height={10} center={true} />
      </Row>
      {advertisements.length === 0 ? (
        <div className="content-card mt-4 p-3 text-center">
          Aún no cuentas con anuncios disponibles
        </div>
      ) : (
        advertisements.map((item) => (
          <AdvertisementItem
            key={item.id}
            id={item.id}
            title={item.title}
            description={item.description}
            seniority={item.seniority || "professional"}
            urlCode={item.urlCode}
            createdAt={item.createdAt}
            daysToExpire={item.daysToExpire}
            planName={item.planName}
            loadAdvertisements={loadAdvertisements}
          />
        ))
      )}
      <div className="mt-4">
        <PaginationControl
          page={page}
          total={total}
          limit={limit}
          changePage={(page) => {            
            changePage(page);
          }}
          ellipsis={1}
        />
      </div>
      <div className="justify-content-center mt-4 text-center">
        <Button
          variant="third"
          className="ps-4 pe-4 p-2"
          onClick={handleAddAdvertisement}
        >
          Publica un nuevo anuncio laboral
        </Button>
      </div>
    </div>
  );
};

export default CompanyAdvertisements;
