import { apiUrls } from "../common/api-urls";
import { APIMiddleware } from "../middleware/api-middleware";
import { UpdateUserRequest } from "../types/request/update-user-request";

const { USERS_URL } = apiUrls;

export function getUsers(page: number, take: number, filter: string) {
  const url = `${USERS_URL}?page=${page}&take=${take}&filter=${filter}`;
  return APIMiddleware.get(url);
}

export function getUser(id: number) {
  const url = `${USERS_URL}/${id}`;
  return APIMiddleware.get(url);
}

export function updateUser(id: number, req: UpdateUserRequest) {
  const url = `${USERS_URL}/${id}`;
  return APIMiddleware.patch(url, {
    data: {
      firstName: req.firstName,
      lastName: req.lastName,
      role: req.role,
      isExcelence: req.isExcelence,
    },
  });
}

export function removeUser(id: number) {
  const url = `${USERS_URL}/${id}`;
  return APIMiddleware.delete(url);
}
